var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('AppointmentsGrid', {
    key: _vm.key,
    on: {
      "forceUpdate": function forceUpdate($event) {
        _vm.key++;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }