var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, table-row@3, button"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading ? _c('div', [_c('v-card-title', [_vm._v(" Заявка (#" + _vm._s(_vm.id) + ") "), _c('div', {
    staticClass: "ml-5"
  }, [_c('v-btn', {
    staticClass: "mx-4",
    attrs: {
      "small": "",
      "color": "primary",
      "outlined": "",
      "to": "/"
    }
  }, [_vm._v("Все заявки")])], 1)]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "8"
    }
  }, [_c('v-simple-table', {
    attrs: {
      "hide-default-footer": true
    }
  }, [[_c('tbody', [_c('tr', [_c('td', [_vm._v("ID")]), _c('td', [_vm._v(_vm._s(_vm.request.id))])]), _c('tr', [_c('td', [_vm._v("Статус")]), _c('td', [_c('v-chip', {
    attrs: {
      "color": _vm.request.state.chipColor,
      "outlined": "",
      "dark": ""
    }
  }, [_vm.request.appointmentApproved ? _c('v-icon', {
    attrs: {
      "small": "",
      "left": ""
    }
  }, [_vm._v("mdi-check")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.request.state.text) + " ")], 1)], 1)]), _vm.request.appointmentDate ? _c('tr', [_c('td', [_vm._v(" Запись ")]), _c('td', [_c('v-row', [_c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_vm._v(" " + _vm._s(_vm.request.appointmentDate) + " ")]), _c('v-col', [_vm._v(" " + _vm._s(_vm.request.appointmentTime.text) + " ")])], 1), _vm.request.office ? _c('v-row', [_c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_vm._v(" " + _vm._s(_vm.request.office) + " ")])], 1) : _vm._e()], 1)]) : _vm._e(), _vm.request.source && _vm.$helper.permission.canSeeOverview(_vm.user) && _vm.$helper.permission.canSeeSourceOfRequest(_vm.user) ? _c('tr', [_c('td', [_vm._v("Источник")]), _c('td', [_vm._v(_vm._s(_vm.request.source.name))])]) : _vm._e(), _vm.$helper.permission.canSeeOverview(_vm.user) ? _c('tr', [_c('td', [_vm._v("Создана")]), _c('td', [_vm._v(_vm._s(_vm.request.createdAt))])]) : _vm._e(), _vm.$helper.permission.canSeeOverview(_vm.user) ? _c('tr', [_c('td', [_vm._v("Распределена")]), _c('td', [_vm._v(_vm._s(_vm.request.assignedAt))])]) : _vm._e(), _vm.request.operator ? _c('tr', [_c('td', [_vm._v("Оператор")]), _c('td', [_vm._v(_vm._s(_vm.request.operator.fullName))])]) : _vm._e(), _vm.request.userPhone && _vm.$helper.permission.canSeeOverview(_vm.user) ? _c('tr', [_c('td', [_vm._v("Телефон")]), _c('td', [_vm._v(_vm._s(_vm.request.userPhone))])]) : _vm._e(), _vm.request.userName ? _c('tr', [_c('td', [_vm._v("Имя клиента")]), _c('td', [_vm._v(_vm._s(_vm.request.userName))])]) : _vm._e(), _vm.request.userEmail ? _c('tr', [_c('td', [_vm._v("Email клиента")]), _c('td', [_vm._v(_vm._s(_vm.request.userEmail))])]) : _vm._e(), _c('tr', [_c('td', [_vm._v("Проблема")]), _c('td', {
    staticClass: "py-3"
  }, [_vm._v(_vm._s(_vm.request.problem))])])])]], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "4"
    }
  }, [_vm.$helper.permission.canEditRequest(_vm.user) ? _c('v-btn', {
    staticClass: "mr-4",
    attrs: {
      "color": "primary",
      "outlined": "",
      "to": "/requests/".concat(_vm.id, "/edit")
    }
  }, [_vm._v("Редактировать")]) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm.request ? _c('RequestComments', {
    attrs: {
      "id": _vm.request.id
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }