<template>
    <div>
        <v-card-title>
            Редактирование договора
            <div class="text-right mx-3 button-close-edit">
                <v-btn text color="error" @click="$emit('closeEdit')" icon>
                    <v-icon large>mdi-close-circle-outline</v-icon>
                </v-btn>
                <v-btn
                    icon
                    @click="$emit('forceUpdateForm')"
                    v-if="contract !== null"
                    class="ml-4">
                    <v-icon>mdi-reload</v-icon>
                </v-btn>
            </div>

            <div class="ml-2 my-3">
                <v-btn color="deep-purple darken-2" @click="reminderDialogVisible = true">
                    <v-icon left small>mdi-calendar-clock</v-icon>
                    <div>Добавить напоминание</div>
                </v-btn>
                <ReminderDialog :contractId="id" :dialogVisible="reminderDialogVisible" @close="reminderDialogVisible = false" @save="onRemindAdd"></ReminderDialog>
            </div>

            <div
                class="ml-3 pl-2 my-3"
                v-if="contract !== null && $helper.permission.canSendContractForDocumentsPreparation(user)">
                <v-btn color="pink darken-2" @click="sendForDocumentsPreparation()">
                    <div>Отправить на подготовку документов</div>
                </v-btn>
            </div>
            <div
                class="ml-3 pl-2 my-3"
                v-if="contract !== null && $helper.permission.canSetDocumentsAreReadyAt(user)">
                <v-btn color="green accent-3" @click="setDocumentsAreReadyAt()">
                    <div>Документы готовы к выдаче</div>
                </v-btn>
            </div>
            <div
                class="ml-3 pl-2 my-3"
                v-if="contract !== null && $helper.permission.canSetTransferredToOGPAAt(user)">
                <v-btn color="teal darken-1" @click="setDosudebkaEPO()">
                    <div>Досудебка ЭПО</div>
                </v-btn>
            </div>
            <div
                class="ml-3 pl-2 my-3"
                v-if="contract !== null && $helper.permission.canSetTransferredToOGPAAt(user)">
                <v-btn color="teal darken-1" @click="setTransferredToCourt()">
                    <div>Передано на подачу в суд</div>
                </v-btn>
            </div>
            <div
                class="ml-3 pl-2 my-3"
                v-if="contract !== null && $helper.permission.canSetTransferredToOGPAAt(user)">
                <v-btn color="deep-purple lighten-1" @click="setSentToCourt()">
                    <div>Подано в суд</div>
                </v-btn>
            </div>
            <div
                class="ml-3 pl-2 my-3"
                v-if="contract !== null && $helper.permission.canSetTransferredToOGPAAt(user)">
                <v-btn color="yellow accent-2 black--text" @click="setTransferredToOGPAAt()">
                    <div>Передать в ОГПиА</div>
                </v-btn>
            </div>
            <div
                class="ml-3 pl-2 my-3"
                v-if="contract !== null && $helper.permission.canSetTransferredToOGPAAt(user)">
                <v-btn color="deep-purple lighten-1" @click="setPackageFromLenyr()">
                    <div>Пакет от ЛенЮр</div>
                </v-btn>
            </div>
            <div
                class="ml-3 pl-2 my-3"
                v-if="contract !== null && $helper.permission.canSetTransferredToOGPAAt(user)">
                <v-btn color="deep-purple lighten-1" @click="setSentToOKK()">
                    <div>Передать в ОКК</div>
                </v-btn>
            </div>
            <div
                class="ml-3 pl-2 my-3"
                v-if="contract !== null && $helper.permission.canSetTransferredToOGPAAt(user)">
                <v-btn color="orange darken-4" @click="setAdditionalPackageIsReady()">
                    <div>Доп. пакет готов к выдаче</div>
                </v-btn>
            </div>

            <div class="ml-4">
                <v-btn v-if="prevContract" fab text color="white darken-2" @click="changeEdited(prevContract.toString())">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn v-if="nextContract" fab text color="white darken-2" @click="changeEdited(nextContract.toString())">
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </div>
        </v-card-title>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader
                        type="heading, table-row@3, button"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <div v-if="!loading">
            <ContractForm
                    v-if="contract !== null"
                    :page="page"
                    @updated="$emit('updated')"
                    @closeAndUpdate="$emit('closeAndUpdate')"
                    @recallSelected="onRecallSelected"
                    :key="formKey"></ContractForm>
        </div>
        <ScrollToTop
            v-if="inPopup"
            alwaysShow
            style="right: 40px"
            :elementSelector="scrollToTopSelector"
        ></ScrollToTop>
    </div>
</template>

<script>
    import ContractForm from "./ContractForm";
    import {mapState} from 'vuex';
    import ReminderDialog from "./../reminders/ReminderDialog";
    import ContractService from "../_services/ContractService";
    import ScrollToTop from "@/_components/ScrollToTop.vue";

    export default {
        name: 'ContractUpdatePage',
        components: {ScrollToTop, ContractForm, ReminderDialog},
        props: {
            id: String,
            nextContract: Number,
            prevContract: Number,
            inPopup: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                contract: null,
                page: null,
                loading: true,
                reminderDialogVisible: false,
                formKey: 0
            }
        },
        methods: {
            fetch() {
                this.fetchContract();
            },
            fetchContract() {
                ContractService.getForm(this.id).then(data => {
                    this.page = data;
                    this.contract = data.contract;
                    this.loading = false;
                    this.formKey++;
                }, error => {
                    this.flashError(this.$helper.showError(error));
                });
            },
            deleteItem(id) {
                ContractService.delete(id).then(() => {
                    this.$emit('closeAndUpdate')
                });
            },
            onRemindAdd() {
                this.reminderDialogVisible = false;
                this.flashSuccess(`Напоминание добавлено`);
            },
            onRecallSelected() {
                this.reminderDialogVisible = true
            },
            changeEdited(id) {
                this.$emit("changeEdited", id);
            },
            sendForDocumentsPreparation() {
                ContractService.sendForDocumentsPreparation(this.id).then(() => {
                    this.fetch();
                }, error => {
                    this.flashError(this.$helper.showError(error));
                });
            },
            setDocumentsAreReadyAt() {
                ContractService.setDocumentsAreReadyAt(this.id).then(() => {
                    this.fetch();
                }, error => {
                    this.flashError(this.$helper.showError(error));
                });
            },
            setTransferredToOGPAAt() {
                ContractService.setTransferredToOGPAAt(this.id).then(() => {
                    this.fetch();
                }, error => {
                    this.flashError(this.$helper.showError(error));
                });
            },
            setTransferredToCourt() {
                ContractService.setTransferredToCourt(this.id).then(() => {
                    this.fetch();
                }, error => {
                    this.flashError(this.$helper.showError(error));
                });
            },
            setSentToCourt() {
                ContractService.setSetSentToCourt(this.id).then(() => {
                    this.fetch();
                }, error => {
                    this.flashError(this.$helper.showError(error));
                });
            },
            setPackageFromLenyr() {
                ContractService.setPackageFromLenyr(this.id).then(() => {
                    this.fetch();
                }, error => {
                    this.flashError(this.$helper.showError(error));
                });
            },
            setSentToOKK() {
                ContractService.setSentToOKK(this.id).then(() => {
                    this.fetch();
                }, error => {
                    this.flashError(this.$helper.showError(error));
                });
            },
            setAdditionalPackageIsReady() {
                ContractService.setAdditionalPackageIsReady(this.id).then(() => {
                    this.fetch();
                }, error => {
                    this.flashError(this.$helper.showError(error));
                });
            },
            setDosudebkaEPO() {
                ContractService.setDosudebkaEPO(this.id).then(() => {
                    this.fetch();
                }, error => {
                    this.flashError(this.$helper.showError(error));
                });
            },
            scrollToTopSelector() {
                return document.querySelector('.v-dialog--active .v-card__text');
            }
        },
        computed: {
            ...mapState(['user'])
        },
        created() {
            this.fetch();
        }
    };
</script>

<style lang="scss" scoped>
    .v-card__title {
        .v-btn {
            text-transform: none;
            padding: 0 15px;
        }
    }
</style>