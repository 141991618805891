<template>
    <div>
        <v-card-title>
            Список сотрудников
            <v-btn
                icon
                @click="forceUpdate">
                <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <div>
                <v-btn large color="green" to="/users/create">
                    Добавить сотрудника
                </v-btn>
            </div>
        </v-card-title>
        <v-data-table
            class="elevation-1"
            fixedHeader
            :height="$helper.calcTableHeight()"
            :headers="headers"
            :items="users"
            :options.sync="userGridOptions.options"
            :server-items-length="totalElements"
            item-key="id"
            :loading="loading"
            loading-text="Загрузка ..."
            :footer-props="{'items-per-page-options':[20, 30, 50]}">
            <template v-slot:body="{ items }">
                <tbody>
                <tr class="row_sticky">
                    <td>
                        <v-text-field v-model="userGridOptions.filter.id" type="text"></v-text-field>
                    </td>
                    <td width="20">
                        <v-select
                            v-model="userGridOptions.filter.active"
                            :items="checkboxValues"
                            clearable
                            item-value="value"
                            item-text="text"
                        ></v-select>
                    </td>
                    <td></td>
                    <td>
                        <v-text-field
                            v-model="userGridOptions.filter.firstName"
                            type="text">
                        </v-text-field>
                    </td>
                    <td>
                        <v-text-field
                            v-model="userGridOptions.filter.lastName"
                            type="text">
                        </v-text-field>
                    </td>
                    <td>
                        <v-text-field
                            v-model="userGridOptions.filter.phone"
                            type="text">
                        </v-text-field>
                    </td>
                    <td>
                        <DateField
                            v-model="userGridOptions.filter.birthday"
                            clearable>
                        </DateField>
                    </td>
                    <td>
                        <v-select
                            v-model="userGridOptions.filter.roles"
                            multiple
                            :items="roles"
                            clearable>
                        </v-select>
                    </td>
                    <td>
                        <v-select
                            v-model="userGridOptions.filter.outOfPlace"
                            :items="checkboxValues"
                            clearable
                            item-value="value"
                            item-text="text">
                        </v-select>
                    </td>
                    <td></td>
                </tr>
                <tr v-for="item in items" :key="item.id">
                    <td width="60">{{ item.id }}</td>
                    <td>
                        <v-icon
                            v-if="item.active"
                            small
                            color="green">
                            mdi-check
                        </v-icon>
                    </td>
                    <td width="60">
                        {{ $moment(item.createdAt).dateFormat() }}
                    </td>
                    <td width="60">
                        {{ item.firstName }}
                    </td>
                    <td width="60">
                        {{ item.lastName }}
                    </td>
                    <td width="100">
                        {{ item.phone }}
                    </td>
                    <td width="60">
                        {{ $moment(item.birthday).dateFormat() }}
                    </td>
                    <td width="60">
                        {{ item.roleDesc }}
                    </td>
                    <td width="60">
                        <v-switch
                            v-model="item.outOfPlace"
                            :disabled="$helper.permission.canSetOutOfPlace(user)"
                            @change="changeOutOfOffice(item.id, item.outOfPlace)"
                            hide-details>
                        </v-switch>
                    </td>
                    <td width="60">
                        <div v-if="$helper.permission.canEditUsers(user)">
                            <v-icon small @click="$router.push(`/users/${item.id}/edit`)">mdi-pencil</v-icon>
                            <v-dialog v-model="deleteDialog[item.id]" max-width="320">
                                <template v-slot:activator="{ on }">
                                    <v-icon class="ml-4" small color="red" v-on="on">mdi-delete</v-icon>
                                </template>
                                <v-card>
                                    <v-card-text class="text-center pt-7">Удалить выбранный элемент?</v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn text @click="deleteDialog[item.id] = false">Отмена</v-btn>
                                        <v-btn color="red" text @click="deleteItem(item.id)">Удалить</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </div>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import UserService from "../_services/UserService";
import DateField from "@/_chunks/DateField.vue";
import {mapState} from "vuex";

export default {
    name: "UsersGrid",
    components: {DateField},
    data() {
        return {
            headers: [
                {text: 'ID', value: 'id'},
                {text: 'Показывать', value: 'active'},
                {text: 'Создан', value: 'createdAt'},
                {text: 'Имя', value: 'firstName'},
                {text: 'Фамилия', value: 'lastName'},
                {text: 'Телефон', value: 'phone'},
                {text: 'День рождения', value: 'birthday'},
                {text: 'Роль', value: 'role'},
                {text: 'Не на месте', value: 'outOfPlace'},
                {text: '', value: ''},
            ],
            checkboxValues: [
                {value: true, text: 'Да'},
                {value: false, text: 'Нет'}
            ],
            users: [],
            roles: [],
            loading: true,
            userGridOptions: this.$store.state.userGridOptions,
            totalElements: 0,
            deleteDialog: {}
        }
    },
    watch: {
        userGridOptions: {
            handler() {
                this.fetchGrid();
            },
            deep: true
        },
    },
    methods: {
        forceUpdate() {
            this.fetchGrid();
        },
        fetch() {
            this.fetchRoles();
        },
        fetchRoles() {
            UserService.getRoles().then(data => {
                this.roles = data;
            });
        },
        fetchGrid() {
            this.loading = true;
            UserService.getAllWithNotActive(this.userGridOptions.options, this.userGridOptions.filter).then(data => {
                this.users = data.content;
                this.totalElements = data.totalElements;
                this.loading = false;
            });
        },
        deleteItem(id) {
            UserService.delete(id).then(() => {
                this.fetchGrid();
            });
        },
        changeOutOfOffice(userId, value) {
            console.log(userId);
            console.log(value);
            UserService.updateOutOfPlace(userId, value);
        },
    },
    computed: {
        ...mapState(['user'])
    },
    created() {
        this.fetch();
        this.$store.dispatch('loading', false);
    },
    beforeCreate() {
        this.$store.dispatch('loading', true);
    }
};
</script>
