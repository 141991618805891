var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-title', [_vm._v(" Настройки ")]), _vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, table-row@3, button"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading ? _c('v-card', [_c('v-card-text', [_c('form', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "8"
    }
  }, [_c('h2', [_vm._v("Отчеты")]), _c('h3', {
    staticClass: "my-4"
  }, [_vm._v("Юр отдел")]), _c('h4', {
    staticClass: "my-3"
  }, [_vm._v("План дня")]), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "План дня"
    },
    model: {
      value: _vm.form.reporting.planDay,
      callback: function callback($$v) {
        _vm.$set(_vm.form.reporting, "planDay", $$v);
      },
      expression: "form.reporting.planDay"
    }
  }), _vm.form.errors.has('reporting.planDay') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('reporting.planDay')) + " ")]) : _vm._e()], 1)], 1), _c('h4', {
    staticClass: "my-3"
  }, [_vm._v("План отрезка")]), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "План отрезка"
    },
    model: {
      value: _vm.form.reporting.planInterval,
      callback: function callback($$v) {
        _vm.$set(_vm.form.reporting, "planInterval", $$v);
      },
      expression: "form.reporting.planInterval"
    }
  }), _vm.form.errors.has('reporting.planInterval') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('reporting.planInterval')) + " ")]) : _vm._e()], 1), _c('v-col', [_c('DateField', {
    attrs: {
      "range": true,
      "label": "Период отрезка"
    },
    model: {
      value: _vm.form.reporting.intervalDates,
      callback: function callback($$v) {
        _vm.$set(_vm.form.reporting, "intervalDates", $$v);
      },
      expression: "form.reporting.intervalDates"
    }
  }), _vm.form.errors.has('reporting.intervalDates') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('reporting.intervalDates')) + " ")]) : _vm._e()], 1)], 1), _c('h4', {
    staticClass: "my-3"
  }, [_vm._v("План периода")]), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "План периода"
    },
    model: {
      value: _vm.form.reporting.planPeriod,
      callback: function callback($$v) {
        _vm.$set(_vm.form.reporting, "planPeriod", $$v);
      },
      expression: "form.reporting.planPeriod"
    }
  }), _vm.form.errors.has('reporting.planPeriod') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('reporting.planPeriod')) + " ")]) : _vm._e()], 1), _c('v-col', [_c('DateField', {
    attrs: {
      "range": true,
      "label": "Период"
    },
    model: {
      value: _vm.form.reporting.periodDates,
      callback: function callback($$v) {
        _vm.$set(_vm.form.reporting, "periodDates", $$v);
      },
      expression: "form.reporting.periodDates"
    }
  }), _vm.form.errors.has('reporting.periodDates') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('reporting.periodDates')) + " ")]) : _vm._e()], 1)], 1), _c('h3', {
    staticClass: "my-4"
  }, [_vm._v("Орк")]), _c('h4', {
    staticClass: "my-3"
  }, [_vm._v("План дня")]), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "План дня"
    },
    model: {
      value: _vm.form.reportingOrk.planDay,
      callback: function callback($$v) {
        _vm.$set(_vm.form.reportingOrk, "planDay", $$v);
      },
      expression: "form.reportingOrk.planDay"
    }
  }), _vm.form.errors.has('reportingOrk.planDay') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('reportingOrk.planDay')) + " ")]) : _vm._e()], 1)], 1), _c('h4', {
    staticClass: "my-3"
  }, [_vm._v("План отрезка")]), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "План отрезка"
    },
    model: {
      value: _vm.form.reportingOrk.planInterval,
      callback: function callback($$v) {
        _vm.$set(_vm.form.reportingOrk, "planInterval", $$v);
      },
      expression: "form.reportingOrk.planInterval"
    }
  }), _vm.form.errors.has('reportingOrk.planInterval') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('reportingOrk.planInterval')) + " ")]) : _vm._e()], 1), _c('v-col', [_c('DateField', {
    attrs: {
      "range": true,
      "label": "Период отрезка"
    },
    model: {
      value: _vm.form.reportingOrk.intervalDates,
      callback: function callback($$v) {
        _vm.$set(_vm.form.reportingOrk, "intervalDates", $$v);
      },
      expression: "form.reportingOrk.intervalDates"
    }
  }), _vm.form.errors.has('reportingOrk.intervalDates') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('reportingOrk.intervalDates')) + " ")]) : _vm._e()], 1)], 1), _c('h4', {
    staticClass: "my-3"
  }, [_vm._v("План периода")]), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "План периода"
    },
    model: {
      value: _vm.form.reportingOrk.planPeriod,
      callback: function callback($$v) {
        _vm.$set(_vm.form.reportingOrk, "planPeriod", $$v);
      },
      expression: "form.reportingOrk.planPeriod"
    }
  }), _vm.form.errors.has('reportingOrk.planPeriod') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('reportingOrk.planPeriod')) + " ")]) : _vm._e()], 1), _c('v-col', [_c('DateField', {
    attrs: {
      "range": true,
      "label": "Период"
    },
    model: {
      value: _vm.form.reportingOrk.periodDates,
      callback: function callback($$v) {
        _vm.$set(_vm.form.reportingOrk, "periodDates", $$v);
      },
      expression: "form.reportingOrk.periodDates"
    }
  }), _vm.form.errors.has('reportingOrk.periodDates') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('reportingOrk.periodDates')) + " ")]) : _vm._e()], 1)], 1), _vm.form.errors.any() ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" Исправьте ошибки ")]) : _vm._e(), _vm.form.errors.has('error') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('error')) + " ")]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.form.busy,
      expression: "!form.busy"
    }]
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        return _vm.update();
      }
    }
  }, [_vm._v(" Сохранить ")])], 1), _c('v-progress-circular', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.busy,
      expression: "form.busy"
    }],
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  })], 1)], 1)], 1)])], 1) : _vm._e(), _c('SettingRevisions', {
    attrs: {
      "id": "global"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }