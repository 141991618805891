import {http} from "../_helpers";
import Service from "./Service";

class PaymentService extends Service {

    getAll(options, filter) {
        return http.get(this.makeOptionsUrl(`/payments`, options, filter));
    }

    addProviderPayment(form) {
        return http.post(`/payments/providers`, form);
    }

    addPayment(form) {
        return http.post(`/payments`, form);
    }

    delete(id) {
        return http.delete(`/payments/${id}`);
    }
}

export default new PaymentService;
