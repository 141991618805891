<template>
    <div>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader
                        type="heading, table-row@3, button"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <div class="my-10" v-if="!loading">
            <v-card-title>
                Комментарии
                <v-spacer></v-spacer>
                <CommentDialog :id="id" @saved="onCommentAdd"></CommentDialog>
            </v-card-title>
            <v-data-table :headers="headers" :items="comments" class="elevation-1" item-key="id" :loading="loading"
                          loading-text="Загрузка ..." :items-per-page="5">
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td width="220">{{ item.date }}</td>
                        <td width="220">{{ item.user.fullName }}</td>
                        <td style="white-space: pre-line;">{{ item.text }}</td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
    import RequestService from "../../_services/RequestService";
    import CommentDialog from "./CommentDialog";

    export default {
        components: {CommentDialog},
        props: {id: Number},
        data() {
            return {
                headers: [
                    {text: 'Дата', value: 'date'},
                    {text: 'Пользователь', value: 'user'},
                    {text: 'Текст', value: 'text'}
                ],
                comments: [],
                loading: true
            }
        },
        methods: {
            fetch() {
                this.loading = true;
                this.fetchComments();
            },
            fetchComments() {
                RequestService.getComments(this.id).then(data => {
                    this.comments = data;
                    this.loading = false;
                });
            },
            onCommentAdd() {
                this.fetchComments();
            }
        },
        created() {
            this.fetch();
        }
    };
</script>
