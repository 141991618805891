var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-7"
  }, [_c('h3', {
    staticClass: "mb-3"
  }, [_vm._v(_vm._s(_vm.fieldTitle))]), _vm._l(_vm.formField, function (field, index) {
    return _c('v-row', {
      key: index
    }, [_c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [_c('v-textarea', {
      attrs: {
        "label": "".concat(_vm.fieldTitle, " (").concat(index + 1, ")"),
        "disabled": _vm.isDisabledField(index),
        "rows": "2",
        "auto-grow": ""
      },
      model: {
        value: _vm.formField[index],
        callback: function callback($$v) {
          _vm.$set(_vm.formField, index, $$v);
        },
        expression: "formField[index]"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [!_vm.isDisabledField(index) ? _c('v-btn', {
      attrs: {
        "text": "",
        "color": "error",
        "small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.removeField(index);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-minus-box-outline")])], 1) : _vm._e(), _vm.showAddMoreButton(index) ? _c('v-btn', {
      attrs: {
        "text": "",
        "color": "success",
        "small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.addField();
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-plus-box-outline")])], 1) : _vm._e()], 1)], 1);
  }), _c('div', [_vm.formField.length === 0 ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "success",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addField();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus-box-outline")])], 1) : _vm._e()], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }