<template>
    <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on }">
            <v-btn color="green" v-on="on">
                Добавить оплату
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Добавить оплату</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                        <v-col>
                            <v-col>
                                <v-dialog
                                    ref="dateDialog" v-model="dateDialog"
                                    :return-value.sync="form.date"
                                    persistent width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="form.date"
                                            label="Дата"
                                            prepend-icon="mdi-calendar-month-outline"
                                            readonly
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="form.date" type="date" scrollable
                                        first-day-of-week="1">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateDialog = false">Отмена</v-btn>
                                        <v-btn text color="primary"
                                               @click="$refs.dateDialog.save(form.date)">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                                <v-alert v-if="form.errors.has('date')" dense outlined type="error"
                                         :icon="false">{{ form.errors.get('date') }}
                                </v-alert>
                            </v-col>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                                v-model="form.lawyer"
                                :items="page.lawyers"
                                label="Юрист"
                                item-value="id"
                                item-text="fullName"
                                :clearable="false">
                            </v-select>
                            <v-alert v-if="form.errors.has('lawyer')" dense outlined type="error" :icon="false">
                                {{ form.errors.get('lawyer') }}
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="form.amount"
                                v-on:change="checkIfReturn"
                                label="Сумма">
                            </v-text-field>
                            <v-alert v-if="form.errors.has('amount')" dense outlined type="error" :icon="false">
                                {{ form.errors.get('amount') }}
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                                v-model="form.type"
                                :items="page.paymentTypes"
                                v-on:change="checkIfReturn"
                                label="Вид оплаты">
                            </v-select>
                            <v-alert v-if="form.errors.has('type')" dense outlined type="error" :icon="false">
                                {{ form.errors.get('type') }}
                            </v-alert>
                        </v-col>
                        <v-col cols="auto">
                            <v-checkbox
                                class="chekbox__blue"
                                v-model="form.isSentForBuhgalter"
                                label="Бухгалтер"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row v-if="form.type === 'TRANSPORT'">
                        <v-col>
                            <v-select
                                v-model="form.additionalExpensesType"
                                label="Прочие Д/С"
                                :items="page.additionalExpensesType"
                                v-on:change="checkIfReturn"
                                return-object
                                item-value="value"
                                item-text="text">
                            </v-select>
                            <v-alert v-if="form.errors.has('additionalExpensesType')" dense outlined type="error"
                                     :icon="false">
                                {{ form.errors.get('additionalExpensesType') }}
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea
                                v-model="form.comment"
                                label="Комментарий"
                                rows="2">
                            </v-textarea>
                            <v-alert v-if="form.errors.has('comment')" dense outlined type="error" :icon="false">
                                {{ form.errors.get('comment') }}
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row v-if="form.isSentForBuhgalter === true">
                        <v-col>
                            <v-textarea
                                v-model="form.commentForBuhgalter"
                                label="Комментарий для бухгалтера"
                                rows="2">
                            </v-textarea>
                            <v-alert v-if="form.errors.has('commentForBuhgalter')" dense outlined type="error"
                                     :icon="false">
                                {{ form.errors.get('commentForBuhgalter') }}
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="auto">
                            <v-checkbox
                                class="chekbox__blue"
                                v-model="form.isBezNal"
                                label="Б/н">
                            </v-checkbox>
                        </v-col>
                        <v-col v-if="form.isBezNal === true">
                            <v-select
                                v-model="form.bezNalType"
                                :items="page.bezNalTypes"
                                :rules="[(v) => !!v || 'Поле обязательно']"
                                label="Вид б/н">
                            </v-select>
                            <v-alert v-if="form.errors.has('bezNalType')" dense outlined type="error" :icon="false">
                                {{ form.errors.get('bezNalType') }}
                            </v-alert>
                        </v-col>
                        <v-col cols="auto" v-if="form.isBezNal !== true">
                            <v-chip
                                color="green"
                                outlined
                                dark
                                style="margin-top: 16px">
                                <v-checkbox
                                    class="chekbox__green"
                                    v-model="form.nalType"
                                    value="CARD"
                                    label="Наличные (карта)">
                                </v-checkbox>
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">Отмена</v-btn>
                <v-btn color="blue darken-1" text @click="save">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Form from "../../_helpers/_form/Form";
import ContractService from "../../_services/ContractService";

const OUTCOME_DEBIT_TYPE = 'OUTCOME';

export default {
    props: {
        id: Number,
        contract: Object,
        page: { // contract form page
            type: Object,
            default: null
        },
    },
    data() {
        return {
            form: new Form({
                type: 'AVANS',
                //bezNalType: 'TERMINAL',
                additionalExpensesType: {
                    value: 'TRANSPORT'
                },
                lawyer: this.contract?.lawyer || this.page?.lawyers[0]
            }),
            valid: true,
            dialog: false,
            dateDialog: false
        }
    },
    methods: {
        save() {
            //this.$refs.form.resetValidation();
            const valid = this.$refs.form.validate();
            if (valid) {
                ContractService.addPayment(this.id, this.form).then(() => {
                    this.dialog = false;
                    this.$emit('saved');
                });
            }
        },
        checkIfReturn() {
            const selectedType =
                this.page.paymentTypes
                    .find(type => type.value === this.form.type);
            const selectedAdditionalExpensesType =
                this.page.additionalExpensesType
                    .find(type => type.value === this.form.additionalExpensesType?.value);
            const amount = parseInt(this.form.amount);
            if (
                (
                    selectedType?.debitType === OUTCOME_DEBIT_TYPE
                    || selectedAdditionalExpensesType?.debitType === OUTCOME_DEBIT_TYPE
                )
                && !isNaN(amount)) {
                this.form.amount = -Math.abs(amount);
            }
        }
    }
};
</script>
