import {http} from "../_helpers";
import Service from "./Service";

class NotificationService extends Service {

    create(form) {
        return http.post(`/notifications`, form);
    }

    getUserNotifications(options, filter) {
        return http.get(this.makeOptionsUrl(`/notifications/filter`, options, filter));
    }

    getNotifications(filter) {
        return http.get(this.makeOptionsUrl(`/notifications`, {}, filter));
    }

    delete(id) {
        return http.delete(`/notifications/${id}`);
    }

    deleteUserNotification(id) {
        return http.delete(`/notifications/${id}/user`);
    }

    getInitData() {
        return http.get(`/notifications/init-data`);
    }
}

export default new NotificationService;
