import {http} from "../_helpers";
import Service from "./Service";

class ContractCallService extends Service {

    getAll(options, filter) {
        return http.get(this.makeOptionsUrl(`/contractCalls/proxy/callHistory/enterprise`, options, filter));
    }

    getRecord(recordingId) {
        return http.get(`/contractCalls/records/play/${recordingId}`);
    }
}

export default new ContractCallService;
