var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-list', {
    attrs: {
      "subheader": ""
    }
  }, [_c('v-subheader', [_vm._v("План")]), _c('table', {
    staticClass: "table__plan v-data-table theme--dark"
  }, [_c('tr', {
    staticStyle: {
      "background": "#2196F3"
    }
  }, [_c('td', [_vm._v("от 3,0")]), _c('td', [_vm._v("+ 4,5/2,5 %")])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }