<template>
    <div>
        <v-card-title>
            Статистика Юр.Отдела
        </v-card-title>
        <v-card-text>
            <v-row align-content="bottom">
                <v-col xl="6">
                    <v-row>
                        <v-col>
                            <v-select
                                v-model="options.reportType"
                                item-value="id"
                                item-text="text"
                                :items="filteredReportTypes">
                            </v-select>
                        </v-col>
                        <v-col>
                            <DateField
                                label="Дата"
                                :range="true"
                                v-model="filter.dates"
                            ></DateField>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn
                                large
                                color="green"
                                @click="getReport">
                                Сформировать
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col xl="6">
                    <PlanTable
                        v-if="$helper.permission.canUpdateContract(user)"
                        :key="reportForceRerenderKey">
                    </PlanTable>
                </v-col>
            </v-row>
        </v-card-text>

        <ReportLawyers
            v-if="options.reportType == 'LAWYERS'"
            :filter="filter"
            :key="reportForceRerenderKey"
        ></ReportLawyers>

        <ReportLawyerDepartment
            v-if="options.reportType == 'LAWYER_DEPARTMENT'"
            :filter="filter"
            :key="reportForceRerenderKey"
        ></ReportLawyerDepartment>

        <ChartLawyerPlans
            v-if="options.reportType == 'LAWYERS_CHART'"
            :filter="filter"
            :key="reportForceRerenderKey"
        ></ChartLawyerPlans>
    </div>
</template>

<script>

    import {mapState} from "vuex";
    import ReportLawyers from "@/reports/lawyers/ReportLawyers";
    import ReportLawyerDepartment from "@/reports/lawyers/ReportLawyerDepartment";
    import ChartLawyerPlans from "@/reports/lawyers/charts/ChartLawyerPlans";
    import DateField from "@/_chunks/DateField";
    import PlanTable from "@/reports/lawyers/PlanTable";

    export default {
        name: 'ReportLawyersGrid',
        components: {PlanTable, DateField, ChartLawyerPlans, ReportLawyerDepartment, ReportLawyers},
        data: function() {
            return {
                loading: false,
                reportForceRerenderKey: 0,
                timer: null,
                reportTypes: [
                    {
                        "id": "LAWYER_DEPARTMENT",
                        "text": "Юр. Отдел",
                        "roles": ['ADMIN', 'LAWYER_MANAGER', 'RECEPTION', 'MANAGER_OKK', 'MANAGER_OGPIA', 'MANAGER_EPO']
                    },
                    {
                        "id": "LAWYERS",
                        "text": "Юристы"
                    },
                    {
                        "id": "LAWYERS_CHART",
                        "text": "График"
                    },
                ]
            }
        },
        watch: { },
        methods: {
            getReport() {
                this.reportForceRerenderKey++;
            },
        },
        computed: {
            ...mapState(['user']),
            filteredReportTypes: function () {
                return this.reportTypes.filter(type => {
                    if (type.roles) {
                        return type.roles.includes(this.user.role);
                    }
                    return true;
                })
            },
            filter: function() {
                return this.$store.state.reportLawyerGridOptions.filter;
            },
            options: function() {
                return this.$store.state.reportLawyerGridOptions.options;
            }
        },
        beforeCreate() {
            this.$store.dispatch('loading', true);
        },
        created() {
            this.$store.dispatch('loading', false);
        },
        mounted: function () {
            this.timer = setInterval(() => {
                this.getReport()
            }, 30 * 60 * 1000)
        },
        beforeDestroy() {
            clearInterval(this.timer)
        }
    };
</script>