<template>
    <v-card-text>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader type="table-row@3"></v-skeleton-loader>
            </v-col>
        </v-row>
        <div v-if="!loading" style="margin-top:-30px; overflow: auto;">
            <v-data-table
                :style="`min-width: ${getTableWidth()}px; margin:-2px`"
                :headers="headers"
                :items="page.items"
                hide-default-footer
                disable-pagination
                class="table__lined table__framed"
                :height="tableHeight">
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td width="100">{{ $moment(item.date).dateFormat() }}</td>
                        <td>{{ $helper.format.number(item.clients) }}</td>
                        <td>{{ item.clientsOnline }}</td>
                        <td width="140">{{ item.contracts }}</td>
                        <td width="160">
                            <span v-html="$helper.report.getPercentageHtml(item.contracts, item.clients, $helper.report.contractStatOptions)"></span>
                        </td>
                        <td width="160">
                            {{item.contractTypes?.PRIMARY || 0}}
                            (<span v-html="$helper.report.getPercentageHtml(item.contractTypes?.PRIMARY, item.clients, $helper.report.contractPrimaryStatOptions)"></span>)
                        </td>
                        <td width="140">{{ $helper.format.number(item.cash) }}</td>
                        <td width="140">{{ $helper.format.number(item.contractSum) }}</td>
                        <td width="140">
                            <span
                                :class="$helper.report.getStatColor(((item.contractSum / item.contracts) || 0), $helper.report.lawyerDepartment.avgSumm)"
                                v-html="$helper.format.number((item.contractSum / item.contracts) || 0)">
                            </span>
                        </td>
                        <td width="140">
                            <span :class="$helper.report.getStatColor((item.cash / item.clients), $helper.report.payloadStatOptions)">
                                {{ $helper.format.currency((item.cash / item.clients) || 0) }}
                            </span>
                        </td>
                        <td width="140">
                            <span :class="$helper.report.getStatColor(item.moneyBack, $helper.report.moneyBackStatOptions)">
                                {{ $helper.format.currency(item.moneyBack) }}
                            </span>
                        </td>
                        <td>{{ $helper.format.number(item.halva10) }}</td>
                        <td>{{ $helper.format.number(item.cleanCash) }}</td>
                        <td>
                            <span :class="$helper.report.getStatColor((item.cleanCash / item.clients), $helper.report.payloadStatOptions)">
                                {{ $helper.format.currency((item.cleanCash / item.clients) || 0) }}
                            </span>
                        </td>
                    </tr>
                    <tr class="result-row">
                        <td v-for="(header, i) in headers" :key="i">
                            <div v-if="header.value === 'day'">Всего:</div>
                            <div v-if="header.value === 'contractPercent'">
                                <span v-html="$helper.report.getPercentageHtml(total('contracts'), total('clients'), $helper.report.contractStatOptions)"></span>
                            </div>
                            <div v-if="header.value === 'contractPercentPrimary'">
                                {{ total('contractTypes.PRIMARY') }}
                                (<span v-html="$helper.report.getPercentageHtml(total('contractTypes.PRIMARY'), total('clients'), $helper.report.contractPrimaryStatOptions)"></span>)
                            </div>
                            <div v-if="header.value === 'avgSum'">
                                 <span
                                     :class="$helper.report.getStatColor(((total('contractSum') / total('contracts')) || 0), $helper.report.lawyerDepartment.avgSumm)"
                                     v-html="$helper.format.number((total('contractSum') / total('contracts')) || 0)">
                                </span>
                            </div>
                            <div v-if="header.value === 'moneyBack'">
                                <span :class="$helper.report.getStatColor(total('moneyBack'), $helper.report.moneyBackStatOptions)">
                                    {{ $helper.format.currency(total('moneyBack')) }}
                                </span>
                            </div>
                            <div v-if="header.value === 'payload'">
                                <span :class="$helper.report.getStatColor((total('cash') / total('clients')), $helper.report.payloadStatOptions)">
                                    {{ $helper.format.currency((total('cash') / total('clients')) || 0) }}
                                </span>
                            </div>
                            <div v-if="header.value === 'cleanPayload'">
                                <span :class="$helper.report.getStatColor((total('cleanCash') / total('clients')), $helper.report.payloadStatOptions)">
                                    {{ $helper.format.currency((total('cleanCash') / total('clients')) || 0) }}
                                </span>
                            </div>
                            <div v-if="header.value === 'cleanCash'">
                                <span :class="$helper.report.getStatColor(total('cleanCash'), $helper.report.cleanCashStatOptions)">
                                    {{ $helper.format.currency(total('cleanCash')) }}
                                </span>
                            </div>
                            <div v-if="header.custom !== true">
                                {{ $helper.format.number(total(header.value)) }}
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
    </v-card-text>
</template>

<script>

    import ReportService from "@/_services/ReportService";
    import $ from 'jquery';
    import {mapState} from "vuex";

    export default {
        name: 'ReportLawyerDepartment',
        components: { },
        props: {
            filter: Object,
        },
        data: function() {
            return {
                loading: false,
                page: {
                    items: []
                },
                tableHeight:  this.calcTableHeight(),
                headers: [
                    {text: 'день', value: 'day', custom: true},
                    {text: 'клиентов всего', value: 'clients'},
                    {text: 'клиентов Онлайн', value: 'clientsOnline'},
                    {text: 'договоры', value: 'contracts'},
                    {text: '% договоров', value: 'contractPercent', custom: true},
                    {text: '% договоров с первички', value: 'contractPercentPrimary', custom: true},
                    {text: 'касса', value: 'cash'},
                    {text: 'сумма договоров', value: 'contractSum'},
                    {text: 'ср. сумма', value: 'avgSum', custom: true},
                    {text: 'нагрузка', value: 'payload', custom: true},
                    {text: 'возврат', value: 'moneyBack', custom: true},
                    {text: '10% вычет по халве', value: 'halva10'},
                    {text: 'чистая касса', value: 'cleanCash', custom: true},
                    {text: 'чистая нагрузка', value: 'cleanPayload', custom: true},
                ],
            }
        },
        watch: { },
        methods: {
            fetch() {
                ReportService.getLawyerDepartmentReport(this.filter).then(page => {
                    this.page = page;
                    this.loading = false;
                });
            },
            getTableWidth() {
                return 2000;
            },
            getPercentageHtml(number1, number2, options = {}) {
                const number = this.getPercentage(number1, number2);
                const percentage = Math.round((number + Number.EPSILON) * 100);
                const statColor = this.getStatColor(percentage, options);
                return `(<span class="${statColor}">${percentage}%</span>)`;
            },
            getStatColor(number, options) {
                const colorSuffix = '_link';
                const defaultColor = 'yellow';
                const colors = options?.colors;
                let color = defaultColor;
                if (colors) {
                    Object.entries(colors).forEach(([colorProp, value]) => {
                        if (Array.isArray(value)) {
                            const from = value[0];
                            const to = value[1];
                            if (number >= from && number <= to) {
                                color = colorProp;
                            }
                        }
                    });
                }
                return color + colorSuffix;
            },
            getPercentage(number1, number2) {
                if (!number1 || !number2) {
                    return 0;
                }
                return  number2 == 0 ? 0 : number1 / number2;
            },
            onResize() {
                this.tableHeight = this.calcTableHeight();
            },
            calcTableHeight() {
                const minHeight = $('.table__framed table').height();
                let height = this.$helper.calcTableHeight() - 90;
                return minHeight < height ? minHeight : height;
            }
        },
        computed: {
            ...mapState(['globalSettings']),
            total() {
                return (column, items) => {
                    if (!column) {
                        return '';
                    }
                    items = items || this.page.items;
                    let total = 0;
                    items?.forEach(item => {
                        if (column.includes('.')) {
                            const columnPath = column.split('.');
                            total += Number.parseFloat(item[columnPath[0]][columnPath[1]]) || 0;
                        } else {
                            total += Number.parseFloat(item[column]) || 0;
                        }
                    });
                    return total;
                }
            },
        },
        created() {
            this.loading = true;
            this.fetch();
        },
        beforeCreate() {

        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        mounted() {
            window.addEventListener('resize', this.onResize)
        },
        updated() {
            this.onResize();
        }
    };
</script>