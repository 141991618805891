var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c(_vm.layout, {
    tag: "component"
  }, [_c('transition', {
    attrs: {
      "mode": "out-in",
      "name": "fade"
    }
  }, [_c('router-view')], 1)], 1), _c('div', {
    staticClass: "flashpool"
  }, [_c('flash-message')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }