<template>
    <div>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader type="heading, table-row@3, button"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-card v-if="!loading">
            <v-card-text>
                <form>
                    <v-row>
                        <v-col cols="12" xl="9">
                            <v-row v-if="act !== null">
                                <v-col v-if="form.id">
                                    <v-text-field :value="form.id" disabled="disabled" label="ID"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-select
                                        v-model="form.type"
                                        label="Тип"
                                        :items="actTypes"
                                        clearable
                                        return-object
                                        item-value="value"
                                        item-text="text"
                                    ></v-select>
                                </v-col>
                                <v-col>
                                    <DateField
                                        label="Дата"
                                        :disabled="!$helper.permission.canCreateAct(user)"
                                        v-model="form.date"
                                    ></DateField>
                                </v-col>
                                <v-col>
                                    <v-dialog ref="signDateDialog" v-model="signDateDialog"
                                              :return-value.sync="form.signDate"
                                              persistent width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="form.signDate"
                                                    label="Дата подписания"
                                                    prepend-icon="mdi-calendar-month-outline"
                                                    readonly
                                                    v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="form.signDate" type="date" scrollable
                                                       first-day-of-week="1">
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="signDateDialog = false">Отмена</v-btn>
                                            <v-btn text color="primary" @click="$refs.signDateDialog.save(form.signDate)">
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-dialog>
                                    <v-alert v-if="form.errors.has('signDate')" dense outlined type="error"
                                             :icon="false">{{ form.errors.get('signDate') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="form.number" label="Номер"></v-text-field>
                                    <v-alert v-if="form.errors.has('number')" dense outlined type="error" :icon="false">
                                        {{form.errors.get('number')}}
                                    </v-alert>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="form.amount" label="Сумма акта"></v-text-field>
                                    <v-alert v-if="form.errors.has('amount')" dense outlined type="error" :icon="false">
                                        {{form.errors.get('amount')}}
                                    </v-alert>
                                </v-col>
                                <v-col v-if="form.contract">
                                    <v-text-field v-model="form.contract.number" label="Договор" disabled="disabled"></v-text-field>
                                </v-col>
                                <v-col v-if="$_.get(page, 'act.contract')">
                                    <v-btn :to="`/contracts/${page.act.contract.id}/edit`" small outlined color="green">Перейти</v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col v-if="form.contract">
                                    <v-select
                                            v-model="form.contract.lawyer"
                                            :items="lawyers"
                                            label="Юрист (по договору)"
                                            item-value="id"
                                            item-text="fullName"
                                            disabled="disabled"
                                            :clearable="true">
                                    </v-select>
                                </v-col>
                                <v-col v-if="form.contract">
                                    <v-select
                                            v-model="form.contract.lawyerOGPA"
                                            :items="lawyersOGPA"
                                            label="Юрист ОГПиА (по договору)"
                                            item-value="id"
                                            item-text="fullName"
                                            disabled="disabled"
                                            :clearable="true">
                                    </v-select>
                                </v-col>
                                <v-col v-if="$_.get(page, 'act.createdBy')">
                                    <v-text-field v-model="form.createdBy.fullName" label="Создал" disabled="disabled"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-select
                                            v-model="form.lawyerEPO"
                                            :items="lawyersEPO"
                                            label="Юрист ЭПО (по акту)"
                                            item-value="id"
                                            item-text="fullName"
                                            :clearable="true">
                                    </v-select>
                                </v-col>
                                <v-col>
                                    <v-select
                                            v-model="form.lawyerOGPA"
                                            :items="lawyersOGPA"
                                            label="Юрист ОГПиА (по акту)"
                                            item-value="id"
                                            item-text="fullName"
                                            :clearable="true">
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col></v-col>
                                <v-col>
                                    <v-select
                                        v-model="form.lawyerOGPAAssistant"
                                        :items="lawyersOGPA"
                                        label="Помощник юриста ОГПиА (по акту)"
                                        item-value="id"
                                        item-text="fullName"
                                        :clearable="true">
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row v-if="form.contract?.client">
                                <v-col>
                                    <v-text-field v-if="form.contract.client" v-model="form.contract.client.userName" label="Клиент" disabled="disabled"></v-text-field>
                                </v-col>
                                <v-col v-if="$_.get(page, 'act.contract.client.id')">
                                    <v-btn :to="`/clients/${page.act.contract.client.id}/edit`" small outlined color="green">Перейти</v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-textarea v-model="form.comment"
                                                label="Примечание"
                                                rows="2">
                                    </v-textarea>
                                    <v-alert v-if="form.errors.has('comment')" dense outlined type="error"
                                             :icon="false">
                                        {{form.errors.get('comment')}}
                                    </v-alert>
                                </v-col>
                                <v-col cols="auto">
                                    <v-checkbox
                                            class="chekbox__blue"
                                            value="HALVA"
                                            v-model="form.installment"
                                            label="Халва">
                                    </v-checkbox>
                                    <v-alert v-if="form.errors.has('installment')" dense outlined type="error" :icon="false">
                                        {{form.errors.get('installment') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" xl="3">

                        </v-col>
                    </v-row>
                    <v-alert v-if="form.errors.any()" dense outlined type="error" :icon="false">
                        Исправьте ошибки
                    </v-alert>
                    <v-alert v-if="form.errors.has('error')" dense outlined type="error" :icon="false">
                        {{form.errors.get('error')}}
                    </v-alert>
                    <div v-show="!form.busy">
                        <v-btn v-if="$helper.permission.canCreateAct(user)" large color="green" @click="(act && act.id) ? update(false) : save(false)">
                            Сохранить
                        </v-btn>
                        <v-btn v-if="$helper.permission.canCreateAct(user)" large color="green" @click="(act && act.id) ? update(true) : save(false)" class="ml-4">
                            Сохранить и закрыть
                        </v-btn>
                    </div>
                    <v-progress-circular v-show="form.busy" indeterminate color="primary"></v-progress-circular>
                </form>
            </v-card-text>
        </v-card>
        <ActComments v-if="act && act.id" :id="act.id"></ActComments>
        <NotificationsGrid v-if="act && act.id" :id="act.id" type="Act"></NotificationsGrid>
    </div>
</template>

<script>
    import {Form} from '../_helpers/_form';
    import ActService from "../_services/ActService";
    import {mapState} from 'vuex';
    import ActComments from "./comments/ActComments";
    import NotificationsGrid from "../notifications/NotificationsGrid";
    import DateField from "@/_chunks/DateField";

    export default {
        name: "ActForm",
        components: {DateField, NotificationsGrid, ActComments},
        props: {
            page: {
                type: Object,
                default: null
            },
            inPopup: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                form: this.page.act
                    ? new Form(this.page.act)
                    : new Form({}),
                actTypes: this.page.actTypes,
                lawyers: this.page.lawyers,
                lawyersOGPA: this.page.lawyersOGPA,
                lawyersEPO: this.page.lawyersEPO,
                act: this.page.act,
                signDateDialog: false,
                loading: false
            }
        },
        methods: {
            update(needToExit) {
                ActService.update(this.act.id, this.form).then(() => {
                    if (needToExit) {
                        this.$emit('closeAndUpdate');
                    } else {
                        this.$emit('updated');
                    }
                });
            },
            save() {
                ActService.create(this.form).then(() => {
                    this.$router.push('/acts');
                });
            }
        },
        computed: {
            ...mapState(['user']),
            payedAmount() {
                return this.form.amount - this.form.payedAmount;
            }
        }
    }
</script>

<style lang="scss">

</style>
