<template>
    <div>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader type="heading, table-row@3, button"></v-skeleton-loader>
            </v-col>
        </v-row>
        <div class="my-10" v-if="!loading">
            <v-card-title>
                Почтовый менеджер
                <v-spacer></v-spacer>
                <NotificationDialog
                    :id="id"
                    :channelTypes="channelTypes"
                    :types="types"
                    @saved="fetchItems()">
                </NotificationDialog>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="notifications"
                :options.sync="optionsForm"
                :server-items-length="totalElements"
                class="elevation-1"
                item-key="id"
                :loading="loadingTable"
                loading-text="Загрузка ..."
                :items-per-page="10"
                :footer-props="{'items-per-page-options': [10, 20, 30, 50]}">
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td width="20" class="px-5"></td>
                        <td width="160">{{ $moment(item.createdAt).dateTimeFormat() }}</td>
                        <td width="300">{{ item.user.fullName }}</td>
                        <td width="130">
                            <v-icon
                                v-if="item.result?.status === 'SUCCESS' || !item.result"
                                small
                                color="green">
                                mdi-check-circle-outline
                            </v-icon>

                            <div v-if="item.result?.status === 'FAILURE'">
                                <v-tooltip
                                    right
                                    color="red"
                                    content-class='tooltip__primary'>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-on="on"
                                            small
                                            color="red">
                                            mdi-message-alert-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ item.result?.description }}</span>
                                </v-tooltip>
                            </div>
                        </td>
                        <td width="300">
                            <div v-if="item.channel">
                                {{ item.channel.text }}
                            </div>
                        </td>
                        <td>
                            <div v-if="item.type">
                                {{ item.type.text }}
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import NotificationDialog from "./NotificationDialog.vue";
import ClientNotificationService from "@/_services/ClientNotificationService";

export default {
    name: "ContactChannels",
    components: {NotificationDialog},
    props: {
        id: Number,
    },
    data() {
        return {
            headers: [
                {sortable: false},
                {text: 'Дата', value: 'date'},
                {text: 'Сотрудник', value: 'user'},
                {text: 'Отправлено', value: 'sent', sortable: false},
                {text: 'Канал', value: 'channel'},
                {text: 'Тип', value: 'type'}
            ],
            channelTypes: [
                {
                    value: 'EMAIL',
                    text: 'Email',
                },
                // {
                //     value: 'SMS',
                //     text: 'СМС',
                // }
            ],
            types: [],
            optionsForm: {
                sortBy: ['id'],
                sortDesc: [true]
            },
            filterForm: {
                type: null,
                channel: null,
                user: {
                    id: null,
                },
                notificationable: this.id
            },
            notifications: [],
            totalElements: 0,
            loading: true,
            loadingTable: true
        }
    },
    watch: {
        optionsForm: {
            handler() {
                this.fetchItems();
            },
            deep: true
        },
        filterForm: {
            handler() {
                this.fetchItems();
            },
            deep: true
        },
    },
    methods: {
        fetch() {
            this.loading = false;
            // this.fetchItems();
        },
        fetchItems() {
            this.loadingTable = true;
            ClientNotificationService.getAll(this.optionsForm, this.filterForm).then(data => {
                this.notifications = data?.page?.content ?? [];
                this.totalElements = data.page.totalElements;
                this.types = data?.types ? this.getTypesWithDividers(data?.types) : [];
                this.loadingTable = false;
            });
        },
        getTypesWithDividers(types) {
            if (!types) {
                return [];
            }
            const departmentTypes = {};
            types.forEach(type => {
                departmentTypes[type.department.value] =
                    departmentTypes[type.department.value]
                        ? departmentTypes[type.department.value]
                        : {};
                const departmentType = departmentTypes[type.department.value];
                departmentType.name = departmentType.name ?? type.department.text;
                departmentType.items = departmentType.items ?? [];
                departmentType.items.push(type);
            });
            const typesWithDividers = [];
            Object.entries(departmentTypes).forEach(type => {
                // eslint-disable-next-line no-unused-vars
                const [key, value] = type;
                typesWithDividers.push({divider: true});
                typesWithDividers.push({header: value.name});
                typesWithDividers.push(...value.items);
            });
            return typesWithDividers;
        }
    },
    computed: {
        ...mapState(['user'])
    },
    created() {
        this.fetch();
        setTimeout(() => this.loading = false, 2000)
    }
};
</script>

<style lang="scss">
    .tooltip__primary {
        opacity: 1 !important;
    }
</style>