import {http} from "../_helpers";

class SettingService {

    getGlobal() {
        return http.get(`/settings/global`);
    }

    updateGlobal(form) {
        return http.put(`/settings/global`, form);
    }

    getRevisions(id) {
        return http.get(`/settings/${id}/revisions`);
    }

    updateAutoAssignOperator(value) {
        return http.put(`/settings/global/autoAssignOperator/${value}`);
    }

    updateOutOfPlace(value) {
        return http.put(`/users/outOfPlace/${value}`);
    }
}

export default new SettingService;
