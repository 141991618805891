<template>
    <div>
        <v-card-title>
            Редактирование сотрудника
            <div class="ml-5">
                <v-btn small color="primary" class="mx-4" outlined :to="`/users`">Все сотрудники</v-btn>
            </div>
        </v-card-title>
        <UserForm v-if="user !== null" :user="user"></UserForm>
    </div>
</template>

<script>
    import UserForm from "./UserForm";
    import UserService from "../_services/UserService";

    export default {
        name: 'UserUpdate',
        components: {UserForm},
        props: {id: String},
        data() {
            return {
                user: null,
            }
        },
        methods: {
            fetch() {
                this.fetchUser();
            },
            fetchUser() {
                UserService.getOne(this.id).then(data => {
                    this.user = data;
                });
            },
        },
        created() {
            this.fetch();
        }
    };
</script>
