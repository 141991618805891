<template>
    <div>
        <AppointmentsGrid @forceUpdate="key++" :key="key"></AppointmentsGrid>
    </div>
</template>

<script>
    import AppointmentsGrid from "../appointments/AppointmentsGrid";

    export default {
        name: 'AppointmentPage',
        components: {AppointmentsGrid},
        data() {
            return {
                key: 0
            }
        }
    };
</script>
