var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "b-page_newyear"
  }, [_c('div', {
    staticClass: "b-page__content"
  }, [_c('i', {
    staticClass: "b-head-decor"
  }, [_c('i', {
    staticClass: "b-head-decor__inner b-head-decor__inner_n1"
  }, [_c('div', {
    staticClass: "b-ball b-ball_n1 b-ball_bounce",
    attrs: {
      "data-note": "0"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n2 b-ball_bounce",
    attrs: {
      "data-note": "1"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n3 b-ball_bounce",
    attrs: {
      "data-note": "2"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n4 b-ball_bounce",
    attrs: {
      "data-note": "3"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n5 b-ball_bounce",
    attrs: {
      "data-note": "4"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n6 b-ball_bounce",
    attrs: {
      "data-note": "5"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n7 b-ball_bounce",
    attrs: {
      "data-note": "6"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n8 b-ball_bounce",
    attrs: {
      "data-note": "7"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n9 b-ball_bounce",
    attrs: {
      "data-note": "8"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i1"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i2"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i3"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i4"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i5"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i6"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })])]), _c('i', {
    staticClass: "b-head-decor__inner b-head-decor__inner_n2"
  }, [_c('div', {
    staticClass: "b-ball b-ball_n1 b-ball_bounce",
    attrs: {
      "data-note": "9"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n2 b-ball_bounce",
    attrs: {
      "data-note": "10"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n3 b-ball_bounce",
    attrs: {
      "data-note": "11"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n4 b-ball_bounce",
    attrs: {
      "data-note": "12"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n5 b-ball_bounce",
    attrs: {
      "data-note": "13"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n6 b-ball_bounce",
    attrs: {
      "data-note": "14"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n7 b-ball_bounce",
    attrs: {
      "data-note": "15"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n8 b-ball_bounce",
    attrs: {
      "data-note": "16"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n9 b-ball_bounce",
    attrs: {
      "data-note": "17"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i1"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i2"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i3"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i4"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i5"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i6"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })])]), _c('i', {
    staticClass: "b-head-decor__inner b-head-decor__inner_n3"
  }, [_c('div', {
    staticClass: "b-ball b-ball_n1 b-ball_bounce",
    attrs: {
      "data-note": "18"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n2 b-ball_bounce",
    attrs: {
      "data-note": "19"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n3 b-ball_bounce",
    attrs: {
      "data-note": "20"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n4 b-ball_bounce",
    attrs: {
      "data-note": "21"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n5 b-ball_bounce",
    attrs: {
      "data-note": "22"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n6 b-ball_bounce",
    attrs: {
      "data-note": "23"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n7 b-ball_bounce",
    attrs: {
      "data-note": "24"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n8 b-ball_bounce",
    attrs: {
      "data-note": "25"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n9 b-ball_bounce",
    attrs: {
      "data-note": "26"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i1"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i2"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i3"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i4"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i5"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i6"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })])]), _c('i', {
    staticClass: "b-head-decor__inner b-head-decor__inner_n4"
  }, [_c('div', {
    staticClass: "b-ball b-ball_n1 b-ball_bounce",
    attrs: {
      "data-note": "27"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n2 b-ball_bounce",
    attrs: {
      "data-note": "28"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n3 b-ball_bounce",
    attrs: {
      "data-note": "29"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n4 b-ball_bounce",
    attrs: {
      "data-note": "30"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n5 b-ball_bounce",
    attrs: {
      "data-note": "31"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n6 b-ball_bounce",
    attrs: {
      "data-note": "32"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n7 b-ball_bounce",
    attrs: {
      "data-note": "33"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n8 b-ball_bounce",
    attrs: {
      "data-note": "34"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n9 b-ball_bounce",
    attrs: {
      "data-note": "35"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i1"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i2"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i3"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i4"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i5"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i6"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })])]), _c('i', {
    staticClass: "b-head-decor__inner b-head-decor__inner_n5"
  }, [_c('div', {
    staticClass: "b-ball b-ball_n1 b-ball_bounce",
    attrs: {
      "data-note": "0"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n2 b-ball_bounce",
    attrs: {
      "data-note": "1"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n3 b-ball_bounce",
    attrs: {
      "data-note": "2"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n4 b-ball_bounce",
    attrs: {
      "data-note": "3"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n5 b-ball_bounce",
    attrs: {
      "data-note": "4"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n6 b-ball_bounce",
    attrs: {
      "data-note": "5"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n7 b-ball_bounce",
    attrs: {
      "data-note": "6"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n8 b-ball_bounce",
    attrs: {
      "data-note": "7"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n9 b-ball_bounce",
    attrs: {
      "data-note": "8"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i1"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i2"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i3"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i4"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i5"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i6"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })])]), _c('i', {
    staticClass: "b-head-decor__inner b-head-decor__inner_n6"
  }, [_c('div', {
    staticClass: "b-ball b-ball_n1 b-ball_bounce",
    attrs: {
      "data-note": "9"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n2 b-ball_bounce",
    attrs: {
      "data-note": "10"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n3 b-ball_bounce",
    attrs: {
      "data-note": "11"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n4 b-ball_bounce",
    attrs: {
      "data-note": "12"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n5 b-ball_bounce",
    attrs: {
      "data-note": "13"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n6 b-ball_bounce",
    attrs: {
      "data-note": "14"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n7 b-ball_bounce",
    attrs: {
      "data-note": "15"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n8 b-ball_bounce",
    attrs: {
      "data-note": "16"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n9 b-ball_bounce",
    attrs: {
      "data-note": "17"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i1"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i2"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i3"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i4"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i5"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i6"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })])]), _c('i', {
    staticClass: "b-head-decor__inner b-head-decor__inner_n7"
  }, [_c('div', {
    staticClass: "b-ball b-ball_n1 b-ball_bounce",
    attrs: {
      "data-note": "18"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n2 b-ball_bounce",
    attrs: {
      "data-note": "19"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n3 b-ball_bounce",
    attrs: {
      "data-note": "20"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n4 b-ball_bounce",
    attrs: {
      "data-note": "21"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n5 b-ball_bounce",
    attrs: {
      "data-note": "22"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n6 b-ball_bounce",
    attrs: {
      "data-note": "23"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n7 b-ball_bounce",
    attrs: {
      "data-note": "24"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n8 b-ball_bounce",
    attrs: {
      "data-note": "25"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_n9 b-ball_bounce",
    attrs: {
      "data-note": "26"
    }
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i1"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i2"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i3"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i4"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i5"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })]), _c('div', {
    staticClass: "b-ball b-ball_i6"
  }, [_c('div', {
    staticClass: "b-ball__right"
  }), _c('div', {
    staticClass: "b-ball__i"
  })])])])])])]);
}]

export { render, staticRenderFns }