import {http} from "../_helpers";
import Service from "./Service";
import {axiosInstance} from "@/_helpers/axiosInstance";

class ContractEventService extends Service {

    getAll(options, filter) {
        return http.get(this.makeOptionsUrl(`/contract-events`, options, filter));
    }

    getOne(id) {
        return axiosInstance.get(`/contract-events/${id}`);
    }

    updateEvent(id, form) {
        return http.put(`/contract-events/${id}`, form);
    }

    delete(id) {
        return axiosInstance.delete(`/contract-events/${id}`);
    }
}

export default new ContractEventService;
