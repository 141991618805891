<template>
    <div>
        <v-card-title>
            Создание заявки
            <div class="ml-5">
                <v-btn small color="primary" class="mx-4" outlined to="/">Все заявки</v-btn>
            </div>
        </v-card-title>
        <div v-if="!loading">
            <RequestForm :page="page"></RequestForm>
        </div>
    </div>
</template>

<script>
    import RequestForm from "./RequestForm";
    import RequestService from "../_services/RequestService";

    export default {
        components: {RequestForm},
        data() {
            return {
                page: {},
                loading: true
            }
        },
        methods: {
            fetch() {
                this.fetchPage();
            },
            fetchPage() {
                RequestService.getCreatePage(this.id).then(data => {
                    this.page = data;
                    this.loading = false;
                }, error => {
                    this.flashError(this.$helper.showError(error));
                });
            }
        },
        created() {
            this.fetch();
        }
    };
</script>
