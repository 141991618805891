var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-expansion-panels', [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('v-card-title', [_vm._v("История посещений")])], 1), _c('v-expansion-panel-content', [_c('div', [_c('v-data-table', {
    staticClass: "table__framed",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.visits,
      "item-key": "id",
      "loading": _vm.loading,
      "loading-text": "Загрузка ...",
      "footer-props": {
        'items-per-page-options': [10, 20, 30, 100, 500]
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var items = _ref.items;
        return [_c('tbody', _vm._l(items, function (item, key) {
          var _item$user;
          return _c('tr', {
            key: item.id
          }, [_c('td', {
            attrs: {
              "width": "200"
            }
          }, [_vm._v(" " + _vm._s(_vm.$moment(item.createdAt).dateTimeFormat()) + " ")]), _c('td', [_c('div', [_vm._v(_vm._s((_item$user = item.user) === null || _item$user === void 0 ? void 0 : _item$user.fullName))])])]);
        }), 0)];
      }
    }])
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }