var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, table-row@3, button"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading ? _c('div', {
    staticClass: "my-10"
  }, [_c('v-card-title', [_vm._v(" Почтовый менеджер "), _c('v-spacer'), _c('NotificationDialog', {
    attrs: {
      "id": _vm.id,
      "channelTypes": _vm.channelTypes,
      "types": _vm.types
    },
    on: {
      "saved": function saved($event) {
        return _vm.fetchItems();
      }
    }
  })], 1), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.notifications,
      "options": _vm.optionsForm,
      "server-items-length": _vm.totalElements,
      "item-key": "id",
      "loading": _vm.loadingTable,
      "loading-text": "Загрузка ...",
      "items-per-page": 10,
      "footer-props": {
        'items-per-page-options': [10, 20, 30, 50]
      }
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsForm = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var items = _ref.items;
        return [_c('tbody', _vm._l(items, function (item) {
          var _item$result, _item$result2, _item$result3;
          return _c('tr', {
            key: item.id
          }, [_c('td', {
            staticClass: "px-5",
            attrs: {
              "width": "20"
            }
          }), _c('td', {
            attrs: {
              "width": "160"
            }
          }, [_vm._v(_vm._s(_vm.$moment(item.createdAt).dateTimeFormat()))]), _c('td', {
            attrs: {
              "width": "300"
            }
          }, [_vm._v(_vm._s(item.user.fullName))]), _c('td', {
            attrs: {
              "width": "130"
            }
          }, [((_item$result = item.result) === null || _item$result === void 0 ? void 0 : _item$result.status) === 'SUCCESS' || !item.result ? _c('v-icon', {
            attrs: {
              "small": "",
              "color": "green"
            }
          }, [_vm._v(" mdi-check-circle-outline ")]) : _vm._e(), ((_item$result2 = item.result) === null || _item$result2 === void 0 ? void 0 : _item$result2.status) === 'FAILURE' ? _c('div', [_c('v-tooltip', {
            attrs: {
              "right": "",
              "color": "red",
              "content-class": "tooltip__primary"
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function fn(_ref2) {
                var on = _ref2.on;
                return [_c('v-icon', _vm._g({
                  attrs: {
                    "small": "",
                    "color": "red"
                  }
                }, on), [_vm._v(" mdi-message-alert-outline ")])];
              }
            }], null, true)
          }, [_c('span', [_vm._v(_vm._s((_item$result3 = item.result) === null || _item$result3 === void 0 ? void 0 : _item$result3.description))])])], 1) : _vm._e()], 1), _c('td', {
            attrs: {
              "width": "300"
            }
          }, [item.channel ? _c('div', [_vm._v(" " + _vm._s(item.channel.text) + " ")]) : _vm._e()]), _c('td', [item.type ? _c('div', [_vm._v(" " + _vm._s(item.type.text) + " ")]) : _vm._e()])]);
        }), 0)];
      }
    }], null, false, 3478870053)
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }