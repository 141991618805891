<template>
    <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on }">
            <v-btn large color="yellow" class="mx-4" outlined v-on="on">
                <v-icon left>mdi-calendar-clock</v-icon>
                <div v-if="isNewAppointment">Записать</div>
                <div v-if="!isNewAppointment">Изменить запись</div>
            </v-btn>
            <div v-if="!isNewAppointment && request.isOnlineAppointment" class="mx-4 mt-2">
                <v-chip outlined>
                    <v-icon small left>mdi-laptop</v-icon>
                    Онлайн
                </v-chip>
            </div>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Записать на прием</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-select
                            prepend-icon="mdi-office-building"
                            v-model="form.office"
                            label="Офис"
                            :items="offices"
                            item-text="name"
                            item-value="id">
                        </v-select>
                        <v-alert v-if="form.errors.has('office')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('office') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-dialog
                            ref="dateDialog" v-model="dateDialog"
                            :return-value.sync="form.appointmentDate"
                            persistent width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="form.appointmentDate"
                                    label="Дата"
                                    prepend-icon="mdi-calendar-month-outline"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="form.appointmentDate" type="date" scrollable
                                first-day-of-week="1"
                                :min="new Date().toISOString().slice(0,10)">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="dateDialog = false">Отмена</v-btn>
                                <v-btn text color="primary"
                                       @click="$refs.dateDialog.save(form.appointmentDate)">
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                        <v-alert v-if="form.errors.has('appointmentDate')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('appointmentDate') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select
                            v-model="form.appointmentTime"
                            label="Время"
                            :items="times"
                            required>
                        </v-select>
                        <v-alert v-if="form.errors.has('appointmentTime')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('appointmentTime') }}
                        </v-alert>
                    </v-col>
                    <v-col cols="auto">
                        <v-checkbox
                            class="chekbox__blue"
                            v-model="form.isOnlineAppointment"
                            label="Онлайн">
                        </v-checkbox>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">Отмена</v-btn>
                <v-btn color="blue darken-1" text @click="save">Записать</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Form from "../../_helpers/_form/Form";
import RequestService from "../../_services/RequestService";

export default {
    name: 'AppointmentDialog',
    props: {
        id: Number,
        request: Object,
        offices: Array,
        isNewAppointment: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            form: this.request
                ? new Form({...this.request, appointmentTime: this.request?.appointmentTime?.value})
                : new Form({
                    appointmentDate: null,
                    appointmentTime: null,
                    office: null
                }),
            dialog: false,
            dateDialog: false,
            times: []
        }
    },
    methods: {
        fetch() {
            this.fetchOffices();
            this.fetchTime();
        },
        fetchOffices() {
            this.form.office = this.offices[0].id;
        },
        fetchTime() {
            if (!this.form.appointmentDate) return;
            RequestService.getAvailableTimesForDate(this.form.appointmentDate, this.form.office).then(data => {
                this.times = data;
            });
        },
        save() {
            RequestService.addAppointment(this.id, this.form).then(() => {
                this.dialog = false;
                this.$emit('saved');
            });
        },
    },
    watch: {
        date: {
            handler() {
                this.fetchTime();
            }
        },
        office: {
            handler() {
                this.fetchTime();
            }
        }
    },
    computed: {
        date() {
            return this.form.appointmentDate;
        },
        office() {
            return this.form.office;
        }
    },
    created() {
        this.fetch();
    }
};
</script>
