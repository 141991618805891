import {http} from "../_helpers";

class CommentService {

    getComments(filter) {
        return http.get(this.makeOptionsUrl(`/comments`, {}, filter));
    }

    delete(id) {
        return http.delete(`/comments/${id}`);
    }
}

export default new CommentService;
