<template>
    <div style="padding: 0 4px">
        <v-navigation-drawer
            v-model="primaryDrawer.model"
            :clipped="primaryDrawer.clipped"
            :floating="primaryDrawer.floating"
            :mini-variant="primaryDrawer.mini"
            :permanent="primaryDrawer.type === 'permanent'"
            :temporary="primaryDrawer.type === 'temporary'"
            app
            overflow>
            <div class="pa-2 pl-4 pt-4">
                <img :src="appLogoUrl" width="140" alt="CRM">
            </div>
            <v-divider></v-divider>
            <v-list-item three-line>
                <v-list-item-avatar v-if="false">
                    <img src="https://randomuser.me/api/portraits/women/81.jpg" alt="Аватар">
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{ user.fullName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ user.roleDesc }}</v-list-item-subtitle>
                    <div class="pt-2">
                        <v-btn color="error" outlined x-small @click="logout">Выйти</v-btn>
                    </div>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list dense>
                <v-list-item v-for="menu in userMenu" :key="menu.title" link :to="menu.link">
                    <v-list-item-icon>
                        <v-icon>{{ menu.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ menu.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <Billing v-if="$helper.permission.canSeeTheBilling(user)"></Billing>
            <LawyerPlan v-if="$helper.permission.canSeeLawyerPlan(user)"></LawyerPlan>
            <Settings></Settings>
        </v-navigation-drawer>
        <v-app-bar :clipped-left="primaryDrawer.clipped" app>
            <v-app-bar-nav-icon @click.stop="primaryDrawer.model = !primaryDrawer.model"/>
            <Birthday></Birthday>
            <v-spacer></v-spacer>
            <v-btn
                v-if="$helper.permission.canSeeSettings(user)"
                to="/settings"
                text>
                <v-icon>mdi-cog-outline</v-icon>
            </v-btn>
            <v-btn
                text
                v-if="$helper.permission.canSeeNotifications(user)"
                @click="openNotifications()">
                <v-icon>mdi-bell-outline</v-icon>
                Уведомления
            </v-btn>
        </v-app-bar>
        <NyBalls v-if="false"></NyBalls>
        <v-content style="margin-top: 35px">
            <v-app-bar-nav-icon v-if="false" @click.stop="primaryDrawer.model = !primaryDrawer.model"/>
            <v-progress-linear :active="$store.state.loading" :indeterminate="true" height="4"></v-progress-linear>
            <v-container fluid>
                <transition mode="out-in" name="fade">
                    <template v-if="!$store.state.loading">
                        <slot></slot>
                    </template>
                </transition>
                <div class="flashpool">
                    <flash-message></flash-message>
                </div>
            </v-container>
        </v-content>
        <v-navigation-drawer
            v-model="panels.notification.model"
            v-if="$helper.permission.canSeeNotifications(user)"
            right
            fixed
            width="600"
            class="notification-panel">
            <NotificationPanel
                v-on:closeNotificationPanel="panels.notification.model = false"
                @forceUpdate="notificationPanelKey++"
                :key="notificationPanelKey">
            </NotificationPanel>
        </v-navigation-drawer>
        <v-footer :inset="footer.inset" app>
            <span class="px-4">&copy; {{ new Date().getFullYear() }}</span>
        </v-footer>
        <ScrollToTop></ScrollToTop>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import UserService from "../_services/UserService";
import StompClient from "../_helpers/StompClient";
import Settings from "../app/Settings";
import Billing from "../app/Billing";
import NotificationPanel from "../notifications/NotificationPanel";
import NyBalls from "../_chunks/NyBalls";
import LawyerPlan from "@/app/LawyerPlan";
import ScrollToTop from "@/_components/ScrollToTop.vue";
import Birthday from "@/users/widgets/Birthday.vue";

export default {
    name: "DefaultLayout",
    components: {Birthday, ScrollToTop, LawyerPlan, NyBalls, NotificationPanel, Settings, Billing},
    data() {
        return {
            primaryDrawer: {
                model: null,
                clipped: false,
                floating: false,
                mini: false,
            },
            panels: {
                notification: {
                    model: false
                }
            },
            footer: {
                inset: false,
            },
            notificationPanelKey: 0,
        }
    },
    computed: {
        ...mapState(['user']),
        menus() {
            return [
                {
                    title: 'База заявок',
                    icon: 'mdi-format-list-bulleted-square',
                    link: '/',
                    secured: ['ADMIN', 'LAWYER_MANAGER', 'OPERATOR_MANAGER', 'OPERATOR', 'PROVIDER', 'COUNTERPART']
                },
                {
                    title: 'Лист записей',
                    icon: 'mdi-format-list-checks',
                    link: '/',
                    secured: ['RECEPTION', 'RECEPTION_COUNTERPARTY']
                },
                {
                    title: 'Лист записей',
                    icon: 'mdi-format-list-checks',
                    link: '/appointments',
                    secured: ['ADMIN', 'OPERATOR_MANAGER', 'OPERATOR']
                },
                {
                    title: 'База клиентов',
                    icon: 'mdi-account-cash-outline',
                    link: '/',
                    disabled: this.user.role !== 'LAWYER'
                },
                {
                    title: 'База клиентов',
                    icon: 'mdi-account-cash-outline',
                    link: '/clients',
                    secured: ['ADMIN', 'LAWYER_MANAGER', 'MANAGER_OKK', 'RECEPTION'],
                    disabled: !this.$helper.env.isTrue('VUE_APP_CLIENTS_ENABLED')
                },
                {
                    title: 'База договоров',
                    icon: 'mdi-text-box-multiple-outline',
                    link: '/contracts',
                    secured: ['ADMIN', 'LAWYER_MANAGER', 'LAWYER', 'RECEPTION', 'MANAGER_OKK', 'MANAGER_OGPIA', 'MANAGER_EPO', 'LAWYER_OGPIA', 'LAWYER_EPO', 'BUHGALTER'],
                    disabled: !this.$helper.env.isTrue('VUE_APP_CONTRACTS_ENABLED')
                },
                {
                    title: 'База ОКК',
                    icon: 'mdi-text-box-check-outline',
                    link: '/okk/contracts',
                    secured: ['ADMIN', 'LAWYER_MANAGER', 'LAWYER', 'RECEPTION', 'MANAGER_OKK', 'MANAGER_OGPIA', 'MANAGER_EPO', 'LAWYER_OGPIA', 'LAWYER_EPO', 'BUHGALTER'],
                    disabled: !this.$helper.env.isTrue('VUE_APP_CONTRACTS_ENABLED')
                },
                {
                    title: 'Оплаты',
                    icon: 'mdi-cash-100',
                    link: '/contract-payments',
                    secured: ['ADMIN', 'LAWYER_MANAGER', 'LAWYER', 'RECEPTION', 'BUHGALTER'],
                    disabled: !this.$helper.env.isTrue('VUE_APP_CONTRACTS_ENABLED')
                },
                {
                    title: 'База актов и выплат',
                    icon: 'mdi-text-box-check-outline',
                    link: '/acts',
                    secured: ['ADMIN', 'LAWYER_MANAGER', 'LAWYER', 'RECEPTION', 'MANAGER_OKK', 'MANAGER_OGPIA', 'MANAGER_EPO', 'LAWYER_OGPIA', 'LAWYER_EPO', 'BUHGALTER'],
                    disabled: !this.$helper.env.isTrue('VUE_APP_CONTRACTS_ENABLED')
                },
                {
                    title: 'События',
                    icon: 'mdi-clock-edit-outline',
                    link: '/contract-events',
                    secured: ['ADMIN', 'LAWYER_MANAGER', 'LAWYER', 'RECEPTION', 'MANAGER_OKK', 'MANAGER_OGPIA', 'MANAGER_EPO', 'LAWYER_OGPIA', 'LAWYER_EPO', 'BUHGALTER'],
                    disabled: !this.$helper.env.isTrue('VUE_APP_CONTRACTS_ENABLED')
                },

                {title: 'Напоминания', icon: 'mdi-calendar-check-outline', link: '/reminders'},
                {
                    title: 'Статистика ОРК',
                    icon: 'mdi-chart-bar',
                    link: '/reports',
                    secured: ['ADMIN', 'OPERATOR_MANAGER', 'OPERATOR', 'PROVIDER']
                },
                {
                    title: 'Статистика (юр. отдел)',
                    icon: 'mdi-chart-bar',
                    link: '/reports-lawyers',
                    secured: ['ADMIN', 'LAWYER_MANAGER', 'LAWYER', 'MANAGER_OKK', 'RECEPTION']
                },
                {
                    title: 'Статистика (ЭПО)',
                    icon: 'mdi-chart-bar',
                    link: '/reports-epo',
                    secured: ['ADMIN', 'MANAGER_EPO', 'LAWYER_EPO', 'LAWYER_MANAGER', 'MANAGER_OKK']
                },
                {
                    title: 'Сотрудники',
                    icon: 'mdi-account-circle',
                    link: '/users',
                    secured: ['ADMIN', 'OPERATOR_MANAGER']
                },
                {title: 'Подразделения', icon: 'mdi-account-group', link: '/subdivisions', secured: ['ADMIN']},
                {
                    title: 'Биллинг',
                    icon: 'mdi-cash-100',
                    link: '/payments',
                    secured: ['ADMIN', 'PROVIDER', 'COUNTERPART']
                },
                // {title: 'Отчеты', icon: 'mdi-account', link: '/requests/create'},
                // {title: 'Лист записи', icon: 'mdi-account-group-outline', link: '/requests/1'},
            ]
        },
        userMenu() {
            let user = this.user;
            return this.menus.filter(function (item) {
                return (!item.secured || item.secured.includes(user.role)) && !item.disabled;
            });
        },
        appLogoUrl() {
            return require(`../_assets/${process.env.VUE_APP_LOGO}`);
        },
    },
    methods: {
        logout() {
            UserService.logout();
        },
        openNotifications() {
            this.notificationPanelKey++;
            this.panels.notification.model = true
        }
    },
    beforeCreate() {
        this.$vuetify.theme.dark = true
    },
    mounted() {
        if (this.$messaging) {
            Notification.requestPermission()
                .then(permission => {
                    if (permission === "granted") {
                        this.$messaging
                            .getToken({vapidKey: "BBicq5D95lURBugi9EtB-S0K_H1t18m3-YSAiOROovRd1qbJVrNvWehOn-KElMbHwfSbzug9cMMWfBUUdyB3-80"})
                            .then(token => {
                                UserService.updateFirebaseToken(token);
                            });
                    } else {
                        console.log("Unable to get permission to notify.");
                    }
                });
        }
    },
    created() {
        let stompClient = new StompClient(this.user, this.$store);
        stompClient.init();
    }
}
</script>


