<template>
    <v-card-text style="margin-top: -75px;">
        <div v-if="loading">
            <v-skeleton-loader type="table-row@3"></v-skeleton-loader>
        </div>
        <div v-if="!loading">
            <v-data-table
                :headers="plan.headers"
                :items="plan.items"
                hide-default-footer
                disable-pagination
                class="table__lined"
            >
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td width="200">{{ item.target }}</td>
                        <td width="200">{{ $helper.format.currency(item.amount) }}</td>
                        <td width="250">{{ item.date }}</td>
                        <td width="200">
                            <div v-if="item.target == 'План дня'">
                                {{ $helper.format.currency(todayItem?.cleanCash || 0) }}
                            </div>
                            <div v-if="item.target == 'План отрезка'">
                                {{ $helper.format.currency(total('cleanCash', intervalItems)) }}
                            </div>
                            <div v-if="item.target == 'План периода'">
                                {{ $helper.format.currency(total('cleanCash', periodItems)) }}
                            </div>
                        </td>
                        <td>
                            <div v-if="item.target == 'План дня'">
                                <span :class="getResidueColor(getDayResidue())">
                                    {{ $helper.format.currency(getDayResidue()) }}
                                </span>
                            </div>
                            <div v-if="item.target == 'План отрезка'">
                                <span :class="getResidueColor(getIntervalResidue())">
                                    {{ $helper.format.currency(getIntervalResidue()) }}
                                </span>
                            </div>
                            <div v-if="item.target == 'План периода'">
                                <span :class="getResidueColor(getPeriodResidue())">
                                    {{ $helper.format.currency(getPeriodResidue()) }}
                                </span>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
    </v-card-text>
</template>

<script>

import ReportService from "@/_services/ReportService";
import {mapState} from "vuex";
import interval from "echarts/src/scale/Interval";

export default {
    name: 'PlanTable',
    components: { },
    props: {
        filter: Object,
    },
    data: function() {
        return {
            loading: false,
            todayItem: null,
            periodItems: [],
            intervalItems: []
        }
    },
    watch: {
        globalSettings: {
            handler() {
                this.fetch();
            },
            deep: true
        }
    },
    methods: {
        interval,
        fetch() {
            ReportService.getLawyerDepartmentReport({ dates: this.$moment().format('YYYY-MM-DD') }).then(page => {
                this.todayItem = page?.items?.[0] || {};
            });

            // INTERVAL
            const intervalDates = {
                dates: this.globalSettings?.reporting?.intervalDates?.join(",") || this.$moment().format('YYYY-MM-DD')
            }
            ReportService.getLawyerDepartmentReport(intervalDates).then(page => {
                this.intervalItems = page?.items || [];
                this.loading = false;
            });

            // PERIOD
            const periodDates = {
                dates: this.globalSettings?.reporting?.periodDates?.join(",") || this.$moment().format('YYYY-MM-DD')
            }
            ReportService.getLawyerDepartmentReport(periodDates).then(page => {
                this.periodItems = page?.items || [];
                this.loading = false;
            });
        },
        getStatColor(number, options) {
            const colorSuffix = '_link';
            const defaultColor = 'yellow';
            const colors = options?.colors;
            let color = defaultColor;
            if (colors) {
                Object.entries(colors).forEach(([colorProp, value]) => {
                    if (Array.isArray(value)) {
                        const from = value[0];
                        const to = value[1];
                        if (number >= from && number <= to) {
                            color = colorProp;
                        }
                    }
                });
            }
            return color + colorSuffix;
        },
        getDayResidue() {
            return (this.todayItem?.cleanCash || 0) - (this.globalSettings?.reporting?.planDay || 0);
        },
        getIntervalResidue() {
            return this.total('cleanCash', this.intervalItems) - (this.globalSettings?.reporting?.planInterval || 0);
        },
        getPeriodResidue() {
            return this.total('cleanCash', this.periodItems) - (this.globalSettings?.reporting?.planPeriod || 0);
        },
        getResidueColor(residue) {
            return (residue >= 0 ? 'green' : 'red') + '_link';
        }
    },
    computed: {
        ...mapState(['globalSettings']),
        plan() {
            return {
                headers: [
                    {text: 'Цель', value: 'target'},
                    {text: 'Сумма плана', value: 'amount'},
                    {text: 'Дата', value: 'date'},
                    {text: 'Касса', value: 'cash'},
                    {text: 'Остаток', value: 'residue'}
                ],
                items: [
                    {
                        target: 'План дня',
                        amount: this.globalSettings?.reporting?.planDay ?? 0,
                        date: this.$moment().format('YYYY-MM-DD'),
                        cash: 0,
                        residue: 0
                    },
                    {
                        target: 'План отрезка',
                        amount: this.globalSettings?.reporting?.planInterval?? 0,
                        date: Array.isArray(this.globalSettings?.reporting?.intervalDates)
                            ? this.globalSettings.reporting.intervalDates.join(' - ')
                            : this.$moment().format('YYYY-MM-DD'),
                        cash: 0,
                        residue: 0
                    },
                    {
                        target: 'План периода',
                        amount: this.globalSettings?.reporting?.planPeriod ?? 0,
                        date: Array.isArray(this.globalSettings?.reporting?.periodDates)
                            ? this.globalSettings.reporting.periodDates.join(' - ')
                            : this.$moment().format('YYYY-MM-DD'),
                        cash: 0,
                        residue: 0
                    }
                ]
            }
        },
        total() {
            return (column, items) => {
                if (!column) {
                    return '';
                }
                items = items || [];
                let total = 0;
                items?.forEach(item => {
                    if (column.includes('.')) {
                        const columnPath = column.split('.');
                        total += Number.parseFloat(item[columnPath[0]][columnPath[1]]) || 0;
                    } else {
                        total += Number.parseFloat(item[column]) || 0;
                    }
                });
                return total;
            }
        }
    },
    created() {
        this.loading = true;
        this.fetch();
    },
};
</script>