var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "panel__notification",
    class: {
      expanded: _vm.isFiltered()
    }
  }, [_c('v-card-title', {
    staticClass: "text-h6 panel-header__fixed"
  }, [_c('v-row', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "sm": "auto"
    }
  }, [_vm._v(" Уведомления " + _vm._s(_vm.notificationSize) + " ")]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "error",
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('closeNotificationPanel');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-close-circle-outline")])], 1)], 1)], 1), _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('v-dialog', {
    ref: "dateDialog",
    attrs: {
      "return-value": _vm.filterForm.createdAtDates,
      "persistent": "",
      "width": "290px"
    },
    on: {
      "update:returnValue": function updateReturnValue($event) {
        return _vm.$set(_vm.filterForm, "createdAtDates", $event);
      },
      "update:return-value": function updateReturnValue($event) {
        return _vm.$set(_vm.filterForm, "createdAtDates", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "Дата",
            "prepend-icon": "mdi-calendar-month-outline",
            "readonly": "",
            "clearable": ""
          },
          model: {
            value: _vm.filterForm.createdAtDates,
            callback: function callback($$v) {
              _vm.$set(_vm.filterForm, "createdAtDates", $$v);
            },
            expression: "filterForm.createdAtDates"
          }
        }, on))];
      }
    }]),
    model: {
      value: _vm.dialog.createdDates.modal,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog.createdDates, "modal", $$v);
      },
      expression: "dialog.createdDates.modal"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "type": "date",
      "scrollable": "",
      "range": "",
      "first-day-of-week": "1"
    },
    model: {
      value: _vm.dialog.createdDates.model,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog.createdDates, "model", $$v);
      },
      expression: "dialog.createdDates.model"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.dialog.createdDates.modal = false;
      }
    }
  }, [_vm._v("Отмена")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.dateDialog.save(_vm.dialog.createdDates.model);
      }
    }
  }, [_vm._v("OK ")])], 1)], 1)], 1), _c('v-row', {
    staticClass: "panel__notification-filter-checkbox"
  }, [_c('v-col', {
    attrs: {
      "sm": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Уведомления",
      "value": "Contract",
      "multiple": "",
      "hide-details": ""
    },
    model: {
      value: _vm.filterForm.notificationableTypes,
      callback: function callback($$v) {
        _vm.$set(_vm.filterForm, "notificationableTypes", $$v);
      },
      expression: "filterForm.notificationableTypes"
    }
  })], 1), _c('v-col', {
    attrs: {
      "sm": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Комментарии",
      "value": "Comment",
      "multiple": "",
      "hide-details": ""
    },
    model: {
      value: _vm.filterForm.notificationableTypes,
      callback: function callback($$v) {
        _vm.$set(_vm.filterForm, "notificationableTypes", $$v);
      },
      expression: "filterForm.notificationableTypes"
    }
  })], 1), _c('v-col', {
    attrs: {
      "sm": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Напоминания",
      "value": "Reminder",
      "multiple": "",
      "hide-details": ""
    },
    model: {
      value: _vm.filterForm.notificationableTypes,
      callback: function callback($$v) {
        _vm.$set(_vm.filterForm, "notificationableTypes", $$v);
      },
      expression: "filterForm.notificationableTypes"
    }
  })], 1)], 1), _vm.isFiltered() ? _c('v-expansion-panels', {
    staticClass: "filter-expansion"
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v(" Фильтр ")]), _c('v-expansion-panel-content', [_c('v-row', {
    staticStyle: {
      "margin-bottom": "-30px"
    }
  }, [_c('v-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('v-autocomplete', {
    staticStyle: {
      "z-index": "99"
    },
    attrs: {
      "label": "Автор",
      "items": _vm.users,
      "item-value": "id",
      "item-text": "fullName",
      "clearable": true
    },
    model: {
      value: _vm.filterForm.user,
      callback: function callback($$v) {
        _vm.$set(_vm.filterForm, "user", $$v);
      },
      expression: "filterForm.user"
    }
  })], 1)], 1), _vm.isNotificationSelected() ? _c('v-row', {
    staticStyle: {
      "margin-bottom": "-30px"
    }
  }, [_c('v-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('v-autocomplete', {
    staticStyle: {
      "z-index": "99"
    },
    attrs: {
      "label": "Тип уведомления",
      "multiple": "",
      "items": _vm.notificationTypes,
      "clearable": true
    },
    model: {
      value: _vm.filterForm.notificationTypes,
      callback: function callback($$v) {
        _vm.$set(_vm.filterForm, "notificationTypes", $$v);
      },
      expression: "filterForm.notificationTypes"
    }
  })], 1)], 1) : _vm._e(), _vm.isCommentSelected() ? _c('v-row', {
    staticStyle: {
      "margin-bottom": "-30px"
    }
  }, [_c('v-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('v-select', {
    staticStyle: {
      "z-index": "99"
    },
    attrs: {
      "label": "Тип комментария",
      "multiple": "",
      "items": _vm.commentTypes,
      "clearable": true
    },
    model: {
      value: _vm.filterForm.commentTypes,
      callback: function callback($$v) {
        _vm.$set(_vm.filterForm, "commentTypes", $$v);
      },
      expression: "filterForm.commentTypes"
    }
  })], 1)], 1) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1), _c('v-card-text', _vm._l(_vm.notifications, function (notification) {
    return _c('div', {
      key: notification.notificationId,
      staticClass: "flash__message",
      class: notification.colorClass
    }, [_c('div', {
      staticClass: "flash__message-content"
    }, [notification.type && notification.type.value !== 'DEFAULT' ? _c('div', {
      staticClass: "subtitle-1"
    }, [_c('strong', [_vm._v(_vm._s(notification.type.text))])]) : _vm._e(), _c('strong', {
      staticClass: "subtitle-2",
      domProps: {
        "innerHTML": _vm._s(notification.title)
      }
    }), _c('br'), notification.subTitle ? _c('div', [_c('strong', {
      staticClass: "subtitle-2",
      domProps: {
        "innerHTML": _vm._s(notification.subTitle)
      }
    }), _c('br')]) : _vm._e(), _c('a', {
      staticClass: "linkTo",
      attrs: {
        "href": notification.url
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('closeNotificationPanel');
        }
      }
    }, [_vm._v("Открыть #" + _vm._s(notification.id))]), _vm._v(" "), _c('br'), _vm._v(" " + _vm._s(_vm.$helper.format.dateTime(notification.createdAt))), _c('br'), _c('div', {
      staticClass: "flash__message-content-body",
      domProps: {
        "innerHTML": _vm._s(notification.body)
      }
    })]), _c('button', {
      staticClass: "flash__close-button",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function click($event) {
          return _vm.deleteNotification(notification.notificationId);
        }
      }
    }, [_c('span', [_vm._v("×")])])]);
  }), 0), _vm.moreDataToAvailable ? _c('v-skeleton-loader', _vm._b({
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: _vm.loadNextPage,
      expression: "loadNextPage"
    }],
    key: _vm.loaderKey,
    attrs: {
      "type": "article"
    },
    on: {
      "forceUpdate": function forceUpdate($event) {
        _vm.loaderKey++;
      }
    }
  }, 'v-skeleton-loader', _vm.skeletonAttrs, false)) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }