export const reportHelper = {
    // ork
    orkCameResultStatOptions: {
        colors: {
            red: [0, 16.999999],
            yellow: [17, 22.999999],
            green: [23, 9999999999999]
        }
    },

    // lawyers
    contractStatOptions: {
        colors: {
            red: [0, 19],
            yellow: [20, 25],
            green: [26, 100]
        }
    },
    contractPrimaryStatOptions: {
        colors: {
            red: [0, 16],
            yellow: [17, 20],
            green: [21, 100]
        }
    },
    payloadStatOptions: {
        colors: {
            red: [0, 13000.99],
            yellow: [13001, 17599.99],
            green: [17600, 9999999999999]
        }
    },
    moneyBackStatOptions: {
        colors: {
            red: [-99999999, -0.0001],
            yellow: [0, 9999999999999]
        }
    },
    cleanCashStatOptions: {
        colors: {
            red: [-99999999, 3000000],
            yellow: [3000001, 4500000],
            green: [4500001, 9999999999999]
        }
    },
    lawyerDepartment: {
        avgSumm: {
            colors: {
                red: [-99999999, 69999],
                yellow: [70000, 79999],
                green: [80000, 9999999999999]
            }
        }
    },

    getPercentageHtml(number1, number2, options = {}) {
        const number = this.getPercentage(number1, number2);
        const percentage = Math.round((number + Number.EPSILON) * 100);
        const statColor = this.getStatColor(percentage, options);
        return `<span class="${statColor}">${percentage}%</span>`;
    },
    getStatColor(number, options) {
        const colorSuffix = '_link';
        const defaultColor = 'yellow';
        const colors = options?.colors;
        let color = defaultColor;
        if (colors) {
            Object.entries(colors).forEach(([colorProp, value]) => {
                if (Array.isArray(value)) {
                    const from = value[0];
                    const to = value[1];
                    if (number >= from && number <= to) {
                        color = colorProp;
                    }
                }
            });
        }
        return color + colorSuffix;
    },
    getPercentage(number1, number2) {
        if (!number1 || !number2) {
            return 0;
        }
        return number2 == 0 ? 0 : number1 / number2;
    },
    getPairKey(pair) {
        if (!pair) {
            return null;
        }
        const pairObject = this.getPairObject(pair);
        return parseInt(pairObject?.key);
    },
    getPairValue(pair) {
        if (!pair) {
            return null;
        }
        const pairObject = this.getPairObject(pair);
        return pairObject?.value;
    },
    getPairObject: function (pair) {
        const entries = Object.entries(pair);
        const [key, value] = entries?.[0];
        return { key, value };
    },
    getOrkCompletedPlan: function (periodStat) {
        const appointmentsCame = periodStat.find(el => el.state === "APPOINTMENT_CAME")?.count ?? 0;
        const appointmentsOnline = periodStat.find(el => el.state === "APPOINTMENT_ONLINE")?.count ?? 0;
        return (appointmentsCame - (appointmentsOnline * 0.5)) || 0;
    },
    getOrkContractResult: function (periodStat) {
        return periodStat.find(el => el.state === "CONTRACT_RESULT")?.count ?? 0;
    }
};