<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-header>
                <v-card-title>История изменений</v-card-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div style="overflow-x: scroll; overflow-y: auto">
                    <v-data-table
                        :style="`min-width: 3850px`"
                        :headers="headers"
                        fixed-header
                        :items="contracts"
                        :options.sync="contractGridsOptions.options"
                        :server-items-length="totalElements"
                        item-key="id"
                        :disable-sort="true"
                        :loading="loading"
                        loading-text="Загрузка ..."
                        :footer-props="{'items-per-page-options':[10, 20, 30, 100, 500]}"
                        class="table__framed"
                        :height="tableHeight">>
                        <template v-slot:body="{ items }">
                            <tbody>
                            <tr v-for="(item, key) in items" :key="item.id">
                                <td width="150">
                                    <div v-if="item.modifiedAt">
                                        {{ $moment(item.modifiedAt).dateTimeFormat() }}
                                    </div>
                                </td>
                                <td width="200">
                                    <div>{{ item.modifiedBy }}</div>
                                </td>
                                <td>
                                    <div v-if="item.number">
                                        {{ item.number }}
                                    </div>
                                    <div v-html="showChange(item, 'number')"></div>
                                </td>
                                <td>
                                    <v-chip v-if="item.state" :color="item.state.chipColor" outlined dark>
                                        {{item.state.text}}
                                    </v-chip>
                                    <div v-html="showChange(item, 'state', (field) => field.text)"></div>
                                </td>
                                <td>
                                    <v-chip v-if="item.phase" :color="item.phase.chipColor" outlined dark>
                                        {{item.phase.text}}
                                    </v-chip>
                                    <div v-html="showChange(item, 'phase', (field) => field.text)"></div>
                                </td>
                                <td>
                                    <div v-if="item.userName">
                                        {{ item.userName }}
                                    </div>
                                    <div v-html="showChange(item, 'userName')"></div>
                                </td>
                                <td>
                                    <div v-if="item.principal">
                                        {{ item.principal }}
                                    </div>
                                    <div v-html="showChange(item, 'principal')"></div>
                                </td>
                                <td>
                                    <div v-if="item.clientEmail">
                                        {{ item.clientEmail }}
                                    </div>
                                    <div v-html="showChange(item, 'clientEmail')"></div>
                                </td>
                                <td>
                                    <div v-if="item.lawyer">
                                        {{ item.lawyer.fullName }}
                                    </div>
                                    <div v-html="showChange(item, 'lawyer', lawyer => lawyer?.fullName)"></div>
                                </td>
                                <td>
                                    <div v-if="item.lawyerEPO">
                                        {{ item.lawyerEPO.fullName }}
                                    </div>
                                    <div v-html="showChange(item, 'lawyerEPO', lawyer => lawyer?.fullName)"></div>
                                </td>
                                <td width="200">
                                    <div v-if="item.lawyerOGPA">
                                        {{ item.lawyerOGPA.fullName }}
                                    </div>
                                    <div v-html="showChange(item, 'lawyerOGPA', lawyer => lawyer?.fullName)"></div>
                                </td>
                                <td>
                                    <div v-if="item.isPaymentOGPA">
                                        {{ $helper.showBooleanValue(item.isPaymentOGPA) }}
                                    </div>
                                    <div v-html="showChange(item, 'isPaymentOGPA')"></div>
                                </td>
                                <td>
                                    {{ $moment(item.createdAt).dateTimeFormat() }}
                                </td>
                                <td>
                                    <div v-if="item.amount">
                                        {{ item.amount }}
                                    </div>
                                    <div v-html="showChange(item, 'amount')"></div>
                                </td>
                                <td>
                                    <div v-if="item.type">
                                        {{ getTypeName(item.type) }}
                                    </div>
                                    <div v-html="showChange(item, 'type', type => getTypeName(type))"></div>
                                </td>
                                <td>
                                    <div v-if="item.subject">
                                        {{ getSubjectName(item.subject) }}
                                    </div>
                                    <div v-html="showChange(item, 'subject', subject => getSubjectName(subject))"></div>
                                </td>
                                <td>
                                    <div v-if="item.actions">
                                        {{ item.actions }}
                                    </div>
                                    <div v-html="showChange(item, 'actions')"></div>
                                </td>
                                <td>
                                    <div v-if="item.additionalLawyer">
                                        {{ item.additionalLawyer.fullName }}
                                    </div>
                                    <div v-html="showChange(item, 'additionalLawyer', lawyer => lawyer?.fullName)"></div>
                                </td>
                                <td width="200">
                                    <div v-if="item.additionalLawyerOGPA">
                                        {{ item.additionalLawyerOGPA.fullName }}
                                    </div>
                                    <div v-html="showChange(item, 'additionalLawyerOGPA', lawyer => lawyer?.fullName)"></div>
                                </td>
                                <td>
                                    <div v-if="item.lawyerAssistant">
                                        {{ item.lawyerAssistant.fullName }}
                                    </div>
                                    <div v-html="showChange(item, 'lawyerAssistant', lawyer => lawyer?.fullName)"></div>
                                </td>
                                <td width="200">
                                    <div v-if="item.lawyerOGPAAssistant">
                                        {{ item.lawyerOGPAAssistant.fullName }}
                                    </div>
                                    <div v-html="showChange(item, 'lawyerOGPAAssistant', lawyer => lawyer?.fullName)"></div>
                                </td>
                                <td>
                                    <div v-if="item.additionalLawyerEPO">
                                        {{ item.additionalLawyerEPO.fullName }}
                                    </div>
                                    <div v-html="showChange(item, 'additionalLawyerEPO', lawyer => lawyer?.fullName)"></div>
                                </td>
                                <td>
                                    {{ $moment(item.courtDateTime).dateFormat() }}
                                    <div v-html="showChange(item, 'courtDateTime', (time) => $moment(time).dateFormat())"></div>
                                </td>
                                <td>
                                    <div v-if="item.applicationDate">
                                        {{ $moment(item.applicationDate).dateFormat() }}
                                    </div>
                                    <div v-html="showChange(item, 'applicationDate', (time) => $moment(time).dateFormat())"></div>
                                </td>
                                <td>
                                    <div v-if="item.answerDate">
                                        {{ $moment(item.answerDate).dateFormat() }}
                                    </div>
                                    <div v-html="showChange(item, 'answerDate', (time) => $moment(time).dateFormat())"></div>
                                </td>
                                <td>
                                    <div v-if="item.moneyBackDate">
                                        {{ $moment(item.moneyBackDate).dateFormat() }}
                                    </div>
                                    <div v-html="showChange(item, 'moneyBackDate', (time) => $moment(time).dateFormat())"></div>
                                </td>
                                <td>
                                    <div v-if="item.area">
                                        {{ item.area.name }}
                                    </div>
                                    <div v-html="showChange(item, 'area', area => area?.name)"></div>
                                </td>
                                <td>
                                    <div v-if="item.comment">
                                        {{ item.comment }}
                                    </div>
                                    <div v-html="showChange(item, 'comment')"></div>
                                </td>
                                <td>
                                    <div v-if="item.office">
                                        {{ item.office.name }}
                                    </div>
                                    <div v-html="showChange(item, 'office', office => office?.name)"></div>
                                </td>
                                <!--
                                <td>
                                    <div v-if="item.transport">
                                        {{ item.transport }}
                                    </div>
                                    <div v-html="showChange(item, 'transport')"></div>
                                </td>
                                -->
                            </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import ContractService from "../../_services/ContractService";
import $ from "jquery";

export default {
        name: 'ContractRevisions',
        props: {
            id: Number,
            page: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                contracts: [],
                totalElements: 0,
                loading: true,
                headers: [
                    {text: 'Изменено', value: 'modifiedAt'},
                    {text: 'Изменил', value: 'modifiedBy'},
                    {text: 'Номер', value: 'number'},
                    {text: 'Статус', value: 'state'},
                    {text: 'Этап исполнения', value: 'phase'},
                    {text: 'Клиент', value: 'userName'},
                    {text: 'Доверитель', value: 'principal'},
                    {text: 'Email', value: 'clientEmail'},
                    {text: 'Юрист', value: 'lawyer'},
                    {text: 'Юрист ЭПО', value: 'lawyerEPO'},
                    {text: 'Юрист ОГПиА', value: 'lawyerOGPA'},
                    {text: 'Нагрузка ОГПиА', value: 'isPaymentOGPA'},
                    {text: 'Зарегистрирован', value: 'date'},
                    {text: 'Сумма', value: 'amount'},
                    {text: 'Вид', value: 'type'},
                    {text: 'Предмет', value: 'subject'},
                    {text: 'Действие по договору', value: 'actions'},
                    {text: 'Доп. юрист', value: 'additionalLawyer'},
                    {text: 'Доп. юрист ОГПиА', value: 'additionalLawyerOGPA'},
                    {text: 'Помощник юриста', value: 'lawyerAssistant'},
                    {text: 'Пом. юриста ОГПиА', value: 'lawyerOGPAAssistant'},
                    {text: 'Подача документов в суд (юрист ЭПО)', value: 'additionalLawyerEPO'},
                    {text: 'Дата суда', value: 'courtDate'},
                    {text: 'Дата поступления заявления', value: 'applicationDate'},
                    {text: 'Дата направления ответа', value: 'answerDate'},
                    {text: 'Дата возврата денежных средств', value: 'moneyBackDate'},
                    {text: 'Область права', value: 'area'},
                    {text: 'Примечание', value: 'comment'},
                    {text: 'Офис', value: 'office'},
                    //{text: 'Транспорт', value: 'transport'},
                ],
                types: this.page.types,
                subjects: this.page.subjects,
                contractGridsOptions: {
                    filter: {}
                },
                tableHeight:  this.calcTableHeight(),
            }
        },
        watch: {
            contractGridsOptions: {
                handler() {
                    this.fetchGrid();
                },
                deep: true
            }
        },
        methods: {
            fetchGrid() {
                this.loading = true;
                ContractService.getRevisions(this.id, this.contractGridsOptions.options).then(data => {
                    this.contracts = data.content;
                    this.totalElements = data.totalElements;
                    this.loading = false;
                });
            },
            getTypeName(type) {
                return this.types.find(item => item.value === type).text;
            },
            getSubjectName(subject) {
                return this.subjects.find(item => item.value === subject).text;
            },
            onResize() {
                this.tableHeight = this.calcTableHeight();
            },
            calcTableHeight() {
                const minHeight = $('.table__framed table').height();
                let maxHeight = window.innerHeight - 200;
                return minHeight < maxHeight ? minHeight : maxHeight;
            },
            showChange(item, field, supplier) {
                if (!field || !item?.changes?.[field] || !item?.[field]) return '';
                //const currentValue = item[field];
                let displayValue = item.changes[field].previousValue ?? 'пусто';
                if (supplier && displayValue !== 'пусто') {
                    displayValue = supplier(displayValue);
                }
                if (typeof displayValue == "boolean") {
                    displayValue = this.$helper.showBooleanValue(displayValue);
                }
                return `<div class="revision_old_value">${displayValue}</div>`;
            }
        },
        created() {
            this.fetchGrid();
        }
    };
</script>
