<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-header>
                <v-card-title>История посещений</v-card-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div>
                    <v-data-table
                        :headers="headers"
                        :items="visits"
                        item-key="id"
                        :loading="loading"
                        loading-text="Загрузка ..."
                        :footer-props="{'items-per-page-options':[10, 20, 30, 100, 500]}"
                        class="table__framed">
                        <template v-slot:body="{ items }">
                            <tbody>
                            <tr v-for="(item, key) in items" :key="item.id">
                                <td width="200">
                                    {{ $moment(item.createdAt).dateTimeFormat() }}
                                </td>
                                <td>
                                    <div>{{ item.user?.fullName }}</div>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import ContractService from "../../_services/ContractService";

export default {
        name: 'ContractVisits',
        props: {
            id: Number
        },
        data() {
            return {
                visits: [],
                loading: true,
                headers: [
                    {text: 'Дата', value: 'createdAt'},
                    {text: 'Пользователь', value: 'user'},
                ],
            }
        },
        methods: {
            fetchGrid() {
                this.loading = true;
                ContractService.getVisits(this.id, {}).then(data => {
                    this.visits = data;
                    this.loading = false;
                });
            },
        },
        created() {
            this.fetchGrid();
        }
    };
</script>
