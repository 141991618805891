var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "padding": "0 4px"
    }
  }, [_c('v-navigation-drawer', {
    attrs: {
      "clipped": _vm.primaryDrawer.clipped,
      "floating": _vm.primaryDrawer.floating,
      "mini-variant": _vm.primaryDrawer.mini,
      "permanent": _vm.primaryDrawer.type === 'permanent',
      "temporary": _vm.primaryDrawer.type === 'temporary',
      "app": "",
      "overflow": ""
    },
    model: {
      value: _vm.primaryDrawer.model,
      callback: function callback($$v) {
        _vm.$set(_vm.primaryDrawer, "model", $$v);
      },
      expression: "primaryDrawer.model"
    }
  }, [_c('div', {
    staticClass: "pa-2 pl-4 pt-4"
  }, [_c('img', {
    attrs: {
      "src": _vm.appLogoUrl,
      "width": "140",
      "alt": "CRM"
    }
  })]), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "three-line": ""
    }
  }, [false ? _c('v-list-item-avatar', [_c('img', {
    attrs: {
      "src": "https://randomuser.me/api/portraits/women/81.jpg",
      "alt": "Аватар"
    }
  })]) : _vm._e(), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.user.fullName))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.user.roleDesc))]), _c('div', {
    staticClass: "pt-2"
  }, [_c('v-btn', {
    attrs: {
      "color": "error",
      "outlined": "",
      "x-small": ""
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("Выйти")])], 1)], 1)], 1), _c('v-divider'), _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.userMenu, function (menu) {
    return _c('v-list-item', {
      key: menu.title,
      attrs: {
        "link": "",
        "to": menu.link
      }
    }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(_vm._s(menu.icon))])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(menu.title))])], 1)], 1);
  }), 1), _c('v-divider'), _vm.$helper.permission.canSeeTheBilling(_vm.user) ? _c('Billing') : _vm._e(), _vm.$helper.permission.canSeeLawyerPlan(_vm.user) ? _c('LawyerPlan') : _vm._e(), _c('Settings')], 1), _c('v-app-bar', {
    attrs: {
      "clipped-left": _vm.primaryDrawer.clipped,
      "app": ""
    }
  }, [_c('v-app-bar-nav-icon', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.primaryDrawer.model = !_vm.primaryDrawer.model;
      }
    }
  }), _c('Birthday'), _c('v-spacer'), _vm.$helper.permission.canSeeSettings(_vm.user) ? _c('v-btn', {
    attrs: {
      "to": "/settings",
      "text": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-cog-outline")])], 1) : _vm._e(), _vm.$helper.permission.canSeeNotifications(_vm.user) ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openNotifications();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-bell-outline")]), _vm._v(" Уведомления ")], 1) : _vm._e()], 1), false ? _c('NyBalls') : _vm._e(), _c('v-content', {
    staticStyle: {
      "margin-top": "35px"
    }
  }, [false ? _c('v-app-bar-nav-icon', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.primaryDrawer.model = !_vm.primaryDrawer.model;
      }
    }
  }) : _vm._e(), _c('v-progress-linear', {
    attrs: {
      "active": _vm.$store.state.loading,
      "indeterminate": true,
      "height": "4"
    }
  }), _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('transition', {
    attrs: {
      "mode": "out-in",
      "name": "fade"
    }
  }, [!_vm.$store.state.loading ? [_vm._t("default")] : _vm._e()], 2), _c('div', {
    staticClass: "flashpool"
  }, [_c('flash-message')], 1)], 1)], 1), _vm.$helper.permission.canSeeNotifications(_vm.user) ? _c('v-navigation-drawer', {
    staticClass: "notification-panel",
    attrs: {
      "right": "",
      "fixed": "",
      "width": "600"
    },
    model: {
      value: _vm.panels.notification.model,
      callback: function callback($$v) {
        _vm.$set(_vm.panels.notification, "model", $$v);
      },
      expression: "panels.notification.model"
    }
  }, [_c('NotificationPanel', {
    key: _vm.notificationPanelKey,
    on: {
      "closeNotificationPanel": function closeNotificationPanel($event) {
        _vm.panels.notification.model = false;
      },
      "forceUpdate": function forceUpdate($event) {
        _vm.notificationPanelKey++;
      }
    }
  })], 1) : _vm._e(), _c('v-footer', {
    attrs: {
      "inset": _vm.footer.inset,
      "app": ""
    }
  }, [_c('span', {
    staticClass: "px-4"
  }, [_vm._v("© " + _vm._s(new Date().getFullYear()))])]), _c('ScrollToTop')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }