var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v("Отправить юристу")])]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "label": "Юрист",
      "items": _vm.lawyers,
      "item-value": "id",
      "item-text": "fullName"
    },
    model: {
      value: _vm.form.lawyer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lawyer", $$v);
      },
      expression: "form.lawyer"
    }
  }), _vm.form.errors.has('lawyer') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('lawyer')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "auto-grow": "",
      "label": "Комментарий"
    },
    model: {
      value: _vm.form.comment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  }), _vm.form.errors.has('comment') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('comment')) + " ")]) : _vm._e()], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Отмена")]), _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Отправить")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }