<template>
    <div>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader type="heading, table-row@3, button"></v-skeleton-loader>
            </v-col>
        </v-row>
        <div v-if="!loading">
            <v-card-title>
                События
                <v-btn icon @click="forceUpdate">
                    <v-icon>mdi-reload</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="events"
                class="elevation-1"
                item-key="id"
                :loading="loadingTable"
                :options.sync="eventGridOptions.options"
                loading-text="Загрузка ..."
                :server-items-length="totalElements"
                :footer-props="{'items-per-page-options': itemPerPage}">
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr>
                        <td></td>
                        <td>
                            <DateField
                                label="Дата"
                                range
                                clearable
                                v-model="eventGridOptions.filter.dateTimes"
                            ></DateField>
                        </td>
                        <td>
                            <v-select
                                v-model="eventGridOptions.filter.types"
                                multiple
                                :items="types"
                                clearable
                                item-value="value"
                                item-text="text"
                            ></v-select>
                        </td>
                        <td></td>
                        <td>
                            <v-select
                                v-model="eventGridOptions.filter.executors"
                                multiple
                                :items="executors"
                                clearable
                                item-value="id"
                                item-text="fullName"
                            ></v-select>
                        </td>
                        <td>
                            <v-select
                                v-model="eventGridOptions.filter.substitutions"
                                multiple
                                :items="executors"
                                clearable
                                item-value="id"
                                item-text="fullName"
                            ></v-select>
                        </td>
                        <td>
                            <v-text-field
                                v-model="eventGridOptions.filter.contract"
                                type="text">
                            </v-text-field>
                        </td>
                        <td></td>
                        <td>
                            <DateField
                                label="Создан"
                                range
                                clearable
                                v-model="eventGridOptions.filter.createdAtDates"
                            ></DateField>
                        </td>
                        <td>
                            <v-autocomplete
                                v-model="eventGridOptions.filter.user"
                                :items="users"
                                item-value="id"
                                item-text="fullName"
                                :clearable="true">
                            </v-autocomplete>
                        </td>
                    </tr>
                    <tr v-for="item in items" :key="item.id">
                        <td width="20" class="px-5"></td>
                        <td width="200">
                            {{ $moment(item.dateTime).dateTimeFormat() }}
                        </td>
                        <td width="260">
                            <div v-if="item.type">
                                {{ item.type?.text }}
                            </div>
                        </td>
                        <td>
                            {{ item.comment }}
                        </td>
                        <td width="200">
                            {{ item.executor?.fullName }}
                        </td>
                        <td width="270">
                            {{ item.substitution?.fullName }}
                        </td>
                        <td width="110">
                            <router-link :to="`/contracts/${item.contract}/edit`">
                                #{{ item.contract }}
                            </router-link>
                        </td>
                        <td>
                            {{ item?.clientName }}
                        </td>
                        <td width="270">
                            {{ $moment(item.createdAt).dateFormat() }}
                        </td>
                        <td width="310">
                            {{ item?.user?.fullName }}
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import ContractEventService from "@/_services/ContractEventService";
import DateField from "@/_chunks/DateField.vue";

export default {
    name: "ContractEventGrid",
    components: {DateField},
    data() {
        return {
            headers: [
                {sortable: false},
                {text: 'Дата и время', value: 'dateTime'},
                {text: 'Тип', value: 'type'},
                {text: 'Описание', value: 'comment', sortable: false},
                {text: 'Исполнитель', value: 'executor', sortable: false},
                {text: 'Замена', value: 'substitution', sortable: false},
                {text: 'Договор', value: 'contract'},
                {text: 'ФИО клиента', value: 'clientName', sortable: false},
                {text: 'Дата создания', value: 'createdAt'},
                {text: 'Сотрудник', value: 'user'},
            ],
            eventGridOptions: this.$store.state.eventGridOptions,
            events: [],
            types: [],
            users: [],
            executors: [],
            totalElements: 0,
            loading: true,
            loadingTable: false
        }
    },
    watch: {
        eventGridOptions: {
            handler() {
                this.fetchItems();
            },
            deep: true
        },
    },
    methods: {
        forceUpdate() {
            this.fetchItems();
        },
        fetch() {
            this.loading = false;
            this.fetchItems();
        },
        fetchItems() {
            this.loadingTable = true;
            ContractEventService
                .getAll(this.eventGridOptions.options, this.eventGridOptions.filter)
                .then(data => {
                    this.events = data.page.content;
                    this.types = data.types;
                    this.users = data.users;
                    this.executors = data.executors;
                    this.totalElements = data.page.totalElements;
                    this.loading = false;
                    this.loadingTable = false;
                });
        },
        onEventAdd() {
            this.fetchItems();
        },
    },
    computed: {
        ...mapState(['user']),
        itemPerPage: function () {
            return [20, 30, 50, 100];
        },
    },
    created() {
        this.fetch();
    }
};
</script>
