<template>
    <div>
        <v-card-title>
            Отчеты
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col xl="6">
                    <v-row>
                        <v-col
                            cols="auto"
                            v-if="$helper.permission.canAssignRequest(user)">
                            <v-label>Тип отчета</v-label>
                            <v-select
                                v-model="reportGridsOptions.filter.reportType"
                                item-value="id"
                                item-text="text"
                                :items="reportTypes">
                            </v-select>
                        </v-col>
                        <v-col>
                            <v-label>Дата</v-label>
                            <v-dialog
                                ref="dialog"
                                v-model="dialog.createdAt.modal"
                                :return-value.sync="reportGridsOptions.filter.request.createdAtDates"
                                persistent width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="reportGridsOptions.filter.request.createdAtDates"
                                        prepend-icon="mdi-calendar-month-outline"
                                        readonly
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="dialog.createdAt.model"
                                    type="date"
                                    scrollable
                                    range
                                    first-day-of-week="1">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="dialog.createdAt.modal = false">Отмена</v-btn>
                                    <v-btn text color="primary" @click="$refs.dialog.save(dialog.createdAt.model)">
                                        Выбрать
                                    </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-col>
                        <v-col v-if="false && $helper.permission.canAssignRequest(user)">
                            <v-label>Источник</v-label>
                            <v-select
                                v-model="reportGridsOptions.filter.request.source"
                                item-value="id"
                                item-text="name"
                                :items="sources"
                                clearable>
                            </v-select>
                        </v-col>
                        <v-col v-if="false && $helper.permission.canAssignRequest(user)">
                            <v-label>Оператор</v-label>
                            <v-select
                                v-model="reportGridsOptions.filter.request.operator"
                                :items="operators"
                                clearable
                                item-value="id"
                                item-text="fullName">
                            </v-select>
                        </v-col>
                        <v-col>
                            <v-btn
                                large
                                color="green"
                                @click="getReport">
                                Сформировать
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col xl="6">
                    <PlanTable :key="reportForceRerenderKey"></PlanTable>
                </v-col>
            </v-row>
            <div
                v-if="!loading && reportGridsOptions.filter.reportType !== 'GRAFIK'"
                style="overflow: auto; border-left: thin solid rgba(255, 255, 255, 0.18) !important;">
                <v-data-table
                    :style="`min-width: ${getTableWidth()}px`"
                    :headers="headers"
                    :items="reportsTuples"
                    hide-default-footer
                    disable-pagination
                    :custom-sort="customSort"
                    class="table__lined">
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="item in items" :key="item.id">
                            <td width="120">{{ item.item }}</td>
                            <td width="100">{{ item.count }}</td>
                            <td width="120" v-html="printStateRow(item, 'SHLAK')"></td>
                            <td width="120" v-html="printStateRow(item, 'BRAK')"></td>
                            <td width="120" v-html="printStateRow(item, 'FAILURE')"></td>
                            <td width="120" v-html="printStateRow(item, 'CONTROL')"></td>
                            <td width="120" v-html="printStateRow(item, 'RECALL')"></td>
                            <td width="120" v-html="printStateRow(item, 'ZAPIS')"></td>
                            <td width="120" v-html="printStateRow(item, 'APPOINTMENT_ZAPIS')"></td>
                            <td width="160" v-html="printStateRow(item, 'CANCEL_ZAPIS')"></td>
                            <td width="110" v-html="printStateRow(item, 'APPOINTMENT_ONLINE')"></td>
                            <td width="120" v-html="printStateRow(item, 'CAME')"></td>
                            <td width="120"
                                v-html="printStateRow(
                                    item,
                                    'APPOINTMENT_CAME',
                                    false,
                                    {
                                        statColorOptions: reportHelper.orkCameResultStatOptions,
                                        stateSubstract: 'APPOINTMENT_ONLINE',
                                        stateSubstractPercent: 0.5,
                                        ceil: true
                                    }
                                )">
                            </td>
                            <td width="120" v-html="printStateRow(item, 'CONTRACT')"></td>
                            <td width="120">
                                <span v-html="printStateRow(item, 'CONTRACT_RESULT', false, {withoutPercent: true})"></span>
                                <span>&nbsp;</span>
                                <span v-html="getPercentInfo(
                                        printStateRow(item, 'CONTRACT_RESULT', false, {withoutPercent: true}),
                                        printStateRow(
                                            item,
                                            'APPOINTMENT_CAME',
                                            false,
                                            {
                                                withoutPercent: true,
                                                stateSubstract: 'APPOINTMENT_ONLINE',
                                                stateSubstractPercent: 0.5,
                                                ceil: true
                                            }
                                        ),
                                        reportHelper.orkCameResultStatOptions
                                    )"></span>
                            </td>
                            <td width="120" v-html="printStateRow(item, 'PULL_OFF')"></td>
                            <td width="120" v-if="false" v-html="getItemKPD(item)"></td>
                            <td width="120" v-if="false" v-html="printStateRow(item, ['ZAPIS', 'CAME'], true)"></td>
                        </tr>
                        <tr
                            class="result-row"
                            v-if="summary.length > 0">
                            <td><b>Всего:</b></td>
                            <td v-html="printSummaryRow(null, true)"></td>
                            <td v-html="printSummaryRow('SHLAK')"></td>
                            <td v-html="printSummaryRow('BRAK')"></td>
                            <td v-html="printSummaryRow('FAILURE')"></td>
                            <td v-html="printSummaryRow('CONTROL')"></td>
                            <td v-html="printSummaryRow('RECALL')"></td>
                            <td v-html="printSummaryRow('ZAPIS')"></td>
                            <td v-html="printSummaryRow('APPOINTMENT_ZAPIS')"></td>
                            <td v-html="printSummaryRow('CANCEL_ZAPIS')"></td>
                            <td v-html="printSummaryRow('APPOINTMENT_ONLINE')"></td>
                            <td v-html="printSummaryRow('CAME')"></td>
                            <td v-html="printSummaryRow(
                                    'APPOINTMENT_CAME',
                                    false,
                                    {
                                        statColorOptions: reportHelper.orkCameResultStatOptions,
                                        stateSubstract: 'APPOINTMENT_ONLINE',
                                        stateSubstractPercent: 0.5,
                                        ceil: true
                                    }
                                )">
                            </td>
                            <td v-html="printSummaryRow('CONTRACT')"></td>
                            <td>
                                <span v-html="printSummaryRow('CONTRACT_RESULT', true)"></span>
                                <span>&nbsp;</span>
                                <span v-html="getPercentInfo(
                                        printSummaryRow('CONTRACT_RESULT', true),
                                        printSummaryRow(
                                            'APPOINTMENT_CAME',
                                            true,
                                            {
                                                stateSubstract: 'APPOINTMENT_ONLINE',
                                                stateSubstractPercent: 0.5,
                                                ceil: true
                                            }
                                        ),
                                        reportHelper.orkCameResultStatOptions
                                    )"></span>
                            </td>
                            <td v-html="printSummaryRow('PULL_OFF')"></td>
                            <td v-if="false" v-html="getItemKPD()"></td>
                            <td v-if="false" v-html="printSummaryRow(['ZAPIS', 'CAME'])"></td>
                        </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </div>
        </v-card-text>

        <ChartOrkPlans
            v-if="reportGridsOptions.filter.reportType === 'GRAFIK'"
            :filter="reportGridsOptions.filter"
            :key="reportForceRerenderKey">
        </ChartOrkPlans>

        <Charts
            v-if="false && states.length > 0 && reportsTuples.length > 0"
            :states="states"
            :reportsTuples="reportsTuples"
            :key="chartsKey">
        </Charts>
    </div>
</template>

<script>
import RequestService from "../_services/RequestService";
import {mapState} from 'vuex';
import SourceService from "../_services/SourceService";
import Charts from "./Charts";
import UserService from "../_services/UserService";
import ReportService from "../_services/ReportService";
import PlanTable from "@/reports/ork/PlanTable.vue";
import ChartOrkPlans from "@/reports/ork/charts/ChartOrkPlans.vue";
import {reportHelper} from "@/_helpers/report/reportHelper";

export default {
    name: 'ReportGrid',
    components: {ChartOrkPlans, PlanTable, Charts},
    data: function () {
        return {
            reportsTuples: [],
            summary: [],
            allCount: 0,
            states: [],
            sources: [],
            operators: [],
            loading: false,
            headers: [
                {text: 'Ф.И.О', value: 'item', sortable: false},
                {text: 'Заявки', value: 'count'},
                {text: 'Шлак', value: 'SHLAK'},
                {text: 'Брак', value: 'BRAK'},
                {text: 'Отказ', value: 'FAILURE'},
                {text: 'Контроль', value: 'CONTROL'},
                {text: 'Перезвон', value: 'RECALL'},
                {text: 'Запись (общее)', value: 'ZAPIS'},
                {text: 'Запись (на сегодня)', value: 'APPOINTMENT_ZAPIS'},
                {text: 'Отмена консультации', value: 'CANCEL_ZAPIS'},
                {text: 'Пришел Онлайн', value: 'APPOINTMENT_ONLINE'},
                {text: 'Пришел', value: 'CAME'},
                {text: 'Пришел (итого)', value: 'APPOINTMENT_CAME'},
                {text: 'Договор', value: 'CONTRACT'},
                {text: 'Договор (итого)', value: 'CONTRACT_RESULT'},
                {text: 'Снята', value: 'PULL_OFF'},
                //{text: 'КПД', value: 'KPD'},
                //{text: 'Доход', value: 'CAME'}
            ],
            dialog: {
                createdAt: {
                    model: [],
                    modal: false
                }
            },
            reportTypes: [
                {
                    "id": "OPERATOR",
                    "text": "Юрист-оператор"
                },
                {
                    "id": "SOURCE",
                    "text": "Источник"
                },
                {
                    "id": "SUBDIVISION",
                    "text": "Подразделение"
                },
                {
                    "id": "OFFICE",
                    "text": "Офис"
                },
                {
                    "id": "GRAFIK",
                    "text": "График"
                }
            ],
            chartsKey: 0,
            reportForceRerenderKey: 0,
        }
    },
    watch: {
        reportsTuples: function () {
            this.chartsKey++;
        }
    },
    methods: {
        customSort: function (items, index, isDesc) {
            items.sort((a, b) => {
                if (!index[0]) return;
                let aRes = a[index[0]];
                let bRes = b[index[0]];
                if (index[0] !== 'count') {
                    let aPercent = a.tuples.find(el => el.state === index[0]);
                    let bPercent = b.tuples.find(el => el.state === index[0]);

                    aRes = aPercent ? aPercent.count / a.count * 100 : 0;
                    bRes = bPercent ? bPercent.count / b.count * 100 : 0;
                }

                if (!isDesc[0]) {
                    return bRes - aRes;
                } else {
                    return aRes - bRes;
                }
            });
            return items;
        },
        printSummaryRow(state, withoutPercent = false, options = {}) {
            let counts = 0, procents = 0;
            if (Array.isArray(state)) {
                let _this = this;
                state.forEach(function (el) {
                    let {count} = _this.getSummaryRow(el, options);
                    counts += count;
                });
                procents = counts / this.getItemKPD() * 100;
            } else {
                let {count, procent} = this.getSummaryRow(state, options);
                counts = count;
                procents = procent;
            }
            let percentValue = Math.round((procents + Number.EPSILON) * 100) / 100;
            if (options.ceil) { // надо округлить проценты
                percentValue = Math.floor(procents);
            }
            if (procents > 0 && !withoutPercent) {
                const colorPercent = reportHelper.getStatColor(percentValue, options?.statColorOptions);
                return `${counts} (<span class="${colorPercent}">${percentValue}</span>%)`;
            } else {
                return `${counts}`;
            }
        },
        getSummaryRow(state, options = {}) {
            let item = this.summary.find(el => el.state === state);
            let stateSubstract = null;
            if (options.stateSubstract) {
                stateSubstract = this.summary.find(el => el.state === options.stateSubstract);
            }
            let count = 0, procent = 0;
            if (item) {
                count = item.count;
                if (stateSubstract && options.stateSubstractPercent) {
                    count = count - (stateSubstract.count * options.stateSubstractPercent)
                }
                if (this.allCount > 0) {
                    procent = count / this.getItemKPD(null, {state}) * 100;
                }
            }
            return {...{count, procent}};
        },
        printStateRow(item, state, onlyPercent = false, options = {}) {
            let tuples = 0, procents = 0;
            if (Array.isArray(state)) {
                let _this = this;
                state.forEach(function (el) {
                    let {tuple} = _this.getStateRow(item, el, options);
                    tuples += tuple;
                });
                procents = tuples / this.getItemKPD(item) * 100;
            } else {
                let {tuple, procent} = this.getStateRow(item, state, options);
                tuples = tuple;
                procents = procent;
            }
            let percentValue = Math.round((procents + Number.EPSILON) * 100) / 100;
            if (options.ceil) { // надо округлить проценты
                percentValue = Math.floor(procents);
            }
            let result = tuples;
            if (onlyPercent) {
                return `${percentValue}%`;
            }
            if (options.withoutPercent !== true) {
                const colorPercent = reportHelper.getStatColor(percentValue, options?.statColorOptions);
                result = `${result} (<span class="${colorPercent}">${percentValue}</span>%)`;
            }
            return result;
        },
        getPercentInfo(value1, value2, statColorOptions) {
            let percentValue = (value1 / value2) * 100;
            percentValue = Math.round((percentValue + Number.EPSILON) * 100) / 100;
            const colorPercent = reportHelper.getStatColor(percentValue, statColorOptions);
            return `(<span class="${colorPercent}">${percentValue}</span>%)`;
        },
        getStateRow(item, state, options = {}) {
            let tuple = item.tuples.find(el => el.state === state);
            let tupleSubstract = null;
            if (options.stateSubstract) {
                tupleSubstract = item.tuples.find(el => el.state === options.stateSubstract);
            }
            let procent = 0;
            if (tuple) {
                let count = tuple.count;
                if (tupleSubstract && options.stateSubstractPercent) {
                    count = count - (tupleSubstract.count * options.stateSubstractPercent)
                }
                procent = count / item.count * 100;
                tuple = count;
            } else {
                tuple = 0;
            }
            return {...{tuple, procent}};
        },
        // eslint-disable-next-line no-unused-vars
        getItemKPD(item = null, options = {}) {
            let tuple = 0, allCount = 0;
            if (item) {
                tuple = item.tuples.find(el => el.state === 'PULL_OFF');
                allCount = item.count;
            } else {
                //tuple = this.summary.find(el => el.state === 'PULL_OFF');
                allCount = this.allCount;
            }
            let pullOffCount = tuple ? tuple.count : 0;
            return allCount - pullOffCount;
        },
        fetch() {
            this.fetchStates();
            this.fetchOperators();
            this.fetchSources();
            this.getReport();
        },
        getReport() {
            this.reportForceRerenderKey++;
            if (this.reportGridsOptions.filter.reportType === "GRAFIK") return;
            this.loading = true;
            if (this.$helper.isUserRole(this.user, "PROVIDER")) {
                this.reportGridsOptions.filter.reportType = "SOURCE"
            }
            ReportService.getReport(this.reportGridsOptions.filter).then(data => {
                this.reportsTuples = data.items;
                this.summary = data.summary;
                this.allCount = this.summary.find(el => el.state === null).count;
                this.loading = false;
            });
        },
        fetchOperators() {
            UserService.getAllOperators().then(data => {
                this.operators = data.content;
            });
        },
        fetchStates() {
            RequestService.getAllStates().then(data => {
                this.states = data;
            });
        },
        fetchSources() {
            SourceService.getAll().then(data => {
                this.sources = data;
            });
        },
        getTableWidth() {
            return 2240;
        }
    },
    computed: {
        reportHelper() {
            return reportHelper
        },
        ...mapState(['user', 'reportGridsOptions'])
    },
    created() {
        this.$store.dispatch('loading', false);
        this.fetch();
    },
    beforeCreate() {
        this.$store.dispatch('loading', true);
    }
};
</script>
<style lang="scss">
.red_link {
    color: #ff6f6f !important;
}

.yellow_link {
    color: #ffeb3b !important;
}

.green_link {
    color: #4aff52 !important;
}
</style>
