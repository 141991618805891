var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card-text', [_vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "table-row@3"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading ? _c('div', {
    staticStyle: {
      "margin-top": "-30px",
      "overflow": "auto"
    }
  }, [_c('v-data-table', {
    staticClass: "table__lined table__framed",
    style: "min-width: ".concat(_vm.getTableWidth(), "px; margin:-2px"),
    attrs: {
      "headers": _vm.headers,
      "items": _vm.page.items,
      "hide-default-footer": "",
      "disable-pagination": "",
      "height": _vm.tableHeight
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var items = _ref.items;
        return [_c('tbody', [_vm._l(items, function (item) {
          var _item$contractTypes, _item$contractTypes2;
          return _c('tr', {
            key: item.id
          }, [_c('td', {
            attrs: {
              "width": "100"
            }
          }, [_vm._v(_vm._s(_vm.$moment(item.date).dateFormat()))]), _c('td', [_vm._v(_vm._s(_vm.$helper.format.number(item.clients)))]), _c('td', [_vm._v(_vm._s(item.clientsOnline))]), _c('td', {
            attrs: {
              "width": "140"
            }
          }, [_vm._v(_vm._s(item.contracts))]), _c('td', {
            attrs: {
              "width": "160"
            }
          }, [_c('span', {
            domProps: {
              "innerHTML": _vm._s(_vm.$helper.report.getPercentageHtml(item.contracts, item.clients, _vm.$helper.report.contractStatOptions))
            }
          })]), _c('td', {
            attrs: {
              "width": "160"
            }
          }, [_vm._v(" " + _vm._s(((_item$contractTypes = item.contractTypes) === null || _item$contractTypes === void 0 ? void 0 : _item$contractTypes.PRIMARY) || 0) + " ("), _c('span', {
            domProps: {
              "innerHTML": _vm._s(_vm.$helper.report.getPercentageHtml((_item$contractTypes2 = item.contractTypes) === null || _item$contractTypes2 === void 0 ? void 0 : _item$contractTypes2.PRIMARY, item.clients, _vm.$helper.report.contractPrimaryStatOptions))
            }
          }), _vm._v(") ")]), _c('td', {
            attrs: {
              "width": "140"
            }
          }, [_vm._v(_vm._s(_vm.$helper.format.number(item.cash)))]), _c('td', {
            attrs: {
              "width": "140"
            }
          }, [_vm._v(_vm._s(_vm.$helper.format.number(item.contractSum)))]), _c('td', {
            attrs: {
              "width": "140"
            }
          }, [_c('span', {
            class: _vm.$helper.report.getStatColor(item.contractSum / item.contracts || 0, _vm.$helper.report.lawyerDepartment.avgSumm),
            domProps: {
              "innerHTML": _vm._s(_vm.$helper.format.number(item.contractSum / item.contracts || 0))
            }
          })]), _c('td', {
            attrs: {
              "width": "140"
            }
          }, [_c('span', {
            class: _vm.$helper.report.getStatColor(item.cash / item.clients, _vm.$helper.report.payloadStatOptions)
          }, [_vm._v(" " + _vm._s(_vm.$helper.format.currency(item.cash / item.clients || 0)) + " ")])]), _c('td', {
            attrs: {
              "width": "140"
            }
          }, [_c('span', {
            class: _vm.$helper.report.getStatColor(item.moneyBack, _vm.$helper.report.moneyBackStatOptions)
          }, [_vm._v(" " + _vm._s(_vm.$helper.format.currency(item.moneyBack)) + " ")])]), _c('td', [_vm._v(_vm._s(_vm.$helper.format.number(item.halva10)))]), _c('td', [_vm._v(_vm._s(_vm.$helper.format.number(item.cleanCash)))]), _c('td', [_c('span', {
            class: _vm.$helper.report.getStatColor(item.cleanCash / item.clients, _vm.$helper.report.payloadStatOptions)
          }, [_vm._v(" " + _vm._s(_vm.$helper.format.currency(item.cleanCash / item.clients || 0)) + " ")])])]);
        }), _c('tr', {
          staticClass: "result-row"
        }, _vm._l(_vm.headers, function (header, i) {
          return _c('td', {
            key: i
          }, [header.value === 'day' ? _c('div', [_vm._v("Всего:")]) : _vm._e(), header.value === 'contractPercent' ? _c('div', [_c('span', {
            domProps: {
              "innerHTML": _vm._s(_vm.$helper.report.getPercentageHtml(_vm.total('contracts'), _vm.total('clients'), _vm.$helper.report.contractStatOptions))
            }
          })]) : _vm._e(), header.value === 'contractPercentPrimary' ? _c('div', [_vm._v(" " + _vm._s(_vm.total('contractTypes.PRIMARY')) + " ("), _c('span', {
            domProps: {
              "innerHTML": _vm._s(_vm.$helper.report.getPercentageHtml(_vm.total('contractTypes.PRIMARY'), _vm.total('clients'), _vm.$helper.report.contractPrimaryStatOptions))
            }
          }), _vm._v(") ")]) : _vm._e(), header.value === 'avgSum' ? _c('div', [_c('span', {
            class: _vm.$helper.report.getStatColor(_vm.total('contractSum') / _vm.total('contracts') || 0, _vm.$helper.report.lawyerDepartment.avgSumm),
            domProps: {
              "innerHTML": _vm._s(_vm.$helper.format.number(_vm.total('contractSum') / _vm.total('contracts') || 0))
            }
          })]) : _vm._e(), header.value === 'moneyBack' ? _c('div', [_c('span', {
            class: _vm.$helper.report.getStatColor(_vm.total('moneyBack'), _vm.$helper.report.moneyBackStatOptions)
          }, [_vm._v(" " + _vm._s(_vm.$helper.format.currency(_vm.total('moneyBack'))) + " ")])]) : _vm._e(), header.value === 'payload' ? _c('div', [_c('span', {
            class: _vm.$helper.report.getStatColor(_vm.total('cash') / _vm.total('clients'), _vm.$helper.report.payloadStatOptions)
          }, [_vm._v(" " + _vm._s(_vm.$helper.format.currency(_vm.total('cash') / _vm.total('clients') || 0)) + " ")])]) : _vm._e(), header.value === 'cleanPayload' ? _c('div', [_c('span', {
            class: _vm.$helper.report.getStatColor(_vm.total('cleanCash') / _vm.total('clients'), _vm.$helper.report.payloadStatOptions)
          }, [_vm._v(" " + _vm._s(_vm.$helper.format.currency(_vm.total('cleanCash') / _vm.total('clients') || 0)) + " ")])]) : _vm._e(), header.value === 'cleanCash' ? _c('div', [_c('span', {
            class: _vm.$helper.report.getStatColor(_vm.total('cleanCash'), _vm.$helper.report.cleanCashStatOptions)
          }, [_vm._v(" " + _vm._s(_vm.$helper.format.currency(_vm.total('cleanCash'))) + " ")])]) : _vm._e(), header.custom !== true ? _c('div', [_vm._v(" " + _vm._s(_vm.$helper.format.number(_vm.total(header.value))) + " ")]) : _vm._e()]);
        }), 0)], 2)];
      }
    }], null, false, 3030239015)
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }