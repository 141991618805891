<template>
    <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on }">
            <v-btn color="green" v-on="on">
                Добавить доплату
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Добавить доплату</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-col>
                            <v-dialog
                                ref="dateDialog"
                                v-model="dateDialog"
                                :return-value.sync="form.date"
                                persistent width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="form.date"
                                            label="Дата"
                                            prepend-icon="mdi-calendar-month-outline"
                                            readonly
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="form.date"
                                    type="date"
                                    scrollable
                                    first-day-of-week="1">
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text color="primary"
                                        @click="dateDialog = false">
                                        Отмена
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.dateDialog.save(form.date)">
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-dialog>
                            <v-alert v-if="form.errors.has('date')" dense outlined type="error" :icon="false">
                                {{ form.errors.get('date') }}
                            </v-alert>
                        </v-col>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="form.amount"
                            label="Сумма">
                        </v-text-field>
                        <v-alert v-if="form.errors.has('amount')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('amount') }}
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">Отмена</v-btn>
                <v-btn color="blue darken-1" text @click="save">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Form from "../../_helpers/_form/Form";
import ContractService from "../../_services/ContractService";

export default {
    props: {id: Number},
    data() {
        return {
            form: new Form({}),
            dialog: false,
            dateDialog: false
        }
    },
    methods: {
        save() {
            ContractService.addAdditionalPayment(this.id, this.form).then(() => {
                this.dialog = false;
                this.$emit('saved');
            });
        },
    }
};
</script>
