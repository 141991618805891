var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-title', [_vm._v(" Список записей "), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('forceUpdate');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-reload")])], 1), _c('v-spacer')], 1), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.requests,
      "options": _vm.appointmentsGridsOptions.options,
      "server-items-length": _vm.totalElements,
      "item-key": "id",
      "loading": _vm.loading,
      "loading-text": "Загрузка ...",
      "fixed-header": "",
      "height": _vm.tableHeight,
      "footer-props": {
        'items-per-page-options': [20, 30, 50]
      }
    },
    on: {
      "update:options": function updateOptions($event) {
        return _vm.$set(_vm.appointmentsGridsOptions, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var items = _ref.items;
        return [_c('tbody', [_c('tr', {
          staticClass: "row_sticky"
        }, [_c('td', {
          attrs: {
            "width": "30"
          }
        }), _c('td', {
          attrs: {
            "width": "100"
          }
        }), _vm.isVisibleColumn('contract') ? _c('td', {
          attrs: {
            "width": "120"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.checkboxValues,
            "clearable": "",
            "item-value": "value",
            "item-text": "text"
          },
          model: {
            value: _vm.appointmentsGridsOptions.filter.contract,
            callback: function callback($$v) {
              _vm.$set(_vm.appointmentsGridsOptions.filter, "contract", $$v);
            },
            expression: "appointmentsGridsOptions.filter.contract"
          }
        })], 1) : _vm._e(), _c('td', {
          attrs: {
            "width": "120"
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.appointmentsGridsOptions.filter.id,
            callback: function callback($$v) {
              _vm.$set(_vm.appointmentsGridsOptions.filter, "id", $$v);
            },
            expression: "appointmentsGridsOptions.filter.id"
          }
        })], 1), _c('td', {
          attrs: {
            "width": "180"
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.states,
            "clearable": ""
          },
          model: {
            value: _vm.appointmentsGridsOptions.filter.states,
            callback: function callback($$v) {
              _vm.$set(_vm.appointmentsGridsOptions.filter, "states", $$v);
            },
            expression: "appointmentsGridsOptions.filter.states"
          }
        })], 1), _vm.isVisibleColumn('isOnlineAppointment') ? _c('td', {
          attrs: {
            "width": "100"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.checkboxValues,
            "clearable": "",
            "item-value": "value",
            "item-text": "text"
          },
          model: {
            value: _vm.appointmentsGridsOptions.filter.isOnlineAppointment,
            callback: function callback($$v) {
              _vm.$set(_vm.appointmentsGridsOptions.filter, "isOnlineAppointment", $$v);
            },
            expression: "appointmentsGridsOptions.filter.isOnlineAppointment"
          }
        })], 1) : _vm._e(), _c('td', {
          attrs: {
            "width": "200"
          }
        }, [_c('v-dialog', {
          ref: "dialog2",
          attrs: {
            "return-value": _vm.appointmentsGridsOptions.filter.appointmentDates,
            "persistent": "",
            "width": "290px"
          },
          on: {
            "update:returnValue": function updateReturnValue($event) {
              return _vm.$set(_vm.appointmentsGridsOptions.filter, "appointmentDates", $event);
            },
            "update:return-value": function updateReturnValue($event) {
              return _vm.$set(_vm.appointmentsGridsOptions.filter, "appointmentDates", $event);
            }
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('v-text-field', _vm._g({
                attrs: {
                  "prepend-icon": "mdi-calendar-month-outline",
                  "readonly": "",
                  "clearable": ""
                },
                model: {
                  value: _vm.appointmentsGridsOptions.filter.appointmentDates,
                  callback: function callback($$v) {
                    _vm.$set(_vm.appointmentsGridsOptions.filter, "appointmentDates", $$v);
                  },
                  expression: "appointmentsGridsOptions.filter.appointmentDates"
                }
              }, on))];
            }
          }], null, true),
          model: {
            value: _vm.dialog.appointmentDate.modal,
            callback: function callback($$v) {
              _vm.$set(_vm.dialog.appointmentDate, "modal", $$v);
            },
            expression: "dialog.appointmentDate.modal"
          }
        }, [_c('v-date-picker', {
          attrs: {
            "type": "date",
            "scrollable": "",
            "range": "",
            "first-day-of-week": "1"
          },
          model: {
            value: _vm.dialog.appointmentDate.model,
            callback: function callback($$v) {
              _vm.$set(_vm.dialog.appointmentDate, "model", $$v);
            },
            expression: "dialog.appointmentDate.model"
          }
        }, [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.dialog.appointmentDate.modal = false;
            }
          }
        }, [_vm._v("Отмена")]), _c('v-btn', {
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.$refs.dialog2.save(_vm.dialog.appointmentDate.model);
            }
          }
        }, [_vm._v(" Выбрать ")])], 1)], 1)], 1), _vm.isVisibleColumn('userPhone') ? _c('td', {
          attrs: {
            "width": "240"
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.appointmentsGridsOptions.filter.cleanPhone,
            callback: function callback($$v) {
              _vm.$set(_vm.appointmentsGridsOptions.filter, "cleanPhone", $$v);
            },
            expression: "appointmentsGridsOptions.filter.cleanPhone"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('userName') ? _c('td', {
          attrs: {
            "width": "300"
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.appointmentsGridsOptions.filter.userName,
            callback: function callback($$v) {
              _vm.$set(_vm.appointmentsGridsOptions.filter, "userName", $$v);
            },
            expression: "appointmentsGridsOptions.filter.userName"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('operator') ? _c('td', {
          attrs: {
            "width": "240"
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.operators,
            "clearable": "",
            "item-value": "id",
            "item-text": "fullName"
          },
          model: {
            value: _vm.appointmentsGridsOptions.filter.operators,
            callback: function callback($$v) {
              _vm.$set(_vm.appointmentsGridsOptions.filter, "operators", $$v);
            },
            expression: "appointmentsGridsOptions.filter.operators"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('lawyer') ? _c('td', {
          attrs: {
            "width": "200"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.lawyers,
            "clearable": "",
            "item-value": "id",
            "item-text": "fullName"
          },
          model: {
            value: _vm.appointmentsGridsOptions.filter.lawyers,
            callback: function callback($$v) {
              _vm.$set(_vm.appointmentsGridsOptions.filter, "lawyers", $$v);
            },
            expression: "appointmentsGridsOptions.filter.lawyers"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('subdivision') ? _c('td', {
          attrs: {
            "width": "160"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.subdivisions,
            "clearable": "",
            "item-value": "id",
            "item-text": "name"
          },
          model: {
            value: _vm.appointmentsGridsOptions.filter.subdivision,
            callback: function callback($$v) {
              _vm.$set(_vm.appointmentsGridsOptions.filter, "subdivision", $$v);
            },
            expression: "appointmentsGridsOptions.filter.subdivision"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('office') ? _c('td', {
          attrs: {
            "width": "160"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.offices,
            "clearable": "",
            "item-value": "id",
            "item-text": "name"
          },
          model: {
            value: _vm.appointmentsGridsOptions.filter.office,
            callback: function callback($$v) {
              _vm.$set(_vm.appointmentsGridsOptions.filter, "office", $$v);
            },
            expression: "appointmentsGridsOptions.filter.office"
          }
        })], 1) : _vm._e()]), _vm._l(items, function (item) {
          return _c('tr', {
            key: item.id,
            class: {
              'grey darken-3': item.isNew
            }
          }, [_c('td', [_vm.$helper.permission.canOverview(_vm.user) ? _c('v-col', [_c('v-icon', {
            attrs: {
              "small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.$router.push("/requests/".concat(item.id));
              }
            }
          }, [_vm._v("mdi-eye-outline")])], 1) : _vm._e()], 1), _c('td', [_c('v-row', [_vm.$helper.permission.canSetComingState(_vm.user) ? _c('v-col', {
            staticStyle: {
              "white-space": "nowrap"
            }
          }, [item.state.value !== 'CAME' ? _c('v-btn', {
            staticClass: "mr-2",
            attrs: {
              "color": "green",
              "x-small": "",
              "outlined": ""
            },
            on: {
              "click": function click($event) {
                return _vm.setRequestState(item.id, 'CAME');
              }
            }
          }, [_vm._v(" Пришел ")]) : _vm._e(), item.state.value !== 'NOT_CAME' ? _c('v-btn', {
            attrs: {
              "color": "red",
              "x-small": "",
              "outlined": ""
            },
            on: {
              "click": function click($event) {
                return _vm.setRequestState(item.id, 'NOT_CAME');
              }
            }
          }, [_vm._v(" Не пришел ")]) : _vm._e()], 1) : _vm._e(), _vm.$helper.permission.canAssignLawyer(_vm.user) && !item.lawyer ? _c('v-col', {
            staticStyle: {
              "white-space": "nowrap",
              "display": "none"
            }
          }, [_c('v-btn', {
            staticClass: "mr-2",
            attrs: {
              "color": "green",
              "x-small": "",
              "outlined": ""
            },
            on: {
              "click": function click($event) {
                return _vm.openAssignLawyerDialog(item.id);
              }
            }
          }, [_vm._v(" Отправить юристу ")])], 1) : _vm._e()], 1)], 1), _vm.isVisibleColumn('contract') ? _c('td', [_c('v-checkbox', {
            staticClass: "ma-0 ml-2",
            attrs: {
              "disabled": !_vm.$helper.permission.canSetComingState(_vm.user),
              "hide-details": ""
            },
            on: {
              "change": function change($event) {
                return _vm.changeContract(item.id, $event);
              }
            },
            model: {
              value: item.contract,
              callback: function callback($$v) {
                _vm.$set(item, "contract", $$v);
              },
              expression: "item.contract"
            }
          })], 1) : _vm._e(), _c('td', [_vm._v(_vm._s(item.id))]), _c('td', [_c('v-chip', {
            attrs: {
              "color": item.state.chipColor,
              "outlined": "",
              "dark": ""
            }
          }, [item.appointmentApproved ? _c('v-icon', {
            attrs: {
              "color": "green",
              "small": "",
              "left": ""
            }
          }, [_vm._v("mdi-check")]) : _vm._e(), _vm._v(" " + _vm._s(item.state.text) + " ")], 1)], 1), _vm.isVisibleColumn('isOnlineAppointment') ? _c('td', [_c('v-checkbox', {
            staticClass: "ma-0 ml-2",
            attrs: {
              "disabled": "",
              "hide-details": ""
            },
            model: {
              value: item.isOnlineAppointment,
              callback: function callback($$v) {
                _vm.$set(item, "isOnlineAppointment", $$v);
              },
              expression: "item.isOnlineAppointment"
            }
          })], 1) : _vm._e(), _c('td', [_vm._v(_vm._s(_vm.$moment(item.appointmentDate).dateFormat()) + " " + _vm._s(item.appointmentTime.text))]), _vm.isVisibleColumn('userPhone') ? _c('td', [_vm._v(" " + _vm._s(item.userPhone) + " "), item.userName ? _c('span', [_vm._v("(" + _vm._s(item.userName) + ")")]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('userName') ? _c('td', [_vm._v(" " + _vm._s(item.userName) + " ")]) : _vm._e(), _vm.isVisibleColumn('operator') ? _c('td', [item.operator ? _c('div', [_vm._v(_vm._s(item.operator.fullName))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('lawyer') ? _c('td', [item.lawyer ? _c('div', [_vm._v(_vm._s(item.lawyer.fullName))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('subdivision') ? _c('td', [item.subdivision ? _c('div', [_vm._v(_vm._s(item.subdivision.name))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('office') ? _c('td', [item.office ? _c('div', [_vm._v(_vm._s(item.office.name))]) : _vm._e()]) : _vm._e()]);
        })], 2)];
      }
    }])
  }), _c('v-dialog', {
    attrs: {
      "max-width": "400"
    },
    model: {
      value: _vm.dialog.assignLawyer.model,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog.assignLawyer, "model", $$v);
      },
      expression: "dialog.assignLawyer.model"
    }
  }, [_c('AssignLawyerForm', {
    attrs: {
      "id": _vm.assignLawyerRequestId
    },
    on: {
      "saved": _vm.fetchGrid,
      "close": function close($event) {
        _vm.dialog.assignLawyer.model = false;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }