import {http} from "../_helpers";
import Service from "./Service";
import {axiosInstance} from "../_helpers/axiosInstance";

class ClientService extends Service {

    getAll(options, filter) {
        return http.get(this.makeOptionsUrl(`/clients`, options, filter));
    }

    getOne(id) {
        return http.get(`/clients/${id}`);
    }

    getForm(id) {
        return http.get(`/clients/${id}/update`);
    }

    create(form) {
        return http.post(`/clients`, form);
    }

    getCreatePage(requestId) {
        let url = `/clients/create`;
        if (requestId) {
            url += `?requestId=${requestId}`;
        }
        return http.get(url);
    }

    update(id, form) {
        return http.put(`/clients/${id}`, form);
    }

    updateReport(id, form) {
        return http.put(`/clients/${id}/report`, form);
    }

    delete(id) {
        return http.delete(`/clients/${id}`);
    }

    deleteBulk(ids) {
        return axiosInstance.post(`/clients/deleteBulk`, {'ids': ids});
    }

    getAllStates() {
        return http.get(`/clients/states`);
    }

    getComments(id) {
        return http.get(`/clients/${id}/comments`);
    }

    addComment(id, form) {
        return http.post(`/clients/${id}/comments`, form);
    }

    getVisits(id) {
        return http.get(`/clients/${id}/visits`);
    }

    addVisit(id, form) {
        return http.post(`/clients/${id}/visits`, form);
    }

    addToFavorites(id) {
        return axiosInstance.put(`/clients/${id}/favorites`);
    }

    removeFromFavorites(id) {
        return axiosInstance.delete(`/clients/${id}/favorites`);
    }
}

export default new ClientService;
