var render = function () {
  var _vm$actions, _vm$actions2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [(_vm$actions = _vm.actions) !== null && _vm$actions !== void 0 && _vm$actions.length ? _c('v-text-field', {
    attrs: {
      "disabled": "disabled",
      "label": _vm.label
    }
  }) : _vm._e(), (_vm$actions2 = _vm.actions) !== null && _vm$actions2 !== void 0 && _vm$actions2.length ? _c('div', [_c('div', {
    staticClass: "input__label_wrapper"
  }, [_c('div', {
    staticClass: "input__label"
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")])]), _vm._l(_vm.actions, function (action) {
    return _c('div', {
      key: action.id,
      staticClass: "contract-action"
    }, [_c('v-text-field', {
      attrs: {
        "value": action.createdAt,
        "disabled": "disabled"
      }
    }), action.user ? _c('div', {
      staticClass: "field-info"
    }, [_vm._v(" " + _vm._s(action.user.fullName) + " ")]) : _vm._e(), _vm.$helper.permission.canDeleteContractActions(_vm.user) ? _c('div', {
      staticClass: "contract-action__action"
    }, [_c('v-icon', {
      attrs: {
        "right": "",
        "color": "red"
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('deleteAction', action.id);
        }
      }
    }, [_vm._v(" mdi-close ")])], 1) : _vm._e()], 1);
  })], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }