<template>
    <v-card-text>
        <v-row v-if="loading">
            <v-col
                cols="12"
                md="7"
            >
                <v-skeleton-loader type="table-row@3"></v-skeleton-loader>
            </v-col>
        </v-row>
        <div
            v-if="!loading && lawyers.length > 0"
            style="width: 100%; overflow-x: auto;"
        >
            <div :style="{ width: getBarWidth() + 'px', 'min-width': 'calc(100%)' }">
                <v-chart :options="option"/>
            </div>
        </div>
    </v-card-text>
</template>

<script>
    import "echarts/lib/chart/bar";
    import 'echarts/lib/component/tooltip';
    import 'echarts/lib/component/markPoint';
    import ReportService from "@/_services/ReportService";

    export default {
        name: "ChartLawyerPlans",
        components: { },
        props: {
            filter: Object,
        },
        data: function() {
            return {
                loading: false,
                lawyers: [],
                days: this.getDays(this.filter?.dates)
            }
        },
        computed:{
            option() {
                return {
                    layout: {
                        padding: 20
                    },
                    textStyle: {
                        color: "#fff",
                        fontFamily: 'Roboto'
                    },
                    tooltip: {
                        trigger: "item"
                    },
                    grid: {
                        left: '15px',
                        right: '15px',
                        top: '15px',
                        containLabel: true
                    },
                    dataset: {
                        dimensions: ["lawyer", "Касса", "План"],
                        source: this.lawyers
                            .filter(lawyer => lawyer.cleanCash !== 0)
                            .map(lawyer => {
                                return {
                                    lawyer: this.getXAxis(lawyer),
                                    "Касса": lawyer.cleanCash,
                                    "План": this.getPlan(lawyer.lawyerPlan)
                                }
                            })
                    },
                    xAxis: {
                        type: "category",
                        axisLabel: {
                            fontSize: 14,
                        },
                    },
                    yAxis: {
                        axisLabel: {
                            fontSize: 15,
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#444444'
                            }
                        }
                    },
                    // Declare several bar series, each will be mapped
                    // to a column of dataset.source by default.
                    series: [
                        {
                            type: "bar",
                            barGap: 0,
                            barMaxWidth: 120,
                            itemStyle: {
                                color: (param) => {
                                    const value = param.value['Касса'] / param.value['План'];
                                    const colors = ["red", "#FB8C00", "green"];
                                    const amounts = [0.699999, 0.999999];
                                    let color = colors[colors.length - 1];
                                    for (const [index, amount] of amounts.entries()) {
                                        if (value <= amount) {
                                            color = colors[index];
                                            break;
                                        }
                                    }
                                    return color;
                                }
                            },
                            label: {
                                show: true,
                                fontSize: 15,
                                position: 'inside'
                            },
                            markPoint: this.getMarkPoints(),
                        },
                        {
                            type: "bar",
                            barGap: 0,
                            barMaxWidth: 120,
                            itemStyle: { color: 'grey' },
                            label: {
                                show: true,
                                fontSize: 15,
                                position: 'inside'
                            },
                        }
                    ]
                }
            },
            goldMedalSVG () {
                return require(`../../../_assets/gold-medal.svg`);
            },
            silverMedalSVG () {
                return require(`../../../_assets/silver-medal.svg`);
            },
            bronzeMedalSVG () {
                return require(`../../../_assets/bronze-medal.svg`);
            },
        },
        methods: {
            fetch() {
                ReportService.getLawyersReport(this.filter).then(page => {
                    this.page = page;
                    this.lawyers = page.items;
                    this.loading = false;
                });
            },
            getBarWidth() {
                let minBarWidth = 140;
                const windowWidth = window.innerWidth - 340;
                const lawyersAmount = this.lawyers?.length || 1;
                let barWidth = windowWidth / lawyersAmount;
                barWidth = barWidth > minBarWidth ? barWidth : minBarWidth;
                return barWidth * lawyersAmount;
            },
            getDays(dates) {
                if (!dates) return 0;
                const daysArr = Array.isArray(dates) ? dates : dates.split(',');
                if (daysArr.length == 1) {
                    return 1;
                }
                daysArr.sort();
                const dateStart = this.$moment(daysArr[0]);
                const dateEnd = this.$moment(daysArr[1]);
                return dateEnd.diff(dateStart, 'days') + 1;
            },
            getPlan(dayLawyerPlan) {
                return dayLawyerPlan * this.days;
            },
            getMarkPoints() {
                return {
                    data: this.lawyers
                        //.sort((lawyer1, lawyer2) => lawyer1.cleanCash - lawyer2.cleanCash)
                        .slice(0, 3)
                        .map((lawyer, index) => {
                            return {
                                y: '30px',
                                xAxis: this.getXAxis(lawyer),
                                symbol: 'image://' + this.getMedalSVG(index + 1)
                            }
                        })
                }
            },
            getXAxis(lawyer) {
                return `${lawyer.name.split(" ")[0]} (${lawyer.contracts})`;
            },
            getMedalSVG (place) {
                const medals = {
                    1: this.goldMedalSVG,
                    2: this.silverMedalSVG,
                    3: this.bronzeMedalSVG,
                };
                return medals[place];
            },
        },
        created() {
            this.loading = true;
            this.fetch();
        },
    }
</script>

<style scoped>
    .echarts {
        width: 100% !important;
        height: calc(100vh - 370px);
    }
</style>
