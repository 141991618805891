var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "my-10"
  }, [_c('v-card-title', [_vm._v(" Ответы юриста "), _c('v-spacer'), _c('AnswerDialog', {
    attrs: {
      "id": _vm.request.id,
      "request": _vm.request,
      "areas": _vm.areas
    },
    on: {
      "saved": _vm.onAnswerAdd
    }
  })], 1), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.answers,
      "item-key": "id",
      "loading-text": "Загрузка ...",
      "items-per-page": 5
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var items = _ref.items;
        return [_c('tbody', _vm._l(items, function (item) {
          return _c('tr', {
            key: item.id
          }, [_c('td', {
            attrs: {
              "width": "220"
            }
          }, [_vm._v(_vm._s(item.createdAt))]), _c('td', {
            attrs: {
              "width": "220"
            }
          }, [_vm._v(_vm._s(item.user.fullName))]), _c('td', [_vm._v(_vm._s(item.text))])]);
        }), 0)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }