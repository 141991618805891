<template>
    <div>
        <v-card-title>
            Редактирование клиента
            <div class="text-right mx-3 button-close-edit">
                <v-btn text color="error" @click="$emit('closeEdit')" icon>
                    <v-icon large>mdi-close-circle-outline</v-icon>
                </v-btn>
            </div>
            <div class="ml-3">
                <v-btn small outlined color="green" class="mx-4" @click="reminderDialogVisible = true">
                    <v-icon left small>mdi-calendar-clock</v-icon>
                    <div>Добавить напоминание</div>
                </v-btn>
                <ReminderDialog :clientId="id" :dialogVisible="reminderDialogVisible" @close="reminderDialogVisible = false" @save="onRemindAdd"></ReminderDialog>
            </div>
            <div class="ml-3" v-if="$helper.permission.canCreateContract(user)">
                <v-btn :to="`/contracts/create?clientId=${id}`" small outlined color="green" class="mx-4">
                    <v-icon left small>mdi-note-plus-outline</v-icon>
                    <div>Добавить договор</div>
                </v-btn>
            </div>
            <div class="ml-3">
                <v-btn v-if="prevClient" fab text color="white darken-2" @click="changeEdited(prevClient.toString())">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn v-if="nextClient" fab text color="white darken-2" @click="changeEdited(nextClient.toString())">
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </div>
        </v-card-title>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader
                    type="heading, table-row@3, button"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <div v-if="!loading">
            <ClientForm
                v-if="client !== null"
                :page="page"
                @closeAndUpdate="$emit('closeAndUpdate')"
                @updated="$emit('updated')"
                @recallSelected="onRecallSelected">
            </ClientForm>
        </div>
    </div>
</template>

<script>
    import ClientForm from "./ClientForm";
    import {mapState} from 'vuex';
    import ReminderDialog from "./../reminders/ReminderDialog";
    import ClientService from "../_services/ClientService";

    export default {
        name: 'ClientUpdatePage',
        components: {ClientForm, ReminderDialog},
        props: {
            id: String,
            nextClient: Number,
            prevClient: Number,
            inPopup: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                client: null,
                page: null,
                loading: true,
                reminderDialogVisible: false
            }
        },
        methods: {
            fetch() {
                this.fetchClient();
            },
            fetchClient() {
                ClientService.getForm(this.id).then(data => {
                    this.page = data;
                    this.client = data.client;
                    this.loading = false;
                }, error => {
                    this.flashError(this.$helper.showError(error));
                });
            },
            deleteItem(id) {
                ClientService.delete(id).then(() => {
                    this.$emit('closeAndUpdate')
                });
            },
            onRemindAdd() {
                this.reminderDialogVisible = false;
                this.flashSuccess(`Напоминание добавлено`);
            },
            onRecallSelected() {
                this.reminderDialogVisible = true
            },
            changeEdited(id) {
                this.$emit("changeEdited", id);
            },
        },
        computed: {
            ...mapState(['user'])
        },
        created() {
            this.fetch();
        }
    };
</script>
