<template>
    <div>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader
                        type="heading, table-row@3, button"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <div v-if="!loading">
            <v-card-title>
                Заявка (#{{id}})
                <div class="ml-5">
                    <v-btn small color="primary" class="mx-4" outlined to="/">Все заявки</v-btn>
                </div>
            </v-card-title>
            <v-row>
                <v-col cols="12" md="6" sm="8">
                    <v-simple-table :hide-default-footer="true">
                        <template>
                            <tbody>
                            <tr>
                                <td>ID</td>
                                <td>{{request.id}}</td>
                            </tr>
                            <tr>
                                <td>Статус</td>
                                <td>
                                    <v-chip :color="request.state.chipColor" outlined dark>
                                        <v-icon v-if="request.appointmentApproved" small left>mdi-check</v-icon>
                                        {{request.state.text}}
                                    </v-chip>
                                </td>
                            </tr>
                            <tr v-if="request.appointmentDate">
                                <td>
                                    Запись
                                </td>
                                <td>
                                    <v-row>
                                        <v-col md="auto">
                                            {{request.appointmentDate}}
                                        </v-col>
                                        <v-col>
                                            {{request.appointmentTime.text}}
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="request.office">
                                        <v-col md="auto">
                                            {{request.office}}
                                        </v-col>
                                    </v-row>
                                </td>
                            </tr>
                            <tr v-if="request.source && $helper.permission.canSeeOverview(user) && $helper.permission.canSeeSourceOfRequest(user)">
                                <td>Источник</td>
                                <td>{{request.source.name}}</td>
                            </tr>
                            <tr v-if="$helper.permission.canSeeOverview(user)">
                                <td>Создана</td>
                                <td>{{request.createdAt}}</td>
                            </tr>
                            <tr v-if="$helper.permission.canSeeOverview(user)">
                                <td>Распределена</td>
                                <td>{{request.assignedAt}}</td>
                            </tr>
                            <tr v-if="request.operator">
                                <td>Оператор</td>
                                <td>{{request.operator.fullName}}</td>
                            </tr>
                            <tr v-if="request.userPhone && $helper.permission.canSeeOverview(user)">
                                <td>Телефон</td>
                                <td>{{request.userPhone}}</td>
                            </tr>
                            <tr v-if="request.userName">
                                <td>Имя клиента</td>
                                <td>{{request.userName}}</td>
                            </tr>
                            <tr v-if="request.userEmail">
                                <td>Email клиента</td>
                                <td>{{request.userEmail}}</td>
                            </tr>
                            <tr>
                                <td>Проблема</td>
                                <td class="py-3">{{request.problem}}</td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
                <v-col cols="12" md="6" sm="4">
                    <v-btn v-if="$helper.permission.canEditRequest(user)" color="primary" class="mr-4" outlined :to="`/requests/${id}/edit`">Редактировать</v-btn>
                </v-col>
            </v-row>
        </div>
        <RequestComments v-if="request" :id="request.id"></RequestComments>
    </div>
</template>

<script>
    import RequestService from "../_services/RequestService";
    import {mapState} from "vuex";
    import RequestComments from "./comments/RequestComments";

    export default {
        components: {RequestComments},
        props: {id: String},
        data() {
            return {
                request: null,
                loading: true,
                states: []
            }
        },
        computed: {
            ...mapState(['user'])
        },
        methods: {
            fetch() {
                this.loading = true;
                this.fetchRequest();
            },
            fetchRequest() {
                RequestService.getOne(this.id).then(data => {
                    this.request = data;
                    this.loading = false;
                });
            }
        },
        created() {
            this.fetch();
        }
    };
</script>
