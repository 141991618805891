<template>
    <div>
        <v-row>
            <v-col xl="9">
                <v-card-title v-if="title">
                    {{ title }}
                </v-card-title>
                <v-data-table
                    class="elevation-1"
                    :headers="headers"
                    :items="calls"
                    :options.sync="gridOptions.options"
                    :server-items-length="totalElements"
                    item-key="id"
                    :loading="loading"
                    loading-text="Загрузка ..."
                    :footer-props="{'items-per-page-options':[20, 30, 50]}">
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="item in items" :key="item.id">
                            <td width="220">{{ $moment(item.callTime).format("DD.MM.YYYY HH:mm") }}</td>
                            <td width="140">
                                <v-icon small v-if="item.direction == 'TERMINATING'" color="blue">mdi-phone-incoming
                                </v-icon>
                                <v-icon small v-if="item.direction == 'ORIGINATING'" color="orange">mdi-phone-outgoing
                                </v-icon>
                                <v-icon small v-if="item.status == 'MISSED'" class="ml-2" color="red">mdi-phone-missed
                                </v-icon>
                            </td>
                            <td>
                                <span v-if="item.direction == 'TERMINATING'">{{
                                        item.abonentName ? item.abonentName : item.calledNumber
                                    }}</span>
                                <span v-if="item.direction == 'ORIGINATING'">{{
                                        item.abonentName ? item.abonentName : item.callingNumber
                                    }}</span>
                            </td>
                            <td width="180">{{ item.duration }}</td>
                            <td>
                                <v-btn v-if="item.status != 'MISSED'" icon @click="playRecord(item.extTrackingId)">
                                    <v-icon>mdi-play-circle-outline</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-bottom-sheet v-model="dialog.playRecord.modal" persistent>
            <v-sheet class="text-center" height="200px">
                <v-btn class="mt-6" text color="error" @click="closePlayRecord()">закрыть</v-btn>
                <div class="py-3">
                    <PlayRecord v-if="playRecordId" :recordingId="playRecordId"></PlayRecord>
                </div>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>

<script>

import {mapState} from 'vuex';
import ContractCallService from "../../_services/ContractCallService";
import PlayRecord from "./PlayRecord";

export default {
    components: {PlayRecord},
    props: {
        filter: Object,
        title: String
    },
    data() {
        return {
            calls: [],
            headers: [
                {text: 'Дата', value: 'createTime'},
                {text: 'Направление', value: 'direction'},
                {text: 'Сотрудник', value: 'user'},
                {text: 'Длительность, с', value: 'time'},
                {text: 'Записи', sortable: false}
            ],
            loading: true,
            playRecordId: null,
            dialog: {
                playRecord: {
                    modal: false
                }
            },
            gridOptions: {
                options: {
                    sortBy: ['createTime']
                },
                filter: this.filter ? this.filter : {},
            },
            totalElements: 0
        }
    },
    watch: {
        gridOptions: {
            handler() {
                this.fetchGrid();
            },
            deep: true
        }
    },
    methods: {
        fetchGrid() {
            this.loading = true;
            ContractCallService.getAll(this.gridOptions.options, this.gridOptions.filter).then(data => {
                this.calls = data.content;
                this.totalElements = data.totalElements;
                this.loading = false;
            });
        },
        // eslint-disable-next-line no-unused-vars
        playRecord(recordId) {
            this.playRecordId = recordId;
            this.dialog.playRecord.modal = true;
        },
        closePlayRecord() {
            this.dialog.playRecord.modal = !this.dialog.playRecord.modal;
            this.playRecordId = null;
        },
    },
    computed: {
        ...mapState(['user'])
    },
    created() {
        this.$store.dispatch('loading', false);
    },
    beforeCreate() {
        this.$store.dispatch('loading', true);
    }
};
</script>
