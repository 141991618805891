<template>
    <v-card-text>
        <v-row v-if="loading">
            <v-col
                cols="12"
                md="7">
                <v-skeleton-loader type="table-row@3"></v-skeleton-loader>
            </v-col>
        </v-row>
        <div
            v-if="!loading && orks.length > 0"
            style="width: 100%; overflow-x: auto;">
            <div :style="{ width: getBarWidth() + 'px', 'min-width': 'calc(100%)' }">
                <v-chart :options="option"/>
            </div>
        </div>
    </v-card-text>
</template>

<script>
import "echarts/lib/chart/bar";
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/markPoint';
import ReportService from "@/_services/ReportService";
import {reportHelper} from "@/_helpers/report/reportHelper";

export default {
    name: "ChartOrkPlans",
    components: {},
    props: {
        filter: Object,
    },
    data: function () {
        return {
            loading: false,
            orks: [],
            days: this.getDays(this.filter?.request?.createdAtDates)
        }
    },
    computed: {
        option() {
            return {
                layout: {
                    padding: 20
                },
                textStyle: {
                    color: "#fff",
                    fontFamily: 'Roboto'
                },
                tooltip: {
                    trigger: "item"
                },
                grid: {
                    left: '15px',
                    right: '15px',
                    top: '15px',
                    containLabel: true
                },
                dataset: {
                    dimensions: ["ork", "Пришло", "План"],
                    source: this.orks
                        .filter(ork => ork.came !== 0)
                        .map(ork => {
                            return {
                                ork: this.getXAxis(ork),
                                "Пришло": ork.came,
                                "План": this.getPlan(ork.orkPlan)
                            }
                        })
                },
                xAxis: {
                    type: "category",
                    axisLabel: {
                        fontSize: 14,
                    },
                },
                yAxis: {
                    axisLabel: {
                        fontSize: 15,
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#444444'
                        }
                    }
                },
                series: [
                    {
                        type: "bar",
                        barGap: 0,
                        barMaxWidth: 120,
                        itemStyle: {
                            color: (param) => {
                                const value = param.value['Пришло'] / param.value['План'];
                                const colors = ["red", "#FB8C00", "green"];
                                const amounts = [0.699999, 0.999999];
                                let color = colors[colors.length - 1];
                                for (const [index, amount] of amounts.entries()) {
                                    if (value <= amount) {
                                        color = colors[index];
                                        break;
                                    }
                                }
                                return color;
                            }
                        },
                        label: {
                            show: true,
                            fontSize: 15,
                            position: 'inside'
                        },
                        markPoint: this.getMarkPoints(),
                    },
                    {
                        type: "bar",
                        barGap: 0,
                        barMaxWidth: 120,
                        itemStyle: {color: 'grey'},
                        label: {
                            show: true,
                            fontSize: 15,
                            position: 'inside'
                        },
                    }
                ]
            }
        },
        goldMedalSVG() {
            return require(`../../../_assets/gold-medal.svg`);
        },
        silverMedalSVG() {
            return require(`../../../_assets/silver-medal.svg`);
        },
        bronzeMedalSVG() {
            return require(`../../../_assets/bronze-medal.svg`);
        },
    },
    methods: {
        fetch() {
            const filter = {
                ...this.filter,
                reportType: "OPERATOR"
            };
            ReportService.getReport(filter).then(page => {
                page.items = page.items
                    .map(ork => {
                        return {
                            ...ork,
                            came: reportHelper.getOrkCompletedPlan(ork?.tuples) || 0,
                            contracts: reportHelper.getOrkContractResult(ork?.tuples) || 0,
                            orkPlan: ork.orkPlan ?? 0
                        }
                    })
                    .sort((a, b) => b.came - a.came);
                this.page = page;
                this.orks = page.items;
                this.loading = false;
            });
        },
        getBarWidth() {
            let minBarWidth = 140;
            const windowWidth = window.innerWidth - 340;
            const orksAmount = this.orks?.length || 1;
            let barWidth = windowWidth / orksAmount;
            barWidth = barWidth > minBarWidth ? barWidth : minBarWidth;
            return barWidth * orksAmount;
        },
        getDays(dates) {
            if (!dates) return 0;
            const daysArr = Array.isArray(dates) ? dates : dates.split(',');
            if (daysArr.length == 1) {
                return 1;
            }
            daysArr.sort();
            const dateStart = this.$moment(daysArr[0]);
            const dateEnd = this.$moment(daysArr[1]);
            return dateEnd.diff(dateStart, 'days') + 1;
        },
        getPlan(dayOrkPlan) {
            return dayOrkPlan * this.days;
        },
        getMarkPoints() {
            return {
                data: this.orks
                    .slice(0, 3)
                    .map((ork, index) => {
                        return {
                            y: '30px',
                            xAxis: this.getXAxis(ork),
                            symbol: 'image://' + this.getMedalSVG(index + 1)
                        }
                    })
            }
        },
        getXAxis(ork) {
            return `${ork.item.split(" ")[0]} (${ork.contracts})`;
        },
        getMedalSVG(place) {
            const medals = {
                1: this.goldMedalSVG,
                2: this.silverMedalSVG,
                3: this.bronzeMedalSVG,
            };
            return medals[place];
        },
    },
    created() {
        this.loading = true;
        this.fetch();
    },
}
</script>

<style scoped>
.echarts {
    width: 100% !important;
    height: calc(100vh - 370px);
}
</style>
