import Stomp from "webstomp-client";
import SockJS from "sockjs-client";
import {helper} from "./helper";

export default class StompClient {
    stomp;
    store;
    user;

    constructor(user, store) {
        this.store = store;
        this.user = user;

        this.connect();
    }

    connect() {
        this.stomp = Stomp.over(new SockJS(process.env.VUE_APP_WEBSOCKET_URL), {debug: process.env.VUE_APP_DEBUG_HTTP === 'true'});
        this.store.dispatch('stompConnect', this);
    }

    reConnect() {
        // new AWN().alert("Связь с сервером не установлена. Переподключение через 10 сек...");
        console.log('STOMP reconnect... ');
        this.connect();
        this.init();
    }

    init() {
        this.stomp.connect({'Authorization': 'Bearer ' + helper.ls.get('user').token}, () => {
            this.subscribeReminders(this.user);
            this.subscribeNewRequests(this.user);
            this.subscribeUpdateAccount(this.user);
            this.subscribeNewClients(this.user);
            this.subscribeNewVisits(this.user);
            this.subscribeNotifications(this.user);
        }, (error) => {
            console.log('STOMP: ' + error);
            setTimeout(() => this.reConnect(), 10000);
            console.log('STOMP: Reconnecting in 10 seconds');
        });
    }

    subscribeNewRequests() {
        // eslint-disable-next-line no-unused-vars
        this.stomp.subscribe(`/user/requests/new`, (request) => {
            this.store.dispatch('newRequestReceived', JSON.parse(request.body));
        });
    }

    subscribeNewClients() {
        // eslint-disable-next-line no-unused-vars
        this.stomp.subscribe(`/user/clients/new`, (request) => {
            this.store.dispatch('newClientReceived', JSON.parse(request.body));
        });
    }

    subscribeReminders() {
        this.stomp.subscribe(`/user/reminders/remind`, (remind) => {
            this.store.dispatch('remindReceived', JSON.parse(remind.body));
        });
    }

    subscribeUpdateAccount() {
        // eslint-disable-next-line no-unused-vars
        this.stomp.subscribe(`/user/accounts/update`, (request) => {
            this.store.dispatch('updateAccountReceived', JSON.parse(request.body));
        });
    }

    subscribeNewVisits() {
        // eslint-disable-next-line no-unused-vars
        this.stomp.subscribe(`/user/visits/new`, (request) => {
            console.log(request)
            this.store.dispatch('newVisitReceived', JSON.parse(request.body));
        });
    }

    subscribeNotifications() {
        // eslint-disable-next-line no-unused-vars
        this.stomp.subscribe(`/user/notifications/new`, (request) => {
            this.store.dispatch('notificationReceived', JSON.parse(request.body));
        });
    }
}
