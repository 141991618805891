<template>
    <div>
        <v-row v-show="calls.length > 0">
            <v-col xl="9">
                <v-card-title v-if="title">
                    {{ title }}
                </v-card-title>
                <v-data-table
                    class="elevation-1"
                    :headers="headers"
                    :items="calls"
                    :options.sync="gridOptions.options"
                    :server-items-length="totalElements"
                    item-key="id"
                    :loading="loading"
                    loading-text="Загрузка ..."
                    :footer-props="{'items-per-page-options':[20, 30, 50]}">
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="item in items" :key="item.id">
                            <td width="220">{{ item.createTime }}</td>
                            <td width="140">
                                <v-icon small v-if="item.direction == 'INCOMING'" color="blue">mdi-phone-incoming
                                </v-icon>
                                <v-icon small v-if="item.direction == 'OUTCOMING'" color="orange">mdi-phone-outgoing
                                </v-icon>
                                <v-icon small v-if="!item.notMissed" class="ml-2" color="red">mdi-phone-missed</v-icon>
                            </td>
                            <td>{{ item.user ? item.user.fullName : "" }}</td>
                            <td width="180">{{ item.time }}</td>
                            <td v-if="isVisibleColumn('Записи')">
                                <v-btn v-for="(record, index) in item.records" v-bind:key="index" icon
                                       @click="playRecord(record.recordingId)">
                                    <v-icon>mdi-play-circle-outline</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-bottom-sheet v-model="dialog.playRecord.modal" persistent>
            <v-sheet class="text-center" height="200px">
                <v-btn class="mt-6" text color="error" @click="closePlayRecord()">закрыть</v-btn>
                <div class="py-3">
                    <PlayRecord v-if="playRecordId" :recordingId="playRecordId"></PlayRecord>
                </div>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>

<script>
import CallService from "../_services/CallService";
import PlayRecord from "./PlayRecord";
import {mapState} from 'vuex';

export default {
    name: "CallsGrid",
    components: {PlayRecord},
    props: {
        filter: Object,
        title: String
    },
    data() {
        return {
            calls: [],
            loading: true,
            playRecordId: null,
            dialog: {
                playRecord: {
                    modal: false
                }
            },
            gridOptions: {
                options: {
                    sortBy: ['createTime']
                },
                filter: this.filter ? this.filter : {},
            },
            totalElements: 0
        }
    },
    computed: {
        headers: function () {
            return this.$helper.callsGridColumns.getForRole(this.user.role);
        },
        ...mapState(['user'])
    },
    watch: {
        gridOptions: {
            handler() {
                this.fetchGrid();
            },
            deep: true
        }
    },
    methods: {
        fetch() {
            //this.fetchUsers();
        },
        fetchGrid() {
            this.loading = true;
            CallService.getAll(this.gridOptions.options, this.gridOptions.filter).then(data => {
                this.calls = data;
                this.totalElements = data.totalElements;
                this.loading = false;
            });
        },
        // eslint-disable-next-line no-unused-vars
        playRecord(recordId) {
            this.playRecordId = recordId;
            this.dialog.playRecord.modal = true;
        },
        closePlayRecord() {
            this.dialog.playRecord.modal = !this.dialog.playRecord.modal;
            this.playRecordId = null;
        },
        isVisibleColumn(column) {
            return this.headers.find(el => el.text === column) !== undefined;
        },
    },
    created() {
        this.fetch();
        this.$store.dispatch('loading', false);
    },
    beforeCreate() {
        this.$store.dispatch('loading', true);
    }
};
</script>
