<template>
    <div>
        <v-card-title>
            Создание сотрудника
            <div class="ml-5">
                <v-btn small color="primary" class="mx-4" outlined to="/users">Все сотрудники</v-btn>
            </div>
        </v-card-title>
        <UserForm></UserForm>
    </div>
</template>

<script>
    import UserForm from "./UserForm";

    export default {
        name: 'UserCreatePage',
        components: {UserForm},
    };
</script>
