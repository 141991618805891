var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, table-row@3, button"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading ? _c('div', {
    staticClass: "my-10"
  }, [_c('v-card-title', [_vm._v(" Комментарии "), _c('v-spacer'), _c('CommentDialog', {
    attrs: {
      "id": _vm.id
    },
    on: {
      "saved": _vm.onCommentAdd
    }
  })], 1), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.comments,
      "item-key": "id",
      "loading": _vm.loading,
      "loading-text": "Загрузка ...",
      "items-per-page": 5
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var items = _ref.items;
        return [_c('tbody', _vm._l(items, function (item) {
          return _c('tr', {
            key: item.id
          }, [_c('td', {
            attrs: {
              "width": "220"
            }
          }, [_vm._v(_vm._s(item.date))]), _c('td', {
            attrs: {
              "width": "220"
            }
          }, [_vm._v(_vm._s(item.user.fullName))]), _c('td', {
            staticStyle: {
              "white-space": "pre-line"
            }
          }, [_vm._v(_vm._s(item.text))])]);
        }), 0)];
      }
    }], null, false, 2042435537)
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }