var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "row": "",
      "justify-center": ""
    }
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "content": "",
      "content-class": "centered-dialog"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, [_c('v-container', {
    attrs: {
      "fill-height": ""
    }
  }, [_c('v-layout', {
    staticClass: "p-5",
    attrs: {
      "column": "",
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": 30,
      "width": 3,
      "color": _vm.progressColor
    }
  }), _vm.message != null ? _c('h1', [_vm._v(_vm._s(_vm.message))]) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }