var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-3"
  }, [_c('v-expansion-panels', [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('v-card-title', [_vm._v("История изменений")])], 1), _c('v-expansion-panel-content', [_c('v-simple-table', {
    attrs: {
      "hide-default-footer": true,
      "align": "top"
    }
  }, [[_c('tbody', _vm._l(_vm.revisions, function (revision) {
    var _revision$changes, _revision$changes2, _revision$changes3, _revision$changes4, _revision$changes5, _revision$changes6, _revision$changes7, _revision$changes8, _revision$changes9, _revision$changes10, _revision$changes11, _revision$changes12;
    return _c('tr', {
      key: revision.revisionDate
    }, [_c('td', {
      staticStyle: {
        "vertical-align": "top"
      },
      attrs: {
        "width": "220px"
      }
    }, [_vm._v(" " + _vm._s(_vm.$moment(revision.revisionDate).dateTimeFormat()) + " ")]), _c('td', {
      staticClass: "revision-changes"
    }, [_c('v-row', [revision !== null && revision !== void 0 && (_revision$changes = revision.changes) !== null && _revision$changes !== void 0 && _revision$changes.reporting ? _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "mb-3 mr-5"
    }, [_c('h4', {
      staticClass: "mb-3"
    }, [_vm._v("Юр. отдел")]), _c('v-row', [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', [_vm._v("план дня:")])]), _c('v-col', {
      domProps: {
        "innerHTML": _vm._s(_vm.showChange(revision === null || revision === void 0 ? void 0 : (_revision$changes2 = revision.changes) === null || _revision$changes2 === void 0 ? void 0 : _revision$changes2.reporting, 'planDay'))
      }
    })], 1), _c('div', [_c('table', {
      attrs: {
        "cellspacing": "0"
      }
    }, [_c('tr', [_c('td', {
      staticStyle: {
        "padding-left": "0 !important",
        "vertical-align": "top"
      },
      attrs: {
        "rowspan": "2"
      }
    }, [_c('span', [_vm._v("план отрезка:")])]), _c('td', [_c('span', [_vm._v("план:")])]), _c('td', [_c('font', {
      domProps: {
        "innerHTML": _vm._s(_vm.showChange(revision === null || revision === void 0 ? void 0 : (_revision$changes3 = revision.changes) === null || _revision$changes3 === void 0 ? void 0 : _revision$changes3.reporting, 'planInterval'))
      }
    })], 1)]), _c('tr', [_c('td', [_c('span', [_vm._v("период:")])]), _c('td', [_c('font', {
      domProps: {
        "innerHTML": _vm._s(_vm.showChange(revision === null || revision === void 0 ? void 0 : (_revision$changes4 = revision.changes) === null || _revision$changes4 === void 0 ? void 0 : _revision$changes4.reporting, 'intervalDates', function (time) {
          return _vm.showDatePeriod(time);
        }))
      }
    })], 1)])])]), _c('div', [_c('table', {
      attrs: {
        "cellspacing": "0"
      }
    }, [_c('tr', [_c('td', {
      staticStyle: {
        "padding-left": "0 !important",
        "vertical-align": "top"
      },
      attrs: {
        "rowspan": "2"
      }
    }, [_c('span', [_vm._v("план периода:")])]), _c('td', [_c('span', [_vm._v("план:")])]), _c('td', [_c('font', {
      domProps: {
        "innerHTML": _vm._s(_vm.showChange(revision === null || revision === void 0 ? void 0 : (_revision$changes5 = revision.changes) === null || _revision$changes5 === void 0 ? void 0 : _revision$changes5.reporting, 'planPeriod'))
      }
    })], 1)]), _c('tr', [_c('td', [_c('span', [_vm._v("период:")])]), _c('td', [_c('font', {
      domProps: {
        "innerHTML": _vm._s(_vm.showChange(revision === null || revision === void 0 ? void 0 : (_revision$changes6 = revision.changes) === null || _revision$changes6 === void 0 ? void 0 : _revision$changes6.reporting, 'periodDates', function (time) {
          return _vm.showDatePeriod(time);
        }))
      }
    })], 1)])])])], 1)]) : _vm._e(), revision !== null && revision !== void 0 && (_revision$changes7 = revision.changes) !== null && _revision$changes7 !== void 0 && _revision$changes7.reportingOrk ? _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "mb-3 mr-5"
    }, [_c('h4', {
      staticClass: "mb-3"
    }, [_vm._v("Орк. отдел")]), _c('div', [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', [_vm._v("план дня:")])]), _c('v-col', {
      domProps: {
        "innerHTML": _vm._s(_vm.showChange(revision === null || revision === void 0 ? void 0 : (_revision$changes8 = revision.changes) === null || _revision$changes8 === void 0 ? void 0 : _revision$changes8.reportingOrk, 'planDay'))
      }
    })], 1)], 1), _c('div', [_c('table', {
      attrs: {
        "cellspacing": "0"
      }
    }, [_c('tr', [_c('td', {
      staticStyle: {
        "padding-left": "0 !important",
        "vertical-align": "top"
      },
      attrs: {
        "rowspan": "2"
      }
    }, [_c('span', [_vm._v("план отрезка:")])]), _c('td', [_c('span', [_vm._v("план:")])]), _c('td', [_c('font', {
      domProps: {
        "innerHTML": _vm._s(_vm.showChange(revision === null || revision === void 0 ? void 0 : (_revision$changes9 = revision.changes) === null || _revision$changes9 === void 0 ? void 0 : _revision$changes9.reportingOrk, 'planInterval'))
      }
    })], 1)]), _c('tr', [_c('td', [_c('span', [_vm._v("период:")])]), _c('td', [_c('font', {
      domProps: {
        "innerHTML": _vm._s(_vm.showChange(revision === null || revision === void 0 ? void 0 : (_revision$changes10 = revision.changes) === null || _revision$changes10 === void 0 ? void 0 : _revision$changes10.reportingOrk, 'intervalDates', function (time) {
          return _vm.showDatePeriod(time);
        }))
      }
    })], 1)])])]), _c('div', [_c('table', {
      attrs: {
        "cellspacing": "0"
      }
    }, [_c('tr', [_c('td', {
      staticStyle: {
        "padding-left": "0 !important",
        "vertical-align": "top"
      },
      attrs: {
        "rowspan": "2"
      }
    }, [_c('span', [_vm._v("план периода:")])]), _c('td', [_c('span', [_vm._v("план:")])]), _c('td', [_c('font', {
      domProps: {
        "innerHTML": _vm._s(_vm.showChange(revision === null || revision === void 0 ? void 0 : (_revision$changes11 = revision.changes) === null || _revision$changes11 === void 0 ? void 0 : _revision$changes11.reportingOrk, 'planPeriod'))
      }
    })], 1)]), _c('tr', [_c('td', [_c('span', [_vm._v("период:")])]), _c('td', [_c('font', {
      domProps: {
        "innerHTML": _vm._s(_vm.showChange(revision === null || revision === void 0 ? void 0 : (_revision$changes12 = revision.changes) === null || _revision$changes12 === void 0 ? void 0 : _revision$changes12.reportingOrk, 'periodDates', function (time) {
          return _vm.showDatePeriod(time);
        }))
      }
    })], 1)])])])])]) : _vm._e()], 1)], 1)]);
  }), 0)]], 2)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }