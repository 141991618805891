import store from '../_store/';
import {http} from "../_helpers";
import Service from "./Service";
import {axiosInstance} from "../_helpers/axiosInstance";

class UserService extends Service {

    login(username, password) {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({username, password})
        };

        return fetch(`${process.env.VUE_APP_API_URL}/users/login`, requestOptions)
            .then(this.handleResponse)
            .then(user => {
                store.dispatch('auth', user);

                return user;
            });
    }

    create(form) {
        return http.post(`/users`, form);
    }

    update(id, form) {
        return http.put(`/users/${id}`, form);
    }

    delete(id) {
        return http.delete(`/users/${id}`);
    }

    getAll(options, filter) {
        return http.get(this.makeOptionsUrl(`/users`, options, filter));
    }

    getAllWithNotActive(options, filter) {
        return http.get(this.makeOptionsUrl(`/users/all`, options, filter));
    }

    getOne(id) {
        return http.get(`/users/${id}`);
    }

    getCurrent() {
        return http.get(`/users/current`);
    }
    getAllOperators() {
        return http.get(`/users?role=OPERATOR&size=100`);
    }

    getAllLawyers() {
        return http.get(`/users?role=LAWYER&size=100`);
    }

    getAllCounterparts() {
        return http.get(`/users?role=COUNTERPART&size=100`);
    }

    getRoles() {
        return http.get(`/users/roles`);
    }

    updateFirebaseToken(token) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(`/users/firebase-token`, {'token': token}).then(
                (response) => resolve(response.data)
            ).catch(error => {
                reject(error)
            })
        });
    }

    updateOutOfPlace(userId, value) {
        return http.put(`/users/${userId}/outOfPlace/${value}`);
    }

    logout() {
        store.dispatch('logout');
    }

    handleResponse(response) {
        const token = response.headers.get('token');
        if (!token) {
            return Promise.reject("Неверный Email или пароль");
        }
        return response.text().then((text) => {
            const data = text && JSON.parse(text);
            data.token = token;
            return data;
        });
    }
}

export default new UserService;
