var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "list-item-avatar-two-line"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading ? _c('v-card', [_c('vuetify-audio', {
    attrs: {
      "file": _vm.filePath,
      "color": "success",
      "autoPlay": "",
      "downloadable": ""
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }