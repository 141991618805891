import {http} from "../_helpers";
import Service from "./Service";

class ReminderService extends Service {

    getAll(options, filter) {
        return http.get(this.makeOptionsUrl(`/reminders`, options, filter));
    }

    getOne(id) {
        return http.get(`/reminders/${id}`);
    }

    create(form) {
        return http.post(`/reminders`, form);
    }

    update(id, form) {
        return http.put(`/reminders/${id}`, form);
    }

    delete(id) {
        return http.delete(`/reminders/${id}`);
    }
}

export default new ReminderService;
