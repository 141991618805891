<template>
    <div>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader type="heading, table-row@3, button"></v-skeleton-loader>
            </v-col>
        </v-row>
        <div class="my-10" v-if="!loading">
            <v-card-title>
                События
                <v-spacer></v-spacer>
                <EventDialog
                    :contractId="id"
                    :types="types"
                    :executors="executors"
                    @saved="onEventAdd">
                </EventDialog>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="events"
                class="elevation-1"
                item-key="id"
                :loading="loadingTable"
                :options.sync="eventGridOptions.options"
                loading-text="Загрузка ..."
                :items-per-page="10">
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td width="90">
                            <v-row>
                                <v-col>
                                    <EventDialog
                                        :id="item.id"
                                        :contractId="item.contract"
                                        :types="types"
                                        :executors="executors"
                                        @saved="onEventAdd">
                                    </EventDialog>
                                </v-col>
                                <v-col>
                                    <v-dialog
                                        v-model="deleteDialog[item.id]"
                                        max-width="320"
                                        v-if="$helper.permission.canDeleteContract(user)">
                                        <template v-slot:activator="{ on }">
                                            <v-icon small color="red" v-on="on">mdi-delete</v-icon>
                                        </template>
                                        <v-card>
                                            <v-card-text class="text-center pt-7">Удалить выбранный элемент?</v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn text @click="deleteDialog[item.id] = false">Отмена</v-btn>
                                                <v-btn
                                                    color="red"
                                                    text
                                                    @click="deleteItem(item.id)">
                                                    Удалить
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                        </td>
                        <td width="200">
                            {{ $moment(item.dateTime).dateTimeFormat() }}
                        </td>
                        <td width="260">
                            <div v-if="item.type">
                                {{ item.type?.text }}
                            </div>
                        </td>
                        <td>
                            {{ item.comment }}
                        </td>
                        <td width="200">
                            {{ item.executor?.fullName }}
                        </td>
                        <td width="270">
                            {{ item.substitution?.fullName }}
                        </td>
                        <td width="270">
                            {{ $moment(item.createdAt).dateFormat() }}
                        </td>
                        <td>
                            {{ item?.clientName }}
                        </td>
                        <td width="310">
                            {{ item?.user?.fullName }}
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import EventDialog from "@/contracts/events/EventDialog.vue";
import ContractService from "@/_services/ContractService";
import ContractEventService from "@/_services/ContractEventService";

export default {
    name: "Events",
    components: {EventDialog},
    props: {
        id: Number,
        types: Array,
        executors: Array
    },
    data() {
        return {
            headers: [
                {sortable: false},
                {text: 'Дата и время', value: 'dateTime'},
                {text: 'Тип', value: 'type'},
                {text: 'Описание', value: 'comment', sortable: false},
                {text: 'Исполнитель', value: 'executor', sortable: false},
                {text: 'Замена', value: 'substitution', sortable: false},
                {text: 'Дата создания', value: 'createdAt'},
                {text: 'ФИО клиента', value: 'clientName', sortable: false},
                {text: 'Сотрудник', value: 'user'}
            ],
            filterForm: {},
            eventGridOptions: {
                options: {
                    sortBy: ['id'],
                    sortDesc: [true],
                    page: 1,
                    itemsPerPage: 10
                }
            },
            events: [],
            loading: true,
            loadingTable: false,
            deleteDialog: {}
        }
    },
    watch: {
        eventGridOptions: {
            handler() {
                this.fetchItems();
            },
            deep: true
        },
        filterForm: {
            handler() {
                this.fetchItems();
            },
            deep: true
        },
    },
    methods: {
        fetch() {
            this.loading = false;
        },
        fetchItems() {
            this.loadingTable = true;
            ContractService
                .getEvents(this.id, this.eventGridOptions.options, this.filterForm)
                .then(data => {
                    this.events = data.content;
                    this.loading = false;
                    this.loadingTable = false;
                });
        },
        onEventAdd() {
            this.fetchItems();
            this.$emit('saved');
        },
        deleteItem(id) {
            ContractEventService.delete(id).then(() => {
                this.fetchItems();
            });
        },
    },
    computed: {
        ...mapState(['user']),
    },
    created() {
        this.fetch();
    }
};
</script>
