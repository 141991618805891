<template>
    <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on }">
            <v-btn color="green" v-on="on">
                Добавить акт/выплату
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Добавить акт/выплату</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-select
                            v-model="form.type"
                            label="Тип"
                            :items="actTypes"
                            clearable
                            return-object
                            item-value="value"
                            item-text="text">
                        </v-select>
                        <v-alert v-if="form.errors.has('type')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('type') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="form.number"
                            label="Номер">
                        </v-text-field>
                        <v-alert v-if="form.errors.has('number')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('number') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="form.amount"
                            label="Сумма">
                        </v-text-field>
                        <v-alert v-if="form.errors.has('amount')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('amount') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select
                            v-model="form.lawyerEPO"
                            label="Юрист ЭПО"
                            :items="lawyersEPO"
                            clearable
                            item-value="id"
                            item-text="fullName"></v-select>
                        <v-alert v-if="form.errors.has('lawyerEPO')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('lawyerEPO') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select
                            v-model="form.lawyerOGPA"
                            label="Юрист ОГПиА"
                            :items="lawyersOGPA"
                            clearable
                            item-value="id"
                            item-text="fullName">
                        </v-select>
                        <v-alert v-if="form.errors.has('lawyerOGPA')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('lawyerOGPA') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select
                            v-model="form.lawyerOGPAAssistant"
                            label="Помощник юриста ОГПиА"
                            :items="lawyersOGPA"
                            clearable
                            item-value="id"
                            item-text="fullName">
                        </v-select>
                        <v-alert v-if="form.errors.has('lawyerOGPAAssistant')" dense outlined type="error"
                                 :icon="false">
                            {{ form.errors.get('lawyerOGPAAssistant') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <DateField
                            label="Дата подписания"
                            v-model="form.signDate"
                        ></DateField>
                        <v-alert v-if="form.errors.has('signDate')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('signDate') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea
                            v-model="form.comment"
                            label="Примечание"
                            rows="2">
                        </v-textarea>
                        <v-alert v-if="form.errors.has('comment')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('comment') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea
                            auto-grow
                            v-model="form.text"
                            label="Комментарий">
                        </v-textarea>
                        <v-alert v-if="form.errors.has('text')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('text') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-checkbox
                            class="chekbox__blue"
                            value="HALVA"
                            v-model="form.installment"
                            label="Халва">
                        </v-checkbox>
                        <v-alert v-if="form.errors.has('installment')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('installment') }}
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">Отмена</v-btn>
                <v-btn color="blue darken-1" text @click="save">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Form from "../_helpers/_form/Form";
import DateField from "@/_chunks/DateField.vue";
import ActService from "@/_services/ActService";

export default {
    components: {DateField},
    props: {
        lawyersOGPA: Array,
        lawyersEPO: Array,
        actTypes: Array,
    },
    data() {
        return {
            form: new Form({}),
            dialog: false,
            signDateDialog: false
        }
    },
    methods: {
        save() {
            ActService.create(this.form).then(() => {
                this.dialog = false;
                this.$emit('saved');
            });
        },
    }
};
</script>
