<template>
    <div>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader
                        type="heading, table-row@3, button"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <div class="my-10" v-if="!loading">
            <v-card-title>
                Доп. соглашение
                <v-spacer></v-spacer>
                <AdditionalAgreementDialog v-if="$helper.permission.canCreateContract(user)" :id="id" @saved="onItemAdd"></AdditionalAgreementDialog>
            </v-card-title>
            <v-data-table :headers="headers" :items="items" class="elevation-1" item-key="id" :loading="loading"
                          loading-text="Загрузка ..." :items-per-page="5">
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td width="20" class="px-5">
                            <v-dialog
                                v-model="deleteDialog[item.id]"
                                max-width="320"
                                v-if="$helper.permission.canDeleteRequest(item, user)">
                                <template v-slot:activator="{ on }">
                                    <v-icon small color="red" v-on="on">mdi-delete</v-icon>
                                </template>
                                <v-card>
                                    <v-card-text class="text-center pt-7">Удалить выбранный элемент?</v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn text @click="deleteDialog[item.id] = false">Отмена</v-btn>
                                        <v-btn color="red" text @click="deleteItem(item.id)">Удалить</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </td>
                        <td width="160">{{ $moment(item.createdAt).dateTimeFormat() }}</td>
                        <td width="220">{{ item.date }}</td>
                        <td>{{ item.text }}</td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
    import ContractService from "../../_services/ContractService";
    import AdditionalAgreementDialog from "./AdditionalAgreementDialog";
    import {mapState} from 'vuex';

    export default {
        components: {AdditionalAgreementDialog},
        props: {id: Number},
        data() {
            return {
                headers: [
                    {sortable: false},
                    {text: 'Дата создания', value: 'createdAt'},
                    {text: 'Дата', value: 'date'},
                    {text: 'Комментарий', value: 'text'}
                ],
                items: [],
                deleteDialog: {},
                loading: true
            }
        },
        methods: {
            fetch() {
                this.loading = true;
                this.fetchItems();
            },
            fetchItems() {
                ContractService.getAdditionalAgreements(this.id).then(data => {
                    this.items = data;
                    this.loading = false;
                });
            },
            onItemAdd() {
                this.fetchItems();
            },
            deleteItem(id) {
                ContractService.deleteAdditionalAgreement(id).then(() => {
                    this.fetchItems();
                });
            }
        },
        computed: {
            ...mapState(['user'])
        },
        created() {
            this.fetch();
        }
    };
</script>
