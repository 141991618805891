var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.loading ? _c('div', [_c('v-list', {
    attrs: {
      "subheader": ""
    }
  }, [_c('v-subheader', [_vm._v("Биллинг")]), _vm._l(_vm.accounts, function (account) {
    return _c('div', {
      key: account.id
    }, [_c('Balance', {
      key: _vm.balanceKey,
      attrs: {
        "account": account
      }
    }), _c('v-divider')], 1);
  })], 2)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }