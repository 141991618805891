<template>
    <div>
        <v-card-title>
            Биллинг
            <v-spacer></v-spacer>
            <div>
                <v-dialog
                    v-model="dialog.paymentAdd.modal" max-width="500px"
                    v-if="$helper.permission.canAddPayments(this.user) || this.user.role === 'PROVIDER'">
                    <template v-slot:activator="{ on }">
                        <v-btn large color="green" v-on="on">
                            Добавить оплату
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">Добавить оплату</span>
                        </v-card-title>
                        <v-card-text v-if="$helper.permission.canAddPayments(this.user)">
                            <v-autocomplete
                                v-model="form.account"
                                :items="accounts"
                                item-value="id"
                                item-text="user"
                                label="Аккаунт"
                                :clearable="true">
                            </v-autocomplete>
                            <v-alert v-if="form.errors.has('account')" dense outlined type="error" :icon="false">
                                {{ form.errors.get('account') }}
                            </v-alert>
                        </v-card-text>
                        <v-card-text>
                            <v-text-field v-model="form.paidAmount" label="Сумма"></v-text-field>
                            <v-alert v-if="form.errors.has('paidAmount')" dense outlined type="error" :icon="false">
                                {{ form.errors.get('paidAmount') }}
                            </v-alert>
                        </v-card-text>
                        <v-card-text>
                            <v-textarea auto-grow v-model="form.comment" label="Комментарий"></v-textarea>
                            <v-alert v-if="form.errors.has('comment')" dense outlined type="error" :icon="false">
                                {{ form.errors.get('comment') }}
                            </v-alert>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog.paymentAdd.modal = false">Отмена</v-btn>
                            <v-btn color="blue darken-1" text @click="save">Сохранить</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </v-card-title>
        <v-data-table
            class="elevation-1"
            :headers="headers"
            :items="payments"
            :options.sync="options"
            :server-items-length="totalElements"
            item-key="id"
            :loading="loading"
            loading-text="Загрузка ..."
            :footer-props="{'items-per-page-options':[20, 30, 50]}">
            <template v-slot:body="{ items }">
                <tbody>
                <tr>
                    <td width="20" class="px-2"></td>
                    <td v-if="isVisibleColumn('id')" width="160">
                        <v-text-field
                            v-model="filter.id"
                            type="text">
                        </v-text-field>
                    </td>
                    <td v-if="isVisibleColumn('account')" width="240">
                        <v-select
                            v-model="filter.account"
                            :items="accounts"
                            clearable
                            item-value="id"
                            item-text="user"
                        ></v-select>
                    </td>
                    <td v-if="isVisibleColumn('createdAt')" width="500">
                        <v-dialog
                            ref="dialog"
                            v-model="dialog.createdAt.modal"
                            :return-value.sync="filter.createdAtDates"
                            persistent width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filter.createdAtDates"
                                    prepend-icon="mdi-calendar-month-outline"
                                    readonly
                                    clearable
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="dialog.createdAt.model"
                                type="date"
                                scrollable
                                range
                                first-day-of-week="1">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="dialog.createdAt.modal = false">Отмена</v-btn>
                                <v-btn text color="primary" @click="$refs.dialog.save(dialog.createdAt.model)">
                                    Выбрать
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </td>
                    <td v-if="isVisibleColumn('paidAmount')">
                        <v-text-field v-model="filter.paidAmount" type="text"></v-text-field>
                    </td>
                    <td v-if="isVisibleColumn('requestPrice')">
                        <v-text-field v-model="filter.requestPrice" type="text"></v-text-field>
                    </td>
                    <td v-if="isVisibleColumn('requestQuantity')">
                        <v-text-field v-model="filter.requestQuantity" type="text"></v-text-field>
                    </td>
                    <td v-if="isVisibleColumn('remainingBalance')"></td>
                    <td v-if="isVisibleColumn('comment')">
                        <v-text-field v-model="filter.comment" type="text"></v-text-field>
                    </td>
                </tr>
                <tr v-for="item in items" :key="item.id">
                    <td class="px-2">
                        <v-dialog
                            v-model="deleteDialog[item.id]"
                            max-width="320"
                            v-if="$helper.isUserRole(user, 'ADMIN')">
                            <template v-slot:activator="{ on }">
                                <v-icon small color="red" v-on="on">mdi-delete</v-icon>
                            </template>
                            <v-card>
                                <v-card-text class="text-center pt-7">Удалить выбранный элемент?</v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="deleteDialog[item.id] = false">Отмена</v-btn>
                                    <v-btn color="red" text @click="deleteItem(item.id)">Удалить</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </td>
                    <td v-if="isVisibleColumn('id')">{{ item.id }}</td>
                    <td v-if="isVisibleColumn('account')">
                        <div v-if="item.account && item.account.user">{{ item.account.user }}</div>
                    </td>
                    <td v-if="isVisibleColumn('createdAt')">{{ $moment(item.createdAt).dateTimeFormat() }}</td>
                    <td v-if="isVisibleColumn('paidAmount')">{{ item.paidAmount }}</td>
                    <td v-if="isVisibleColumn('requestPrice')">{{ item.requestPrice }}</td>
                    <td v-if="isVisibleColumn('requestQuantity')">{{ item.requestQuantity }}</td>
                    <td v-if="isVisibleColumn('remainingBalance')">{{ item.remainingBalance }}</td>
                    <td v-if="isVisibleColumn('comment')">{{ item.comment }}</td>
                </tr>
                </tbody>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import PaymentService from "../_services/PaymentService";
import AccountService from "../_services/AccountService";
import {mapState} from 'vuex';
import Form from "../_helpers/_form/Form";

export default {
    name: "PaymentGrid",
    data() {
        return {
            form: new Form({}),
            dialog: {
                createdAt: {
                    model: [],
                    modal: false
                },
                paymentAdd: {
                    modal: false
                }
            },
            deleteDialog: {},
            payments: [],
            loading: true,
            options: {
                sortBy: ['id'],
                sortDesc: [true]
            },
            filter: {},
            accounts: [],
            totalElements: 0
        }
    },
    watch: {
        options: {
            handler() {
                this.fetchGrid();
            },
            deep: true
        },
        filter: {
            handler() {
                this.fetchGrid();
            },
            deep: true
        },
    },
    methods: {
        fetch() {
            if (this.user.role == 'ADMIN') {
                AccountService.getAllAccounts().then(data => {
                    this.accounts = data;
                });
            }
        },
        fetchGrid() {
            this.loading = true;
            PaymentService.getAll(this.options, this.filter).then(data => {
                this.payments = data.content;
                this.totalElements = data.totalElements;
                this.loading = false;
            });
        },
        save() {
            if (this.user.role == 'COUNTERPART') {
                PaymentService.addProviderPayment(this.form).then(() => {
                    this.dialog.paymentAdd.modal = false;
                    this.fetchGrid();
                });
            } else {
                PaymentService.addPayment(this.form).then(() => {
                    this.dialog.paymentAdd.modal = false;
                    this.fetchGrid();
                });
            }
        },
        isVisibleColumn(column) {
            return this.headers.find(el => el.value === column) !== undefined;
        },
        deleteItem(id) {
            PaymentService.delete(id).then(() => {
                this.fetchGrid();
            });
        }
    },
    computed: {
        ...mapState(['user']),
        headers: function () {
            return this.$helper.paymentsGridColumns.getForRole(this.user.role);
        },
    },
    created() {
        this.fetch();
        this.$store.dispatch('loading', false);
    },
    beforeCreate() {
        this.$store.dispatch('loading', true);
    }
};
</script>
