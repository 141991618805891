<template>
    <div>
        <span
            v-for="(contract, i) in contracts"
            :key="i"
        >
            <router-link :to="`/contracts/${contract.id}/edit`">
                #{{ contract.number }}
            </router-link>
        </span>
    </div>
</template>

<script>
  export default {
      name: "ContractLinks",
      props: {
          contracts: Array,
      },
  }
</script>

<style scoped>

</style>