import Vue from 'vue';
import Vuex from 'vuex';
import {helper, router} from '../_helpers';
import {defaultSettings} from '../_helpers/defaultSettings';
import moment from "moment";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        pageTitle: 'Home',
        loading: false,
        user: null,
        globalSettings: {},
        unMute: defaultSettings.unMute,
        settings: {
            unNotify: true,
        },
        message: {
            type: null,
            body: null
        },
        requestGridsOptions: {
            options: {
                sortBy: ['id'],
                sortDesc: [true]
            },
            filter: {
                createdAtDates: moment().format("YYYY-MM-DD"),
                assignedAtDates: null
            }
        },
        clientGridsOptions: {
            options: {
                sortBy: ['id'],
                sortDesc: [true]
            },
            filter: {
                createdAtDates: moment().format("YYYY-MM-DD"),
                office: Number.parseInt(process.env.VUE_APP_DEFAULT_OFFICE)
            }
        },
        userGridOptions: {
            options: {
                sortBy: ['lastName']
            },
            filter: {}
        },
        eventGridOptions: {
            options: {
                sortBy: ['id'],
                sortDesc: [true],
                page: 1,
                itemsPerPage: 20
            },
            filter: {
                dateTimes: moment().format("YYYY-MM-DD"),
            }
        },
        contractGridsOptions: {
            options: {
                sortBy: ['id'],
                sortDesc: [true]
            },
            filter: {
                dates: moment().format("YYYY-MM-DD"),
                flow: {},
                taskCivilianRepresentatives: {},
                taskLegalExpert: {},
                taskAddLegalExpert: {}
            }
        },
        contractOKKGridsOptions: {
            options: {
                sortBy: ['id'],
                sortDesc: [true]
            },
            filter: {
                sentToOkkActionsDates: moment().format("YYYY-MM-DD"),
                flow: {},
            }
        },
        contractPaymentsGridsOptions: {
            options: {
                sortBy: ['id'],
                sortDesc: [true]
            },
            filter: {
                dates: moment().format("YYYY-MM-DD"),
                //isTransport: false,
                additionalExpensesTypes: ["TRANSPORT"],
                contract: {}
            }
        },
        actGridsOptions: {
            options: {
                sortBy: ['id'],
                sortDesc: [true]
            },
            filter: {
                dates: moment().format("YYYY-MM-DD"),
                contract: {},
                createdBy: {},
            }
        },
        appointmentsGridsOptions: {
            options: {
                sortBy: ['id'],
                sortDesc: [true]
            },
            filter: {
                appointmentDates: moment().format("YYYY-MM-DD")
            },
        },
        reportGridsOptions: {
            options: {
                sortBy: ['id'],
                sortDesc: [true]
            },
            filter: {
                reportType: "OPERATOR",
                request: {
                    createdAtDates: moment().format("YYYY-MM-DD")
                }
            },
        },
        reportLawyerGridOptions: {
            options: {
                reportType: "LAWYER_DEPARTMENT"
            },
            filter: {
                dates: moment().format("YYYY-MM-DD"),
                plan: {
                    day: 370000,
                    period: 2850000,
                },
                lawyer: {

                },
                department: {

                },
                lawyerChart: {

                }
            }
        },
        reportLawyerEPOGridOptions: {
            filter: {
                dates: moment().format("YYYY-MM-DD"),
            }
        },
        notificationPanelOptions: {
            createdAtDates: moment().format("YYYY-MM-DD"),
        },
        stompClient: null
    },
    mutations: {
        setPageTitle(state, data) {
            state.pageTitle = data
        },
        setStompClint(state, stompClient) {
            state.stompClient = stompClient
        },
        showMessage(state, type, body) {
            state.message = {type, body}
        },
        setAuth(state, user) {
            state.user = user;
            helper.ls.set('user', user);
        },
        unSetAuth(state) {
            state.user = null;
            state.stompClient.stomp.disconnect();
            state.stompClient = null;
            helper.ls.remove('user');
            router.push('login');
        },
        setRequestGridsOptions(state, options) {
            state.requestGridsOptions = options;
        },
        setClientGridsOptions(state, options) {
            state.clientGridsOptions = options;
        },
        setAppointmentsGridsOptions(state, options) {
            state.appointmentsGridsOptions = options;
        },
        setReportGridsOptions(state, options) {
            state.reportGridsOptions = options;
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        setUnMute(state, unMute) {
            state.unMute = unMute;
            helper.ls.set("settings_unmute", unMute);
        },
        setUnNotify(state, unNotify) {
            state.unNotify = unNotify;
        }
    },
    actions: {
        checkPageTitle({commit}, title) {
            commit('setPageTitle', title);
        },
        auth({commit}, user) {
            commit('setAuth', user)
        },
        stompConnect({commit}, stompClient) {
            commit('setStompClint', stompClient)
        },
        logout({commit}) {
            commit('unSetAuth')
        },
        checkAuth({commit}) {
            if (helper.ls.get('user')) {
                commit('setAuth', helper.ls.get('user'))
            }
        },
        setUnMute({commit}, unMute) {
            commit('setUnMute', unMute)
        },
        // eslint-disable-next-line no-unused-vars
        newRequestReceived({commit}, request) {
            //used only for subscribes purposes on this action from components
        },
        // eslint-disable-next-line no-unused-vars
        newClientReceived({commit}, client) {
            //used only for subscribes purposes on this action from components
        },
        // eslint-disable-next-line no-unused-vars
        remindReceived({commit}, remind) {
            //used only for subscribes purposes on this action from components
        },
        // eslint-disable-next-line no-unused-vars
        updateAccountReceived({commit}, request) {
            //used only for subscribes purposes on this action from components
        },
        // eslint-disable-next-line no-unused-vars
        newVisitReceived({commit}, request) {
            //used only for subscribes purposes on this action from components
        },
        // eslint-disable-next-line no-unused-vars
        notificationReceived({commit}, request) {
            //used only for subscribes purposes on this action from components
        },
        loading({commit}, loading) {
            commit('setLoading', loading)
        },
        updateRequestGridOptions({commit}, options) {
            commit('setRequestGridsOptions', options)
        },
        updateClientGridOptions({commit}, options) {
            commit('setClientGridsOptions', options)
        },
        updateAppointmentsGridOptions({commit}, options) {
            commit('setAppointmentsGridsOptions', options)
        },
        updateReportGridOptions({commit}, options) {
            commit('setReportGridsOptions', options)
        },
    }
});

export default store;
