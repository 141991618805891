<template>
    <div>
        <v-text-field
            v-if="actions?.length"
            disabled="disabled"
            :label="label">
        </v-text-field>
        <div v-if="actions?.length">
            <div class="input__label_wrapper">
                <div class="input__label">
                    {{ label }}
                </div>
            </div>
            <div
                v-for="action in actions"
                :key="action.id"
                class="contract-action">
                <v-text-field
                    :value="action.createdAt"
                    disabled="disabled">
                </v-text-field>
                <div
                    v-if="action.user"
                    class="field-info">
                    {{action.user.fullName}}
                </div>
                <div
                    v-if="$helper.permission.canDeleteContractActions(user)"
                    class="contract-action__action">
                    <v-icon
                        right
                        color="red"
                        @click="$emit('deleteAction', action.id)">
                        mdi-close
                    </v-icon>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'ContractAction',
  props: {
      actions: Array,
      label: String
  },
  data() {
    return {

    }
  },
  computed: {
      ...mapState(['user']),
  },
  methods: {},
  watch: {}
}
</script>

<style lang="scss">
    .contract-action {
        position: relative;
        &:hover {
            .contract-action__action {
                display: block;
            }
        }
    }
    .contract-action__action {
        display: none;
        position: absolute;
        right: -20px;
        top: 29px;
        padding: 20px;
        cursor: pointer;
    }
</style>