var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-title', [_vm._v(" Редактирование сотрудника "), _c('div', {
    staticClass: "ml-5"
  }, [_c('v-btn', {
    staticClass: "mx-4",
    attrs: {
      "small": "",
      "color": "primary",
      "outlined": "",
      "to": "/users"
    }
  }, [_vm._v("Все сотрудники")])], 1)]), _vm.user !== null ? _c('UserForm', {
    attrs: {
      "user": _vm.user
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }