import {http} from "../_helpers";
import Service from "./Service";

class ContractPaymentService extends Service {

    getAll(options, filter) {
        return http.get(this.makeOptionsUrl(`/contracts/payments`, options, filter));
    }
}

export default new ContractPaymentService;
