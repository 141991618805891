var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.user.role === 'ADMIN' ? _c('v-list-item', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.account.user) + " ")])], 1) : _vm._e(), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" Стоимость заявки ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.account.requestPrice) + " руб. ")])], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" Кол-во заявок ")]), _c('v-list-item-subtitle', {
    class: {
      'red--text': _vm.account.requestQuantity < 0
    }
  }, [_vm._v(" " + _vm._s(_vm.account.requestQuantity) + " ")])], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" Остаток на балансе ")]), _c('v-list-item-subtitle', {
    class: {
      'red--text': _vm.balance < 0
    }
  }, [_vm._v(" " + _vm._s(_vm.balance) + " руб. ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }