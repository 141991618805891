<template>
    <div>
        <v-list-item v-if="user.role === 'ADMIN'">
            <v-list-item-title>
                {{account.user}}
            </v-list-item-title>
        </v-list-item>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>
                    Стоимость заявки
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{account.requestPrice}} руб.
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>
                    Кол-во заявок
                </v-list-item-title>
                <v-list-item-subtitle v-bind:class="{ 'red--text': (account.requestQuantity < 0) }">
                    {{account.requestQuantity}}
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>
                    Остаток на балансе
                </v-list-item-title>
                <v-list-item-subtitle v-bind:class="{ 'red--text': (balance < 0) }">
                    {{balance}} руб.
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </div>
</template>

<script>

    import {mapState} from "vuex";

    export default {
        name: "Balance",
        props: {
            account: {
                type: Object,
                default: null
            }
        },
        computed: {
            ...mapState(['user']),
            balance() {
                return this.account.balance + (this.account.requestQuantity * this.account.requestPrice);
            }
        }
    }
</script>

<style scoped>

</style>
