<!--suppress JSUnresolvedReference -->
<template>
    <v-expansion-panels
        class="mt-5 pt-5 task-expansion"
        v-model="expand">
        <v-expansion-panel>
            <v-expansion-panel-header>
                <v-card-title class="px-1 py-2">
                    Отчет по работе с клиентом
                </v-card-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <!--suppress JSUnusedLocalSymbols -->
                <ReportMultiField
                    field-title="Ситуация клиента"
                    :form-field="form.clientSituation"
                    :field-initial-state="initialState.clientSituation"
                    class="mb-5">
                </ReportMultiField>
                <ReportMultiField
                    field-title="Потребность клиента"
                    :form-field="form.clientNeeds"
                    :field-initial-state="initialState.clientNeeds">
                </ReportMultiField>
                <ReportMultiField
                    field-title="Предложено клиенту"
                    :form-field="form.offeredToClient"
                    :field-initial-state="initialState.offeredToClient">
                </ReportMultiField>
                <ReportMultiField
                    field-title="Итог"
                    :form-field="form.output"
                    :field-initial-state="initialState.output">
                </ReportMultiField>
                <v-row>
                    <v-col cols="auto">
                        <v-datetime-picker
                            v-model="form.callbackDate"
                            type="date"
                            scrollable
                            label="Дата события"
                            clearText="Удалить"
                            okText="Сохранить"
                            :datePickerProps="{'first-day-of-week': '1'}"
                            :timePickerProps="{'format': '24h'}">
                        </v-datetime-picker>
                        <v-alert v-if="form.errors.has('callbackDate')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('callbackDate') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea
                            v-model="form.reportComment"
                            label="Примечание"
                            rows="2"
                            auto-grow>
                        </v-textarea>
                        <v-alert v-if="form.errors.has('reportComment')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('reportComment') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <div>
                    <v-alert v-if="form.errors.any()" dense outlined type="error" :icon="false">
                        Исправьте ошибки
                    </v-alert>
                    <v-alert v-if="form.errors.has('error')" dense outlined type="error" :icon="false">
                        {{ form.errors.get('error') }}
                    </v-alert>
                    <div v-show="!form.busy">
                        <v-btn
                            v-if="$helper.permission.canUpdateClientReport(user)"
                            large color="green"
                            @click="update()">
                            Сохранить
                        </v-btn>
                    </div>
                    <v-progress-circular
                        v-show="form.busy"
                        indeterminate
                        color="primary">
                    </v-progress-circular>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import {deepCopy} from '@/_helpers/_form/util'
import ReportMultiField from "@/_components/MultiField.vue";
import ClientService from "@/_services/ClientService";
import {Form} from "@/_helpers/_form";
import {mapState} from "vuex";

export default {
    name: 'ClientReport',
    components: {ReportMultiField},
    props: {
        clientId: Number,
        contractForm: Object,
        defaultExpand: Boolean
    },
    data() {
        return {
            form: new Form(this.contractForm.data()),
            initialState: deepCopy(this.contractForm.data()),
            expand: this.defaultExpand ? 0 : undefined,
        }
    },
    methods: {
        update() {
            ClientService.updateReport(this.clientId, this.form).then(() => {
                //this.$emit('updated');
            }).catch(error => {
                this.flashError(this.$helper.showError(error));
            });
        },
    },
    computed: {
        ...mapState(['user'])
    },
    created() {
    }
};
</script>
