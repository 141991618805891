var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-title', [_vm._v(" Список подразделений "), _c('v-spacer'), _c('div', [_c('v-btn', {
    attrs: {
      "large": "",
      "color": "green",
      "to": "/subdivisions/create"
    }
  }, [_vm._v(" Добавить подразделение ")])], 1)], 1), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.subdivisions,
      "options": _vm.options,
      "server-items-length": _vm.totalElements,
      "item-key": "id",
      "loading": _vm.loading,
      "loading-text": "Загрузка ...",
      "footer-props": {
        'items-per-page-options': [20, 30, 50]
      }
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var items = _ref.items;
        return [_c('tbody', [_c('tr', [_c('td', [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.filter.id,
            callback: function callback($$v) {
              _vm.$set(_vm.filter, "id", $$v);
            },
            expression: "filter.id"
          }
        })], 1), _c('td', [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.filter.name,
            callback: function callback($$v) {
              _vm.$set(_vm.filter, "name", $$v);
            },
            expression: "filter.name"
          }
        })], 1), _c('td', [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.filter.percentageOfReceivedRequests,
            callback: function callback($$v) {
              _vm.$set(_vm.filter, "percentageOfReceivedRequests", $$v);
            },
            expression: "filter.percentageOfReceivedRequests"
          }
        })], 1), _c('td')]), _vm._l(items, function (item) {
          return _c('tr', {
            key: item.id
          }, [_c('td', {
            attrs: {
              "width": "300"
            }
          }, [_vm._v(_vm._s(item.id))]), _c('td', [_vm._v(_vm._s(item.name))]), _c('td', {
            attrs: {
              "width": "160"
            }
          }, [_vm._v(_vm._s(item.percentageOfReceivedRequests))]), _c('td', {
            attrs: {
              "width": "160"
            }
          }, [_c('v-icon', {
            attrs: {
              "small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.$router.push("/subdivisions/".concat(item.id, "/edit"));
              }
            }
          }, [_vm._v("mdi-pencil")]), _c('v-dialog', {
            attrs: {
              "max-width": "320"
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function fn(_ref2) {
                var on = _ref2.on;
                return [_c('v-icon', _vm._g({
                  staticClass: "ml-4",
                  attrs: {
                    "small": "",
                    "color": "red"
                  }
                }, on), [_vm._v("mdi-delete")])];
              }
            }], null, true),
            model: {
              value: _vm.deleteDialog[item.id],
              callback: function callback($$v) {
                _vm.$set(_vm.deleteDialog, item.id, $$v);
              },
              expression: "deleteDialog[item.id]"
            }
          }, [_c('v-card', [_c('v-card-text', {
            staticClass: "text-center pt-7"
          }, [_vm._v("Удалить выбранный элемент?")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
            attrs: {
              "text": ""
            },
            on: {
              "click": function click($event) {
                _vm.deleteDialog[item.id] = false;
              }
            }
          }, [_vm._v("Отмена")]), _c('v-btn', {
            attrs: {
              "color": "red",
              "text": ""
            },
            on: {
              "click": function click($event) {
                return _vm.deleteItem(item.id);
              }
            }
          }, [_vm._v("Удалить")])], 1)], 1)], 1)], 1)]);
        })], 2)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }