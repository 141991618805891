import {http} from "../_helpers";
import Service from "./Service";
import {axiosInstance} from "../_helpers/axiosInstance";

class ContractService extends Service {

    getAll(options, filter, customUrl) {
        const url = customUrl ? customUrl : `/contracts`;
        return http.get(this.makeOptionsUrl(url, options, filter));
    }

    getOne(id) {
        return http.get(`/contracts/${id}`);
    }

    getForm(id) {
        return http.get(`/contracts/${id}/update`);
    }

    create(form) {
        return http.post(`/contracts`, form);
    }

    getCreatePage(clientId) {
        let url = `/contracts/create`;
        if (clientId) {
            url += `?clientId=${clientId}`;
        }
        return http.get(url);
    }

    update(id, form) {
        return http.put(`/contracts/${id}`, form);
    }

    uploadFiles(id, form) {
        return http.put(`/contracts/${id}/files`, form);
    }

    delete(id) {
        return http.delete(`/contracts/${id}`);
    }

    deleteBulk(ids) {
        return axiosInstance.post(`/contracts/deleteBulk`, {'ids': ids});
    }

    getPayments(id) {
        return http.get(`/contracts/${id}/payments`);
    }

    addPayment(id, form) {
        return http.post(`/contracts/${id}/payments`, form);
    }

    getActs(id) {
        return http.get(`/contracts/${id}/acts`);
    }

    addAct(id, form) {
        return http.post(`/contracts/${id}/acts`, form);
    }

    addAdditionalAgreement(id, form) {
        return http.post(`/contracts/${id}/additional-agreements`, form);
    }

    getAdditionalAgreements(id) {
        return http.get(`/contracts/${id}/additional-agreements`);
    }

    getComments(id, filter) {
        return http.get(this.makeOptionsUrl(`/contracts/${id}/comments`, {}, filter));
    }

    addComment(id, form) {
        return http.post(`/contracts/${id}/comments`, form);
    }

    getEvents(id, options, filter) {
        return http.get(this.makeOptionsUrl(`/contracts/${id}/events`, options, filter));
    }

    addEvent(id, form) {
        return http.post(`/contracts/${id}/events`, form);
    }

    sendForDocumentsPreparation(id) {
        return axiosInstance.patch(`/contracts/${id}/sent-for-documents-preparation`);
    }

    setDocumentsAreReadyAt(id) {
        return axiosInstance.patch(`/contracts/${id}/documents-ready`);
    }

    setTransferredToOGPAAt(id) {
        return axiosInstance.patch(`/contracts/${id}/transfer-to-ogpa`);
    }

    setTransferredToCourt(id) {
        return axiosInstance.patch(`/contracts/${id}/transfer-to-court`);
    }

    setSetSentToCourt(id) {
        return axiosInstance.patch(`/contracts/${id}/sent-to-court`);
    }

    setPackageFromLenyr(id) {
        return axiosInstance.patch(`/contracts/${id}/package-from-lenyr`);
    }

    setSentToOKK(id) {
        return axiosInstance.patch(`/contracts/${id}/sent-to-okk`);
    }

    setAdditionalPackageIsReady(id) {
        return axiosInstance.patch(`/contracts/${id}/additional-package-is-ready`);
    }

    setDosudebkaEPO(id) {
        return axiosInstance.patch(`/contracts/${id}/dosudebka-epo`);
    }
    deleteAct(id) {
        return axiosInstance.delete(`/contracts/acts/${id}`);
    }

    deleteAdditionalAgreement(id) {
        return axiosInstance.delete(`/contracts/additional-agreements/${id}`);
    }

    deletePayment(id) {
        return axiosInstance.delete(`/contracts/payments/${id}`);
    }

    getAdditionalPayments(id) {
        return http.get(`/contracts/${id}/additional-payments`);
    }

    addAdditionalPayment(id, form) {
        return http.post(`/contracts/${id}/additional-payments`, form);
    }

    deleteAdditionalPayment(id) {
        return axiosInstance.delete(`/contracts/additional-payments/${id}`);
    }

    recalculatePayedAmount() {
        return http.post(`/contracts/recalculate-payed-amount`);
    }

    getAllStates() {
        return http.get(`/contracts/states`);
    }

    signTaskCivilianRepr(id, form) {
        return http.post(`/contracts/${id}/tasks/signature/CivilianRepresentatives`, form);
    }

    signTaskLegalExpert(id, form) {
        return http.post(`/contracts/${id}/tasks/signature/LegalExpert`, form);
    }

    signAddTaskLegalExpert(id, form) {
        return http.post(`/contracts/${id}/tasks/signature/AddLegalExpert`, form);
    }

    getRevisions(id, options) {
        return http.get(this.makeOptionsUrl(`/contracts/${id}/revisions`, options));
    }

    addToFavorites(id) {
        return axiosInstance.put(`/contracts/${id}/favorites`);
    }

    removeFromFavorites(id) {
        return axiosInstance.delete(`/contracts/${id}/favorites`);
    }

    deleteSentForDocumentsActions(contractId, actionId) {
        return axiosInstance.delete(`/contracts/${contractId}/sent-for-documents-preparation/${actionId}`);
    }

    deleteDocumentsAreReadyActions(contractId, actionId) {
        return axiosInstance.delete(`/contracts/${contractId}/documents-ready/${actionId}`);
    }

    deleteTransferredToCourtActions(contractId, actionId) {
        return axiosInstance.delete(`/contracts/${contractId}/transfer-to-court/${actionId}`);
    }

    deleteSentToCourtActions(contractId, actionId) {
        return axiosInstance.delete(`/contracts/${contractId}/sent-to-court/${actionId}`);
    }

    deleteTransferredToOGPAActions(contractId, actionId) {
        return axiosInstance.delete(`/contracts/${contractId}/transfer-to-ogpa/${actionId}`);
    }

    deletePackageFromLenyrActions(contractId, actionId) {
        return axiosInstance.delete(`/contracts/${contractId}/package-from-lenyr/${actionId}`);
    }

    deleteSentToOkkActions(contractId, actionId) {
        return axiosInstance.delete(`/contracts/${contractId}/sent-to-okk/${actionId}`);
    }

    deleteSentDosudebkaEPOActions(contractId, actionId) {
        return axiosInstance.delete(`/contracts/${contractId}/dosudebka-epo/${actionId}`);
    }

    deleteAdditionalPackageIsReadyActions(contractId, actionId) {
        return axiosInstance.delete(`/contracts/${contractId}/additional-package-is-ready/${actionId}`);
    }

    getVisits(id, options) {
        return http.get(this.makeOptionsUrl(`/contracts/${id}/visits`, options));
    }
}

export default new ContractService;
