<template>
    <div>
        <v-card-title>
            Создание договора
            <div class="ml-5">
                <v-btn small color="primary" class="mx-4" outlined to="/contracts">Все договора</v-btn>
            </div>
        </v-card-title>
        <div v-if="!loading">
            <ContractForm :page="page"></ContractForm>
        </div>
    </div>
</template>

<script>

    import ContractService from "../_services/ContractService";
    import ContractForm from "./ContractForm";

    export default {
        components: {ContractForm},
        data() {
            return {
                page: {},
                loading: true
            }
        },
        methods: {
            fetch() {
                this.fetchPage();
            },
            fetchPage() {
                const clientId = this.$route.query.clientId;
                ContractService.getCreatePage(clientId).then(data => {
                    this.page = data;
                    this.loading = false;
                }, error => {
                    this.flashError(this.$helper.showError(error));
                });
            }
        },
        created() {
            this.fetch();
        }
    };
</script>
