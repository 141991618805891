<!--suppress JSUnresolvedReference -->
<template>
    <div class="mb-7">
        <h3 class="mb-3">{{ fieldTitle }}</h3>
        <v-row
            v-for="(field, index) in formField"
            :key="index">
            <v-col cols="10">
                <v-textarea
                    v-model="formField[index]"
                    :label="`${fieldTitle} (${index + 1})`"
                    :disabled="isDisabledField(index)"
                    rows="2"
                    auto-grow>
                </v-textarea>
            </v-col>
            <v-col cols="2">
                <v-btn
                    text
                    color="error"
                    @click="removeField(index)"
                    small
                    v-if="!isDisabledField(index)">
                    <v-icon>mdi-minus-box-outline</v-icon>
                </v-btn>
                <v-btn
                    text
                    color="success"
                    @click="addField()"
                    small
                    v-if="showAddMoreButton(index)">
                    <v-icon>mdi-plus-box-outline</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <div>
            <v-btn
                text
                color="success"
                @click="addField()"
                small
                v-if="formField.length === 0">
                <v-icon>mdi-plus-box-outline</v-icon>
            </v-btn>
        </div>

    </div>
</template>

<script>
    import {deepCopy} from '@/_helpers/_form/util'

    export default {
        name: 'ReportMultiField',
        components: {},
        props: {
            fieldTitle: String,
            formField: Array,
            fieldInitialState: Array,
        },
        data() {
            return {
                initialState: deepCopy(this.form)
            }
        },
        methods: {
            addField() {
                this.formField.push('');
            },
            removeField(index) {
                this.formField.splice(index, 1);
            },
            isDisabledField(index) {
                return this.fieldInitialState?.length > index;
            },
            showAddMoreButton(index) {
                return this.formField[index + 1] === undefined;
            }
        },
        created() {
        }
    };
</script>
