var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "overflow-x": "scroll"
    }
  }, [!_vm.title ? _c('v-card-title', [_vm._v(" База клиентов "), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.forceUpdate
    }
  }, [_c('v-icon', [_vm._v("mdi-reload")])], 1), _c('v-spacer'), _c('div', [_vm.$helper.permission.canAssignClient(_vm.user) ? _c('v-btn', {
    attrs: {
      "large": "",
      "color": "green",
      "to": "/clients/create"
    }
  }, [_vm._v(" Добавить клиента ")]) : _vm._e()], 1)], 1) : _vm._e(), _vm.title ? _c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " ")]) : _vm._e(), _c('v-data-table', {
    staticClass: "elevation-1",
    staticStyle: {
      "min-width": "2800px"
    },
    attrs: {
      "headers": _vm.headers,
      "items": _vm.clients,
      "options": _vm.clientGridsOptions.options,
      "server-items-length": _vm.totalElements,
      "item-key": "id",
      "loading": _vm.loading,
      "loading-text": "Загрузка ...",
      "footer-props": {
        'items-per-page-options': _vm.itemPerPage
      },
      "fixed-header": _vm.fixedHeader,
      "height": _vm.tableHeight
    },
    on: {
      "update:options": function updateOptions($event) {
        return _vm.$set(_vm.clientGridsOptions, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var items = _ref.items;
        return [_c('tbody', [!_vm.filter ? _c('tr', {
          staticClass: "row_sticky"
        }, [_c('td', {
          staticClass: "px-2",
          attrs: {
            "width": "20"
          }
        }), _vm.isVisibleColumn('inFavorites') ? _c('td', {
          staticClass: "pr-0",
          attrs: {
            "width": "30"
          }
        }, [_c('v-checkbox', {
          staticClass: "ma-0",
          attrs: {
            "value": _vm.user.id,
            "hide-details": ""
          },
          model: {
            value: _vm.clientGridsOptions.filter['usersAddToFavorites[0].id'],
            callback: function callback($$v) {
              _vm.$set(_vm.clientGridsOptions.filter, 'usersAddToFavorites[0].id', $$v);
            },
            expression: "clientGridsOptions.filter['usersAddToFavorites[0].id']"
          }
        })], 1) : _vm._e(), _c('td', {
          attrs: {
            "width": "80"
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.clientGridsOptions.filter.id,
            callback: function callback($$v) {
              _vm.$set(_vm.clientGridsOptions.filter, "id", $$v);
            },
            expression: "clientGridsOptions.filter.id"
          }
        })], 1), _vm.isVisibleColumn('userName') ? _c('td', {
          attrs: {
            "width": "160"
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.clientGridsOptions.filter.userName,
            callback: function callback($$v) {
              _vm.$set(_vm.clientGridsOptions.filter, "userName", $$v);
            },
            expression: "clientGridsOptions.filter.userName"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('fromSource') ? _c('td', {
          attrs: {
            "width": "190"
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.clientGridsOptions.filter.fromSource,
            callback: function callback($$v) {
              _vm.$set(_vm.clientGridsOptions.filter, "fromSource", $$v);
            },
            expression: "clientGridsOptions.filter.fromSource"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('principal') ? _c('td', {
          attrs: {
            "width": "160"
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.clientGridsOptions.filter.principal,
            callback: function callback($$v) {
              _vm.$set(_vm.clientGridsOptions.filter, "principal", $$v);
            },
            expression: "clientGridsOptions.filter.principal"
          }
        })], 1) : _vm._e(), _c('td', {
          attrs: {
            "width": "100"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.checkboxValues,
            "clearable": "",
            "item-value": "value",
            "item-text": "text"
          },
          model: {
            value: _vm.clientGridsOptions.filter.isOnline,
            callback: function callback($$v) {
              _vm.$set(_vm.clientGridsOptions.filter, "isOnline", $$v);
            },
            expression: "clientGridsOptions.filter.isOnline"
          }
        })], 1), _c('td', {
          attrs: {
            "width": "120"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.checkboxValues,
            "clearable": "",
            "item-value": "value",
            "item-text": "text"
          },
          model: {
            value: _vm.clientGridsOptions.filter.isDouble,
            callback: function callback($$v) {
              _vm.$set(_vm.clientGridsOptions.filter, "isDouble", $$v);
            },
            expression: "clientGridsOptions.filter.isDouble"
          }
        })], 1), _c('td', {
          attrs: {
            "width": "100"
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.states,
            "clearable": ""
          },
          model: {
            value: _vm.clientGridsOptions.filter.states,
            callback: function callback($$v) {
              _vm.$set(_vm.clientGridsOptions.filter, "states", $$v);
            },
            expression: "clientGridsOptions.filter.states"
          }
        })], 1), _vm.isVisibleColumn('callbackDate') ? _c('td', {
          attrs: {
            "width": "190"
          }
        }, [_c('DateField', {
          attrs: {
            "label": "",
            "range": "",
            "clearable": true
          },
          model: {
            value: _vm.clientGridsOptions.filter.callbackDates,
            callback: function callback($$v) {
              _vm.$set(_vm.clientGridsOptions.filter, "callbackDates", $$v);
            },
            expression: "clientGridsOptions.filter.callbackDates"
          }
        })], 1) : _vm._e(), _c('td', {
          attrs: {
            "width": "190"
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.clientGridsOptions.filter.cleanPhone,
            callback: function callback($$v) {
              _vm.$set(_vm.clientGridsOptions.filter, "cleanPhone", $$v);
            },
            expression: "clientGridsOptions.filter.cleanPhone"
          }
        })], 1), _c('td', {
          attrs: {
            "width": "180"
          }
        }, [_c('v-dialog', {
          ref: "dialog",
          attrs: {
            "return-value": _vm.clientGridsOptions.filter.createdAtDates,
            "persistent": "",
            "width": "290px"
          },
          on: {
            "update:returnValue": function updateReturnValue($event) {
              return _vm.$set(_vm.clientGridsOptions.filter, "createdAtDates", $event);
            },
            "update:return-value": function updateReturnValue($event) {
              return _vm.$set(_vm.clientGridsOptions.filter, "createdAtDates", $event);
            }
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('v-text-field', _vm._g({
                attrs: {
                  "prepend-icon": "mdi-calendar-month-outline",
                  "readonly": "",
                  "clearable": ""
                },
                model: {
                  value: _vm.clientGridsOptions.filter.createdAtDates,
                  callback: function callback($$v) {
                    _vm.$set(_vm.clientGridsOptions.filter, "createdAtDates", $$v);
                  },
                  expression: "clientGridsOptions.filter.createdAtDates"
                }
              }, on))];
            }
          }], null, true),
          model: {
            value: _vm.dialog.createdAt.modal,
            callback: function callback($$v) {
              _vm.$set(_vm.dialog.createdAt, "modal", $$v);
            },
            expression: "dialog.createdAt.modal"
          }
        }, [_c('v-date-picker', {
          attrs: {
            "type": "date",
            "scrollable": "",
            "range": "",
            "first-day-of-week": "1"
          },
          model: {
            value: _vm.dialog.createdAt.model,
            callback: function callback($$v) {
              _vm.$set(_vm.dialog.createdAt, "model", $$v);
            },
            expression: "dialog.createdAt.model"
          }
        }, [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.dialog.createdAt.modal = false;
            }
          }
        }, [_vm._v("Отмена")]), _c('v-btn', {
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.$refs.dialog.save(_vm.dialog.createdAt.model);
            }
          }
        }, [_vm._v(" Выбрать ")])], 1)], 1)], 1), _c('td', {
          attrs: {
            "width": "190"
          }
        }, [_c('v-dialog', {
          ref: "dialog2",
          attrs: {
            "return-value": _vm.clientGridsOptions.filter.assignedAtDate,
            "persistent": "",
            "width": "290px"
          },
          on: {
            "update:returnValue": function updateReturnValue($event) {
              return _vm.$set(_vm.clientGridsOptions.filter, "assignedAtDate", $event);
            },
            "update:return-value": function updateReturnValue($event) {
              return _vm.$set(_vm.clientGridsOptions.filter, "assignedAtDate", $event);
            }
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on;
              return [_c('v-text-field', _vm._g({
                attrs: {
                  "prepend-icon": "mdi-calendar-month-outline",
                  "readonly": "",
                  "clearable": ""
                },
                model: {
                  value: _vm.clientGridsOptions.filter.assignedAtDate,
                  callback: function callback($$v) {
                    _vm.$set(_vm.clientGridsOptions.filter, "assignedAtDate", $$v);
                  },
                  expression: "clientGridsOptions.filter.assignedAtDate"
                }
              }, on))];
            }
          }], null, true),
          model: {
            value: _vm.dialog.assignedAt.modal,
            callback: function callback($$v) {
              _vm.$set(_vm.dialog.assignedAt, "modal", $$v);
            },
            expression: "dialog.assignedAt.modal"
          }
        }, [_c('v-date-picker', {
          attrs: {
            "type": "date",
            "scrollable": "",
            "first-day-of-week": "1"
          },
          model: {
            value: _vm.dialog.assignedAt.model,
            callback: function callback($$v) {
              _vm.$set(_vm.dialog.assignedAt, "model", $$v);
            },
            expression: "dialog.assignedAt.model"
          }
        }, [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.dialog.assignedAt.modal = false;
            }
          }
        }, [_vm._v("Отмена")]), _c('v-btn', {
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.$refs.dialog2.save(_vm.dialog.assignedAt.model);
            }
          }
        }, [_vm._v(" Выбрать ")])], 1)], 1)], 1), _c('td', {
          attrs: {
            "width": "160"
          }
        }, [_c('v-dialog', {
          ref: "dialog3",
          attrs: {
            "return-value": _vm.clientGridsOptions.filter.lastVisitAtDate,
            "persistent": "",
            "width": "290px"
          },
          on: {
            "update:returnValue": function updateReturnValue($event) {
              return _vm.$set(_vm.clientGridsOptions.filter, "lastVisitAtDate", $event);
            },
            "update:return-value": function updateReturnValue($event) {
              return _vm.$set(_vm.clientGridsOptions.filter, "lastVisitAtDate", $event);
            }
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on;
              return [_c('v-text-field', _vm._g({
                attrs: {
                  "prepend-icon": "mdi-calendar-month-outline",
                  "readonly": "",
                  "clearable": ""
                },
                model: {
                  value: _vm.clientGridsOptions.filter.lastVisitAtDate,
                  callback: function callback($$v) {
                    _vm.$set(_vm.clientGridsOptions.filter, "lastVisitAtDate", $$v);
                  },
                  expression: "clientGridsOptions.filter.lastVisitAtDate"
                }
              }, on))];
            }
          }], null, true),
          model: {
            value: _vm.dialog.lastVisitAt.modal,
            callback: function callback($$v) {
              _vm.$set(_vm.dialog.lastVisitAt, "modal", $$v);
            },
            expression: "dialog.lastVisitAt.modal"
          }
        }, [_c('v-date-picker', {
          attrs: {
            "type": "date",
            "scrollable": "",
            "first-day-of-week": "1"
          },
          model: {
            value: _vm.dialog.lastVisitAt.model,
            callback: function callback($$v) {
              _vm.$set(_vm.dialog.lastVisitAt, "model", $$v);
            },
            expression: "dialog.lastVisitAt.model"
          }
        }, [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.dialog.lastVisitAt.modal = false;
            }
          }
        }, [_vm._v("Отмена")]), _c('v-btn', {
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.$refs.dialog3.save(_vm.dialog.lastVisitAt.model);
            }
          }
        }, [_vm._v(" Выбрать ")])], 1)], 1)], 1), _vm.isVisibleColumn('lawyer') ? _c('td', {
          attrs: {
            "width": "160"
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.lawyers,
            "clearable": "",
            "item-value": "id",
            "item-text": "fullName"
          },
          model: {
            value: _vm.clientGridsOptions.filter.lawyers,
            callback: function callback($$v) {
              _vm.$set(_vm.clientGridsOptions.filter, "lawyers", $$v);
            },
            expression: "clientGridsOptions.filter.lawyers"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('additionalLawyer') ? _c('td', {
          attrs: {
            "width": "160"
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.lawyers,
            "clearable": "",
            "item-value": "id",
            "item-text": "fullName"
          },
          model: {
            value: _vm.clientGridsOptions.filter.additionalLawyers,
            callback: function callback($$v) {
              _vm.$set(_vm.clientGridsOptions.filter, "additionalLawyers", $$v);
            },
            expression: "clientGridsOptions.filter.additionalLawyers"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('lawyerAssistant') ? _c('td', {
          attrs: {
            "width": "160"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.lawyers,
            "clearable": "",
            "item-value": "id",
            "item-text": "fullName"
          },
          model: {
            value: _vm.clientGridsOptions.filter.lawyerAssistant,
            callback: function callback($$v) {
              _vm.$set(_vm.clientGridsOptions.filter, "lawyerAssistant", $$v);
            },
            expression: "clientGridsOptions.filter.lawyerAssistant"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('subdivision') ? _c('td', {
          attrs: {
            "width": "160"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.subdivisions,
            "clearable": "",
            "item-value": "id",
            "item-text": "name"
          },
          model: {
            value: _vm.clientGridsOptions.filter.subdivision,
            callback: function callback($$v) {
              _vm.$set(_vm.clientGridsOptions.filter, "subdivision", $$v);
            },
            expression: "clientGridsOptions.filter.subdivision"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('comment') ? _c('td', {
          attrs: {
            "width": "160"
          }
        }) : _vm._e(), _vm.isVisibleColumn('area') ? _c('td', {
          attrs: {
            "width": "160"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.areas,
            "clearable": "",
            "item-value": "id",
            "item-text": "name"
          },
          model: {
            value: _vm.clientGridsOptions.filter.area,
            callback: function callback($$v) {
              _vm.$set(_vm.clientGridsOptions.filter, "area", $$v);
            },
            expression: "clientGridsOptions.filter.area"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('office') ? _c('td', {
          attrs: {
            "width": "100"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.offices,
            "clearable": "",
            "item-value": "id",
            "item-text": "name"
          },
          model: {
            value: _vm.clientGridsOptions.filter.office,
            callback: function callback($$v) {
              _vm.$set(_vm.clientGridsOptions.filter, "office", $$v);
            },
            expression: "clientGridsOptions.filter.office"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('reportComment') ? _c('td', {
          attrs: {
            "width": "220"
          }
        }) : _vm._e()]) : _vm._e(), _vm._l(items, function (item, key) {
          return _c('tr', {
            key: item.id,
            class: {
              'grey darken-3': _vm.isHighlighted(item)
            }
          }, [_c('td', {
            staticClass: "pl-5 pr-3"
          }, [_vm.$helper.permission.canDeleteClient(item, _vm.user) ? _c('v-dialog', {
            attrs: {
              "max-width": "320"
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function fn(_ref5) {
                var on = _ref5.on;
                return [_c('v-icon', _vm._g({
                  attrs: {
                    "small": "",
                    "color": "red"
                  }
                }, on), [_vm._v("mdi-delete")])];
              }
            }], null, true),
            model: {
              value: _vm.deleteDialog[item.id],
              callback: function callback($$v) {
                _vm.$set(_vm.deleteDialog, item.id, $$v);
              },
              expression: "deleteDialog[item.id]"
            }
          }, [_c('v-card', [_c('v-card-text', {
            staticClass: "text-center pt-7"
          }, [_vm._v("Удалить выбранный элемент?")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
            attrs: {
              "text": ""
            },
            on: {
              "click": function click($event) {
                _vm.deleteDialog[item.id] = false;
              }
            }
          }, [_vm._v("Отмена")]), _c('v-btn', {
            attrs: {
              "color": "red",
              "text": ""
            },
            on: {
              "click": function click($event) {
                return _vm.deleteItem(item.id);
              }
            }
          }, [_vm._v("Удалить")])], 1)], 1)], 1) : _vm._e()], 1), _vm.isVisibleColumn('inFavorites') ? _c('td', [item.inFavorites ? _c('v-icon', {
            staticClass: "ml-1",
            attrs: {
              "color": "yellow"
            },
            on: {
              "click": function click($event) {
                return _vm.removeFromFavorites(item);
              }
            }
          }, [_vm._v(" mdi-star ")]) : _vm._e(), !item.inFavorites ? _c('v-icon', {
            staticClass: "ml-1",
            attrs: {
              "color": "grey"
            },
            on: {
              "click": function click($event) {
                return _vm.addToFavorites(item);
              }
            }
          }, [_vm._v(" mdi-star-outline ")]) : _vm._e()], 1) : _vm._e(), _c('td', [_c('a', {
            on: {
              "click": function click($event) {
                return _vm.editClient(item.id, items[key - 1], items[key + 1]);
              }
            }
          }, [_vm._v(_vm._s(item.id))])]), _vm.isVisibleColumn('userName') ? _c('td', [item.userName ? _c('div', [_vm._v(_vm._s(item.userName))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('fromSource') ? _c('td', [_vm._v(" " + _vm._s(item.fromSource) + " ")]) : _vm._e(), _vm.isVisibleColumn('principal') ? _c('td', [item.principal ? _c('div', [_vm._v(_vm._s(item.principal))]) : _vm._e()]) : _vm._e(), _c('td', [item.isOnline ? _c('v-chip', {
            attrs: {
              "color": "yellow",
              "outlined": "",
              "dark": ""
            }
          }, [_vm._v("Онлайн")]) : _vm._e()], 1), _c('td', [item.isDouble ? _c('v-chip', {
            attrs: {
              "color": "deep-orange",
              "outlined": "",
              "dark": ""
            }
          }, [_vm._v("Вторичный")]) : _vm._e()], 1), _c('td', [_c('v-chip', {
            attrs: {
              "color": item.state.chipColor,
              "outlined": "",
              "dark": ""
            }
          }, [_vm._v(" " + _vm._s(item.state.text) + " ")])], 1), _vm.isVisibleColumn('callbackDate') ? _c('td', [_vm._v(" " + _vm._s(_vm.$moment(item.callbackDate).dateTimeFormat()) + " ")]) : _vm._e(), _c('td', [_c('a', {
            attrs: {
              "href": "tel:".concat(item.userPhone)
            }
          }, [_vm._v(_vm._s(item.userPhone))]), item.isDouble === true && 1 === 0 ? _c('span', {
            staticStyle: {
              "padding-left": "10px"
            }
          }, [_c('v-icon', [_vm._v("mdi-repeat-once")])], 1) : _vm._e()]), _c('td', [_vm._v(_vm._s(_vm.$moment(item.createdAt).dateTimeFormat()))]), _c('td', [_vm._v(_vm._s(_vm.$moment(item.assignedAt).dateTimeFormat()))]), _c('td', [_vm._v(_vm._s(_vm.$moment(item.lastVisitAt).dateTimeFormat()))]), _vm.isVisibleColumn('lawyer') ? _c('td', [item.lawyer ? _c('div', [_vm._v(_vm._s(item.lawyer.fullName))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('additionalLawyer') ? _c('td', [item.additionalLawyer ? _c('div', [_vm._v(_vm._s(item.additionalLawyer.fullName))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('lawyerAssistant') ? _c('td', [item.lawyerAssistant ? _c('div', [_vm._v(_vm._s(item.lawyerAssistant.fullName))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('subdivision') ? _c('td', [item.subdivision ? _c('div', [_vm._v(_vm._s(item.subdivision.name))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('comment') ? _c('td', [item.comment ? _c('div', [_vm._v(_vm._s(item.comment))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('area') ? _c('td', [item.area ? _c('div', [_vm._v(_vm._s(item.area.name))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('office') ? _c('td', [item.office && item.office.name ? _c('div', [_vm._v(_vm._s(item.office.name))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('reportComment') ? _c('td', [item.reportComment ? _c('div', [_vm._v(_vm._s(item.reportComment))]) : _vm._e()]) : _vm._e()]);
        })], 2)];
      }
    }]),
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }), _c('v-bottom-sheet', {
    attrs: {
      "fullscreen": "",
      "scrollable": "",
      "persistent": "",
      "retain-focus": false
    },
    model: {
      value: _vm.dialog.clientEdit.modal,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog.clientEdit, "modal", $$v);
      },
      expression: "dialog.clientEdit.modal"
    }
  }, [_c('v-card', {
    staticStyle: {
      "background": "none"
    }
  }, [_c('v-card-text', {
    staticStyle: {
      "height": "100vh"
    }
  }, [_vm.editedClient ? _c('ClientUpdatePage', {
    key: _vm.editedClientKey,
    attrs: {
      "id": _vm.editedClient,
      "inPopup": true,
      "nextClient": _vm.nextClient,
      "prevClient": _vm.prevClient
    },
    on: {
      "changeEdited": _vm.changeEdited,
      "closeAndUpdate": _vm.closeEditAndFetch,
      "updated": _vm.forceUpdate,
      "closeEdit": _vm.closeEdit
    }
  }) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }