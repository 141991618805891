<template>
    <div>
        <v-card-title>
            Редактирование подразделения
            <div class="ml-5">
                <v-btn small color="primary" class="mx-4" outlined :to="`/subdivisions`">Все подразделения</v-btn>
            </div>
        </v-card-title>
        <SubdivisionForm v-if="subdivision !== null" :subdivision="subdivision"></SubdivisionForm>
    </div>
</template>

<script>
    import SubdivisionForm from "./SubdivisionForm";
    import SubdivisionService from "../_services/SubdivisionService";

    export default {
        name: 'SubdivisionUpdate',
        components: {SubdivisionForm},
        props: {id: String},
        data() {
            return {
                subdivision: null,
            }
        },
        methods: {
            fetch() {
                this.fetchSubdivision();
            },
            fetchSubdivision() {
                SubdivisionService.getOne(this.id).then(data => {
                    let sources = {};
                    data.sources.forEach(el => {
                        sources[el.source] = el;
                    });
                    data.sources = sources;
                    this.subdivision = data;
                });
            },
        },
        created() {
            this.fetch();
        }
    };
</script>
