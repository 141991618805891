<template>
    <div style="overflow-x: scroll">
        <v-card-title v-if="!title">
            База актов и выплат
            <v-btn icon @click="forceUpdate">
                <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <div>
                <ActDialog
                    :lawyersOGPA="lawyersOGPA"
                    :lawyersEPO="lawyersEPO"
                    :actTypes="actTypes"
                    @saved="onItemAdd"
                    v-if="$helper.permission.canUpdateContract(user)">
                </ActDialog>
            </div>
        </v-card-title>
        <v-card-title v-if="title">
            {{ title }}
        </v-card-title>
        <v-data-table
            :style="`min-width: ${getTableWidth()}`"
            class="elevation-1"
            :headers="headers"
            :items="acts"
            :options.sync="actGridsOptions.options"
            :server-items-length="totalElements"
            v-model="selected"
            item-key="id"
            :loading="loading"
            loading-text="Загрузка ..."
            :footer-props="{'items-per-page-options': itemPerPage}"
            :fixed-header="fixedHeader"
            :height="tableHeight">
            <template v-slot:body="{ items }">
                <tbody>
                <tr v-if="!filter" class="row_sticky">
                    <td width="20" class="px-2"></td>
                    <td
                        width="30"
                        v-if="isVisibleColumn('inFavorites')"
                        class="pr-0">
                        <v-checkbox
                            v-model="actGridsOptions.filter['usersAddToFavorites[0].id']"
                            :value="user.id"
                            hide-details class="ma-0"/>
                    </td>
                    <td width="60">
                        <v-text-field v-model="actGridsOptions.filter.id" type="text"></v-text-field>
                    </td>
                    <td v-if="isVisibleColumn('date')" width="130">
                        <v-dialog
                            ref="dialogDates"
                            v-model="dialog.dates.modal"
                            :return-value.sync="actGridsOptions.filter.dates"
                            persistent width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="actGridsOptions.filter.dates"
                                    prepend-icon="mdi-calendar-month-outline"
                                    readonly
                                    clearable
                                    v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker
                                v-model="dialog.dates.model"
                                type="date"
                                scrollable
                                range
                                first-day-of-week="1">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="dialog.dates.modal = false">Отмена</v-btn>
                                <v-btn text color="primary" @click="$refs.dialogDates.save(dialog.dates.model)">
                                    Выбрать
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </td>
                    <td v-if="isVisibleColumn('signDate')" width="120">
                        <v-dialog ref="dialogSignDates"
                                  v-model="dialog.signDates.modal"
                                  :return-value.sync="actGridsOptions.filter.signDates"
                                  persistent width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="actGridsOptions.filter.signDates"
                                    prepend-icon="mdi-calendar-month-outline"
                                    readonly
                                    clearable
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="dialog.signDates.model"
                                           type="date"
                                           scrollable
                                           range
                                           first-day-of-week="1">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="dialog.signDates.modal = false">Отмена</v-btn>
                                <v-btn text color="primary" @click="$refs.dialogSignDates.save(dialog.signDates.model)">
                                    Выбрать
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </td>
                    <td v-if="isVisibleColumn('type')" width="150">
                        <v-select
                            v-model="actGridsOptions.filter.type"
                            :items="actTypes"
                            clearable
                            item-value="value"
                            item-text="text"
                        ></v-select>
                    </td>
                    <td v-if="isVisibleColumn('number')" width="80">
                        <v-text-field v-model="actGridsOptions.filter.number" type="text"></v-text-field>
                    </td>
                    <td v-if="isVisibleColumn('contract.number')" width="100">
                        <v-text-field v-model="actGridsOptions.filter.contract.number" type="text"></v-text-field>
                    </td>
                    <td v-if="isVisibleColumn('contract.type')" width="150">
                        <v-select
                            v-model="actGridsOptions.filter.contract.type"
                            :items="types"
                            clearable
                            item-value="value"
                            item-text="text"
                        ></v-select>
                    </td>
                    <td v-if="isVisibleColumn('contract.subject')" width="150">
                        <v-select
                            v-model="actGridsOptions.filter.contract.subject"
                            :items="subjects"
                            clearable
                            item-value="value"
                            item-text="text"
                        ></v-select>
                    </td>
                    <td v-if="isVisibleColumn('contract.client')" width="120">
                        <v-text-field v-model="actGridsOptions.filter.contract.userName" type="text"></v-text-field>
                    </td>
                    <td v-if="isVisibleColumn('contract.lawyer')" width="130">
                        <v-select
                            v-model="actGridsOptions.filter.contract.lawyers"
                            multiple
                            :items="lawyers"
                            clearable
                            item-value="id"
                            item-text="fullName">
                        </v-select>
                    </td>
                    <td v-if="isVisibleColumn('contract.lawyerOGPA')" width="130">
                        <v-select
                            v-model="actGridsOptions.filter.contract.lawyerOGPAs"
                            multiple
                            :items="lawyersOGPA"
                            clearable
                            item-value="id"
                            item-text="fullName">
                        </v-select>
                    </td>
                    <td v-if="isVisibleColumn('lawyerOGPAAssistant')" width="150">
                        <v-select
                            v-model="actGridsOptions.filter.lawyerOGPAAssistants"
                            multiple
                            :items="lawyersOGPA"
                            clearable
                            item-value="id"
                            item-text="fullName">
                        </v-select>
                    </td>
                    <td v-if="isVisibleColumn('lawyerEPO')" width="130">
                        <v-select
                            v-model="actGridsOptions.filter.lawyerEPOs"
                            multiple
                            :items="lawyersEPO"
                            clearable
                            item-value="id"
                            item-text="fullName"></v-select>
                    </td>
                    <td v-if="isVisibleColumn('lawyerOGPA')" width="150">
                        <v-select
                            v-model="actGridsOptions.filter.lawyerOGPAs"
                            multiple
                            :items="lawyersOGPA"
                            clearable
                            item-value="id"
                            item-text="fullName">
                        </v-select>
                    </td>
                    <td v-if="isVisibleColumn('amountAct')" width="120"></td>
                    <td v-if="isVisibleColumn('amountPayout')" width="120"></td>
                    <td v-if="isVisibleColumn('amount')" width="120"></td>
                    <td v-if="isVisibleColumn('remainder')" width="100"></td>
                    <td v-if="isVisibleColumn('createdBy')" width="100"></td>
                    <td v-if="isVisibleColumn('installment')" width="120">
                        <v-select v-model="actGridsOptions.filter.installment"
                                  :items="installments"
                                  clearable
                                  item-value="id"
                                  item-text="name"></v-select>
                    </td>
                    <td v-if="isVisibleColumn('comment')" width="230"></td>
                </tr>
                <tr v-for="(item, key) in items" :key="item.id" v-bind:class="{'grey darken-3': isHighlighted(item)}">
                    <td class="pl-5 pr-3">
                        <v-dialog v-model="deleteDialog[item.id]" max-width="320"
                                  v-if="$helper.permission.canDeleteAct(item, user)">
                            <template v-slot:activator="{ on }">
                                <v-icon small color="red" v-on="on">mdi-delete</v-icon>
                            </template>
                            <v-card>
                                <v-card-text class="text-center pt-7">Удалить выбранный элемент?</v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="deleteDialog[item.id] = false">Отмена</v-btn>
                                    <v-btn color="red" text @click="deleteItem(item.id)">Удалить</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </td>
                    <td v-if="isVisibleColumn('inFavorites')">
                        <v-icon
                            v-if="item.inFavorites"
                            @click="removeFromFavorites(item)"
                            color="yellow"
                            class="ml-1">
                            mdi-star
                        </v-icon>
                        <v-icon
                            v-if="!item.inFavorites"
                            @click="addToFavorites(item)"
                            color="grey"
                            class="ml-1">
                            mdi-star-outline
                        </v-icon>
                    </td>
                    <td>
                        <a @click="editAct(item.id, items[key-1], items[key + 1])">{{ item.id }}</a>
                    </td>
                    <td v-if="isVisibleColumn('date')">{{ $moment(item.date).dateFormat() }}</td>
                    <td v-if="isVisibleColumn('signDate')">
                        {{ $moment(item.signDate).dateFormat() }}
                    </td>
                    <td v-if="isVisibleColumn('type')">
                        <div v-if="item.type">{{ item.type.text }}</div>
                    </td>
                    <td v-if="isVisibleColumn('number')">
                        <div v-if="item.number">{{ item.number }}</div>
                    </td>
                    <td v-if="isVisibleColumn('contract.number')">
                        <div v-if="item.contract?.number">
                            <router-link :to="`/contracts/${item.contract.id}/edit`">
                                {{ item.contract.number }}
                            </router-link>
                        </div>
                    </td>
                    <td v-if="isVisibleColumn('contract.type')">
                        <div v-if="item.contract?.type">{{ getTypeName(item.contract.type) }}</div>
                    </td>
                    <td v-if="isVisibleColumn('contract.subject')">
                        <div v-if="item.contract?.type">{{ getSubjectName(item.contract.subject) }}</div>
                    </td>
                    <td v-if="isVisibleColumn('contract.client')">
                        <div v-if="item.contract?.client">{{ item.contract.client.userName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('contract.lawyer')">
                        <div v-if="item.contract?.lawyer">{{ item.contract.lawyer.fullName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('contract.lawyerOGPA')">
                        <div v-if="item.contract?.lawyerOGPA">{{ item.contract.lawyerOGPA.fullName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('lawyerOGPAAssistant')">
                        <div v-if="item.lawyerOGPAAssistant">
                            {{ item.lawyerOGPAAssistant.fullName }}
                        </div>
                    </td>
                    <td v-if="isVisibleColumn('lawyerEPO')">
                        <div v-if="item.lawyerEPO">{{ item.lawyerEPO.fullName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('lawyerOGPA')">
                        <div v-if="item.lawyerOGPA">{{ item.lawyerOGPA.fullName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('amountAct')">
                        <span v-if="item.type?.value === 'ACT'">{{ item.amount }}</span>
                    </td>
                    <td v-if="isVisibleColumn('amountPayout')">
                        <span v-if="item.type?.value === 'PAYOUT'">{{ item.amount }}</span>
                    </td>
                    <td v-if="isVisibleColumn('amount')">
                        {{ item.amount }}
                    </td>
                    <td v-if="isVisibleColumn('remainder')">
                        {{ getActRemainder(item) }}
                    </td>
                    <td v-if="isVisibleColumn('createdBy')">
                        <div v-if="item.createdBy">{{ item.createdBy.fullName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('installment')">
                        <div v-if="item.installment">{{ $helper.getInstallmentName(item.installment) }}</div>
                    </td>
                    <td v-if="isVisibleColumn('comment')">
                        <div v-if="item.comment">{{ item.comment }}</div>
                    </td>
                </tr>
                <tr class="result-row">
                    <td v-for="(header,i) in headers" :key="i">
                        <div v-if="i === 1">Всего:</div>
                        <div v-if="header.value == 'amountAct'">
                            {{ $helper.format.currency(totalActAmount) }}
                        </div>
                        <div v-if="header.value == 'amountPayout'">
                            {{ $helper.format.currency(totalPayoutAmount) }}
                        </div>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-data-table>

        <v-bottom-sheet
            v-model="dialog.actEdit.modal"
            fullscreen
            scrollable
            persistent
            :retain-focus="false">
            <v-card style="background: none">
                <v-card-text style="height: 100vh;">
                    <ActUpdatePage
                        v-if="editedAct"
                        :id="editedAct"
                        :inPopup="true"
                        :nextAct="nextAct"
                        :prevAct="prevAct"
                        :key="editedActKey"
                        @changeEdited="changeEdited"
                        @closeAndUpdate="closeEditAndFetch"
                        @updated="forceUpdate"
                        @closeEdit="closeEdit"></ActUpdatePage>
                </v-card-text>
            </v-card>
        </v-bottom-sheet>
    </div>
</template>

<script>
import ActService from "../_services/ActService";
import {mapState} from 'vuex';
import _ from "lodash";
import ActUpdatePage from "./ActUpdatePage";
import ActDialog from "@/acts/ActDialog.vue";

export default {
    name: 'ActGrid',
    components: {ActDialog, ActUpdatePage},
    props: {
        filter: Object,
        title: String,
        exclude: Array
    },
    data: function () {
        return {
            acts: [],
            lawyers: [],
            types: [],
            actTypes: [],
            lawyersOGPA: [],
            lawyersEPO: [],
            installments: [{
                id: 'HALVA',
                name: 'Халва'
            }],
            selected: [],
            loading: true,
            totalElements: 0,
            dialog: {
                dates: {
                    model: [],
                    modal: false
                },
                signDates: {
                    model: [],
                    modal: false
                },
                actEdit: {
                    modal: false
                }
            },
            deleteDialog: {},
            deleteBulkDialog: false,
            tableHeight: !this.filter ? this.$helper.calcTableHeightWithHorizontalScroll() : null,
            fixedHeader: !this.filter,
            actGridsOptions: !this.filter ? this.$store.state.actGridsOptions :
                {
                    options: {
                        sortBy: ['id'],
                        sortDesc: [true]
                    },
                    filter: this.filter
                },
            editedAct: null,
            nextAct: null,
            prevAct: null,
            editedActKey: 0
        }
    },
    watch: {
        actGridsOptions: {
            handler() {
                this.fetchGrid();
            },
            deep: true
        }
    },
    methods: {
        forceUpdate() {
            this.fetchGrid();
        },
        fetchGrid() {
            this.loading = true;
            ActService
                .getAll(this.actGridsOptions.options, this.actGridsOptions.filter)
                .then(data => {
                    if (this.exclude) {
                        data.page.content = data.page.content.filter(el => this.exclude.indexOf(el.id) === -1);
                    }
                    this.acts = data.page.content;
                    this.totalElements = data.page.totalElements;
                    this.lawyers = data.lawyers;
                    this.types = data.types;
                    this.actTypes = data.actTypes;
                    this.subjects = data.subjects;
                    this.lawyersEPO = data.lawyersEPO;
                    this.lawyersOGPA = data.lawyersOGPA;
                    this.loading = false;
                })
                .catch(error => {
                    this.flashError(this.$helper.showError(error));
                });
        },
        onItemAdd() {
            this.fetchGrid();
        },
        deleteSelected() {
            ActService.deleteBulk(this.selected).then(() => {
                this.fetchGrid();
                this.deleteBulkDialog = false;
                this.selected = [];
            });
        },
        deleteItem(id) {
            ActService.delete(id).then(() => {
                this.fetchGrid();
            });
        },
        onResize() {
            this.tableHeight = this.$helper.calcTableHeight();
        },
        isVisibleColumn(column) {
            return this.headers.find(el => el.value === column) !== undefined;
        },
        editAct(id, prevItem, nextItem) {
            this.editedAct = id.toString();
            this.prevAct = !_.isUndefined(prevItem) ? prevItem.id : null;
            this.nextAct = !_.isUndefined(nextItem) ? nextItem.id : null;
            this.dialog.actEdit.modal = true;
        },
        getTypeName(type) {
            return this.types.find(item => item.value === type).text;
        },
        getSubjectName(subject) {
            return this.subjects.find(item => item.value === subject).text;
        },
        closeEdit() {
            this.dialog.actEdit.modal = false;
            this.editedAct = null;
            this.editedActKey++;
        },
        changeEdited(id) {
            this.editedAct = id;
            let actIndex = this.acts.findIndex(el => {
                return el.id == id;
            });

            let prevItem = this.acts[actIndex - 1];
            let nextItem = this.acts[actIndex + 1];

            this.prevAct = !_.isUndefined(prevItem) ? prevItem.id : null;
            this.nextAct = !_.isUndefined(nextItem) ? nextItem.id : null;

            this.editedActKey++;
        },
        closeEditAndFetch() {
            this.closeEdit();
            this.fetchGrid()
        },
        isHighlighted(item) {
            return item.isNew || _.includes(this.selected, item.id)
        },
        getTableWidth() {
            return this.$helper.actGridColumns.getPropertiesForRole(this.user.role).tableWidth;
        },
        getActRemainder(act) {
            let totalAmount = 0;
            let paymentRemainder = 0;
            const contract = act.contract;
            if (contract) {
                paymentRemainder = contract.amount || 0;
                if (contract && contract.acts) {
                    contract.acts.filter(el => el?.type?.value === 'ACT').forEach(act => {
                        totalAmount += act.amount;
                    })
                }
            }
            return paymentRemainder - totalAmount;
        },
        addToFavorites(act) {
            ActService.addToFavorites(act.id).then(() => {
                act.inFavorites = true;
            });
        },
        removeFromFavorites(act) {
            ActService.removeFromFavorites(act.id).then(() => {
                act.inFavorites = false;
            });
        }
    },
    computed: {
        itemPerPage: function () {
            return [20, 30, 50, 100, 200, 500];
        },
        headers: function () {
            return this.$helper.actGridColumns.getForRole(this.user.role);
        },
        totalActAmount: function () {
            let totalAmount = 0;
            this.acts.filter(el => el?.type?.value === 'ACT').forEach((act) => {
                totalAmount += act.amount
            });
            return totalAmount;
        },
        totalPayoutAmount: function () {
            let totalAmount = 0;
            this.acts.filter(el => el?.type?.value === 'PAYOUT').forEach((act) => {
                totalAmount += act.amount
            });
            return totalAmount;
        },
        ...mapState(['user'])
    },
    created() {
        this.$store.dispatch('loading', false);
        //this.fetchGrid();
        this.$store.subscribeAction((action) => {
            if (action.type === 'newActReceived' && !this.filter) {
                let act = action.payload;
                act.isNew = true;
                this.acts.unshift(action.payload);
            }
        })
    },
    beforeCreate() {
        this.$store.dispatch('loading', true);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    mounted() {
        window.addEventListener('resize', this.onResize)
    }
};
</script>
<style lang="scss">
.v-content {
    padding-top: 0 !important;
}
</style>
