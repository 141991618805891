import {http} from "../_helpers";
import Service from "./Service";
import {axiosInstance} from "../_helpers/axiosInstance";

class RequestService extends Service {

    getAll(options, filter) {
        return http.get(this.makeOptionsUrl(`/requests`, options, filter));
    }

    getAppointmentsList(options, filter) {
        return http.get(this.makeOptionsUrl(`/appointments`, options, filter));
    }

    getOne(id) {
        return http.get(`/requests/${id}`);
    }

    getForm(id) {
        return http.get(`/requests/${id}/update`);
    }

    getCreatePage() {
        return http.get(`/requests/create`);
    }

    create(form) {
        return http.post(`/requests`, form);
    }

    update(id, form) {
        return http.put(`/requests/${id}`, form);
    }

    delete(id) {
        return http.delete(`/requests/${id}`);
    }

    deleteBulk(ids) {
        return axiosInstance.post(`/requests/deleteBulk`, {'ids': ids});
    }

    getAvailableTimesForDate(date, office) {
        return http.get(`/requests/times?appointmentDate=${date}&office=${office}`);
    }

    getAllStates() {
        return http.get(`/requests/states`);
    }

    getHistories(id) {
        return http.get(`/requests/${id}/histories`);
    }

    getComments(id) {
        return http.get(`/requests/${id}/comments`);
    }

    addComment(id, form) {
        return http.post(`/requests/${id}/comments`, form);
    }

    getAnswers(id) {
        return http.get(`/requests/${id}/answers`);
    }

    addAnswer(id, form) {
        return http.post(`/requests/${id}/answers`, form);
    }

    addAppointment(id, form) {
        return http.post(`/requests/${id}/appointment`, form);
    }

    cancelAppointment(id) {
        return axiosInstance.patch(`/requests/${id}/appointment/cancel`);
    }

    approveAppointment(id) {
        return axiosInstance.patch(`/requests/${id}/appointment/approve`);
    }

    cancelApproveAppointment(id) {
        return axiosInstance.patch(`/requests/${id}/appointment/approve/cancel`);
    }

    setAppointmentState(id, state) {
        return axiosInstance.patch(`/appointments/${id}/state`, {value: state});
    }

    assignLawyer(id, form) {
        return http.post(`/appointments/${id}/assignLawyer`, form);
    }

    publishedOnSite(id, form) {
        return http.post(`/requests/${id}/questions/publish`, form);
    }

    patch(id, patch) {
        return axiosInstance.patch(`/appointments/${id}`, patch);
    }
}

export default new RequestService;
