var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card-text', {
    staticStyle: {
      "margin-top": "-75px"
    }
  }, [_vm.loading ? _c('div', [_c('v-skeleton-loader', {
    attrs: {
      "type": "table-row@3"
    }
  })], 1) : _vm._e(), !_vm.loading ? _c('div', [_c('v-data-table', {
    staticClass: "table__lined",
    attrs: {
      "headers": _vm.plan.headers,
      "items": _vm.plan.items,
      "hide-default-footer": "",
      "disable-pagination": ""
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var items = _ref.items;
        return [_c('tbody', _vm._l(items, function (item) {
          return _c('tr', {
            key: item.id
          }, [_c('td', {
            attrs: {
              "width": "200"
            }
          }, [_vm._v(_vm._s(item.target))]), _c('td', {
            attrs: {
              "width": "200"
            }
          }, [_vm._v(_vm._s(item.plan))]), _c('td', {
            attrs: {
              "width": "250"
            }
          }, [_vm._v(_vm._s(item.date))]), _c('td', {
            attrs: {
              "width": "200"
            }
          }, [item.target == 'План дня' ? _c('div', [_vm._v(" " + _vm._s(_vm.todayItem) + " ")]) : _vm._e(), item.target == 'План отрезка' ? _c('div', [_vm._v(" " + _vm._s(_vm.intervalItems) + " ")]) : _vm._e(), item.target == 'План периода' ? _c('div', [_vm._v(" " + _vm._s(_vm.periodItems) + " ")]) : _vm._e()]), _c('td', [item.target == 'План дня' ? _c('div', [_c('span', {
            class: _vm.getResidueColor(_vm.getDayResidue())
          }, [_vm._v(" " + _vm._s(_vm.getDayResidue()) + " ")])]) : _vm._e(), item.target == 'План отрезка' ? _c('div', [_c('span', {
            class: _vm.getResidueColor(_vm.getIntervalResidue())
          }, [_vm._v(" " + _vm._s(_vm.getIntervalResidue()) + " ")])]) : _vm._e(), item.target == 'План периода' ? _c('div', [_c('span', {
            class: _vm.getResidueColor(_vm.getPeriodResidue())
          }, [_vm._v(" " + _vm._s(_vm.getPeriodResidue()) + " ")])]) : _vm._e()])]);
        }), 0)];
      }
    }], null, false, 430595671)
  })], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }