var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "xl": "9"
    }
  }, [_vm.title ? _c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " ")]) : _vm._e(), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.calls,
      "options": _vm.gridOptions.options,
      "server-items-length": _vm.totalElements,
      "item-key": "id",
      "loading": _vm.loading,
      "loading-text": "Загрузка ...",
      "footer-props": {
        'items-per-page-options': [20, 30, 50]
      }
    },
    on: {
      "update:options": function updateOptions($event) {
        return _vm.$set(_vm.gridOptions, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var items = _ref.items;
        return [_c('tbody', _vm._l(items, function (item) {
          return _c('tr', {
            key: item.id
          }, [_c('td', {
            attrs: {
              "width": "220"
            }
          }, [_vm._v(_vm._s(_vm.$moment(item.callTime).format("DD.MM.YYYY HH:mm")))]), _c('td', {
            attrs: {
              "width": "140"
            }
          }, [item.direction == 'TERMINATING' ? _c('v-icon', {
            attrs: {
              "small": "",
              "color": "blue"
            }
          }, [_vm._v("mdi-phone-incoming ")]) : _vm._e(), item.direction == 'ORIGINATING' ? _c('v-icon', {
            attrs: {
              "small": "",
              "color": "orange"
            }
          }, [_vm._v("mdi-phone-outgoing ")]) : _vm._e(), item.status == 'MISSED' ? _c('v-icon', {
            staticClass: "ml-2",
            attrs: {
              "small": "",
              "color": "red"
            }
          }, [_vm._v("mdi-phone-missed ")]) : _vm._e()], 1), _c('td', [item.direction == 'TERMINATING' ? _c('span', [_vm._v(_vm._s(item.abonentName ? item.abonentName : item.calledNumber))]) : _vm._e(), item.direction == 'ORIGINATING' ? _c('span', [_vm._v(_vm._s(item.abonentName ? item.abonentName : item.callingNumber))]) : _vm._e()]), _c('td', {
            attrs: {
              "width": "180"
            }
          }, [_vm._v(_vm._s(item.duration))]), _c('td', [item.status != 'MISSED' ? _c('v-btn', {
            attrs: {
              "icon": ""
            },
            on: {
              "click": function click($event) {
                return _vm.playRecord(item.extTrackingId);
              }
            }
          }, [_c('v-icon', [_vm._v("mdi-play-circle-outline")])], 1) : _vm._e()], 1)]);
        }), 0)];
      }
    }])
  })], 1)], 1), _c('v-bottom-sheet', {
    attrs: {
      "persistent": ""
    },
    model: {
      value: _vm.dialog.playRecord.modal,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog.playRecord, "modal", $$v);
      },
      expression: "dialog.playRecord.modal"
    }
  }, [_c('v-sheet', {
    staticClass: "text-center",
    attrs: {
      "height": "200px"
    }
  }, [_c('v-btn', {
    staticClass: "mt-6",
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": function click($event) {
        return _vm.closePlayRecord();
      }
    }
  }, [_vm._v("закрыть")]), _c('div', {
    staticClass: "py-3"
  }, [_vm.playRecordId ? _c('PlayRecord', {
    attrs: {
      "recordingId": _vm.playRecordId
    }
  }) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }