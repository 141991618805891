import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'typeface-roboto/index.css';
import '@mdi/font/css/materialdesignicons.css';
import VueFlashMessage from 'vue-flash-message';
import ru from 'vuetify/es5/locale/ru'

require('vue-flash-message/dist/vue-flash-message.min.css');
Vue.use(VueFlashMessage);
Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: {ru},
        current: 'ru'
    }
});
