<template>
    <div>
        <div class="b-page_newyear">
            <div class="b-page__content">
                <i class="b-head-decor">
                    <i class="b-head-decor__inner b-head-decor__inner_n1">
                        <div class="b-ball b-ball_n1 b-ball_bounce" data-note="0">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n2 b-ball_bounce" data-note="1">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n3 b-ball_bounce" data-note="2">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n4 b-ball_bounce" data-note="3">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n5 b-ball_bounce" data-note="4">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n6 b-ball_bounce" data-note="5">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n7 b-ball_bounce" data-note="6">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n8 b-ball_bounce" data-note="7">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n9 b-ball_bounce" data-note="8">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i1">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i2">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i3">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i4">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i5">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i6">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                    </i>
                    <i class="b-head-decor__inner b-head-decor__inner_n2">
                        <div class="b-ball b-ball_n1 b-ball_bounce" data-note="9">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n2 b-ball_bounce" data-note="10">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n3 b-ball_bounce" data-note="11">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n4 b-ball_bounce" data-note="12">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n5 b-ball_bounce" data-note="13">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n6 b-ball_bounce" data-note="14">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n7 b-ball_bounce" data-note="15">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n8 b-ball_bounce" data-note="16">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n9 b-ball_bounce" data-note="17">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i1">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i2">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i3">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i4">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i5">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i6">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                    </i>
                    <i class="b-head-decor__inner b-head-decor__inner_n3">
                        <div class="b-ball b-ball_n1 b-ball_bounce" data-note="18">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n2 b-ball_bounce" data-note="19">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n3 b-ball_bounce" data-note="20">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n4 b-ball_bounce" data-note="21">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n5 b-ball_bounce" data-note="22">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n6 b-ball_bounce" data-note="23">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n7 b-ball_bounce" data-note="24">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n8 b-ball_bounce" data-note="25">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n9 b-ball_bounce" data-note="26">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i1">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i2">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i3">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i4">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i5">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i6">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                    </i>
                    <i class="b-head-decor__inner b-head-decor__inner_n4">
                        <div class="b-ball b-ball_n1 b-ball_bounce" data-note="27">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n2 b-ball_bounce" data-note="28">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n3 b-ball_bounce" data-note="29">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n4 b-ball_bounce" data-note="30">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n5 b-ball_bounce" data-note="31">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n6 b-ball_bounce" data-note="32">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n7 b-ball_bounce" data-note="33">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n8 b-ball_bounce" data-note="34">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n9 b-ball_bounce" data-note="35">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i1">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i2">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i3">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i4">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i5">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i6">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                    </i>
                    <i class="b-head-decor__inner b-head-decor__inner_n5">
                        <div class="b-ball b-ball_n1 b-ball_bounce" data-note="0">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n2 b-ball_bounce" data-note="1">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n3 b-ball_bounce" data-note="2">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n4 b-ball_bounce" data-note="3">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n5 b-ball_bounce" data-note="4">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n6 b-ball_bounce" data-note="5">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n7 b-ball_bounce" data-note="6">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n8 b-ball_bounce" data-note="7">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n9 b-ball_bounce" data-note="8">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i1">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i2">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i3">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i4">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i5">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i6">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                    </i>
                    <i class="b-head-decor__inner b-head-decor__inner_n6">
                        <div class="b-ball b-ball_n1 b-ball_bounce" data-note="9">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n2 b-ball_bounce" data-note="10">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n3 b-ball_bounce" data-note="11">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n4 b-ball_bounce" data-note="12">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n5 b-ball_bounce" data-note="13">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n6 b-ball_bounce" data-note="14">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n7 b-ball_bounce" data-note="15">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n8 b-ball_bounce" data-note="16">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n9 b-ball_bounce" data-note="17">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i1">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i2">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i3">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i4">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i5">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i6">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                    </i>
                    <i class="b-head-decor__inner b-head-decor__inner_n7">
                        <div class="b-ball b-ball_n1 b-ball_bounce" data-note="18">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n2 b-ball_bounce" data-note="19">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n3 b-ball_bounce" data-note="20">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n4 b-ball_bounce" data-note="21">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n5 b-ball_bounce" data-note="22">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n6 b-ball_bounce" data-note="23">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n7 b-ball_bounce" data-note="24">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n8 b-ball_bounce" data-note="25">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_n9 b-ball_bounce" data-note="26">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i1">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i2">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i3">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i4">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i5">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                        <div class="b-ball b-ball_i6">
                            <div class="b-ball__right"></div>
                            <div class="b-ball__i"></div>
                        </div>
                    </i>
                </i>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NyBalls"
    }
</script>

<style scoped>
    .b-page_newyear {
        height: 65px;
    }
    .b-head-decor {
        display: none
    }
    .b-page_newyear .b-head-decor {
        position: absolute;
        top: 35px;
        display: block;
        height: 115px;
        width: 100%;
        overflow: hidden;
        background: url(/assets/newyear/b-head-decor_newyear.png) repeat-x 0 0
    }
    .b-page_newyear .b-head-decor__inner {
        position: absolute;
        top: 0;
        left: 0;
        height: 115px;
        display: block;
        width: 373px
    }
    .b-page_newyear .b-head-decor::before {
        content: '';
        display: block;
        position: absolute;
        top: -115px;
        left: 0;
        z-index: 3;
        height: 115px;
        display: block;
        width: 100%;
        box-shadow: 0 15px 30px rgba(0, 0, 0, .75)
    }
    .b-page_newyear .b-head-decor__inner_n2 {
        left: 373px
    }
    .b-page_newyear .b-head-decor__inner_n3 {
        left: 746px
    }
    .b-page_newyear .b-head-decor__inner_n4 {
        left: 1119px
    }
    .b-page_newyear .b-head-decor__inner_n5 {
        left: 1492px
    }
    .b-page_newyear .b-head-decor__inner_n6 {
        left: 1865px
    }
    .b-page_newyear .b-head-decor__inner_n7 {
        left: 2238px
    }
    .b-ball {
        position: absolute
    }
    .b-ball_n1 {
        top: 0;
        left: 3px;
        width: 59px;
        height: 83px
    }
    .b-ball_n2 {
        top: -19px;
        left: 51px;
        width: 55px;
        height: 70px
    }
    .b-ball_n3 {
        top: 9px;
        left: 88px;
        width: 49px;
        height: 67px
    }
    .b-ball_n4 {
        top: 0;
        left: 133px;
        width: 57px;
        height: 102px
    }
    .b-ball_n5 {
        top: 0;
        left: 166px;
        width: 49px;
        height: 57px
    }
    .b-ball_n6 {
        top: 6px;
        left: 200px;
        width: 54px;
        height: 70px
    }
    .b-ball_n7 {
        top: 0;
        left: 240px;
        width: 56px;
        height: 67px
    }
    .b-ball_n8 {
        top: 0;
        left: 283px;
        width: 54px;
        height: 53px
    }
    .b-ball_n9 {
        top: 10px;
        left: 321px;
        width: 49px;
        height: 66px
    }
    .b-ball_n1 .b-ball__i {
        background: url(/assets/newyear/b-ball_n1.png) no-repeat
    }
    .b-ball_n2 .b-ball__i {
        background: url(/assets/newyear/b-ball_n2.png) no-repeat
    }
    .b-ball_n3 .b-ball__i {
        background: url(/assets/newyear/b-ball_n3.png) no-repeat
    }
    .b-ball_n4 .b-ball__i {
        background: url(/assets/newyear/b-ball_n4.png) no-repeat
    }
    .b-ball_n5 .b-ball__i {
        background: url(/assets/newyear/b-ball_n5.png) no-repeat
    }
    .b-ball_n6 .b-ball__i {
        background: url(/assets/newyear/b-ball_n6.png) no-repeat
    }
    .b-ball_n7 .b-ball__i {
        background: url(/assets/newyear/b-ball_n7.png) no-repeat
    }
    .b-ball_n8 .b-ball__i {
        background: url(/assets/newyear/b-ball_n8.png) no-repeat
    }
    .b-ball_n9 .b-ball__i {
        background: url(/assets/newyear/b-ball_n9.png) no-repeat
    }
    .b-ball_i1 .b-ball__i {
        background: url(/assets/newyear/b-ball_i1.png) no-repeat
    }
    .b-ball_i2 .b-ball__i {
        background: url(/assets/newyear/b-ball_i2.png) no-repeat
    }
    .b-ball_i3 .b-ball__i {
        background: url(/assets/newyear/b-ball_i3.png) no-repeat
    }
    .b-ball_i4 .b-ball__i {
        background: url(/assets/newyear/b-ball_i4.png) no-repeat
    }
    .b-ball_i5 .b-ball__i {
        background: url(/assets/newyear/b-ball_i5.png) no-repeat
    }
    .b-ball_i6 .b-ball__i {
        background: url(/assets/newyear/b-ball_i6.png) no-repeat
    }
    .b-ball_i1 {
        top: 0;
        left: 0;
        width: 25px;
        height: 71px
    }
    .b-ball_i2 {
        top: 0;
        left: 25px;
        width: 61px;
        height: 27px
    }
    .b-ball_i3 {
        top: 0;
        left: 176px;
        width: 29px;
        height: 31px
    }
    .b-ball_i4 {
        top: 0;
        left: 205px;
        width: 50px;
        height: 51px
    }
    .b-ball_i5 {
        top: 0;
        left: 289px;
        width: 78px;
        height: 28px
    }
    .b-ball_i6 {
        top: 0;
        left: 367px;
        width: 6px;
        height: 69px
    }
    .b-ball__i {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-transform-origin: 50% 0;
        -moz-transform-origin: 50% 0;
        -o-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        pointer-events: none
    }
    .b-ball_bounce .b-ball__right {
        position: absolute;
        top: 0;
        right: 0;
        left: 50%;
        bottom: 0;
        z-index: 9
    }
    .b-ball_bounce:hover .b-ball__right {
        display: none
    }
    .b-ball_bounce .b-ball__right:hover {
        left: 0;
        display: block !important
    }
    .b-ball_bounce.bounce > .b-ball__i {
        -webkit-transform: rotate(-9deg);
        -moz-transform: rotate(-9deg);
        -o-transform: rotate(-9deg);
        transform: rotate(-9deg)
    }
    .b-ball_bounce .b-ball__right.bounce + .b-ball__i {
        -webkit-transform: rotate(9deg);
        -moz-transform: rotate(9deg);
        -o-transform: rotate(9deg);
        transform: rotate(9deg)
    }
    .b-ball_bounce.bounce1 > .b-ball__i {
        -webkit-transform: rotate(6deg);
        -moz-transform: rotate(6deg);
        -o-transform: rotate(6deg);
        transform: rotate(6deg)
    }
    .b-ball_bounce .b-ball__right.bounce1 + .b-ball__i {
        -webkit-transform: rotate(-6deg);
        -moz-transform: rotate(-6deg);
        -o-transform: rotate(-6deg);
        transform: rotate(-6deg)
    }
    .b-ball_bounce.bounce2 > .b-ball__i {
        -webkit-transform: rotate(-3deg);
        -moz-transform: rotate(-3deg);
        -o-transform: rotate(-3deg);
        transform: rotate(-3deg)
    }
    .b-ball_bounce .b-ball__right.bounce2 + .b-ball__i {
        -webkit-transform: rotate(3deg);
        -moz-transform: rotate(3deg);
        -o-transform: rotate(3deg);
        transform: rotate(3deg)
    }
    .b-ball_bounce.bounce3 > .b-ball__i {
        -webkit-transform: rotate(1.5deg);
        -moz-transform: rotate(1.5deg);
        -o-transform: rotate(1.5deg);
        transform: rotate(1.5deg)
    }
    .b-ball_bounce .b-ball__right.bounce3 + .b-ball__i {
        -webkit-transform: rotate(-1.5deg);
        -moz-transform: rotate(-1.5deg);
        -o-transform: rotate(-1.5deg);
        transform: rotate(-1.5deg)
    }
</style>
