var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, table-row@3, button"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading ? _c('div', [_c('v-card-title', [_vm._v(" События "), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.forceUpdate
    }
  }, [_c('v-icon', [_vm._v("mdi-reload")])], 1), _c('v-spacer')], 1), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.events,
      "item-key": "id",
      "loading": _vm.loadingTable,
      "options": _vm.eventGridOptions.options,
      "loading-text": "Загрузка ...",
      "server-items-length": _vm.totalElements,
      "footer-props": {
        'items-per-page-options': _vm.itemPerPage
      }
    },
    on: {
      "update:options": function updateOptions($event) {
        return _vm.$set(_vm.eventGridOptions, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var items = _ref.items;
        return [_c('tbody', [_c('tr', [_c('td'), _c('td', [_c('DateField', {
          attrs: {
            "label": "Дата",
            "range": "",
            "clearable": ""
          },
          model: {
            value: _vm.eventGridOptions.filter.dateTimes,
            callback: function callback($$v) {
              _vm.$set(_vm.eventGridOptions.filter, "dateTimes", $$v);
            },
            expression: "eventGridOptions.filter.dateTimes"
          }
        })], 1), _c('td', [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.types,
            "clearable": "",
            "item-value": "value",
            "item-text": "text"
          },
          model: {
            value: _vm.eventGridOptions.filter.types,
            callback: function callback($$v) {
              _vm.$set(_vm.eventGridOptions.filter, "types", $$v);
            },
            expression: "eventGridOptions.filter.types"
          }
        })], 1), _c('td'), _c('td', [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.executors,
            "clearable": "",
            "item-value": "id",
            "item-text": "fullName"
          },
          model: {
            value: _vm.eventGridOptions.filter.executors,
            callback: function callback($$v) {
              _vm.$set(_vm.eventGridOptions.filter, "executors", $$v);
            },
            expression: "eventGridOptions.filter.executors"
          }
        })], 1), _c('td', [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.executors,
            "clearable": "",
            "item-value": "id",
            "item-text": "fullName"
          },
          model: {
            value: _vm.eventGridOptions.filter.substitutions,
            callback: function callback($$v) {
              _vm.$set(_vm.eventGridOptions.filter, "substitutions", $$v);
            },
            expression: "eventGridOptions.filter.substitutions"
          }
        })], 1), _c('td', [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.eventGridOptions.filter.contract,
            callback: function callback($$v) {
              _vm.$set(_vm.eventGridOptions.filter, "contract", $$v);
            },
            expression: "eventGridOptions.filter.contract"
          }
        })], 1), _c('td'), _c('td', [_c('DateField', {
          attrs: {
            "label": "Создан",
            "range": "",
            "clearable": ""
          },
          model: {
            value: _vm.eventGridOptions.filter.createdAtDates,
            callback: function callback($$v) {
              _vm.$set(_vm.eventGridOptions.filter, "createdAtDates", $$v);
            },
            expression: "eventGridOptions.filter.createdAtDates"
          }
        })], 1), _c('td', [_c('v-autocomplete', {
          attrs: {
            "items": _vm.users,
            "item-value": "id",
            "item-text": "fullName",
            "clearable": true
          },
          model: {
            value: _vm.eventGridOptions.filter.user,
            callback: function callback($$v) {
              _vm.$set(_vm.eventGridOptions.filter, "user", $$v);
            },
            expression: "eventGridOptions.filter.user"
          }
        })], 1)]), _vm._l(items, function (item) {
          var _item$type, _item$executor, _item$substitution, _item$user;
          return _c('tr', {
            key: item.id
          }, [_c('td', {
            staticClass: "px-5",
            attrs: {
              "width": "20"
            }
          }), _c('td', {
            attrs: {
              "width": "200"
            }
          }, [_vm._v(" " + _vm._s(_vm.$moment(item.dateTime).dateTimeFormat()) + " ")]), _c('td', {
            attrs: {
              "width": "260"
            }
          }, [item.type ? _c('div', [_vm._v(" " + _vm._s((_item$type = item.type) === null || _item$type === void 0 ? void 0 : _item$type.text) + " ")]) : _vm._e()]), _c('td', [_vm._v(" " + _vm._s(item.comment) + " ")]), _c('td', {
            attrs: {
              "width": "200"
            }
          }, [_vm._v(" " + _vm._s((_item$executor = item.executor) === null || _item$executor === void 0 ? void 0 : _item$executor.fullName) + " ")]), _c('td', {
            attrs: {
              "width": "270"
            }
          }, [_vm._v(" " + _vm._s((_item$substitution = item.substitution) === null || _item$substitution === void 0 ? void 0 : _item$substitution.fullName) + " ")]), _c('td', {
            attrs: {
              "width": "110"
            }
          }, [_c('router-link', {
            attrs: {
              "to": "/contracts/".concat(item.contract, "/edit")
            }
          }, [_vm._v(" #" + _vm._s(item.contract) + " ")])], 1), _c('td', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.clientName) + " ")]), _c('td', {
            attrs: {
              "width": "270"
            }
          }, [_vm._v(" " + _vm._s(_vm.$moment(item.createdAt).dateFormat()) + " ")]), _c('td', {
            attrs: {
              "width": "310"
            }
          }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$user = item.user) === null || _item$user === void 0 ? void 0 : _item$user.fullName) + " ")])]);
        })], 2)];
      }
    }], null, false, 23361156)
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }