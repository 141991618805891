<template>
    <div>
        <v-card-title>
            Настройки
        </v-card-title>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader type="heading, table-row@3, button"></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-card v-if="!loading">
            <v-card-text>
                <form>
                    <v-row>
                        <v-col cols="12" xl="8">
                            <h2>Отчеты</h2>

                            <h3 class="my-4">Юр отдел</h3>
                            <h4 class="my-3">План дня</h4>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="form.reporting.planDay"
                                        label="План дня"
                                    ></v-text-field>
                                    <v-alert v-if="form.errors.has('reporting.planDay')" dense outlined type="error" :icon="false">
                                        {{form.errors.get('reporting.planDay') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <h4 class="my-3">План отрезка</h4>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="form.reporting.planInterval"
                                        label="План отрезка"
                                    ></v-text-field>
                                    <v-alert v-if="form.errors.has('reporting.planInterval')" dense outlined type="error" :icon="false">
                                        {{form.errors.get('reporting.planInterval') }}
                                    </v-alert>
                                </v-col>
                                <v-col>
                                    <DateField
                                        v-model="form.reporting.intervalDates"
                                        :range="true"
                                        label="Период отрезка"
                                    ></DateField>
                                    <v-alert v-if="form.errors.has('reporting.intervalDates')" dense outlined type="error" :icon="false">
                                        {{form.errors.get('reporting.intervalDates') }}
                                    </v-alert>
                                </v-col>
                            </v-row><h4 class="my-3">План периода</h4>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="form.reporting.planPeriod"
                                        label="План периода"
                                    ></v-text-field>
                                    <v-alert v-if="form.errors.has('reporting.planPeriod')" dense outlined type="error" :icon="false">
                                        {{form.errors.get('reporting.planPeriod') }}
                                    </v-alert>
                                </v-col>
                                <v-col>
                                    <DateField
                                        v-model="form.reporting.periodDates"
                                        :range="true"
                                        label="Период"
                                    ></DateField>
                                    <v-alert v-if="form.errors.has('reporting.periodDates')" dense outlined type="error" :icon="false">
                                        {{form.errors.get('reporting.periodDates') }}
                                    </v-alert>
                                </v-col>
                            </v-row>

                            <h3 class="my-4">Орк</h3>
                            <h4 class="my-3">План дня</h4>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="form.reportingOrk.planDay"
                                        label="План дня">
                                    </v-text-field>
                                    <v-alert v-if="form.errors.has('reportingOrk.planDay')" dense outlined type="error" :icon="false">
                                        {{form.errors.get('reportingOrk.planDay') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <h4 class="my-3">План отрезка</h4>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="form.reportingOrk.planInterval"
                                        label="План отрезка"
                                    ></v-text-field>
                                    <v-alert v-if="form.errors.has('reportingOrk.planInterval')" dense outlined type="error" :icon="false">
                                        {{form.errors.get('reportingOrk.planInterval') }}
                                    </v-alert>
                                </v-col>
                                <v-col>
                                    <DateField
                                        v-model="form.reportingOrk.intervalDates"
                                        :range="true"
                                        label="Период отрезка">
                                    </DateField>
                                    <v-alert v-if="form.errors.has('reportingOrk.intervalDates')" dense outlined type="error" :icon="false">
                                        {{form.errors.get('reportingOrk.intervalDates') }}
                                    </v-alert>
                                </v-col>
                            </v-row><h4 class="my-3">План периода</h4>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="form.reportingOrk.planPeriod"
                                        label="План периода"
                                    ></v-text-field>
                                    <v-alert v-if="form.errors.has('reportingOrk.planPeriod')" dense outlined type="error" :icon="false">
                                        {{form.errors.get('reportingOrk.planPeriod') }}
                                    </v-alert>
                                </v-col>
                                <v-col>
                                    <DateField
                                        v-model="form.reportingOrk.periodDates"
                                        :range="true"
                                        label="Период"
                                    ></DateField>
                                    <v-alert v-if="form.errors.has('reportingOrk.periodDates')" dense outlined type="error" :icon="false">
                                        {{form.errors.get('reportingOrk.periodDates') }}
                                    </v-alert>
                                </v-col>
                            </v-row>

                            <v-alert v-if="form.errors.any()" dense outlined type="error" :icon="false">
                                Исправьте ошибки
                            </v-alert>
                            <v-alert v-if="form.errors.has('error')" dense outlined type="error" :icon="false">
                                {{form.errors.get('error')}}
                            </v-alert>
                            <div v-show="!form.busy">
                                <v-btn large color="green" @click="update()">
                                    Сохранить
                                </v-btn>
                            </div>
                            <v-progress-circular v-show="form.busy" indeterminate color="primary"></v-progress-circular>
                        </v-col>
                    </v-row>
                </form>
            </v-card-text>
        </v-card>
        <SettingRevisions id="global"></SettingRevisions>
    </div>
</template>
<script>
import {Form} from '../_helpers/_form';
import SettingService from "../_services/SettingService";
import DateField from "@/_chunks/DateField";
import SettingRevisions from "@/settings/revisions/SettingRevisions.vue";

export default {
    name: "GlobalSettings",
    components: {SettingRevisions, DateField},
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            form: new Form({
                reporting: {
                    planDay: null,
                    planInterval: null,
                    intervalDates: null,
                    planPeriod: null,
                    periodDates: null,
                },
                reportingOrk: {
                    planDay: null,
                    planInterval: null,
                    intervalDates: null,
                    planPeriod: null,
                    periodDates: null,
                }
            }),
            loading: true
        }
    },
    methods: {
        fetch() {
            SettingService.getGlobal().then(data => {
                this.form = new Form(data);
                this.loading = false;
            });
        },
        update() {
            SettingService.updateGlobal(this.form).then(() => {
                this.$store.state.globalSettings.reporting = this.form.reporting;
            });
        }
    },
    created() {
        this.fetch();
    },
}
</script>

<style lang="scss">
</style>
