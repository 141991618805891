<template>
    <div>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader
                        type="heading, table-row@3, button"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <div class="my-10" v-if="!loading">
            <v-card-title>
                Визиты клиента
                <v-spacer></v-spacer>
                <VisitDialog v-if="$helper.permission.canCreateVisit(user)" :id="id" @saved="onVisitAdd"></VisitDialog>
            </v-card-title>
            <v-data-table :headers="headers" :items="visits" class="elevation-1" item-key="id" :loading="loading"
                          loading-text="Загрузка ..." :items-per-page="5">
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td width="220">{{ item.createdAt }}</td>
                        <td>{{ item.comment }}</td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
    import ClientService from "../../_services/ClientService";
    import VisitDialog from "./VisitDialog";
    import {mapState} from 'vuex';

    export default {
        components: {VisitDialog},
        props: {id: Number},
        data() {
            return {
                headers: [
                    {text: 'Дата', value: 'createdAt'},
                    {text: 'Комментарий', value: 'comment'}
                ],
                visits: [],
                loading: true
            }
        },
        methods: {
            fetch() {
                this.loading = true;
                this.fetchVisits();
            },
            fetchVisits() {
                ClientService.getVisits(this.id).then(data => {
                    this.visits = data;
                    this.loading = false;
                });
            },
            onVisitAdd() {
                this.fetchVisits();
            }
        },
        created() {
            this.fetch();
        },
        computed: {
            ...mapState(['user'])
        }
    };
</script>
