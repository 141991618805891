import Vue from 'vue';
import VueECharts from 'vue-echarts';
import App from './app/App';
import vuetify from './_plugins/vuetify';
import moment from "moment";
import('./_helpers/prototypes');
import {helper, router} from './_helpers';
import store from './_store/';
import _ from "lodash";
import {config} from "./_helpers/constants";
import DefaultLayout from './_layouts/DefaultLayout.vue'
import AuthLayout from './_layouts/AuthLayout.vue'
import VueMask from 'v-mask';
import VueFlashMessage from 'vue-flash-message';
import DatetimePicker from './_components/DatetimePicker';
import { LocalStorage } from 'ttl-localstorage';
import("./_helpers/globalHandlers");
import VueDragDrop from 'vue-drag-drop';

try {
    import('./firebase')
        .then((firebaseModule) => {
            let firebaseMessaging = firebaseModule.default;
            firebaseMessaging.onMessage(function(payload) {
                console.log("Message received. ", payload);
                let options = {
                    body: payload.notification.body,
                    icon: payload.notification.icon,
                    //actions: payload.notification.actions
                }
                const notification = new Notification(payload.notification.title, options);
                notification.onclick = function () {
                    window.location.href = payload.notification.actions[0].action;
                };
                return notification;
            });
            Vue.prototype.$messaging = firebaseMessaging;
        });
} catch (ex) {
    console.log(ex)
}

Vue.component('v-chart', VueECharts);
Vue.component('default-layout', DefaultLayout);
Vue.component('auth-layout', AuthLayout);
Vue.component('v-datetime-picker', DatetimePicker);

LocalStorage.timeoutInSeconds = 2 * 3600;

Vue.prototype.$moment = moment;
Vue.prototype.$config = config;
Vue.prototype.$helper = helper;
Vue.prototype.$ls = LocalStorage;
Vue.prototype.$_ = _;

global.store = store;

Vue.use(VueMask);
Vue.use(VueFlashMessage);
Vue.use(VueDragDrop);

new Vue({
    el: '#app',
    store,
    router,
    vuetify,
    render: h => h(App),
    created() {
        this.$store.dispatch('checkPageTitle', this.$route.meta.title).then();
        this.$store.dispatch('checkAuth').then();
    }
});
