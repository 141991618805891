var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "widget widget__birthday"
  }, [_vm.isBirthdayToday ? _c('div', [_vm._v(" " + _vm._s(_vm.name) + " поздравляем с Днём Рождения! 🎉 🎈🎈🎈 ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }