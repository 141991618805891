import {helper} from "../../_helpers";

const formFields = {
    date: {
        isEnabled: (user) => {
            return helper.permission.canCreateAct(user)
        }
    },
    state: {
        isEnabled: (user) => {
            return helper.permission.canUpdateContract(user)
        }
    },
    number: {
        isEnabled: (user) => {
            return helper.permission.canCreateAct(user)
        }
    },
    type: {
        isEnabled: (user) => {
            return helper.permission.canCreateAct(user)
        }
    },
    subject: {
        isEnabled: (user) => {
            return helper.permission.canCreateAct(user) || user.role  === 'LAWYER_MANAGER'
        }
    },
    courtDateTime: {
        isEnabled: (user) => {
            return helper.permission.canUpdateContract(user)
        }
    },
    principal: {
        isEnabled: (user) => {
            return helper.permission.canUpdateContract(user)
        }
    },
    amount: {
        isEnabled: (user) => {
            return helper.permission.canCreateAct(user)
        }
    },
    lawyer: {
        isEnabled: (user) => {
            return helper.permission.canCreateAct(user) || user.role  === 'LAWYER_MANAGER'
        }
    },
    lawyerOGPA: {
        isEnabled: (user) => {
            return helper.permission.canCreateAct(user)
                || user.role  === 'MANAGER_OGPIA'
                || user.role  === 'MANAGER_EPO'
        }
    },
    lawyerEPO: {
        isEnabled: (user) => {
            return helper.permission.canCreateAct(user) || user.role  === 'MANAGER_EPO'
        }
    },
    transport: {
        isEnabled: (user) => {
            return helper.permission.canCreateAct(user)
        }
    },
    userName: {
        isEnabled: (user) => {
            return helper.permission.canCreateAct(user)
        }
    },
    clientEmail: {
        isEnabled: (user) => {
            return helper.permission.canUpdateContract(user)
        }
    },
    actions: {
        isEnabled: (user) => {
            return helper.permission.canUpdateContract(user)
        }
    },
    comment: {
        isEnabled: (user) => {
            return helper.permission.canUpdateContract(user)
        }
    },
    commentForBuhgalter: {
        isEnabled: (user) => {
            return user.role === 'BUHGALTER'
        }
    },
    isResale: {
        isEnabled: (user) => {
            return helper.permission.canUpdateContract(user)
        }
    },
    isTermination: {
        isEnabled: (user) => {
            return helper.permission.canUpdateContract(user)
        }
    },
    sentForBuhgalter: {
        isEnabled: (user) => {
            return helper.permission.canCreateContract(user)
        }
    },
    isBezNal: {
        isEnabled: (user) => {
            return helper.permission.canCreateContract(user)
        }
    },
    visibleFieldsForRole: {
        BUHGALTER: ['ContractActs', 'ContractPayments', 'ContractAdditionalPayments']
    },
    visibleFieldsOnlyForRole: {
        commentForBuhgalter: ['BUHGALTER'],
        buhgalter: ['ADMIN', 'RECEPTION'],
        // taskCivilianRepr: ['ADMIN', 'LAWYER', 'LAWYER_MANAGER', 'MANAGER_OGPIA', 'LAWYER_EPO', 'LAWYER_EPO']
    },
}

export {
    formFields
}
