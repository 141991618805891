<template>
    <ReminderGrid @forceUpdate="key++" :key="key"></ReminderGrid>
</template>

<script>
    import ReminderGrid from "./ReminderGrid";
    export default {
        name: "ReminderPage",
        components: {ReminderGrid},
        data() {
            return {
                key: 0
            }
        }
    }
</script>

<style scoped>

</style>
