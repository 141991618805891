var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card-text', [_vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "table-row@3"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading && _vm.orks.length > 0 ? _c('div', {
    staticStyle: {
      "width": "100%",
      "overflow-x": "auto"
    }
  }, [_c('div', {
    style: {
      width: _vm.getBarWidth() + 'px',
      'min-width': 'calc(100%)'
    }
  }, [_c('v-chart', {
    attrs: {
      "options": _vm.option
    }
  })], 1)]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }