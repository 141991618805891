<template>
    <div>
        <v-card-title>
            Создание клиента
            <div class="ml-5">
                <v-btn small color="primary" class="mx-4" outlined to="/clients">Все клиенты</v-btn>
            </div>
        </v-card-title>
        <div v-if="!loading">
            <ClientForm :page="page"></ClientForm>
        </div>
    </div>
</template>

<script>

    import ClientService from "../_services/ClientService";
    import ClientForm from "./ClientForm";

    export default {
        components: {ClientForm},
        data() {
            return {
                page: {},
                loading: true
            }
        },
        methods: {
            fetch() {
                this.fetchPage();
            },
            fetchPage() {
                const requestId = this.$route.query.requestId;
                ClientService.getCreatePage(requestId).then(data => {
                    this.page = data;
                    this.loading = false;
                }, error => {
                    this.flashError(this.$helper.showError(error));
                });
            }
        },
        created() {
            this.fetch();
        }
    };
</script>
