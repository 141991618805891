import {http} from "../_helpers";
import Service from "./Service";

class OfficeService extends Service {

    getAll() {
        return http.get(`/offices`)
    }
}

export default new OfficeService;
