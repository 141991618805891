<template>
    <div>
        <v-card-title>
            Редактирование акта
            <div class="text-right mx-3 button-close-edit">
                <v-btn text color="error" @click="$emit('closeEdit')" icon>
                    <v-icon large>mdi-close-circle-outline</v-icon>
                </v-btn>
            </div>
            <div class="ml-3">
                <v-btn v-if="prevAct" fab text color="white darken-2" @click="changeEdited(prevAct.toString())">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn v-if="nextAct" fab text color="white darken-2" @click="changeEdited(nextAct.toString())">
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </div>
        </v-card-title>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader
                        type="heading, table-row@3, button"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <div v-if="!loading">
            <ActForm
                    v-if="act !== null"
                    :page="page"
                    @closeAndUpdate="$emit('closeAndUpdate')"
                    @updated="$emit('updated')"
                    @recallSelected="onRecallSelected"></ActForm>
        </div>
    </div>
</template>

<script>
    import ActForm from "./ActForm";
    import {mapState} from 'vuex';
    import ActService from "../_services/ActService";

    export default {
        name: 'ActUpdatePage',
        components: {ActForm},
        props: {
            id: String,
            nextAct: Number,
            prevAct: Number,
            inPopup: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                act: null,
                page: null,
                loading: true,
                reminderDialogVisible: false
            }
        },
        methods: {
            fetch() {
                this.fetchAct();
            },
            fetchAct() {
                ActService.getForm(this.id).then(data => {
                    this.page = data;
                    this.act = data.act;
                    this.loading = false;
                }, error => {
                    this.flashError(this.$helper.showError(error));
                });
            },
            deleteItem(id) {
                ActService.delete(id).then(() => {
                    this.$emit('closeAndUpdate')
                });
            },
            onRemindAdd() {
                this.reminderDialogVisible = false;
                this.flashSuccess(`Напоминание добавлено`);
            },
            onRecallSelected() {
                this.reminderDialogVisible = true
            },
            changeEdited(id) {
                this.$emit("changeEdited", id);
            },
        },
        computed: {
            ...mapState(['user'])
        },
        created() {
            this.fetch();
        }
    };
</script>
