var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    ref: "dialog",
    attrs: {
      "return-value": _vm.value,
      "persistent": "",
      "width": "400px"
    },
    on: {
      "update:returnValue": function updateReturnValue($event) {
        _vm.value = $event;
      },
      "update:return-value": function updateReturnValue($event) {
        _vm.value = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "disabled": _vm.disabled,
            "label": _vm.label,
            "prepend-icon": "mdi-calendar-month-outline",
            "readonly": "",
            "clearable": _vm.clearable
          },
          model: {
            value: _vm.value,
            callback: function callback($$v) {
              _vm.value = $$v;
            },
            expression: "value"
          }
        }, on))];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "type": "date",
      "scrollable": "",
      "full-width": "",
      "range": _vm.range,
      "first-day-of-week": "1"
    },
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v(" Отмена ")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.dialog.save(_vm.model);
      }
    }
  }, [_vm._v(" OK ")])], 1)], 1), _vm.form && _vm.form.errors.has(_vm.fieldPath) ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get(_vm.fieldPath)) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }