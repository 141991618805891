<template>
    <div class="fill-height">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "AuthLayout",
        data() {
            return {}
        }
    }
</script>
