var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-content', {
    staticClass: "fill-height"
  }, [_c('v-container', {
    staticClass: "fill-height",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "sm": "12"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.appLogoUrl,
      "width": "220"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4",
      "sm": "8"
    }
  }, [_c('v-card', {
    staticClass: "elevation-12"
  }, [_c('v-card-text', {
    staticClass: "pa-6"
  }, [_c('v-form', [_c('v-text-field', {
    attrs: {
      "label": "Email",
      "name": "username",
      "prepend-icon": "mdi-account",
      "type": "text"
    },
    model: {
      value: _vm.username,
      callback: function callback($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Пароль",
      "name": "password",
      "prepend-icon": "mdi-lock",
      "type": "password"
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1), _c('v-alert', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.error,
      expression: "error"
    }],
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error"
    }
  }, [_vm._v(_vm._s(_vm.error))])], 1), _c('v-card-actions', {
    staticClass: "pa-6 pt-0"
  }, [_c('v-spacer'), _c('v-progress-circular', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "color": "primary",
      "indeterminate": ""
    }
  }), _c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading,
      expression: "!loading"
    }],
    attrs: {
      "color": "primary",
      "large": ""
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v("Войти")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }