<template>
    <div>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader type="list-item-avatar-two-line" class="mx-auto"></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-card v-if="!loading">
            <vuetify-audio :file="filePath" color="success" autoPlay downloadable></vuetify-audio>
        </v-card>
    </div>
</template>

<script>
   // import ContractCallService from "../../_services/ContractCallService";

    export default {
        name: "PlayRecord",
        components:{
            VuetifyAudio: () => import('vuetify-audio'),
        },
        props: {
            recordingId: String
        },
        data() {
            return {
                path: null,
                loading: false
            }
        },
        watch: {
            recordingId: {
                handler() {
                    this.fetch();
                }
            }
        },
        computed: {
            filePath: function() {
                console.log(`${process.env.VUE_APP_API_URL}/contractCalls/records/play/${this.recordingId}`);
                return  `${process.env.VUE_APP_API_URL}/contractCalls/records/play/${this.recordingId}?jwt=${this.$helper.ls.get('user').token}`;
            },
        },
        methods: {
            fetch() {
                // this.loading = true
                // ContractCallService.getRecord(this.recordingId).then(fileName => {
                //     this.path = `${process.env.VUE_APP_API_URL}/calls/records/play/${fileName}?jwt=${this.$helper.ls.get('user').token}`;
                //     setTimeout(() => {
                //         this.loading = false;
                //     }, 500)
                // });
            }
        },
        created() {
            this.fetch();
        }
    }
</script>

<style scoped>

</style>
