<template>
    <v-list v-if="signatures.length">
        <div
                v-for="signature in signatures"
                :key="signature.id">
            <v-list-item-content>
                Подписано - {{ signature.user.fullName }} - {{ $moment(signature.createdAt).dateTimeFormat() }}
            </v-list-item-content>
        </div>
    </v-list>
</template>

<script>
    export default {
        props: {
            signatures: Array,
        },
        data() {
            return {}
        },
        methods: {}
    };
</script>
