<template>
    <div>
        <v-dialog
            ref="dialog"
            v-model="dialog"
            :return-value.sync="value"
            persistent
            width="400px">
            <template v-slot:activator="{ on }">
                <v-text-field
                    :disabled="disabled"
                    v-model="value"
                    :label="label"
                    prepend-icon="mdi-calendar-month-outline"
                    readonly
                    :clearable="clearable"
                    v-on="on"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="model"
                type="date"
                scrollable
                full-width
                :range="range"
                first-day-of-week="1">
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="dialog = false">
                    Отмена
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(model)">
                    OK
                </v-btn>
            </v-date-picker>
        </v-dialog>
        <v-alert
            v-if="form && form.errors.has(fieldPath)"
            dense
            outlined
            type="error"
            :icon="false">
            {{ form.errors.get(fieldPath) }}
        </v-alert>
    </div>
</template>

<script>
export default {
    name: "DateField",
    props: {
        form: Object,
        value: Array,
        disabled: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        range: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: "Дата"
        },
        fieldPath: String
    },
    data: function () {
        return {
            dialog: false,
            model: null,
        }
    },
    watch: {
        value() {
            this.$emit('input', this.value);
        }
    }
}
</script>
