var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.title ? _c('v-card-title', [_vm._v(" База заявок "), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.forceUpdate
    }
  }, [_c('v-icon', [_vm._v("mdi-reload")])], 1), _c('v-spacer'), _c('div', [_c('v-btn', {
    attrs: {
      "large": "",
      "color": "green",
      "to": "/requests/create"
    }
  }, [_vm._v(" Добавить заявку ")])], 1)], 1) : _vm._e(), _vm.title ? _c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " ")]) : _vm._e(), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.requests,
      "options": _vm.requestGridsOptions.options,
      "server-items-length": _vm.totalElements,
      "show-select": "",
      "item-key": "id",
      "loading": _vm.loading,
      "loading-text": "Загрузка ...",
      "footer-props": {
        'items-per-page-options': _vm.itemPerPage
      },
      "fixed-header": _vm.fixedHeader,
      "height": _vm.tableHeight
    },
    on: {
      "update:options": function updateOptions($event) {
        return _vm.$set(_vm.requestGridsOptions, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var items = _ref.items;
        return [_c('tbody', [!_vm.filter ? _c('tr', {
          staticClass: "row_sticky"
        }, [_c('td', {
          staticClass: "px-2",
          attrs: {
            "width": "20"
          }
        }), _c('td', {
          staticClass: "px-2",
          attrs: {
            "width": "20"
          }
        }), _c('td', {
          attrs: {
            "width": "60"
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.requestGridsOptions.filter.id,
            callback: function callback($$v) {
              _vm.$set(_vm.requestGridsOptions.filter, "id", $$v);
            },
            expression: "requestGridsOptions.filter.id"
          }
        })], 1), _c('td', {
          attrs: {
            "width": "80"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.states,
            "multiple": "",
            "clearable": ""
          },
          model: {
            value: _vm.requestGridsOptions.filter.states,
            callback: function callback($$v) {
              _vm.$set(_vm.requestGridsOptions.filter, "states", $$v);
            },
            expression: "requestGridsOptions.filter.states"
          }
        })], 1), _vm.isVisibleColumn('source') ? _c('td', {
          attrs: {
            "width": "180"
          }
        }, [_c('v-select', {
          attrs: {
            "item-value": "id",
            "multiple": "",
            "item-text": "name",
            "items": _vm.sources,
            "clearable": ""
          },
          model: {
            value: _vm.requestGridsOptions.filter.sources,
            callback: function callback($$v) {
              _vm.$set(_vm.requestGridsOptions.filter, "sources", $$v);
            },
            expression: "requestGridsOptions.filter.sources"
          }
        })], 1) : _vm._e(), _c('td', {
          attrs: {
            "width": "120"
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.requestGridsOptions.filter.cleanPhone,
            callback: function callback($$v) {
              _vm.$set(_vm.requestGridsOptions.filter, "cleanPhone", $$v);
            },
            expression: "requestGridsOptions.filter.cleanPhone"
          }
        })], 1), _vm.isVisibleColumn('createdAt') ? _c('td', {
          attrs: {
            "width": "120"
          }
        }, [_c('v-dialog', {
          ref: "dialog",
          attrs: {
            "return-value": _vm.requestGridsOptions.filter.createdAtDates,
            "persistent": "",
            "width": "290px"
          },
          on: {
            "update:returnValue": function updateReturnValue($event) {
              return _vm.$set(_vm.requestGridsOptions.filter, "createdAtDates", $event);
            },
            "update:return-value": function updateReturnValue($event) {
              return _vm.$set(_vm.requestGridsOptions.filter, "createdAtDates", $event);
            }
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('v-text-field', _vm._g({
                attrs: {
                  "prepend-icon": "mdi-calendar-month-outline",
                  "readonly": "",
                  "clearable": ""
                },
                model: {
                  value: _vm.requestGridsOptions.filter.createdAtDates,
                  callback: function callback($$v) {
                    _vm.$set(_vm.requestGridsOptions.filter, "createdAtDates", $$v);
                  },
                  expression: "requestGridsOptions.filter.createdAtDates"
                }
              }, on))];
            }
          }], null, true),
          model: {
            value: _vm.dialog.createdAt.modal,
            callback: function callback($$v) {
              _vm.$set(_vm.dialog.createdAt, "modal", $$v);
            },
            expression: "dialog.createdAt.modal"
          }
        }, [_c('v-date-picker', {
          attrs: {
            "type": "date",
            "scrollable": "",
            "range": "",
            "first-day-of-week": "1"
          },
          model: {
            value: _vm.dialog.createdAt.model,
            callback: function callback($$v) {
              _vm.$set(_vm.dialog.createdAt, "model", $$v);
            },
            expression: "dialog.createdAt.model"
          }
        }, [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.dialog.createdAt.modal = false;
            }
          }
        }, [_vm._v("Отмена")]), _c('v-btn', {
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.$refs.dialog.save(_vm.dialog.createdAt.model);
            }
          }
        }, [_vm._v(" Выбрать ")])], 1)], 1)], 1) : _vm._e(), _vm.isVisibleColumn('assignedAt') ? _c('td', {
          attrs: {
            "width": "120"
          }
        }, [_c('v-dialog', {
          ref: "dialog2",
          attrs: {
            "return-value": _vm.requestGridsOptions.filter.assignedAtDates,
            "persistent": "",
            "width": "290px"
          },
          on: {
            "update:returnValue": function updateReturnValue($event) {
              return _vm.$set(_vm.requestGridsOptions.filter, "assignedAtDates", $event);
            },
            "update:return-value": function updateReturnValue($event) {
              return _vm.$set(_vm.requestGridsOptions.filter, "assignedAtDates", $event);
            }
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on;
              return [_c('v-text-field', _vm._g({
                attrs: {
                  "prepend-icon": "mdi-calendar-month-outline",
                  "readonly": "",
                  "clearable": ""
                },
                model: {
                  value: _vm.requestGridsOptions.filter.assignedAtDates,
                  callback: function callback($$v) {
                    _vm.$set(_vm.requestGridsOptions.filter, "assignedAtDates", $$v);
                  },
                  expression: "requestGridsOptions.filter.assignedAtDates"
                }
              }, on))];
            }
          }], null, true),
          model: {
            value: _vm.dialog.assignedAt.modal,
            callback: function callback($$v) {
              _vm.$set(_vm.dialog.assignedAt, "modal", $$v);
            },
            expression: "dialog.assignedAt.modal"
          }
        }, [_c('v-date-picker', {
          attrs: {
            "type": "date",
            "scrollable": "",
            "range": "",
            "first-day-of-week": "1"
          },
          model: {
            value: _vm.dialog.assignedAt.model,
            callback: function callback($$v) {
              _vm.$set(_vm.dialog.assignedAt, "model", $$v);
            },
            expression: "dialog.assignedAt.model"
          }
        }, [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.dialog.assignedAt.modal = false;
            }
          }
        }, [_vm._v("Отмена")]), _c('v-btn', {
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.$refs.dialog2.save(_vm.dialog.assignedAt.model);
            }
          }
        }, [_vm._v(" Выбрать ")])], 1)], 1)], 1) : _vm._e(), _vm.isVisibleColumn('operator') ? _c('td', {
          attrs: {
            "width": "160"
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.operators,
            "clearable": "",
            "item-value": "id",
            "item-text": "fullName"
          },
          model: {
            value: _vm.requestGridsOptions.filter.operators,
            callback: function callback($$v) {
              _vm.$set(_vm.requestGridsOptions.filter, "operators", $$v);
            },
            expression: "requestGridsOptions.filter.operators"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('userName') ? _c('td', {
          attrs: {
            "width": "160"
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.requestGridsOptions.filter.userName,
            callback: function callback($$v) {
              _vm.$set(_vm.requestGridsOptions.filter, "userName", $$v);
            },
            expression: "requestGridsOptions.filter.userName"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('counterpart') ? _c('td', {
          attrs: {
            "width": "160"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.counterparts,
            "clearable": "",
            "item-value": "id",
            "item-text": "fullName"
          },
          model: {
            value: _vm.requestGridsOptions.filter.counterpart,
            callback: function callback($$v) {
              _vm.$set(_vm.requestGridsOptions.filter, "counterpart", $$v);
            },
            expression: "requestGridsOptions.filter.counterpart"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('city') ? _c('td', {
          attrs: {
            "width": "100"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.cities,
            "clearable": "",
            "item-value": "id",
            "item-text": "name"
          },
          model: {
            value: _vm.requestGridsOptions.filter.city,
            callback: function callback($$v) {
              _vm.$set(_vm.requestGridsOptions.filter, "city", $$v);
            },
            expression: "requestGridsOptions.filter.city"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('subdivision') ? _c('td', {
          attrs: {
            "width": "160"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.subdivisions,
            "clearable": "",
            "item-value": "id",
            "item-text": "name"
          },
          model: {
            value: _vm.requestGridsOptions.filter.subdivision,
            callback: function callback($$v) {
              _vm.$set(_vm.requestGridsOptions.filter, "subdivision", $$v);
            },
            expression: "requestGridsOptions.filter.subdivision"
          }
        })], 1) : _vm._e()]) : _vm._e(), _vm._l(items, function (item, key) {
          return _c('tr', {
            key: item.id,
            class: {
              'grey darken-3': _vm.isHighlighted(item),
              'lightgrey': _vm.isRequestViewed(item.id)
            }
          }, [_c('td', {
            staticClass: "pr-0"
          }, [_vm.$helper.permission.canDeleteRequest(item, _vm.user) ? _c('v-checkbox', {
            staticClass: "ma-0",
            attrs: {
              "value": item,
              "hide-details": ""
            },
            model: {
              value: _vm.selected,
              callback: function callback($$v) {
                _vm.selected = $$v;
              },
              expression: "selected"
            }
          }) : _vm._e()], 1), _c('td', {
            staticClass: "px-2"
          }, [_vm.$helper.permission.canDeleteRequest(item, _vm.user) ? _c('v-dialog', {
            attrs: {
              "max-width": "320"
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function fn(_ref4) {
                var on = _ref4.on;
                return [_c('v-icon', _vm._g({
                  attrs: {
                    "small": "",
                    "color": "red"
                  }
                }, on), [_vm._v("mdi-delete")])];
              }
            }], null, true),
            model: {
              value: _vm.deleteDialog[item.id],
              callback: function callback($$v) {
                _vm.$set(_vm.deleteDialog, item.id, $$v);
              },
              expression: "deleteDialog[item.id]"
            }
          }, [_c('v-card', [_c('v-card-text', {
            staticClass: "text-center pt-7"
          }, [_vm._v("Удалить выбранный элемент?")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
            attrs: {
              "text": ""
            },
            on: {
              "click": function click($event) {
                _vm.deleteDialog[item.id] = false;
              }
            }
          }, [_vm._v("Отмена")]), _c('v-btn', {
            attrs: {
              "color": "red",
              "text": ""
            },
            on: {
              "click": function click($event) {
                return _vm.deleteItem(item.id);
              }
            }
          }, [_vm._v("Удалить")])], 1)], 1)], 1) : _vm._e()], 1), _c('td', [_c('a', {
            on: {
              "click": function click($event) {
                return _vm.editRequest(item.id, items[key - 1], items[key + 1]);
              }
            }
          }, [_vm._v(_vm._s(item.id))]), item.isPublishedOnSite ? _c('span', {
            staticStyle: {
              "position": "relative"
            }
          }, [_c('v-icon', {
            staticStyle: {
              "position": "absolute",
              "left": "10px"
            },
            attrs: {
              "small": "",
              "color": "green",
              "title": "Опубликован на сайте"
            }
          }, [_vm._v("mdi-share-outline")])], 1) : _vm._e()]), _c('td', [_c('v-chip', {
            attrs: {
              "color": item.state.chipColor,
              "outlined": "",
              "dark": ""
            }
          }, [item.appointmentApproved ? _c('v-icon', {
            attrs: {
              "color": "green",
              "small": "",
              "left": ""
            }
          }, [_vm._v("mdi-check")]) : _vm._e(), item.callState === 'APPEARED' ? _c('v-icon', {
            attrs: {
              "color": "blue",
              "small": "",
              "left": ""
            }
          }, [_vm._v("mdi-phone-forward")]) : _vm._e(), item.callState === 'CONNECTED' ? _c('v-icon', {
            attrs: {
              "color": "green",
              "small": "",
              "left": ""
            }
          }, [_vm._v("mdi-phone-check")]) : _vm._e(), item.callState === 'ON_HOLD' ? _c('v-icon', {
            attrs: {
              "color": "orange",
              "small": "",
              "left": ""
            }
          }, [_vm._v("mdi-phone-lock")]) : _vm._e(), item.callState === 'MISSED' ? _c('v-icon', {
            attrs: {
              "color": "red",
              "small": "",
              "left": ""
            }
          }, [_vm._v("mdi-phone-missed")]) : _vm._e(), _vm._v(" " + _vm._s(item.state.text) + " "), _vm.isOnline(item) ? _c('v-icon', {
            staticClass: "ml-2",
            attrs: {
              "small": "",
              "left": ""
            }
          }, [_vm._v("mdi-laptop")]) : _vm._e()], 1)], 1), _vm.isVisibleColumn('source') ? _c('td', [item.source ? _c('div', [_vm._v(" " + _vm._s(item.source.name) + " "), item.isJivo === true ? _c('span', [_vm._v("(Jivo)")]) : _vm._e(), item.additionalInfo ? _c('span', [_vm._v(" (" + _vm._s(item.additionalInfo) + ")")]) : _vm._e()]) : _vm._e()]) : _vm._e(), _c('td', [_vm._v(" " + _vm._s(item.userPhone) + " "), item.isDouble === true && _vm.$helper.permission.canAssignRequest(_vm.user) ? _c('span', {
            staticStyle: {
              "padding-left": "10px"
            }
          }, [_c('v-icon', {
            attrs: {
              "color": "yellow"
            }
          }, [_vm._v("mdi-repeat-once")])], 1) : _vm._e(), item.additionalUserPhone ? _c('div', [_vm._v(" " + _vm._s(item.additionalUserPhone) + " ")]) : _vm._e()]), _vm.isVisibleColumn('createdAt') ? _c('td', [_vm._v(_vm._s(_vm.$moment(item.createdAt).dateTimeFormat()))]) : _vm._e(), _vm.isVisibleColumn('assignedAt') ? _c('td', [_vm._v(_vm._s(_vm.$moment(item.assignedAt).dateTimeFormat()))]) : _vm._e(), _vm.isVisibleColumn('operator') ? _c('td', [item.operator ? _c('div', [_vm._v(_vm._s(item.operator.fullName))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('userName') ? _c('td', [item.userName ? _c('div', [_vm._v(_vm._s(item.userName))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('counterpart') ? _c('td', [item.counterpart ? _c('div', [_vm._v(_vm._s(item.counterpart.fullName))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('city') ? _c('td', [item.city ? _c('div', [_vm._v(_vm._s(item.city.name))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('subdivision') ? _c('td', [item.subdivision ? _c('div', [_vm._v(_vm._s(item.subdivision.name))]) : _vm._e()]) : _vm._e()]);
        })], 2)];
      }
    }]),
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }), _c('div', {
    staticClass: "table-footer-prepend d-flex pl-3 align-center"
  }, [_vm.selected.length ? _c('div', {
    staticClass: "table-action"
  }, [_vm.$helper.permission.canDeleteBulkRequest(_vm.user) ? _c('v-dialog', {
    attrs: {
      "max-width": "320"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref5) {
        var on = _ref5.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "color": "error",
            "outlined": "",
            "small": ""
          }
        }, on), [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "x-small": ""
          }
        }, [_vm._v("mdi-delete")]), _vm._v(" Удалить выбранные (" + _vm._s(_vm.selected.length) + ") ")], 1)];
      }
    }], null, false, 1110457266),
    model: {
      value: _vm.deleteBulkDialog,
      callback: function callback($$v) {
        _vm.deleteBulkDialog = $$v;
      },
      expression: "deleteBulkDialog"
    }
  }, [_c('v-card', [_c('v-card-text', {
    staticClass: "text-center pt-7"
  }, [_vm._v("Удалить выбранные элементы?")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.deleteBulkDialog = false;
      }
    }
  }, [_vm._v("Отмена")]), _c('v-btn', {
    attrs: {
      "color": "red",
      "text": ""
    },
    on: {
      "click": _vm.deleteSelected
    }
  }, [_vm._v("Удалить")])], 1)], 1)], 1) : _vm._e(), _c('v-btn', {
    staticClass: "ml-3",
    attrs: {
      "color": "primary",
      "outlined": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        _vm.selected = [];
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("mdi-cancel")]), _vm._v(" Отменить выбор ")], 1)], 1) : _vm._e()]), _c('v-bottom-sheet', {
    attrs: {
      "fullscreen": "",
      "scrollable": "",
      "persistent": "",
      "retain-focus": false
    },
    model: {
      value: _vm.dialog.requestEdit.modal,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog.requestEdit, "modal", $$v);
      },
      expression: "dialog.requestEdit.modal"
    }
  }, [_c('v-card', {
    staticStyle: {
      "background": "none"
    }
  }, [_c('v-card-text', {
    staticStyle: {
      "height": "100vh"
    }
  }, [_vm.editedRequest ? _c('RequestUpdatePage', {
    key: _vm.editedRequestKey,
    attrs: {
      "id": _vm.editedRequest,
      "nextRequest": _vm.nextRequest,
      "prevRequest": _vm.prevRequest
    },
    on: {
      "changeEdited": _vm.changeEdited,
      "closeAndUpdate": _vm.closeEditAndFetch,
      "updated": _vm.forceUpdate,
      "fetchGrid": _vm.fetchGrid,
      "closeEdit": _vm.closeEdit
    }
  }) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }