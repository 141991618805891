<template>
    <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on }">
            <v-btn color="green" v-on="on">
                Добавить уведомление
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Добавить уведомление</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-select
                                label="Тип уведомления"
                                v-model="form.type"
                                :items="types"
                                :clearable="true">
                        </v-select>
                        <v-alert v-if="form.errors.has('type')" dense outlined type="error" :icon="false">
                            {{form.errors.get('type')}}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea auto-grow v-model="form.description" label="Сообщение"></v-textarea>
                        <v-alert v-if="form.errors.has('description')" dense outlined type="error" :icon="false">
                            {{form.errors.get('description')}}
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions v-show="!form.busy">
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">Отмена</v-btn>
                <v-btn color="blue darken-1" text @click="save">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Form from "../_helpers/_form/Form";
    import NotificationService from "../_services/NotificationService";

    export default {
        props: {
            id: Number,
            type: String,
            types: Array,
        },
        data() {
            return {
                form: new Form({
                    notificationableId: this.id,
                    notificationableType: this.type
                }),
                dialog: false
            }
        },
        methods: {
            save() {
                NotificationService.create(this.form).then(() => {
                    this.dialog = false;
                    this.$emit('saved');
                });
            },
        }
    };
</script>
