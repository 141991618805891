<template>
    <div>
        <v-card-title>
            Создание подразделения
            <div class="ml-5">
                <v-btn small color="primary" class="mx-4" outlined to="/subdivisions">Все подразедления</v-btn>
            </div>
        </v-card-title>
        <SubdivisionForm></SubdivisionForm>
    </div>
</template>

<script>
    import SubdivisionForm from "./SubdivisionForm";

    export default {
        name: 'SubdivisionCreatePage',
        components: {SubdivisionForm},
    };
</script>
