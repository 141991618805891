<template>
    <div style="overflow-x: scroll">
        <v-card-title v-if="!title">
            База клиентов
            <v-btn icon @click="forceUpdate">
                <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <div>
                <v-btn v-if="$helper.permission.canAssignClient(user)" large color="green" to="/clients/create">
                    Добавить клиента
                </v-btn>
            </div>
        </v-card-title>
        <v-card-title v-if="title">
            {{ title }}
        </v-card-title>
        <v-data-table
            style="min-width: 2800px"
            class="elevation-1"
            :headers="headers"
            :items="clients"
            :options.sync="clientGridsOptions.options"
            :server-items-length="totalElements"
            v-model="selected"
            item-key="id"
            :loading="loading"
            loading-text="Загрузка ..."
            :footer-props="{'items-per-page-options': itemPerPage}"
            :fixed-header="fixedHeader"
            :height="tableHeight">
            <template v-slot:body="{ items }">
                <tbody>
                <tr v-if="!filter" class="row_sticky">
                    <td width="20" class="px-2"></td>
                    <td width="30" v-if="isVisibleColumn('inFavorites')" class="pr-0">
                        <v-checkbox
                            v-model="clientGridsOptions.filter['usersAddToFavorites[0].id']"
                            :value="user.id"
                            hide-details class="ma-0"/>
                    </td>
                    <td width="80">
                        <v-text-field v-model="clientGridsOptions.filter.id" type="text"></v-text-field>
                    </td>
                    <td v-if="isVisibleColumn('userName')" width="160">
                        <v-text-field
                            v-model="clientGridsOptions.filter.userName"
                            type="text">
                        </v-text-field>
                    </td>
                    <td v-if="isVisibleColumn('fromSource')" width="190">
                        <v-text-field
                            v-model="clientGridsOptions.filter.fromSource"
                            type="text"
                        ></v-text-field>
                    </td>
                    <td v-if="isVisibleColumn('principal')" width="160">
                        <v-text-field v-model="clientGridsOptions.filter.principal" type="text"></v-text-field>
                    </td>
                    <td width="100">
                        <v-select
                            v-model="clientGridsOptions.filter.isOnline"
                            :items="checkboxValues"
                            clearable
                            item-value="value"
                            item-text="text"
                        ></v-select>
                    </td>
                    <td width="120">
                        <v-select
                            v-model="clientGridsOptions.filter.isDouble"
                            :items="checkboxValues"
                            clearable
                            item-value="value"
                            item-text="text"
                        ></v-select>
                    </td>
                    <td width="100">
                        <v-select
                            v-model="clientGridsOptions.filter.states"
                            multiple
                            :items="states"
                            clearable>
                        </v-select>
                    </td>
                    <td
                        v-if="isVisibleColumn('callbackDate')"
                        width="190">
                        <DateField
                            label=""
                            range
                            :clearable="true"
                            v-model="clientGridsOptions.filter.callbackDates"
                        ></DateField>
                    </td>
                    <td width="190">
                        <v-text-field v-model="clientGridsOptions.filter.cleanPhone" type="text"></v-text-field>
                    </td>
                    <td width="180">
                        <v-dialog
                            ref="dialog"
                            v-model="dialog.createdAt.modal"
                            :return-value.sync="clientGridsOptions.filter.createdAtDates"
                            persistent width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="clientGridsOptions.filter.createdAtDates"
                                    prepend-icon="mdi-calendar-month-outline"
                                    readonly
                                    clearable
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="dialog.createdAt.model"
                                type="date"
                                scrollable
                                range
                                first-day-of-week="1">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="dialog.createdAt.modal = false">Отмена</v-btn>
                                <v-btn text color="primary" @click="$refs.dialog.save(dialog.createdAt.model)">
                                    Выбрать
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </td>
                    <td width="190">
                        <v-dialog
                            ref="dialog2"
                            v-model="dialog.assignedAt.modal"
                            :return-value.sync="clientGridsOptions.filter.assignedAtDate"
                            persistent width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="clientGridsOptions.filter.assignedAtDate"
                                    prepend-icon="mdi-calendar-month-outline"
                                    readonly
                                    clearable
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="dialog.assignedAt.model"
                                type="date"
                                scrollable
                                first-day-of-week="1">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="dialog.assignedAt.modal = false">Отмена</v-btn>
                                <v-btn text color="primary" @click="$refs.dialog2.save(dialog.assignedAt.model)">
                                    Выбрать
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </td>
                    <td width="160">
                        <v-dialog
                            ref="dialog3"
                            v-model="dialog.lastVisitAt.modal"
                            :return-value.sync="clientGridsOptions.filter.lastVisitAtDate"
                            persistent width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="clientGridsOptions.filter.lastVisitAtDate"
                                    prepend-icon="mdi-calendar-month-outline"
                                    readonly
                                    clearable
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="dialog.lastVisitAt.model"
                                type="date"
                                scrollable
                                first-day-of-week="1">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="dialog.lastVisitAt.modal = false">Отмена</v-btn>
                                <v-btn text color="primary" @click="$refs.dialog3.save(dialog.lastVisitAt.model)">
                                    Выбрать
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </td>
                    <td v-if="isVisibleColumn('lawyer')" width="160">
                        <v-select v-model="clientGridsOptions.filter.lawyers"
                                  multiple
                                  :items="lawyers"
                                  clearable
                                  item-value="id"
                                  item-text="fullName">
                        </v-select>
                    </td>
                    <td v-if="isVisibleColumn('additionalLawyer')" width="160">
                        <v-select
                            v-model="clientGridsOptions.filter.additionalLawyers"
                            multiple
                            :items="lawyers"
                            clearable
                            item-value="id"
                            item-text="fullName">
                        </v-select>
                    </td>
                    <td v-if="isVisibleColumn('lawyerAssistant')" width="160">
                        <v-select
                            v-model="clientGridsOptions.filter.lawyerAssistant"
                            :items="lawyers"
                            clearable
                            item-value="id"
                            item-text="fullName">
                        </v-select>
                    </td>
                    <td v-if="isVisibleColumn('subdivision')" width="160">
                        <v-select v-model="clientGridsOptions.filter.subdivision" :items="subdivisions" clearable
                                  item-value="id"
                                  item-text="name"></v-select>
                    </td>
                    <td v-if="isVisibleColumn('comment')" width="160"></td>
                    <td v-if="isVisibleColumn('area')" width="160">
                        <v-select
                            v-model="clientGridsOptions.filter.area"
                            :items="areas"
                            clearable
                            item-value="id"
                            item-text="name"
                        ></v-select>
                    </td>
                    <td v-if="isVisibleColumn('office')" width="100">
                        <v-select v-model="clientGridsOptions.filter.office" :items="offices" clearable
                                  item-value="id"
                                  item-text="name"></v-select>
                    </td>
                    <td v-if="isVisibleColumn('reportComment')" width="220"></td>
                </tr>
                <tr v-for="(item, key) in items" :key="item.id" v-bind:class="{'grey darken-3': isHighlighted(item)}">
                    <td class="pl-5 pr-3">
                        <v-dialog
                            v-model="deleteDialog[item.id]"
                            max-width="320"
                            v-if="$helper.permission.canDeleteClient(item, user)">
                            <template v-slot:activator="{ on }">
                                <v-icon small color="red" v-on="on">mdi-delete</v-icon>
                            </template>
                            <v-card>
                                <v-card-text class="text-center pt-7">Удалить выбранный элемент?</v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="deleteDialog[item.id] = false">Отмена</v-btn>
                                    <v-btn color="red" text @click="deleteItem(item.id)">Удалить</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </td>
                    <td v-if="isVisibleColumn('inFavorites')">
                        <v-icon
                            v-if="item.inFavorites"
                            @click="removeFromFavorites(item)"
                            color="yellow"
                            class="ml-1">
                            mdi-star
                        </v-icon>
                        <v-icon
                            v-if="!item.inFavorites"
                            @click="addToFavorites(item)"
                            color="grey"
                            class="ml-1">
                            mdi-star-outline
                        </v-icon>
                    </td>
                    <td>
                        <a @click="editClient(item.id, items[key-1], items[key + 1])">{{ item.id }}</a>
                    </td>
                    <td v-if="isVisibleColumn('userName')">
                        <div v-if="item.userName">{{ item.userName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('fromSource')">
                        {{ item.fromSource }}
                    </td>
                    <td v-if="isVisibleColumn('principal')">
                        <div v-if="item.principal">{{ item.principal }}</div>
                    </td>
                    <td>
                        <v-chip v-if="item.isOnline" color="yellow" outlined dark>Онлайн</v-chip>
                    </td>
                    <td>
                        <v-chip v-if="item.isDouble" color="deep-orange" outlined dark>Вторичный</v-chip>
                    </td>
                    <td>
                        <v-chip :color="item.state.chipColor" outlined dark>
                            {{ item.state.text }}
                        </v-chip>
                    </td>
                    <td v-if="isVisibleColumn('callbackDate')">
                        {{ $moment(item.callbackDate).dateTimeFormat() }}
                    </td>
                    <td>
                        <a :href="`tel:${item.userPhone}`">{{ item.userPhone }}</a>
                        <span v-if="item.isDouble === true && 1 === 0" style="padding-left: 10px">
                            <v-icon>mdi-repeat-once</v-icon>
                        </span>
                    </td>
                    <td>{{ $moment(item.createdAt).dateTimeFormat() }}</td>
                    <td>{{ $moment(item.assignedAt).dateTimeFormat() }}</td>
                    <td>{{ $moment(item.lastVisitAt).dateTimeFormat() }}</td>
                    <td v-if="isVisibleColumn('lawyer')">
                        <div v-if="item.lawyer">{{ item.lawyer.fullName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('additionalLawyer')">
                        <div v-if="item.additionalLawyer">{{ item.additionalLawyer.fullName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('lawyerAssistant')">
                        <div v-if="item.lawyerAssistant">{{ item.lawyerAssistant.fullName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('subdivision')">
                        <div v-if="item.subdivision">{{ item.subdivision.name }}</div>
                    </td>
                    <td v-if="isVisibleColumn('comment')">
                        <div v-if="item.comment">{{ item.comment }}</div>
                    </td>
                    <td v-if="isVisibleColumn('area')">
                        <div v-if="item.area">{{ item.area.name }}</div>
                    </td>
                    <td v-if="isVisibleColumn('office')">
                        <div v-if="item.office && item.office.name">{{ item.office.name }}</div>
                    </td>
                    <td v-if="isVisibleColumn('reportComment')">
                        <div v-if="item.reportComment">{{ item.reportComment }}</div>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-data-table>

        <v-bottom-sheet
            v-model="dialog.clientEdit.modal"
            fullscreen
            scrollable
            persistent
            :retain-focus="false">
            <v-card style="background: none">
                <v-card-text style="height: 100vh;">
                    <ClientUpdatePage
                        v-if="editedClient"
                        :id="editedClient"
                        :inPopup="true"
                        :nextClient="nextClient"
                        :prevClient="prevClient"
                        :key="editedClientKey"
                        @changeEdited="changeEdited"
                        @closeAndUpdate="closeEditAndFetch"
                        @updated="forceUpdate"
                        @closeEdit="closeEdit"></ClientUpdatePage>
                </v-card-text>
            </v-card>
        </v-bottom-sheet>
    </div>
</template>

<script>
import ClientService from "../_services/ClientService";
import {mapState} from 'vuex';
import _ from "lodash";
import ClientUpdatePage from "./ClientUpdatePage";
import DateField from "@/_chunks/DateField.vue";

export default {
    name: 'ClientGrid',
    components: {DateField, ClientUpdatePage},
    props: {
        filter: Object,
        title: String,
        exclude: Array
    },
    data: function () {
        return {
            clients: [],
            states: [],
            areas: [],
            checkboxValues: [
                {value: true, text: 'Да'},
                {value: false, text: 'Нет'}
            ],
            lawyers: [],
            subdivisions: [],
            offices: [],
            selected: [],
            loading: true,
            totalElements: 0,
            dialog: {
                createdAt: {
                    model: [],
                    modal: false
                },
                assignedAt: {
                    model: null,
                    modal: false
                },
                lastVisitAt: {
                    model: null,
                    modal: false
                },
                clientEdit: {
                    modal: false
                }
            },
            deleteDialog: {},
            deleteBulkDialog: false,
            tableHeight: !this.filter ? this.$helper.calcTableHeightWithHorizontalScroll() : null,
            fixedHeader: !this.filter,
            clientGridsOptions: !this.filter ? this.$store.state.clientGridsOptions :
                {
                    options: {
                        sortBy: ['id'],
                        sortDesc: [true]
                    },
                    filter: this.filter
                },
            editedClient: null,
            nextClient: null,
            prevClient: null,
            editedClientKey: 0,
            searchTimeout: 0,
            searchDebounceTime: 500
        }
    },
    watch: {
        clientGridsOptions: {
            handler(options) {
                clearTimeout(this.searchTimeout);
                if (options.filter?.userName) { // for 'userName' contains matcher is used, slow down the user input
                    this.searchTimeout = window.setTimeout(() => {
                        this.fetchGrid();
                    }, this.searchDebounceTime);
                } else {
                    this.fetchGrid();
                }
            },
            deep: true
        }
    },
    methods: {
        fetch() {

        },
        forceUpdate() {
            this.fetchGrid();
            this.fetch();
        },
        fetchGrid() {
            this.loading = true;
            ClientService.getAll(this.clientGridsOptions.options, this.clientGridsOptions.filter).then(data => {
                if (this.exclude) {
                    data.page.content = data.page.content.filter(el => this.exclude.indexOf(el.id) === -1);
                }
                this.clients = data.page.content;
                this.totalElements = data.page.totalElements;
                this.areas = data.areas;
                this.subdivisions = data.subdivisions;
                this.offices = data.offices;
                this.lawyers = data.lawyers;
                this.states = data.states;
                this.loading = false;
            });
        },
        deleteSelected() {
            ClientService.deleteBulk(this.selected).then(() => {
                this.fetchGrid();
                this.deleteBulkDialog = false;
                this.selected = [];
            });
        },
        deleteItem(id) {
            ClientService.delete(id).then(() => {
                this.fetchGrid();
            });
        },
        onResize() {
            this.tableHeight = this.$helper.calcTableHeight();
        },
        isVisibleColumn(column) {
            return this.headers.find(el => el.value === column) !== undefined;
        },
        editClient(id, prevItem, nextItem) {
            this.editedClient = id.toString();
            this.prevClient = !_.isUndefined(prevItem) ? prevItem.id : null;
            this.nextClient = !_.isUndefined(nextItem) ? nextItem.id : null;
            this.dialog.clientEdit.modal = true;
        },
        closeEdit() {
            this.dialog.clientEdit.modal = false;
            this.editedClient = null;
            this.editedClientKey++;
        },
        changeEdited(id) {
            this.editedClient = id;
            let clientIndex = this.clients.findIndex(el => {
                return el.id == id;
            });

            let prevItem = this.clients[clientIndex - 1];
            let nextItem = this.clients[clientIndex + 1];

            this.prevClient = !_.isUndefined(prevItem) ? prevItem.id : null;
            this.nextClient = !_.isUndefined(nextItem) ? nextItem.id : null;

            this.editedClientKey++;
        },
        closeEditAndFetch() {
            this.closeEdit();
            this.fetchGrid()
        },
        isHighlighted(item) {
            return item.isNew || _.includes(this.selected, item.id)
        },
        addToFavorites(client) {
            ClientService.addToFavorites(client.id).then(() => {
                client.inFavorites = true;
            });
        },
        removeFromFavorites(client) {
            ClientService.removeFromFavorites(client.id).then(() => {
                client.inFavorites = false;
            });
        }
    },
    computed: {
        itemPerPage: function () {
            return [20, 30, 50, 100];
        },
        headers: function () {
            return this.$helper.clientGridColumns.getForRole(this.user.role);
        },
        ...mapState(['user'])
    },
    created() {
        this.$store.dispatch('loading', false);
        this.fetch();

        this.$store.subscribeAction((action) => {
            if (action.type === 'newClientReceived' && !this.filter) {
                let client = action.payload;
                client.isNew = true;
                this.clients.unshift(action.payload);
            }
        })
    },
    beforeCreate() {
        this.$store.dispatch('loading', true);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    mounted() {
        window.addEventListener('resize', this.onResize)
    }
};
</script>
<style lang="scss">
.v-content {
    padding-top: 0 !important;
}
</style>
