var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.contracts, function (contract, i) {
    return _c('span', {
      key: i
    }, [_c('router-link', {
      attrs: {
        "to": "/contracts/".concat(contract.id, "/edit")
      }
    }, [_vm._v(" #" + _vm._s(contract.number) + " ")])], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }