<template>
  <v-btn
      v-scroll="onScroll"
      v-show="scrollToTopShow"
      fab
      dark
      fixed
      bottom
      right
      :style="style"
      @click="toTop"
  >
    <v-icon>mdi-chevron-up</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ScrollToTop',
  props: {
    elementSelector: {
      type: Function,
      default: () => null,
      style: String
    },
    alwaysShow: Boolean
  },
  data() {
    return {
      scrollToTopShow: this.alwaysShow,
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === 'undefined') return;
      console.log(this.elementSelector());
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.scrollToTopShow = this.alwaysShow ? true : top > 20;
    },
    toTop() {
      const target = this.elementSelector();
      if (target) {
        target.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        })
      } else {
        this.$vuetify.goTo(0)
      }
    }
  },
  computed: {
  },
}
</script>
