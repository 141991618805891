var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-title', [_vm._v(" Создание заявки "), _c('div', {
    staticClass: "ml-5"
  }, [_c('v-btn', {
    staticClass: "mx-4",
    attrs: {
      "small": "",
      "color": "primary",
      "outlined": "",
      "to": "/"
    }
  }, [_vm._v("Все заявки")])], 1)]), !_vm.loading ? _c('div', [_c('RequestForm', {
    attrs: {
      "page": _vm.page
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }