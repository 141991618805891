<template>
    <v-card-text>
        <div>
            <v-card-title>
                Статистика ЭПО
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <DateField
                            label="Дата"
                            :range="true"
                            v-model="filter.dates"
                        ></DateField>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            large
                            color="green"
                            @click="fetch()">
                            Сформировать
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </div>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader type="table-row@3"></v-skeleton-loader>
            </v-col>
        </v-row>
        <div v-if="!loading" style="overflow: auto; border-left: thin solid rgba(255, 255, 255, 0.18) !important;">
            <v-data-table
                :style="`min-width: ${getTableWidth()}px`"
                :headers="headers"
                :items="page.items"
                hide-default-footer
                disable-pagination
                class="table__lined">
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td width="160px">{{ item.name }}</td>
                        <td>{{ item.sentForDocumentsActions }}</td>
                        <td>{{ item.transferredToCourtActions }}</td>
                        <td>{{ item.documentsAreReadyActions }}</td>
                        <td>
                            {{ $helper.report.getPairKey(item?.additionalPackageIsReadyActions) }}
                            <ContractLinks
                                :contracts="$helper.report.getPairValue(item?.additionalPackageIsReadyActions)"></ContractLinks>
                        </td>
                        <td>
                            {{ $helper.report.getPairKey(item?.sentDosudebkaEPOActions) }}
                            <ContractLinks
                                :contracts="$helper.report.getPairValue(item?.sentDosudebkaEPOActions)"></ContractLinks>
                        </td>
                        <td>
                            {{ $helper.report.getPairKey(item?.sentToCourtActions) }}
                            <ContractLinks
                                :contracts="$helper.report.getPairValue(item?.sentToCourtActions)"></ContractLinks>
                        </td>
                        <td>{{ getHeader('inProgressInEPO')?.calculate(item) }}</td>
                        <td>
                            {{ getHeader('documents')?.calculate(item) }}
                            <ContractLinks
                                :contracts="$helper.report.getPairValue(item?.lawyerContractSubjects?.DOCUMENTS)"></ContractLinks>
                        </td>
                        <td>
                            {{ getHeader('departure')?.calculate(item) }}
                            <ContractLinks
                                :contracts="$helper.report.getPairValue(item?.lawyerContractSubjects?.DEPARTURE)"></ContractLinks>
                        </td>
                        <td>
                            {{ getHeader('court')?.calculate(item) }}
                            <ContractLinks
                                :contracts="$helper.report.getPairValue(item?.lawyerContractSubjects?.COURT)"></ContractLinks>
                        </td>
                        <td>{{ $helper.format.currency(item.amount) }}</td>
                        <td>
                            {{ $helper.format.currency($helper.report.getPairKey(item?.payedAmount)) }}
                            <ContractLinks :contracts="$helper.report.getPairValue(item?.payedAmount)"></ContractLinks>
                        </td>
                        <td>
                            {{ getHeader('riskEvents')?.calculate(item) }}
                        </td>
                        <td>
                            {{ getHeader('terminations')?.calculate(item) }}
                        </td>
                        <td>
                            {{ getHeader('packageToLenyr')?.calculate(item) }}
                            <ContractLinks
                                :contracts="$helper.report.getPairValue(item?.packageToLenyr)"></ContractLinks>
                        </td>
                    </tr>
                    <tr class="result-row">
                        <td v-for="(header, i) in headers" :key="i">
                            <div v-if="header.value === 'name'">Всего:</div>
                            <div v-if="header.value !== 'name'">
                                {{ $helper.format.number(total(header.value)) }}
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
    </v-card-text>
</template>

<script>
import DateField from "@/_chunks/DateField";
import ReportService from "@/_services/ReportService";
import {mapState} from "vuex";
import ContractLinks from "@/reports/epo/ContractLinks.vue";

export default {
    name: 'ReportEPO',
    components: {ContractLinks, DateField},
    props: {},
    data: function () {
        return {
            loading: false,
            page: {
                items: [],
            },
            headers: [
                {text: 'Юрист ЭПО', value: 'name'},
                {text: 'Передано дел', value: 'sentForDocumentsActions'},
                {text: 'Передано на подачу в суд', value: 'transferredToCourtActions'},
                {text: 'Выполнено дел', value: 'documentsAreReadyActions'},
                {
                    text: 'Выполнено дел (доп. пакет)',
                    value: 'additionalPackageIsReadyActions',
                    calculate: (item) => this.$helper.report.getPairKey(item?.additionalPackageIsReadyActions) || 0
                },
                {
                    text: 'Досудебка',
                    value: 'sentDosudebkaEPOActions',
                    calculate: (item) => this.$helper.report.getPairKey(item?.sentDosudebkaEPOActions) || 0
                },
                {
                    text: 'Подано в суд',
                    value: 'sentToCourtActions',
                    calculate: (item) => this.$helper.report.getPairKey(item?.sentToCourtActions) || 0
                },
                {
                    text: 'На исполнении в ЭПО',
                    value: 'inProgressInEPO',
                    calculate: (item) => this.$helper.report.getPairKey(item?.lawyerContractState?.IN_PROGRESS_IN_EPO) || 0
                },
                {
                    text: 'Документы',
                    value: 'documents',
                    calculate: (item) => this.$helper.report.getPairKey(item?.lawyerContractSubjects?.DOCUMENTS) || 0
                },
                {
                    text: 'Выезд',
                    value: 'departure',
                    calculate: (item) => this.$helper.report.getPairKey(item?.lawyerContractSubjects?.DEPARTURE) || 0
                },
                {
                    text: 'Суд',
                    value: 'court',
                    calculate: (item) => this.$helper.report.getPairKey(item?.lawyerContractSubjects?.COURT) || 0
                },
                {
                    text: 'Сумма договоров',
                    value: 'amount'
                },
                {
                    text: 'Сумма выплаты',
                    value: 'payedAmount',
                    calculate: (item) => this.$helper.report.getPairKey(item?.payedAmount) || 0
                },
                {
                    text: 'Риск Событие',
                    value: 'riskEvents',
                    calculate: (item) => this.$helper.report.getPairKey(item?.lawyerContractState?.UNDER_RISK) || 0
                },
                {
                    text: 'Расторжение',
                    value: 'terminations',
                    calculate: (item) => this.$helper.report.getPairKey(item?.lawyerContractState?.TERMINATED) || 0
                },
                {
                    text: 'Пакет от ЛенЮр',
                    value: 'packageToLenyr',
                    calculate: (item) => this.$helper.report.getPairKey(item?.packageToLenyr) || 0
                },
            ],
        }
    },
    watch: {},
    methods: {
        fetch() {
            this.loading = true;
            ReportService.getLawyerEPOReport(this.filter).then(page => {
                this.page = page;
                this.loading = false;
            });
        },
        getTableWidth() {
            return 2000;
        },
        total: function (column) {
            if (!column) {
                return '';
            }
            const calcFunction = this.getHeader(column)?.calculate;
            let total = 0;
            this.page.items?.forEach(item => {
                if (calcFunction) {
                    total += calcFunction(item) || 0;
                } else if (column.includes('.')) {
                    const columnPath = column.split('.');
                    total += item[columnPath[0]][columnPath[1]] || 0;
                } else {
                    total += item[column] || 0;
                }
            });
            return total;
        },
        getHeader: function (column) {
            return this.headers.find(h => h.value === column);
        },
    },
    computed: {
        ...mapState(['user']),
        filter: function () {
            return this.$store.state.reportLawyerEPOGridOptions.filter;
        },
    },
    created() {
        this.fetch();
    },
    beforeCreate() {

    }
};
</script>