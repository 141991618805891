<template>
    <v-card>
        <v-card-title>
            <span class="headline">Отправить юристу</span>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col>
                    <v-select v-model="form.lawyer" label="Юрист" :items="lawyers"
                              item-value="id"
                              item-text="fullName"></v-select>
                    <v-alert v-if="form.errors.has('lawyer')" dense outlined type="error"
                             :icon="false">
                        {{form.errors.get('lawyer') }}
                    </v-alert>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-textarea auto-grow v-model="form.comment" label="Комментарий"></v-textarea>
                    <v-alert v-if="form.errors.has('comment')" dense outlined type="error" :icon="false">
                        {{form.errors.get('comment') }}
                    </v-alert>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="$emit('close')">Отмена</v-btn>
            <v-btn color="blue darken-1" text @click="save">Отправить</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import Form from "../_helpers/_form/Form";
    import RequestService from "../_services/RequestService";
    import UserService from "../_services/UserService";

    export default {
        name: "AssignLawyerForm",
        props: {
            id: Number
        },
        data() {
            return {
                form: new Form({
                    lawyer: null,
                    comment: null
                }),
                lawyers: []
            }
        },
        methods: {
            fetch() {
                this.fetchLawyers();
            },
            fetchLawyers() {
                UserService.getAllLawyers().then(data => {
                    this.lawyers = data.content;
                    this.loading = false;
                });
            },
            save() {
                RequestService.assignLawyer(this.id, this.form).then(() => {
                    this.$emit('close');
                    this.$emit('saved');
                });
            },
        },
        created() {
            this.fetch();
        }
    }
</script>

<style scoped>

</style>
