var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-title', [_vm._v(" Редактирование заявки "), _c('div', {
    staticClass: "ml-5"
  }, [_c('v-btn', {
    staticClass: "mx-4",
    attrs: {
      "small": "",
      "color": "primary",
      "outlined": "",
      "to": "/requests/".concat(_vm.id)
    }
  }, [_vm._v("Просмотр заявки")])], 1), _c('div', {
    staticClass: "ml-3"
  }, [_vm.request !== null && _vm.$helper.permission.canDeleteRequest(_vm.request, _vm.user) ? _c('v-dialog', {
    attrs: {
      "max-width": "320"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "small": "",
            "color": "red",
            "outlined": ""
          }
        }, on), [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": "",
            "color": "red"
          }
        }, [_vm._v("mdi-delete")]), _vm._v(" Удалить ")], 1)];
      }
    }], null, false, 251752102),
    model: {
      value: _vm.deleteDialog[_vm.id],
      callback: function callback($$v) {
        _vm.$set(_vm.deleteDialog, _vm.id, $$v);
      },
      expression: "deleteDialog[id]"
    }
  }, [_c('v-card', [_c('v-card-text', {
    staticClass: "text-center pt-7"
  }, [_vm._v("Удалить выбранный элемент?")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.deleteDialog[_vm.id] = false;
      }
    }
  }, [_vm._v("Отмена")]), _c('v-btn', {
    attrs: {
      "color": "red",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.deleteItem(_vm.id);
      }
    }
  }, [_vm._v("Удалить")])], 1)], 1)], 1) : _vm._e()], 1), _c('div', {
    staticClass: "ml-3"
  }, [_c('v-btn', {
    staticClass: "mx-4",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        _vm.reminderDialogVisible = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-calendar-clock")]), _c('div', [_vm._v("Добавить напоминание")])], 1), _c('ReminderDialog', {
    attrs: {
      "requestId": _vm.id,
      "dialogVisible": _vm.reminderDialogVisible
    },
    on: {
      "close": function close($event) {
        _vm.reminderDialogVisible = false;
      },
      "save": _vm.onRemindAdd
    }
  })], 1), _c('div', {
    staticClass: "ml-3"
  }, [_vm.$helper.permission.canMakeCallback(_vm.user) ? _c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.callbackLoading,
      expression: "!callbackLoading"
    }],
    attrs: {
      "small": "",
      "outlined": "",
      "color": "green"
    },
    on: {
      "click": _vm.makeCallback
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-phone")]), _c('div', [_vm._v("Позвонить")])], 1) : _vm._e(), _c('v-progress-circular', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.callbackLoading,
      expression: "callbackLoading"
    }],
    attrs: {
      "size": 20,
      "width": 2,
      "indeterminate": "",
      "color": "primary"
    }
  })], 1), _c('div', {
    staticClass: "ml-3"
  }, [_vm.prevRequest ? _c('v-btn', {
    attrs: {
      "fab": "",
      "text": "",
      "color": "white darken-2"
    },
    on: {
      "click": function click($event) {
        _vm.changeEdited(_vm.prevRequest.toString());
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1) : _vm._e(), _vm.nextRequest ? _c('v-btn', {
    attrs: {
      "fab": "",
      "text": "",
      "color": "white darken-2"
    },
    on: {
      "click": function click($event) {
        _vm.changeEdited(_vm.nextRequest.toString());
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "text-right mx-3 button-close-edit"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "error",
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('closeEdit');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-close-circle-outline")])], 1)], 1)]), _vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, table-row@3, button"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading ? _c('div', [_vm.request !== null ? _c('RequestForm', {
    attrs: {
      "page": _vm.page
    },
    on: {
      "recallSelected": _vm.onRecallSelected,
      "closeAndUpdate": function closeAndUpdate($event) {
        return _vm.$emit('closeAndUpdate');
      },
      "updated": function updated($event) {
        return _vm.$emit('updated');
      },
      "fetchGrid": function fetchGrid($event) {
        return _vm.$emit('fetchGrid');
      }
    }
  }) : _vm._e()], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }