var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "500px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_vm.$helper.env.isTrue('VUE_APP_CAN_PUBLISH_ON_SITE') ? _c('v-btn', _vm._g({
          attrs: {
            "color": "green mr-3"
          }
        }, on), [_vm.request.isPublishedOnSite ? _c('span', [_vm._v("Обновить на сайте")]) : _vm._e(), !_vm.request.isPublishedOnSite ? _c('span', [_vm._v("Опубликовать на сайте")]) : _vm._e()]) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v("Опубликовать вопрос на сайте")])]), _c('v-card-text', [_c('div', [_c('v-text-field', {
    attrs: {
      "label": "Заголовок"
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  }), _vm.form.errors.has('title') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('title')) + " ")]) : _vm._e()], 1), _c('div', [_c('v-textarea', {
    attrs: {
      "auto-grow": "",
      "label": "Текст"
    },
    model: {
      value: _vm.form.text,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "text", $$v);
      },
      expression: "form.text"
    }
  }), _vm.form.errors.has('text') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('text')) + " ")]) : _vm._e()], 1), _c('div', [_c('v-select', {
    attrs: {
      "items": _vm.areas,
      "item-value": "id",
      "item-text": "name",
      "label": "Область права"
    },
    model: {
      value: _vm.form.area,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "area", $$v);
      },
      expression: "form.area"
    }
  }), _vm.form.errors.has('area') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('area')) + " ")]) : _vm._e()], 1)]), _c('v-card-actions', [_c('v-spacer'), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.form.busy,
      expression: "!form.busy"
    }]
  }, [_c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("Отмена")]), _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Сохранить")])], 1), _c('v-progress-circular', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.busy,
      expression: "form.busy"
    }],
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }