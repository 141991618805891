var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-title', [_vm._v(" Биллинг "), _c('v-spacer'), _c('div', [_vm.$helper.permission.canAddPayments(this.user) || this.user.role === 'PROVIDER' ? _c('v-dialog', {
    attrs: {
      "max-width": "500px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "large": "",
            "color": "green"
          }
        }, on), [_vm._v(" Добавить оплату ")])];
      }
    }], null, false, 179848914),
    model: {
      value: _vm.dialog.paymentAdd.modal,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog.paymentAdd, "modal", $$v);
      },
      expression: "dialog.paymentAdd.modal"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v("Добавить оплату")])]), _vm.$helper.permission.canAddPayments(this.user) ? _c('v-card-text', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.accounts,
      "item-value": "id",
      "item-text": "user",
      "label": "Аккаунт",
      "clearable": true
    },
    model: {
      value: _vm.form.account,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "account", $$v);
      },
      expression: "form.account"
    }
  }), _vm.form.errors.has('account') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('account')) + " ")]) : _vm._e()], 1) : _vm._e(), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "Сумма"
    },
    model: {
      value: _vm.form.paidAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "paidAmount", $$v);
      },
      expression: "form.paidAmount"
    }
  }), _vm.form.errors.has('paidAmount') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('paidAmount')) + " ")]) : _vm._e()], 1), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "auto-grow": "",
      "label": "Комментарий"
    },
    model: {
      value: _vm.form.comment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  }), _vm.form.errors.has('comment') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('comment')) + " ")]) : _vm._e()], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog.paymentAdd.modal = false;
      }
    }
  }, [_vm._v("Отмена")]), _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Сохранить")])], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.payments,
      "options": _vm.options,
      "server-items-length": _vm.totalElements,
      "item-key": "id",
      "loading": _vm.loading,
      "loading-text": "Загрузка ...",
      "footer-props": {
        'items-per-page-options': [20, 30, 50]
      }
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref2) {
        var items = _ref2.items;
        return [_c('tbody', [_c('tr', [_c('td', {
          staticClass: "px-2",
          attrs: {
            "width": "20"
          }
        }), _vm.isVisibleColumn('id') ? _c('td', {
          attrs: {
            "width": "160"
          }
        }, [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.filter.id,
            callback: function callback($$v) {
              _vm.$set(_vm.filter, "id", $$v);
            },
            expression: "filter.id"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('account') ? _c('td', {
          attrs: {
            "width": "240"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.accounts,
            "clearable": "",
            "item-value": "id",
            "item-text": "user"
          },
          model: {
            value: _vm.filter.account,
            callback: function callback($$v) {
              _vm.$set(_vm.filter, "account", $$v);
            },
            expression: "filter.account"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('createdAt') ? _c('td', {
          attrs: {
            "width": "500"
          }
        }, [_c('v-dialog', {
          ref: "dialog",
          attrs: {
            "return-value": _vm.filter.createdAtDates,
            "persistent": "",
            "width": "290px"
          },
          on: {
            "update:returnValue": function updateReturnValue($event) {
              return _vm.$set(_vm.filter, "createdAtDates", $event);
            },
            "update:return-value": function updateReturnValue($event) {
              return _vm.$set(_vm.filter, "createdAtDates", $event);
            }
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on;
              return [_c('v-text-field', _vm._g({
                attrs: {
                  "prepend-icon": "mdi-calendar-month-outline",
                  "readonly": "",
                  "clearable": ""
                },
                model: {
                  value: _vm.filter.createdAtDates,
                  callback: function callback($$v) {
                    _vm.$set(_vm.filter, "createdAtDates", $$v);
                  },
                  expression: "filter.createdAtDates"
                }
              }, on))];
            }
          }], null, true),
          model: {
            value: _vm.dialog.createdAt.modal,
            callback: function callback($$v) {
              _vm.$set(_vm.dialog.createdAt, "modal", $$v);
            },
            expression: "dialog.createdAt.modal"
          }
        }, [_c('v-date-picker', {
          attrs: {
            "type": "date",
            "scrollable": "",
            "range": "",
            "first-day-of-week": "1"
          },
          model: {
            value: _vm.dialog.createdAt.model,
            callback: function callback($$v) {
              _vm.$set(_vm.dialog.createdAt, "model", $$v);
            },
            expression: "dialog.createdAt.model"
          }
        }, [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.dialog.createdAt.modal = false;
            }
          }
        }, [_vm._v("Отмена")]), _c('v-btn', {
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.$refs.dialog.save(_vm.dialog.createdAt.model);
            }
          }
        }, [_vm._v(" Выбрать ")])], 1)], 1)], 1) : _vm._e(), _vm.isVisibleColumn('paidAmount') ? _c('td', [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.filter.paidAmount,
            callback: function callback($$v) {
              _vm.$set(_vm.filter, "paidAmount", $$v);
            },
            expression: "filter.paidAmount"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('requestPrice') ? _c('td', [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.filter.requestPrice,
            callback: function callback($$v) {
              _vm.$set(_vm.filter, "requestPrice", $$v);
            },
            expression: "filter.requestPrice"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('requestQuantity') ? _c('td', [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.filter.requestQuantity,
            callback: function callback($$v) {
              _vm.$set(_vm.filter, "requestQuantity", $$v);
            },
            expression: "filter.requestQuantity"
          }
        })], 1) : _vm._e(), _vm.isVisibleColumn('remainingBalance') ? _c('td') : _vm._e(), _vm.isVisibleColumn('comment') ? _c('td', [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.filter.comment,
            callback: function callback($$v) {
              _vm.$set(_vm.filter, "comment", $$v);
            },
            expression: "filter.comment"
          }
        })], 1) : _vm._e()]), _vm._l(items, function (item) {
          return _c('tr', {
            key: item.id
          }, [_c('td', {
            staticClass: "px-2"
          }, [_vm.$helper.isUserRole(_vm.user, 'ADMIN') ? _c('v-dialog', {
            attrs: {
              "max-width": "320"
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function fn(_ref4) {
                var on = _ref4.on;
                return [_c('v-icon', _vm._g({
                  attrs: {
                    "small": "",
                    "color": "red"
                  }
                }, on), [_vm._v("mdi-delete")])];
              }
            }], null, true),
            model: {
              value: _vm.deleteDialog[item.id],
              callback: function callback($$v) {
                _vm.$set(_vm.deleteDialog, item.id, $$v);
              },
              expression: "deleteDialog[item.id]"
            }
          }, [_c('v-card', [_c('v-card-text', {
            staticClass: "text-center pt-7"
          }, [_vm._v("Удалить выбранный элемент?")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
            attrs: {
              "text": ""
            },
            on: {
              "click": function click($event) {
                _vm.deleteDialog[item.id] = false;
              }
            }
          }, [_vm._v("Отмена")]), _c('v-btn', {
            attrs: {
              "color": "red",
              "text": ""
            },
            on: {
              "click": function click($event) {
                return _vm.deleteItem(item.id);
              }
            }
          }, [_vm._v("Удалить")])], 1)], 1)], 1) : _vm._e()], 1), _vm.isVisibleColumn('id') ? _c('td', [_vm._v(_vm._s(item.id))]) : _vm._e(), _vm.isVisibleColumn('account') ? _c('td', [item.account && item.account.user ? _c('div', [_vm._v(_vm._s(item.account.user))]) : _vm._e()]) : _vm._e(), _vm.isVisibleColumn('createdAt') ? _c('td', [_vm._v(_vm._s(_vm.$moment(item.createdAt).dateTimeFormat()))]) : _vm._e(), _vm.isVisibleColumn('paidAmount') ? _c('td', [_vm._v(_vm._s(item.paidAmount))]) : _vm._e(), _vm.isVisibleColumn('requestPrice') ? _c('td', [_vm._v(_vm._s(item.requestPrice))]) : _vm._e(), _vm.isVisibleColumn('requestQuantity') ? _c('td', [_vm._v(_vm._s(item.requestQuantity))]) : _vm._e(), _vm.isVisibleColumn('remainingBalance') ? _c('td', [_vm._v(_vm._s(item.remainingBalance))]) : _vm._e(), _vm.isVisibleColumn('comment') ? _c('td', [_vm._v(_vm._s(item.comment))]) : _vm._e()]);
        })], 2)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }