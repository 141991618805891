var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "500px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [!_vm.id ? _c('v-btn', _vm._g({
          attrs: {
            "color": "green"
          }
        }, on), [_vm._v(" Создать событие ")]) : _vm._e(), _vm.id ? _c('v-icon', _vm._g({
          attrs: {
            "small": ""
          }
        }, on), [_vm._v(" mdi-pencil ")]) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_vm.loading ? _c('v-card', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, table-row@3, button"
    }
  })], 1)], 1)], 1) : _vm._e(), !_vm.loading ? _c('v-card', [_c('v-card-title', [!_vm.id ? _c('span', {
    staticClass: "headline"
  }, [_vm._v(" Добавить событие ")]) : _vm._e(), _vm.id ? _c('span', {
    staticClass: "headline"
  }, [_vm._v(" Изменить событие #" + _vm._s(_vm.id) + " ")]) : _vm._e()]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-autocomplete', {
    attrs: {
      "label": "Тип",
      "items": _vm.types,
      "clearable": true
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _vm.form.errors.has('type') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('type')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-datetime-picker', {
    attrs: {
      "type": "date",
      "scrollable": "",
      "label": "Дата",
      "clearText": "Удалить",
      "okText": "Сохранить",
      "datePickerProps": {
        'first-day-of-week': '1'
      },
      "timePickerProps": {
        'format': '24h'
      }
    },
    model: {
      value: _vm.form.dateTime,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dateTime", $$v);
      },
      expression: "form.dateTime"
    }
  }), _vm.form.errors.has('dateTime') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('dateTime')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-autocomplete', {
    attrs: {
      "label": "Исполнитель",
      "items": _vm.executors,
      "item-value": "id",
      "item-text": "fullName",
      "clearable": true
    },
    model: {
      value: _vm.form.executor,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "executor", $$v);
      },
      expression: "form.executor"
    }
  }), _vm.form.errors.has('executor') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('executor')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-autocomplete', {
    attrs: {
      "label": "Замена",
      "items": _vm.executors,
      "item-value": "id",
      "item-text": "fullName",
      "clearable": true
    },
    model: {
      value: _vm.form.substitution,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "substitution", $$v);
      },
      expression: "form.substitution"
    }
  }), _vm.form.errors.has('substitution') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('substitution')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "label": "Комментарий"
    },
    model: {
      value: _vm.form.comment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  }), _vm.form.errors.has('comment') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('comment')) + " ")]) : _vm._e()], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.form.busy,
      expression: "!form.busy"
    }],
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v(" Отмена ")]), _c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.form.busy,
      expression: "!form.busy"
    }],
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" Сохранить ")]), _c('v-progress-circular', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.busy,
      expression: "form.busy"
    }],
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  })], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }