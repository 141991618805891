<template>
    <div class="mt-3">
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <v-card-title>История изменений</v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-simple-table :hide-default-footer="true" align="top">
                        <template>
                            <tbody>
                                <tr v-for="(revision) in revisions" :key="revision.revisionDate">
                                    <td width="220px" style="vertical-align: top;">
                                        {{ $moment(revision.revisionDate).dateTimeFormat() }}
                                    </td>
                                    <td class="revision-changes">
                                        <v-row>
                                            <v-col cols="auto" v-if="revision?.changes?.reporting">
                                                <div class="mb-3 mr-5">
                                                    <h4 class="mb-3">Юр. отдел</h4>
                                                    <v-row>
                                                        <v-col cols="auto"><span>план дня:</span></v-col>
                                                        <v-col v-html="showChange(revision?.changes?.reporting, 'planDay')"></v-col>
                                                    </v-row>
                                                    <div>
                                                        <table cellspacing="0">
                                                            <tr>
                                                                <td rowspan="2" style="padding-left: 0 !important; vertical-align: top">
                                                                    <span>план отрезка:</span>
                                                                </td>
                                                                <td><span>план:</span></td>
                                                                <td>
                                                                    <font v-html="showChange(revision?.changes?.reporting, 'planInterval')"></font>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><span>период:</span></td>
                                                                <td>
                                                                    <font v-html="showChange(revision?.changes?.reporting, 'intervalDates', (time) => showDatePeriod(time))"></font>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div>
                                                        <table cellspacing="0">
                                                            <tr>
                                                                <td rowspan="2" style="padding-left: 0 !important; vertical-align: top">
                                                                    <span>план периода:</span>
                                                                </td>
                                                                <td><span>план:</span></td>
                                                                <td>
                                                                    <font v-html="showChange(revision?.changes?.reporting, 'planPeriod')"></font>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><span>период:</span></td>
                                                                <td>
                                                                    <font v-html="showChange(revision?.changes?.reporting, 'periodDates', (time) => showDatePeriod(time))"></font>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </v-col>
                                            <v-col cols="auto" v-if="revision?.changes?.reportingOrk">
                                                <div class="mb-3 mr-5">
                                                    <h4 class="mb-3">Орк. отдел</h4>
                                                    <div>
                                                        <v-row>
                                                            <v-col cols="auto"><span>план дня:</span></v-col>
                                                            <v-col v-html="showChange(revision?.changes?.reportingOrk, 'planDay')"></v-col>
                                                        </v-row>
                                                    </div>
                                                    <div>
                                                        <table cellspacing="0">
                                                            <tr>
                                                                <td rowspan="2" style="padding-left: 0 !important; vertical-align: top">
                                                                    <span>план отрезка:</span>
                                                                </td>
                                                                <td><span>план:</span></td>
                                                                <td>
                                                                    <font v-html="showChange(revision?.changes?.reportingOrk, 'planInterval')"></font>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><span>период:</span></td>
                                                                <td>
                                                                    <font v-html="showChange(revision?.changes?.reportingOrk, 'intervalDates', (time) => showDatePeriod(time))"></font>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div>
                                                        <table cellspacing="0">
                                                            <tr>
                                                                <td rowspan="2" style="padding-left: 0 !important; vertical-align: top">
                                                                    <span>план периода:</span>
                                                                </td>
                                                                <td><span>план:</span></td>
                                                                <td>
                                                                    <font v-html="showChange(revision?.changes?.reportingOrk, 'planPeriod')"></font>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><span>период:</span></td>
                                                                <td>
                                                                    <font v-html="showChange(revision?.changes?.reportingOrk, 'periodDates', (time) => showDatePeriod(time))"></font>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import SettingService from "../../_services/SettingService";

export default {
    name: 'ContractRevisions',
    props: {
        id: String,
    },
    data() {
        return {
            revisions: [],
            loading: true,
        }
    },
    methods: {
        fetchGrid() {
            this.loading = true;
            SettingService.getRevisions(this.id).then(data => {
                this.revisions = data;
                this.loading = false;
            });
        },
        showDatePeriod(dates) {
            if (!dates) return;
            return dates.join(" - ");
        },
        showChange(revision, fieldName, supplier) {
            if (!revision || !fieldName) {
                return;
            }
            let newValue = revision?.newValue?.[fieldName] ?? '(пусто)';
            let previousValue = revision?.previousValue?.[fieldName] ?? '(пусто)';
            if (supplier) {
                if (newValue !== '(пусто)') {
                    newValue = supplier(newValue);
                }
                if (previousValue !== '(пусто)') {
                    previousValue = supplier(previousValue);
                }
            }
            return newValue === previousValue
                ? newValue
                : `<span class="text-decoration-line-through">${previousValue}</span> → <font style="color: #ff7878">${newValue}</font>`;
        }
    },
    created() {
        this.fetchGrid();
    }
};
</script>
