<template>
    <v-card-text style="margin-top: -75px;">
        <div v-if="loading">
            <v-skeleton-loader type="table-row@3"></v-skeleton-loader>
        </div>
        <div v-if="!loading">
            <v-data-table
                :headers="plan.headers"
                :items="plan.items"
                hide-default-footer
                disable-pagination
                class="table__lined">
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr
                        v-for="item in items"
                        :key="item.id">
                        <td width="200">{{ item.target }}</td>
                        <td width="200">{{ item.plan }}</td>
                        <td width="250">{{ item.date }}</td>
                        <td width="200">
                            <div v-if="item.target == 'План дня'">
                                {{ todayItem }}
                            </div>
                            <div v-if="item.target == 'План отрезка'">
                                {{ intervalItems }}
                            </div>
                            <div v-if="item.target == 'План периода'">
                                {{ periodItems }}
                            </div>
                        </td>
                        <td>
                            <div v-if="item.target == 'План дня'">
                                <span :class="getResidueColor(getDayResidue())">
                                    {{ getDayResidue() }}
                                </span>
                            </div>
                            <div v-if="item.target == 'План отрезка'">
                                 <span :class="getResidueColor(getIntervalResidue())">
                                    {{ getIntervalResidue() }}
                                </span>
                            </div>
                            <div v-if="item.target == 'План периода'">
                                <span :class="getResidueColor(getPeriodResidue())">
                                    {{ getPeriodResidue() }}
                                </span>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
    </v-card-text>
</template>

<script>

import {mapState} from "vuex";
import interval from "echarts/src/scale/Interval";
import ReportService from "@/_services/ReportService";
import {reportHelper} from "@/_helpers/report/reportHelper";

export default {
    name: 'PlanTable',
    components: {},
    props: {
        filter: Object,
    },
    data: function () {
        return {
            loading: false,
            todayItem: null,
            periodItems: [],
            intervalItems: []
        }
    },
    watch: {
        globalSettings: {
            handler() {
                this.fetch();
            },
            deep: true
        }
    },
    methods: {
        interval,
        fetch() {
            let dates = this.$moment().format('YYYY-MM-DD');
            ReportService.getReport({reportType: 'SUBDIVISION', 'request.createdAtDates': dates}).then(page => {
                this.todayItem = reportHelper.getOrkCompletedPlan(page?.summary) || 0;
            });

            // INTERVAL
            dates = this.globalSettings?.reportingOrk?.intervalDates?.join(",") || this.$moment().format('YYYY-MM-DD')
            ReportService.getReport({reportType: 'SUBDIVISION', 'request.createdAtDates': dates}).then(page => {
                this.intervalItems = reportHelper.getOrkCompletedPlan(page?.summary) || 0;
                this.loading = false;
            });

            // PERIOD
            dates = this.globalSettings?.reportingOrk?.periodDates?.join(",") || this.$moment().format('YYYY-MM-DD');
            ReportService.getReport({reportType: 'SUBDIVISION', 'request.createdAtDates': dates}).then(page => {
                this.periodItems = reportHelper.getOrkCompletedPlan(page?.summary) || 0;
                this.loading = false;
            });
        },

        getStatColor(number, options) {
            const colorSuffix = '_link';
            const defaultColor = 'yellow';
            const colors = options?.colors;
            let color = defaultColor;
            if (colors) {
                Object.entries(colors).forEach(([colorProp, value]) => {
                    if (Array.isArray(value)) {
                        const from = value[0];
                        const to = value[1];
                        if (number >= from && number <= to) {
                            color = colorProp;
                        }
                    }
                });
            }
            return color + colorSuffix;
        },
        getDayResidue() {
            return this.todayItem - (this.globalSettings?.reportingOrk?.planDay || 0);
        },
        getIntervalResidue() {
            return this.intervalItems - (this.globalSettings?.reportingOrk?.planInterval || 0);
        },
        getPeriodResidue() {
            return this.periodItems - (this.globalSettings?.reportingOrk?.planPeriod || 0);
        },
        getResidueColor(residue) {
            return (residue >= 0 ? 'green' : 'red') + '_link';
        }
    },
    computed: {
        ...mapState(['globalSettings']),
        plan() {
            return {
                headers: [
                    {text: 'Цель', value: 'target', sortable: false},
                    {text: 'План прихода', value: 'plan', sortable: false},
                    {text: 'Дата', value: 'date', sortable: false},
                    {text: 'Приход', value: 'completedPlan', sortable: false},
                    {text: 'Остаток', value: 'residue', sortable: false}
                ],
                items: [
                    {
                        target: 'План дня',
                        plan: this.globalSettings?.reportingOrk?.planDay ?? 0,
                        date: this.$moment().format('YYYY-MM-DD'),
                        completedPlan: 0,
                        residue: 0
                    },
                    {
                        target: 'План отрезка',
                        plan: this.globalSettings?.reportingOrk?.planInterval ?? 0,
                        date: Array.isArray(this.globalSettings?.reportingOrk?.intervalDates)
                            ? this.globalSettings.reportingOrk?.intervalDates.join(' - ')
                            : this.$moment().format('YYYY-MM-DD'),
                        completedPlan: 0,
                        residue: 0
                    },
                    {
                        target: 'План периода',
                        plan: this.globalSettings?.reportingOrk?.planPeriod ?? 0,
                        date: Array.isArray(this.globalSettings?.reportingOrk?.periodDates)
                            ? this.globalSettings.reportingOrk?.periodDates.join(' - ')
                            : this.$moment().format('YYYY-MM-DD'),
                        completedPlan: 0,
                        residue: 0
                    }
                ]
            }
        },
    },
    created() {
        this.loading = true;
    },
    mounted() {
        this.fetch();
    }
};
</script>