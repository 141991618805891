var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-title', [_vm._v(" Статистика Юр.Отдела ")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align-content": "bottom"
    }
  }, [_c('v-col', {
    attrs: {
      "xl": "6"
    }
  }, [_c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "item-value": "id",
      "item-text": "text",
      "items": _vm.filteredReportTypes
    },
    model: {
      value: _vm.options.reportType,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "reportType", $$v);
      },
      expression: "options.reportType"
    }
  })], 1), _c('v-col', [_c('DateField', {
    attrs: {
      "label": "Дата",
      "range": true
    },
    model: {
      value: _vm.filter.dates,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "dates", $$v);
      },
      expression: "filter.dates"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "color": "green"
    },
    on: {
      "click": _vm.getReport
    }
  }, [_vm._v(" Сформировать ")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "xl": "6"
    }
  }, [_vm.$helper.permission.canUpdateContract(_vm.user) ? _c('PlanTable', {
    key: _vm.reportForceRerenderKey
  }) : _vm._e()], 1)], 1)], 1), _vm.options.reportType == 'LAWYERS' ? _c('ReportLawyers', {
    key: _vm.reportForceRerenderKey,
    attrs: {
      "filter": _vm.filter
    }
  }) : _vm._e(), _vm.options.reportType == 'LAWYER_DEPARTMENT' ? _c('ReportLawyerDepartment', {
    key: _vm.reportForceRerenderKey,
    attrs: {
      "filter": _vm.filter
    }
  }) : _vm._e(), _vm.options.reportType == 'LAWYERS_CHART' ? _c('ChartLawyerPlans', {
    key: _vm.reportForceRerenderKey,
    attrs: {
      "filter": _vm.filter
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }