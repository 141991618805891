import {http} from "../_helpers";
import Service from "./Service";
import {axiosInstance} from "../_helpers/axiosInstance";

class ActService extends Service {

    getAll(options, filter) {
        return http.get(this.makeOptionsUrl(`/acts`, options, filter));
    }

    getOne(id) {
        return http.get(`/acts/${id}`);
    }

    getForm(id) {
        return http.get(`/acts/${id}/update`);
    }

    create(form) {
        return http.post(`/acts`, form);
    }

    update(id, form) {
        return http.put(`/acts/${id}`, form);
    }

    delete(id) {
        return http.delete(`/acts/${id}`);
    }

    deleteBulk(ids) {
        return axiosInstance.post(`/acts/deleteBulk`, {'ids': ids});
    }

    getComments(id) {
        return http.get(`/acts/${id}/comments`);
    }

    addComment(id, form) {
        return http.post(`/acts/${id}/comments`, form);
    }

    addToFavorites(id) {
        return axiosInstance.put(`/acts/${id}/favorites`);
    }

    removeFromFavorites(id) {
        return axiosInstance.delete(`/acts/${id}/favorites`);
    }
}

export default new ActService;
