var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "500px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "color": "green"
          }
        }, on), [_vm._v(" Добавить комментарий ")])];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v("Добавить комментарий")])]), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "auto-grow": "",
      "label": "Комментарий"
    },
    model: {
      value: _vm.form.text,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "text", $$v);
      },
      expression: "form.text"
    }
  }), _vm.form.errors.has('text') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('text')) + " ")]) : _vm._e()], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("Отмена")]), _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Сохранить")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }