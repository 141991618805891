var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-title', [_vm._v(" Редактирование клиента "), _c('div', {
    staticClass: "text-right mx-3 button-close-edit"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "error",
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('closeEdit');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-close-circle-outline")])], 1)], 1), _c('div', {
    staticClass: "ml-3"
  }, [_c('v-btn', {
    staticClass: "mx-4",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        _vm.reminderDialogVisible = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-calendar-clock")]), _c('div', [_vm._v("Добавить напоминание")])], 1), _c('ReminderDialog', {
    attrs: {
      "clientId": _vm.id,
      "dialogVisible": _vm.reminderDialogVisible
    },
    on: {
      "close": function close($event) {
        _vm.reminderDialogVisible = false;
      },
      "save": _vm.onRemindAdd
    }
  })], 1), _vm.$helper.permission.canCreateContract(_vm.user) ? _c('div', {
    staticClass: "ml-3"
  }, [_c('v-btn', {
    staticClass: "mx-4",
    attrs: {
      "to": "/contracts/create?clientId=".concat(_vm.id),
      "small": "",
      "outlined": "",
      "color": "green"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-note-plus-outline")]), _c('div', [_vm._v("Добавить договор")])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "ml-3"
  }, [_vm.prevClient ? _c('v-btn', {
    attrs: {
      "fab": "",
      "text": "",
      "color": "white darken-2"
    },
    on: {
      "click": function click($event) {
        _vm.changeEdited(_vm.prevClient.toString());
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1) : _vm._e(), _vm.nextClient ? _c('v-btn', {
    attrs: {
      "fab": "",
      "text": "",
      "color": "white darken-2"
    },
    on: {
      "click": function click($event) {
        _vm.changeEdited(_vm.nextClient.toString());
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1) : _vm._e()], 1)]), _vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, table-row@3, button"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading ? _c('div', [_vm.client !== null ? _c('ClientForm', {
    attrs: {
      "page": _vm.page
    },
    on: {
      "closeAndUpdate": function closeAndUpdate($event) {
        return _vm.$emit('closeAndUpdate');
      },
      "updated": function updated($event) {
        return _vm.$emit('updated');
      },
      "recallSelected": _vm.onRecallSelected
    }
  }) : _vm._e()], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }