var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-title', [_vm._v(" Отчеты ")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "xl": "6"
    }
  }, [_c('v-row', [_vm.$helper.permission.canAssignRequest(_vm.user) ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-label', [_vm._v("Тип отчета")]), _c('v-select', {
    attrs: {
      "item-value": "id",
      "item-text": "text",
      "items": _vm.reportTypes
    },
    model: {
      value: _vm.reportGridsOptions.filter.reportType,
      callback: function callback($$v) {
        _vm.$set(_vm.reportGridsOptions.filter, "reportType", $$v);
      },
      expression: "reportGridsOptions.filter.reportType"
    }
  })], 1) : _vm._e(), _c('v-col', [_c('v-label', [_vm._v("Дата")]), _c('v-dialog', {
    ref: "dialog",
    attrs: {
      "return-value": _vm.reportGridsOptions.filter.request.createdAtDates,
      "persistent": "",
      "width": "290px"
    },
    on: {
      "update:returnValue": function updateReturnValue($event) {
        return _vm.$set(_vm.reportGridsOptions.filter.request, "createdAtDates", $event);
      },
      "update:return-value": function updateReturnValue($event) {
        return _vm.$set(_vm.reportGridsOptions.filter.request, "createdAtDates", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "prepend-icon": "mdi-calendar-month-outline",
            "readonly": ""
          },
          model: {
            value: _vm.reportGridsOptions.filter.request.createdAtDates,
            callback: function callback($$v) {
              _vm.$set(_vm.reportGridsOptions.filter.request, "createdAtDates", $$v);
            },
            expression: "reportGridsOptions.filter.request.createdAtDates"
          }
        }, on))];
      }
    }]),
    model: {
      value: _vm.dialog.createdAt.modal,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog.createdAt, "modal", $$v);
      },
      expression: "dialog.createdAt.modal"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "type": "date",
      "scrollable": "",
      "range": "",
      "first-day-of-week": "1"
    },
    model: {
      value: _vm.dialog.createdAt.model,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog.createdAt, "model", $$v);
      },
      expression: "dialog.createdAt.model"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.dialog.createdAt.modal = false;
      }
    }
  }, [_vm._v("Отмена")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.dialog.save(_vm.dialog.createdAt.model);
      }
    }
  }, [_vm._v(" Выбрать ")])], 1)], 1)], 1), false && _vm.$helper.permission.canAssignRequest(_vm.user) ? _c('v-col', [_c('v-label', [_vm._v("Источник")]), _c('v-select', {
    attrs: {
      "item-value": "id",
      "item-text": "name",
      "items": _vm.sources,
      "clearable": ""
    },
    model: {
      value: _vm.reportGridsOptions.filter.request.source,
      callback: function callback($$v) {
        _vm.$set(_vm.reportGridsOptions.filter.request, "source", $$v);
      },
      expression: "reportGridsOptions.filter.request.source"
    }
  })], 1) : _vm._e(), false && _vm.$helper.permission.canAssignRequest(_vm.user) ? _c('v-col', [_c('v-label', [_vm._v("Оператор")]), _c('v-select', {
    attrs: {
      "items": _vm.operators,
      "clearable": "",
      "item-value": "id",
      "item-text": "fullName"
    },
    model: {
      value: _vm.reportGridsOptions.filter.request.operator,
      callback: function callback($$v) {
        _vm.$set(_vm.reportGridsOptions.filter.request, "operator", $$v);
      },
      expression: "reportGridsOptions.filter.request.operator"
    }
  })], 1) : _vm._e(), _c('v-col', [_c('v-btn', {
    attrs: {
      "large": "",
      "color": "green"
    },
    on: {
      "click": _vm.getReport
    }
  }, [_vm._v(" Сформировать ")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "xl": "6"
    }
  }, [_c('PlanTable', {
    key: _vm.reportForceRerenderKey
  })], 1)], 1), !_vm.loading && _vm.reportGridsOptions.filter.reportType !== 'GRAFIK' ? _c('div', {
    staticStyle: {
      "overflow": "auto",
      "border-left": "thin solid rgba(255, 255, 255, 0.18) !important"
    }
  }, [_c('v-data-table', {
    staticClass: "table__lined",
    style: "min-width: ".concat(_vm.getTableWidth(), "px"),
    attrs: {
      "headers": _vm.headers,
      "items": _vm.reportsTuples,
      "hide-default-footer": "",
      "disable-pagination": "",
      "custom-sort": _vm.customSort
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref2) {
        var items = _ref2.items;
        return [_c('tbody', [_vm._l(items, function (item) {
          return _c('tr', {
            key: item.id
          }, [_c('td', {
            attrs: {
              "width": "120"
            }
          }, [_vm._v(_vm._s(item.item))]), _c('td', {
            attrs: {
              "width": "100"
            }
          }, [_vm._v(_vm._s(item.count))]), _c('td', {
            attrs: {
              "width": "120"
            },
            domProps: {
              "innerHTML": _vm._s(_vm.printStateRow(item, 'SHLAK'))
            }
          }), _c('td', {
            attrs: {
              "width": "120"
            },
            domProps: {
              "innerHTML": _vm._s(_vm.printStateRow(item, 'BRAK'))
            }
          }), _c('td', {
            attrs: {
              "width": "120"
            },
            domProps: {
              "innerHTML": _vm._s(_vm.printStateRow(item, 'FAILURE'))
            }
          }), _c('td', {
            attrs: {
              "width": "120"
            },
            domProps: {
              "innerHTML": _vm._s(_vm.printStateRow(item, 'CONTROL'))
            }
          }), _c('td', {
            attrs: {
              "width": "120"
            },
            domProps: {
              "innerHTML": _vm._s(_vm.printStateRow(item, 'RECALL'))
            }
          }), _c('td', {
            attrs: {
              "width": "120"
            },
            domProps: {
              "innerHTML": _vm._s(_vm.printStateRow(item, 'ZAPIS'))
            }
          }), _c('td', {
            attrs: {
              "width": "120"
            },
            domProps: {
              "innerHTML": _vm._s(_vm.printStateRow(item, 'APPOINTMENT_ZAPIS'))
            }
          }), _c('td', {
            attrs: {
              "width": "160"
            },
            domProps: {
              "innerHTML": _vm._s(_vm.printStateRow(item, 'CANCEL_ZAPIS'))
            }
          }), _c('td', {
            attrs: {
              "width": "110"
            },
            domProps: {
              "innerHTML": _vm._s(_vm.printStateRow(item, 'APPOINTMENT_ONLINE'))
            }
          }), _c('td', {
            attrs: {
              "width": "120"
            },
            domProps: {
              "innerHTML": _vm._s(_vm.printStateRow(item, 'CAME'))
            }
          }), _c('td', {
            attrs: {
              "width": "120"
            },
            domProps: {
              "innerHTML": _vm._s(_vm.printStateRow(item, 'APPOINTMENT_CAME', false, {
                statColorOptions: _vm.reportHelper.orkCameResultStatOptions,
                stateSubstract: 'APPOINTMENT_ONLINE',
                stateSubstractPercent: 0.5,
                ceil: true
              }))
            }
          }), _c('td', {
            attrs: {
              "width": "120"
            },
            domProps: {
              "innerHTML": _vm._s(_vm.printStateRow(item, 'CONTRACT'))
            }
          }), _c('td', {
            attrs: {
              "width": "120"
            }
          }, [_c('span', {
            domProps: {
              "innerHTML": _vm._s(_vm.printStateRow(item, 'CONTRACT_RESULT', false, {
                withoutPercent: true
              }))
            }
          }), _c('span'), _c('span', {
            domProps: {
              "innerHTML": _vm._s(_vm.getPercentInfo(_vm.printStateRow(item, 'CONTRACT_RESULT', false, {
                withoutPercent: true
              }), _vm.printStateRow(item, 'APPOINTMENT_CAME', false, {
                withoutPercent: true,
                stateSubstract: 'APPOINTMENT_ONLINE',
                stateSubstractPercent: 0.5,
                ceil: true
              }), _vm.reportHelper.orkCameResultStatOptions))
            }
          })]), _c('td', {
            attrs: {
              "width": "120"
            },
            domProps: {
              "innerHTML": _vm._s(_vm.printStateRow(item, 'PULL_OFF'))
            }
          }), false ? _c('td', {
            attrs: {
              "width": "120"
            },
            domProps: {
              "innerHTML": _vm._s(_vm.getItemKPD(item))
            }
          }) : _vm._e(), false ? _c('td', {
            attrs: {
              "width": "120"
            },
            domProps: {
              "innerHTML": _vm._s(_vm.printStateRow(item, ['ZAPIS', 'CAME'], true))
            }
          }) : _vm._e()]);
        }), _vm.summary.length > 0 ? _c('tr', {
          staticClass: "result-row"
        }, [_c('td', [_c('b', [_vm._v("Всего:")])]), _c('td', {
          domProps: {
            "innerHTML": _vm._s(_vm.printSummaryRow(null, true))
          }
        }), _c('td', {
          domProps: {
            "innerHTML": _vm._s(_vm.printSummaryRow('SHLAK'))
          }
        }), _c('td', {
          domProps: {
            "innerHTML": _vm._s(_vm.printSummaryRow('BRAK'))
          }
        }), _c('td', {
          domProps: {
            "innerHTML": _vm._s(_vm.printSummaryRow('FAILURE'))
          }
        }), _c('td', {
          domProps: {
            "innerHTML": _vm._s(_vm.printSummaryRow('CONTROL'))
          }
        }), _c('td', {
          domProps: {
            "innerHTML": _vm._s(_vm.printSummaryRow('RECALL'))
          }
        }), _c('td', {
          domProps: {
            "innerHTML": _vm._s(_vm.printSummaryRow('ZAPIS'))
          }
        }), _c('td', {
          domProps: {
            "innerHTML": _vm._s(_vm.printSummaryRow('APPOINTMENT_ZAPIS'))
          }
        }), _c('td', {
          domProps: {
            "innerHTML": _vm._s(_vm.printSummaryRow('CANCEL_ZAPIS'))
          }
        }), _c('td', {
          domProps: {
            "innerHTML": _vm._s(_vm.printSummaryRow('APPOINTMENT_ONLINE'))
          }
        }), _c('td', {
          domProps: {
            "innerHTML": _vm._s(_vm.printSummaryRow('CAME'))
          }
        }), _c('td', {
          domProps: {
            "innerHTML": _vm._s(_vm.printSummaryRow('APPOINTMENT_CAME', false, {
              statColorOptions: _vm.reportHelper.orkCameResultStatOptions,
              stateSubstract: 'APPOINTMENT_ONLINE',
              stateSubstractPercent: 0.5,
              ceil: true
            }))
          }
        }), _c('td', {
          domProps: {
            "innerHTML": _vm._s(_vm.printSummaryRow('CONTRACT'))
          }
        }), _c('td', [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.printSummaryRow('CONTRACT_RESULT', true))
          }
        }), _c('span'), _c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.getPercentInfo(_vm.printSummaryRow('CONTRACT_RESULT', true), _vm.printSummaryRow('APPOINTMENT_CAME', true, {
              stateSubstract: 'APPOINTMENT_ONLINE',
              stateSubstractPercent: 0.5,
              ceil: true
            }), _vm.reportHelper.orkCameResultStatOptions))
          }
        })]), _c('td', {
          domProps: {
            "innerHTML": _vm._s(_vm.printSummaryRow('PULL_OFF'))
          }
        }), false ? _c('td', {
          domProps: {
            "innerHTML": _vm._s(_vm.getItemKPD())
          }
        }) : _vm._e(), false ? _c('td', {
          domProps: {
            "innerHTML": _vm._s(_vm.printSummaryRow(['ZAPIS', 'CAME']))
          }
        }) : _vm._e()]) : _vm._e()], 2)];
      }
    }], null, false, 1958815432)
  })], 1) : _vm._e()], 1), _vm.reportGridsOptions.filter.reportType === 'GRAFIK' ? _c('ChartOrkPlans', {
    key: _vm.reportForceRerenderKey,
    attrs: {
      "filter": _vm.reportGridsOptions.filter
    }
  }) : _vm._e(), false && _vm.states.length > 0 && _vm.reportsTuples.length > 0 ? _c('Charts', {
    key: _vm.chartsKey,
    attrs: {
      "states": _vm.states,
      "reportsTuples": _vm.reportsTuples
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }