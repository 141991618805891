<template>
    <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on }">
            <v-btn color="green" v-on="on">
                Отправить уведомление
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Выбор шаблона</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-select
                            label="Канал"
                            v-model="form.channel"
                            :items="channelTypes"
                            :clearable="true">
                        </v-select>
                        <v-alert
                            v-if="form.errors.has('channel')"
                            dense outlined
                            type="error"
                            :icon="false">
                            {{ form.errors.get('channel') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-autocomplete
                            label="Тип"
                            v-model="form.type"
                            :items="types"
                            :clearable="true">
                        </v-autocomplete>
                        <v-alert
                            v-if="form.errors.has('type')"
                            dense outlined
                            type="error"
                            :icon="false">
                            {{ form.errors.get('type') }}
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text @click="dialog = false">
                    Отмена
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="save">
                    Сохранить
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Form from "../../_helpers/_form/Form";
import ClientNotificationService from "@/_services/ClientNotificationService";

export default {

    props: {
        id: Number,
        channelTypes: Array,
        types: Array
    },
    data() {
        return {
            form: new Form({
                notificationable: this.id,
                channel: 'EMAIL'
            }),
            dialog: false
        }
    },
    methods: {
        save() {
            ClientNotificationService.create(this.form).then(() => {
                this.dialog = false;
                this.$emit('saved');
            });
        },
    }
};
</script>
