import {axiosInstance} from "../_helpers/axiosInstance";

class FileService {

    serveFile(id, formComponent) {
        return axiosInstance.get(
            `/files/${id}/download`, {responseType: 'blob'})
            .then((response) => {
                const contentType = response.headers['content-type'] || '';
                if (contentType.includes("image") || contentType.includes("pdf")) {
                    this.showInNewTab(response, contentType);
                } if (contentType.includes("audio")) {
                    this.playAudio(response, contentType, formComponent);
                } else {
                    this.downloadFile(response);
                }
            });
    }

    playAudio(response, contentType, formComponent) {
        const url = window.URL.createObjectURL(new Blob([response.data], {type: contentType}));
        formComponent.playAudio(url)
    }

    showInNewTab(response, contentType) {
        const url = window.URL.createObjectURL(new Blob([response.data], {type: contentType}));
        const link = document.createElement('a');
        link.href = url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
    }

    downloadFile(response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const filename = decodeURI((response.headers['content-disposition'] || '').split('filename=')[1]);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
    }

    changeFileDirectory(directoryId, fileId) {
        return axiosInstance.put(`/files/${fileId}/directory/${directoryId}`);
    }
}

export default new FileService;
