<template>
    <div>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader
                        type="heading, table-row@3, button"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-card v-if="!loading">
            <v-card-text>
                <form>
                    <v-row>
                        <v-col cols="12" xl="8">
                            <v-row>
                                <v-col>
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="form.name" label="Название"></v-text-field>
                                            <v-alert v-if="form.errors.has('name')" dense outlined type="error"
                                                     :icon="false">
                                                {{form.errors.get('name') }}
                                            </v-alert>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="subdivision">
                                        <v-col v-for="(source, index) in sources" :key="source.id" sm="12" md="6" xl="4">
                                            {{ source.name }}
                                            <v-row>
                                                <v-col>
                                                    <v-text-field v-model="form.sources[index].percentageOfReceivedRequests" label="Процент заявок"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-alert v-if="form.errors.any()" dense outlined type="error" :icon="false">
                                Исправьте ошибки
                            </v-alert>
                            <v-alert v-if="form.errors.has('error')" dense outlined type="error" :icon="false">
                                {{form.errors.get('error')}}
                            </v-alert>
                            <div v-show="!form.busy">
                                <v-btn large color="green" @click="subdivision ? update(false) : save(false)">
                                    Сохранить
                                </v-btn>
                            </div>
                            <v-progress-circular v-show="form.busy" indeterminate color="primary"></v-progress-circular>
                        </v-col>
                    </v-row>
                </form>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
    import {Form} from '../_helpers/_form';
    import SourceService from "../_services/SourceService";
    import _ from "lodash";
    import SubdivisionService from "../_services/SubdivisionService";

    export default {
        name: "SubdivisionForm",
        props: {
            subdivision: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                form: this.subdivision
                    ? new Form({...this.subdivision})
                    : new Form({
                        name: '',
                        sources: []
                    }),
                sources: [],
                loading: true
            }
        },
        methods: {
            fetch() {
                this.loading = false;
                this.fetchSources();
            },
            fetchSources() {
                SourceService.getAll().then(data => {
                    let sources = [];
                    data.forEach(el => {
                        if (_.isUndefined(this.form.sources[el.id])) {
                            sources.push({
                                source: el.id,
                                //subdivision: this.subdivision.id,
                                percentageOfReceivedRequests: 0
                            });
                        } else {
                            sources.push(this.form.sources[el.id]);
                        }
                    });
                    this.form.sources = sources;
                    this.sources = data;
                });
            },
            save() {
                SubdivisionService.create(this.form).then(() => {
                    this.callback()
                });
            },
            update() {
                SubdivisionService.update(this.subdivision.id, this.form).then(() => {
                    this.callback()
                });
            },
            callback() {
                this.$router.push('/subdivisions');
            }
        },
        created() {
            this.fetch();
        },
    }
</script>

<style lang="scss">
</style>
