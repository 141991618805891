var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-expansion-panels', {
    staticClass: "mt-5 pt-5 task-expansion",
    model: {
      value: _vm.expand,
      callback: function callback($$v) {
        _vm.expand = $$v;
      },
      expression: "expand"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('v-card-title', {
    staticClass: "px-1 py-2"
  }, [_vm._v(" Отчет по работе с клиентом ")])], 1), _c('v-expansion-panel-content', [_c('ReportMultiField', {
    staticClass: "mb-5",
    attrs: {
      "field-title": "Ситуация клиента",
      "form-field": _vm.form.clientSituation,
      "field-initial-state": _vm.initialState.clientSituation
    }
  }), _c('ReportMultiField', {
    attrs: {
      "field-title": "Потребность клиента",
      "form-field": _vm.form.clientNeeds,
      "field-initial-state": _vm.initialState.clientNeeds
    }
  }), _c('ReportMultiField', {
    attrs: {
      "field-title": "Предложено клиенту",
      "form-field": _vm.form.offeredToClient,
      "field-initial-state": _vm.initialState.offeredToClient
    }
  }), _c('ReportMultiField', {
    attrs: {
      "field-title": "Итог",
      "form-field": _vm.form.output,
      "field-initial-state": _vm.initialState.output
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-datetime-picker', {
    attrs: {
      "type": "date",
      "scrollable": "",
      "label": "Дата события",
      "clearText": "Удалить",
      "okText": "Сохранить",
      "datePickerProps": {
        'first-day-of-week': '1'
      },
      "timePickerProps": {
        'format': '24h'
      }
    },
    model: {
      value: _vm.form.callbackDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "callbackDate", $$v);
      },
      expression: "form.callbackDate"
    }
  }), _vm.form.errors.has('callbackDate') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('callbackDate')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "label": "Примечание",
      "rows": "2",
      "auto-grow": ""
    },
    model: {
      value: _vm.form.reportComment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "reportComment", $$v);
      },
      expression: "form.reportComment"
    }
  }), _vm.form.errors.has('reportComment') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('reportComment')) + " ")]) : _vm._e()], 1)], 1), _c('div', [_vm.form.errors.any() ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" Исправьте ошибки ")]) : _vm._e(), _vm.form.errors.has('error') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('error')) + " ")]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.form.busy,
      expression: "!form.busy"
    }]
  }, [_vm.$helper.permission.canUpdateClientReport(_vm.user) ? _c('v-btn', {
    attrs: {
      "large": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        return _vm.update();
      }
    }
  }, [_vm._v(" Сохранить ")]) : _vm._e()], 1), _c('v-progress-circular', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.busy,
      expression: "form.busy"
    }],
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }