var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-title', [_vm._v(" Редактирование акта "), _c('div', {
    staticClass: "text-right mx-3 button-close-edit"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "error",
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('closeEdit');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-close-circle-outline")])], 1)], 1), _c('div', {
    staticClass: "ml-3"
  }, [_vm.prevAct ? _c('v-btn', {
    attrs: {
      "fab": "",
      "text": "",
      "color": "white darken-2"
    },
    on: {
      "click": function click($event) {
        _vm.changeEdited(_vm.prevAct.toString());
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1) : _vm._e(), _vm.nextAct ? _c('v-btn', {
    attrs: {
      "fab": "",
      "text": "",
      "color": "white darken-2"
    },
    on: {
      "click": function click($event) {
        _vm.changeEdited(_vm.nextAct.toString());
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1) : _vm._e()], 1)]), _vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, table-row@3, button"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading ? _c('div', [_vm.act !== null ? _c('ActForm', {
    attrs: {
      "page": _vm.page
    },
    on: {
      "closeAndUpdate": function closeAndUpdate($event) {
        return _vm.$emit('closeAndUpdate');
      },
      "updated": function updated($event) {
        return _vm.$emit('updated');
      },
      "recallSelected": _vm.onRecallSelected
    }
  }) : _vm._e()], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }