var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.signatures.length ? _c('v-list', _vm._l(_vm.signatures, function (signature) {
    return _c('div', {
      key: signature.id
    }, [_c('v-list-item-content', [_vm._v(" Подписано - " + _vm._s(signature.user.fullName) + " - " + _vm._s(_vm.$moment(signature.createdAt).dateTimeFormat()) + " ")])], 1);
  }), 0) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }