<template>
    <div>
        <v-list dense>
            <v-list-item>
                <v-list-item-content>
                    <v-switch
                        :prepend-icon="muteIcon"
                        v-model="unMute"
                    ></v-switch>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-switch
                        prepend-icon="mdi-message-outline"
                        v-model="localSettings.unNotify"
                    ></v-switch>
                </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="$helper.permission.canChangeOperatorAutoAssign(user)">
                <v-list-item-content>
                    <v-label class="font-size__small">Автораспределение заявок</v-label>
                    <v-switch
                        prepend-icon="mdi-refresh-auto"
                        v-model="autoAssignOperator"
                    ></v-switch>
                </v-list-item-content>
            </v-list-item>


            <v-list-item v-if="$helper.permission.canSetOutOfPlace(user)">
                <v-list-item-content>
                    <v-label class="font-size__small">Не на месте</v-label>
                    <v-switch
                        prepend-icon="mdi-account-cancel"
                        v-model="outOfPlace"
                    ></v-switch>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
    import SettingService from "@/_services/SettingService";
    import {mapState} from "vuex";

    export default {
        name: "Settings",
        computed: {
            ...mapState(['user']),
            autoAssignOperator: {
                get () {
                    return this.$store.state.globalSettings.autoAssignOperator
                },
                set(value) {
                    SettingService
                        .updateAutoAssignOperator(value)
                        .then(() => {
                            this.$store.state.globalSettings.autoAssignOperator = value;
                        });
                }
            },
            outOfPlace: {
                get() {
                    return this.$store.state.user.outOfPlace;
                },
                set (value) {
                    SettingService
                        .updateOutOfPlace(value)
                        .then(() => {
                            this.$store.state.user.outOfPlace = value;
                        });
                }
            },
            localSettings: function() {
                return this.$store.state.settings
            },
            unMute: {
                get() {
                    return this.$store.state.unMute;
                },
                set(value) {
                    this.$store.dispatch('setUnMute', value)
                }
            },
            muteIcon: function() {
                return this.unMute ? "mdi-volume-high" : "mdi-volume-off"
            }
        }
    }
</script>

<style scoped>

</style>
