<template>
    <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on }">
            <v-btn
                v-if="!id"
                color="green"
                v-on="on">
                Создать событие
            </v-btn>
            <v-icon
                v-if="id"
                small
                v-on="on">
                mdi-pencil
            </v-icon>
        </template>
        <v-card v-if="loading">
            <v-row>
                <v-col cols="12" md="7">
                    <v-skeleton-loader type="heading, table-row@3, button"></v-skeleton-loader>
                </v-col>
            </v-row>
        </v-card>
        <v-card v-if="!loading">
            <v-card-title>
                <span
                    v-if="!id"
                    class="headline">
                    Добавить событие
                </span>
                    <span
                        v-if="id"
                        class="headline">
                    Изменить событие #{{ id }}
                </span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-autocomplete
                            label="Тип"
                            v-model="form.type"
                            :items="types"
                            :clearable="true">
                        </v-autocomplete>
                        <v-alert
                            v-if="form.errors.has('type')"
                            dense outlined
                            type="error"
                            :icon="false">
                            {{ form.errors.get('type') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-datetime-picker
                            v-model="form.dateTime"
                            type="date"
                            scrollable
                            label="Дата"
                            clearText="Удалить"
                            okText="Сохранить"
                            :datePickerProps="{'first-day-of-week': '1'}"
                            :timePickerProps="{'format': '24h'}">
                        </v-datetime-picker>
                        <v-alert
                            v-if="form.errors.has('dateTime')"
                            dense outlined
                            type="error"
                            :icon="false">
                            {{ form.errors.get('dateTime') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-autocomplete
                            v-model="form.executor"
                            label="Исполнитель"
                            :items="executors"
                            item-value="id"
                            item-text="fullName"
                            :clearable="true">
                        </v-autocomplete>
                        <v-alert
                            v-if="form.errors.has('executor')"
                            dense outlined
                            type="error"
                            :icon="false">
                            {{ form.errors.get('executor') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-autocomplete
                            v-model="form.substitution"
                            label="Замена"
                            :items="executors"
                            item-value="id"
                            item-text="fullName"
                            :clearable="true">
                        </v-autocomplete>
                        <v-alert
                            v-if="form.errors.has('substitution')"
                            dense outlined
                            type="error"
                            :icon="false">
                            {{ form.errors.get('substitution') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea
                            label="Комментарий"
                            v-model="form.comment">
                        </v-textarea>
                        <v-alert
                            v-if="form.errors.has('comment')"
                            dense outlined
                            type="error"
                            :icon="false">
                            {{ form.errors.get('comment') }}
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    v-show="!form.busy"
                    color="blue darken-1"
                    text @click="dialog = false">
                    Отмена
                </v-btn>
                <v-btn
                    v-show="!form.busy"
                    color="blue darken-1"
                    text
                    @click="save">
                    Сохранить
                </v-btn>
                <v-progress-circular
                    v-show="form.busy"
                    indeterminate
                    color="primary">
                </v-progress-circular>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Form from "../../_helpers/_form/Form";
import ContractService from "@/_services/ContractService";
import ContractEventService from "@/_services/ContractEventService";

export default {
    name: 'EventDialog',
    components: {},
    props: {
        id: Number,
        contractId: Number,
        types: Array,
        executors: Array
    },
    data() {
        return {
            form: new Form({}),
            dialog: false,
            loading: false,
        }
    },
    watch: {
        dialog(visible) {
            if (visible) {
                if (!this.id) {
                    return;
                }
                this.loading = true;
                ContractEventService.getOne(this.id).then(data => {
                    this.form = new Form(data.data);
                    this.loading = false;
                }, error => {
                    this.loading = false;
                    this.flashError(this.$helper.showError(error));
                });
            }
        }
    },
    methods: {
        save() {
            if (this.id) {
                ContractEventService.updateEvent(this.id, this.form).then(() => {
                    this.dialog = false;
                    this.$emit('saved');
                }, error => {
                    this.loading = false;
                    this.flashError(this.$helper.showError(error));
                });
            } else {
                ContractService.addEvent(this.contractId, this.form).then(() => {
                    this.dialog = false;
                    this.$emit('saved');
                }, error => {
                    this.loading = false;
                    this.flashError(this.$helper.showError(error));
                });
            }
        },
    },
};
</script>
