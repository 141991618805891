import {http} from "../_helpers";
import Service from "./Service";

class ClientNotificationService extends Service {

    getAll(options, filter) {
        return http.get(this.makeOptionsUrl(`/client-notifications/filter`, options, filter));
    }

    create(form) {
        return http.post(`/client-notifications`, form);
    }
}

export default new ClientNotificationService;
