<template>
    <div>
        <div class="display-1">{{code}}</div>
        <p>{{message}}</p>
    </div>
</template>

<script>
    export default {
        name: "Error",
        data() {
            return {
                code: this.$route.query.code || 404,
                message: this.$route.query.message || 'Page Not Found.'
            }
        },
    }
</script>
