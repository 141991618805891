import {http} from "../_helpers";
import Service from "./Service";

class ReportService extends Service {

    getReport(filter) {
        return http.get(this.makeOptionsUrl(`/reports`, {}, filter));
    }

    getLawyersReport(filter) {
        return http.get(this.makeOptionsUrl(`/reports/lawyer`, {}, filter));
    }

    getLawyerDepartmentReport(filter) {
        return http.get(this.makeOptionsUrl(`/reports/lawyer/department`, {}, filter));
    }

    getLawyerEPOReport(filter) {
        return http.get(this.makeOptionsUrl(`/reports/lawyer/epo`, {}, filter));
    }
}

export default new ReportService;
