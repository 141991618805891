var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "500px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          staticClass: "mx-4",
          attrs: {
            "large": "",
            "color": "yellow",
            "outlined": ""
          }
        }, on), [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-calendar-clock")]), _vm.isNewAppointment ? _c('div', [_vm._v("Записать")]) : _vm._e(), !_vm.isNewAppointment ? _c('div', [_vm._v("Изменить запись")]) : _vm._e()], 1), !_vm.isNewAppointment && _vm.request.isOnlineAppointment ? _c('div', {
          staticClass: "mx-4 mt-2"
        }, [_c('v-chip', {
          attrs: {
            "outlined": ""
          }
        }, [_c('v-icon', {
          attrs: {
            "small": "",
            "left": ""
          }
        }, [_vm._v("mdi-laptop")]), _vm._v(" Онлайн ")], 1)], 1) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v("Записать на прием")])]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "prepend-icon": "mdi-office-building",
      "label": "Офис",
      "items": _vm.offices,
      "item-text": "name",
      "item-value": "id"
    },
    model: {
      value: _vm.form.office,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "office", $$v);
      },
      expression: "form.office"
    }
  }), _vm.form.errors.has('office') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('office')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-dialog', {
    ref: "dateDialog",
    attrs: {
      "return-value": _vm.form.appointmentDate,
      "persistent": "",
      "width": "290px"
    },
    on: {
      "update:returnValue": function updateReturnValue($event) {
        return _vm.$set(_vm.form, "appointmentDate", $event);
      },
      "update:return-value": function updateReturnValue($event) {
        return _vm.$set(_vm.form, "appointmentDate", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "Дата",
            "prepend-icon": "mdi-calendar-month-outline",
            "readonly": ""
          },
          model: {
            value: _vm.form.appointmentDate,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "appointmentDate", $$v);
            },
            expression: "form.appointmentDate"
          }
        }, on))];
      }
    }]),
    model: {
      value: _vm.dateDialog,
      callback: function callback($$v) {
        _vm.dateDialog = $$v;
      },
      expression: "dateDialog"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "type": "date",
      "scrollable": "",
      "first-day-of-week": "1",
      "min": new Date().toISOString().slice(0, 10)
    },
    model: {
      value: _vm.form.appointmentDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "appointmentDate", $$v);
      },
      expression: "form.appointmentDate"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.dateDialog = false;
      }
    }
  }, [_vm._v("Отмена")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.dateDialog.save(_vm.form.appointmentDate);
      }
    }
  }, [_vm._v(" OK ")])], 1)], 1), _vm.form.errors.has('appointmentDate') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('appointmentDate')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "label": "Время",
      "items": _vm.times,
      "required": ""
    },
    model: {
      value: _vm.form.appointmentTime,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "appointmentTime", $$v);
      },
      expression: "form.appointmentTime"
    }
  }), _vm.form.errors.has('appointmentTime') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('appointmentTime')) + " ")]) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "chekbox__blue",
    attrs: {
      "label": "Онлайн"
    },
    model: {
      value: _vm.form.isOnlineAppointment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isOnlineAppointment", $$v);
      },
      expression: "form.isOnlineAppointment"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("Отмена")]), _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Записать")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }