<template>
    <div>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader type="list-item-avatar-two-line" class="mx-auto"></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-card v-if="!loading">
            <vuetify-audio :file="audioUrl" color="success" autoPlay downloadable></vuetify-audio>
        </v-card>
    </div>
</template>

<script>

    export default {
        name: "PlayRecord",
        components:{
            VuetifyAudio: () => import('vuetify-audio'),
        },
        props: {
            audioUrl: null
        },
        data() {
            return {
                loading: false
            }
        },
        watch: {
            audioStream: {
                handler() {
                    //this.fetch();
                }
            }
        },
        methods: {
            fetch() {
            }
        },
        created() {
        }
    }
</script>

<style scoped>

</style>
