<template>
    <div>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader
                        type="heading, table-row@3, button"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <div class="my-10" v-if="!loading">
            <v-card-title>
                Акты
                <v-spacer></v-spacer>
                <ActDialog
                        :id="id"
                        :lawyersOGPA="lawyersOGPA"
                        :lawyersEPO="lawyersEPO"
                        :actTypes="actTypes"
                        @saved="onItemAdd"
                        v-if="$helper.permission.canCreateContract(user)">
                </ActDialog>
            </v-card-title>
            <v-data-table
                    :headers="headers"
                    :items="items"
                    class="elevation-1"
                    item-key="id"
                    :loading="loading"
                    loading-text="Загрузка ..."
                    :items-per-page="50">
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td width="20" class="px-5">
                            <v-dialog v-model="deleteDialog[item.id]" max-width="320"
                                      v-if="$helper.permission.canDeleteRequest(item, user)">
                                <template v-slot:activator="{ on }">
                                    <v-icon small color="red" v-on="on">mdi-delete</v-icon>
                                </template>
                                <v-card>
                                    <v-card-text class="text-center pt-7">Удалить выбранный элемент?</v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn text @click="deleteDialog[item.id] = false">Отмена</v-btn>
                                        <v-btn color="red" text @click="deleteItem(item.id)">Удалить</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </td>
                        <td width="160">{{ $moment(item.date).dateFormat() }}</td>
                        <td width="150">{{ item.type.text }}</td>
                        <td width="160">{{ item.signDate }}</td>
                        <td width="160"><router-link :to="`/acts/${item.id}/edit`">{{ item.number }}</router-link></td>
                        <td width="200">
                            <span v-if="item.type?.value === 'ACT'">{{ item.amount }}</span>
                        </td>
                        <td width="200">
                            <span v-if="item.type?.value === 'PAYOUT'">{{ item.amount }}</span>
                        </td>
                        <td width="200"></td>
                        <td><div v-if="item.createdBy">{{ item.createdBy.fullName }}</div></td>
                        <td width="200">
                            <div v-if="item.installment">{{ $helper.getInstallmentName(item.installment) }}</div>
                        </td>
                        <td><div v-if="item.comment">{{ item.comment }}</div></td>
                    </tr>
                    <tr class="result-row">
                        <td v-for="(header, i) in headers" :key="i">
                            <div v-if="i == 0">Всего:</div>
                            <div v-if="header.value == 'amountAct'">
                                {{ $helper.format.currency(totalActAmount) }}
                            </div>
                            <div v-if="header.value == 'amountPayout'">
                                {{ $helper.format.currency(totalPayoutAmount) }}
                            </div>
                            <div v-if="header.value == 'remainder'">
                                {{ $helper.format.currency(contractPaymentRemainder) }}
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
    import ContractService from "../../_services/ContractService";
    import ActDialog from "./ActDialog";
    import {mapState} from 'vuex';

    export default {
        components: {ActDialog},
        props: {
            id: Number,
            contract: Object,
            lawyersOGPA: Array,
            lawyersEPO: Array
        },
        data() {
            return {
                headers: [
                    {sortable: false},
                    {text: 'Дата создания', value: 'createdAt'},
                    {text: 'Тип', value: 'type.text'},
                    {text: 'Дата подписания', value: 'signDate'},
                    {text: 'Номер', value: 'number'},
                    {text: 'Сумма актов', value: 'amountAct'},
                    {text: 'Сумма выплат', value: 'amountPayout'},
                    {text: 'Остаток', value: 'remainder'},
                    {text: 'Создан', value: 'createdBy'},
                    {text: 'Рассрочка', value: 'installment'},
                    {text: 'Примечание', value: 'comment'},
                ],
                items: [],
                actTypes: [],
                deleteDialog: {},
                loading: true
            }
        },
        methods: {
            fetch() {
                this.loading = true;
                this.fetchItems();
            },
            fetchItems() {
                ContractService.getActs(this.id).then(data => {
                    this.items = data.page.content;
                    this.actTypes = data.actTypes;
                    this.loading = false;
                });
            },
            onItemAdd() {
                this.fetchItems();
            },
            deleteItem(id) {
                ContractService.deleteAct(id).then(() => {
                    this.fetchItems();
                });
            }
        },
        computed: {
            ...mapState(['user']),
            totalActAmount: function() {
                let totalAmount = 0;
                this.items.filter(el => el?.type?.value === 'ACT').forEach((act) => {
                    totalAmount += act.amount
                });
                return totalAmount;
            },
            totalPayoutAmount: function() {
                let totalAmount = 0;
                this.items.filter(el => el?.type?.value === 'PAYOUT').forEach((act) => {
                    totalAmount += act.amount
                });
                return totalAmount;
            },
            contractPaymentRemainder: function() {
                let paymentRemainder = this.contract.amount || 0;
                return paymentRemainder - this.totalActAmount;
            }
        },
        created() {
            this.fetch();
        }
    };
</script>
