var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-title', [_vm._v(" Редактирование договора "), _c('div', {
    staticClass: "text-right mx-3 button-close-edit"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "error",
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('closeEdit');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-close-circle-outline")])], 1), _vm.contract !== null ? _c('v-btn', {
    staticClass: "ml-4",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('forceUpdateForm');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-reload")])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "ml-2 my-3"
  }, [_c('v-btn', {
    attrs: {
      "color": "deep-purple darken-2"
    },
    on: {
      "click": function click($event) {
        _vm.reminderDialogVisible = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-calendar-clock")]), _c('div', [_vm._v("Добавить напоминание")])], 1), _c('ReminderDialog', {
    attrs: {
      "contractId": _vm.id,
      "dialogVisible": _vm.reminderDialogVisible
    },
    on: {
      "close": function close($event) {
        _vm.reminderDialogVisible = false;
      },
      "save": _vm.onRemindAdd
    }
  })], 1), _vm.contract !== null && _vm.$helper.permission.canSendContractForDocumentsPreparation(_vm.user) ? _c('div', {
    staticClass: "ml-3 pl-2 my-3"
  }, [_c('v-btn', {
    attrs: {
      "color": "pink darken-2"
    },
    on: {
      "click": function click($event) {
        return _vm.sendForDocumentsPreparation();
      }
    }
  }, [_c('div', [_vm._v("Отправить на подготовку документов")])])], 1) : _vm._e(), _vm.contract !== null && _vm.$helper.permission.canSetDocumentsAreReadyAt(_vm.user) ? _c('div', {
    staticClass: "ml-3 pl-2 my-3"
  }, [_c('v-btn', {
    attrs: {
      "color": "green accent-3"
    },
    on: {
      "click": function click($event) {
        return _vm.setDocumentsAreReadyAt();
      }
    }
  }, [_c('div', [_vm._v("Документы готовы к выдаче")])])], 1) : _vm._e(), _vm.contract !== null && _vm.$helper.permission.canSetTransferredToOGPAAt(_vm.user) ? _c('div', {
    staticClass: "ml-3 pl-2 my-3"
  }, [_c('v-btn', {
    attrs: {
      "color": "teal darken-1"
    },
    on: {
      "click": function click($event) {
        return _vm.setDosudebkaEPO();
      }
    }
  }, [_c('div', [_vm._v("Досудебка ЭПО")])])], 1) : _vm._e(), _vm.contract !== null && _vm.$helper.permission.canSetTransferredToOGPAAt(_vm.user) ? _c('div', {
    staticClass: "ml-3 pl-2 my-3"
  }, [_c('v-btn', {
    attrs: {
      "color": "teal darken-1"
    },
    on: {
      "click": function click($event) {
        return _vm.setTransferredToCourt();
      }
    }
  }, [_c('div', [_vm._v("Передано на подачу в суд")])])], 1) : _vm._e(), _vm.contract !== null && _vm.$helper.permission.canSetTransferredToOGPAAt(_vm.user) ? _c('div', {
    staticClass: "ml-3 pl-2 my-3"
  }, [_c('v-btn', {
    attrs: {
      "color": "deep-purple lighten-1"
    },
    on: {
      "click": function click($event) {
        return _vm.setSentToCourt();
      }
    }
  }, [_c('div', [_vm._v("Подано в суд")])])], 1) : _vm._e(), _vm.contract !== null && _vm.$helper.permission.canSetTransferredToOGPAAt(_vm.user) ? _c('div', {
    staticClass: "ml-3 pl-2 my-3"
  }, [_c('v-btn', {
    attrs: {
      "color": "yellow accent-2 black--text"
    },
    on: {
      "click": function click($event) {
        return _vm.setTransferredToOGPAAt();
      }
    }
  }, [_c('div', [_vm._v("Передать в ОГПиА")])])], 1) : _vm._e(), _vm.contract !== null && _vm.$helper.permission.canSetTransferredToOGPAAt(_vm.user) ? _c('div', {
    staticClass: "ml-3 pl-2 my-3"
  }, [_c('v-btn', {
    attrs: {
      "color": "deep-purple lighten-1"
    },
    on: {
      "click": function click($event) {
        return _vm.setPackageFromLenyr();
      }
    }
  }, [_c('div', [_vm._v("Пакет от ЛенЮр")])])], 1) : _vm._e(), _vm.contract !== null && _vm.$helper.permission.canSetTransferredToOGPAAt(_vm.user) ? _c('div', {
    staticClass: "ml-3 pl-2 my-3"
  }, [_c('v-btn', {
    attrs: {
      "color": "deep-purple lighten-1"
    },
    on: {
      "click": function click($event) {
        return _vm.setSentToOKK();
      }
    }
  }, [_c('div', [_vm._v("Передать в ОКК")])])], 1) : _vm._e(), _vm.contract !== null && _vm.$helper.permission.canSetTransferredToOGPAAt(_vm.user) ? _c('div', {
    staticClass: "ml-3 pl-2 my-3"
  }, [_c('v-btn', {
    attrs: {
      "color": "orange darken-4"
    },
    on: {
      "click": function click($event) {
        return _vm.setAdditionalPackageIsReady();
      }
    }
  }, [_c('div', [_vm._v("Доп. пакет готов к выдаче")])])], 1) : _vm._e(), _c('div', {
    staticClass: "ml-4"
  }, [_vm.prevContract ? _c('v-btn', {
    attrs: {
      "fab": "",
      "text": "",
      "color": "white darken-2"
    },
    on: {
      "click": function click($event) {
        _vm.changeEdited(_vm.prevContract.toString());
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1) : _vm._e(), _vm.nextContract ? _c('v-btn', {
    attrs: {
      "fab": "",
      "text": "",
      "color": "white darken-2"
    },
    on: {
      "click": function click($event) {
        _vm.changeEdited(_vm.nextContract.toString());
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1) : _vm._e()], 1)]), _vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, table-row@3, button"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading ? _c('div', [_vm.contract !== null ? _c('ContractForm', {
    key: _vm.formKey,
    attrs: {
      "page": _vm.page
    },
    on: {
      "updated": function updated($event) {
        return _vm.$emit('updated');
      },
      "closeAndUpdate": function closeAndUpdate($event) {
        return _vm.$emit('closeAndUpdate');
      },
      "recallSelected": _vm.onRecallSelected
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.inPopup ? _c('ScrollToTop', {
    staticStyle: {
      "right": "40px"
    },
    attrs: {
      "alwaysShow": "",
      "elementSelector": _vm.scrollToTopSelector
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }