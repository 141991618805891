import {http} from "../_helpers";

class SourceService {

    getAll() {
        return http.get(`/sources`);
    }
}

export default new SourceService;
