import {http} from "../_helpers";
import Service from "./Service";

class CallService extends Service {

    getAll(options, filter) {
        return http.get(this.makeOptionsUrl(`/calls`, options, filter));
    }

    getRecord(recordingId) {
        return http.get(`/calls/records/${recordingId}`);
    }

    callback(requestId) {
        return http.post(`/webHooks/mango/callback/${requestId}`);
    }
}

export default new CallService;
