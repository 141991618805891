<template>
    <div class="my-10">
        <v-card-title>
            Ответы юриста
            <v-spacer></v-spacer>
            <AnswerDialog :id="request.id" :request="request" :areas="areas" @saved="onAnswerAdd"></AnswerDialog>
        </v-card-title>
        <v-data-table :headers="headers" :items="answers" class="elevation-1" item-key="id"
                      loading-text="Загрузка ..." :items-per-page="5">
            <template v-slot:body="{ items }">
                <tbody>
                <tr v-for="item in items" :key="item.id">
                    <td width="220">{{ item.createdAt }}</td>
                    <td width="220">{{ item.user.fullName }}</td>
                    <td>{{ item.text }}</td>
                </tr>
                </tbody>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import RequestService from "../../_services/RequestService";
    import AnswerDialog from "./AnswerDialog";

    export default {
        name: "RequestAnswers",
        components: {AnswerDialog},
        props: {
            request: Object,
            areas: Array
        },
        data() {
            return {
                headers: [
                    {text: 'Дата', value: 'createdAt'},
                    {text: 'Пользователь', value: 'user'},
                    {text: 'Текст', value: 'text'}
                ],
                answers: this.request.answers,
                loading: true
            }
        },
        methods: {
            fetch() {
                this.loading = true;
                RequestService.getAnswers(this.request.id).then(data => {
                    this.answers = data;
                    this.loading = false;
                });
            },
            onAnswerAdd() {
                this.fetch();
            }
        }
    }
</script>

<style scoped>

</style>
