import {http} from "../_helpers";
import Service from "./Service";

class SubdivisionService extends Service {

    getAll(options, filter) {
        return http.get(this.makeOptionsUrl(`/subdivisions`, options, filter));
    }

    getAlls() {
        return http.get(`/subdivisions?size=100`)
    }

    create(form) {
        return http.post(`/subdivisions`, form);
    }

    update(id, form) {
        return http.put(`/subdivisions/${id}`, form);
    }

    delete(id) {
        return http.delete(`/subdivisions/${id}`);
    }

    getOne(id) {
        return http.get(`/subdivisions/${id}`);
    }

}

export default new SubdivisionService;
