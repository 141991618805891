var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-switch', {
    attrs: {
      "prepend-icon": _vm.muteIcon
    },
    model: {
      value: _vm.unMute,
      callback: function callback($$v) {
        _vm.unMute = $$v;
      },
      expression: "unMute"
    }
  })], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-switch', {
    attrs: {
      "prepend-icon": "mdi-message-outline"
    },
    model: {
      value: _vm.localSettings.unNotify,
      callback: function callback($$v) {
        _vm.$set(_vm.localSettings, "unNotify", $$v);
      },
      expression: "localSettings.unNotify"
    }
  })], 1)], 1), _vm.$helper.permission.canChangeOperatorAutoAssign(_vm.user) ? _c('v-list-item', [_c('v-list-item-content', [_c('v-label', {
    staticClass: "font-size__small"
  }, [_vm._v("Автораспределение заявок")]), _c('v-switch', {
    attrs: {
      "prepend-icon": "mdi-refresh-auto"
    },
    model: {
      value: _vm.autoAssignOperator,
      callback: function callback($$v) {
        _vm.autoAssignOperator = $$v;
      },
      expression: "autoAssignOperator"
    }
  })], 1)], 1) : _vm._e(), _vm.$helper.permission.canSetOutOfPlace(_vm.user) ? _c('v-list-item', [_c('v-list-item-content', [_c('v-label', {
    staticClass: "font-size__small"
  }, [_vm._v("Не на месте")]), _c('v-switch', {
    attrs: {
      "prepend-icon": "mdi-account-cancel"
    },
    model: {
      value: _vm.outOfPlace,
      callback: function callback($$v) {
        _vm.outOfPlace = $$v;
      },
      expression: "outOfPlace"
    }
  })], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }