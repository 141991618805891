var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, table-row@3, button"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading ? _c('v-card', [_c('v-card-text', [_c('form', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "8"
    }
  }, [_c('v-row', [_c('v-col', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Название"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _vm.form.errors.has('name') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('name')) + " ")]) : _vm._e()], 1)], 1), _vm.subdivision ? _c('v-row', _vm._l(_vm.sources, function (source, index) {
    return _c('v-col', {
      key: source.id,
      attrs: {
        "sm": "12",
        "md": "6",
        "xl": "4"
      }
    }, [_vm._v(" " + _vm._s(source.name) + " "), _c('v-row', [_c('v-col', [_c('v-text-field', {
      attrs: {
        "label": "Процент заявок"
      },
      model: {
        value: _vm.form.sources[index].percentageOfReceivedRequests,
        callback: function callback($$v) {
          _vm.$set(_vm.form.sources[index], "percentageOfReceivedRequests", $$v);
        },
        expression: "form.sources[index].percentageOfReceivedRequests"
      }
    })], 1)], 1)], 1);
  }), 1) : _vm._e()], 1)], 1), _vm.form.errors.any() ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" Исправьте ошибки ")]) : _vm._e(), _vm.form.errors.has('error') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('error')) + " ")]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.form.busy,
      expression: "!form.busy"
    }]
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        _vm.subdivision ? _vm.update(false) : _vm.save(false);
      }
    }
  }, [_vm._v(" Сохранить ")])], 1), _c('v-progress-circular', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.busy,
      expression: "form.busy"
    }],
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  })], 1)], 1)], 1)])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }