<template>
    <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on }">
            <v-btn color="green" v-on="on">
                Добавить комментарий
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Добавить комментарий</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-select
                                label="Тип комментария"
                                v-model="form.type"
                                :items="types"
                                :clearable="true">
                        </v-select>
                        <v-alert
                                v-if="form.errors.has('type')"
                                 dense outlined
                                 type="error"
                                 :icon="false">
                            {{ form.errors.get('type') }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea
                                auto-grow
                                v-model="form.text"
                                label="Комментарий">
                        </v-textarea>
                        <v-alert v-if="form.errors.has('text')" dense outlined type="error" :icon="false">
                            {{ form.errors.get('text') }}
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">Отмена</v-btn>
                <v-btn color="blue darken-1" text @click="save">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Form from "../../_helpers/_form/Form";
    import ContractService from "../../_services/ContractService";

    export default {

        props: {
            id: Number,
            types: Array
        },
        data() {
            return {
                form: new Form({}),
                dialog: false
            }
        },
        methods: {
            save() {
                ContractService.addComment(this.id, this.form).then(() => {
                    this.dialog = false;
                    this.$emit('saved');
                });
            },
        }
    };
</script>
