<template>
    <div>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader type="heading, table-row@3, button"></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-card v-if="!loading">
            <v-card-text>
                <form>
                    <v-row>
                        <v-col cols="12" xl="9">
                            <v-row v-if="client !== null">
                                <v-col>
                                    <v-text-field
                                        :value="form.id"
                                        disabled="disabled"
                                        label="ID">
                                    </v-text-field>
                                </v-col>
                                <v-col>
                                    <v-select
                                        v-model="form.state"
                                        :items="states"
                                        :disabled="!isManualDrivenState(form.state) && !$helper.permission.canChangeNotManualState(user, form.state)"
                                        v-on:change="onChangeState"
                                        label="Статус"
                                        :clearable="true">
                                    </v-select>
                                    <v-alert v-if="form.errors.has('state')" dense outlined type="error" :icon="false">
                                        {{ form.errors.get('state') }}
                                    </v-alert>
                                </v-col>
                                <v-col cols="auto">
                                    <v-checkbox
                                        class="chekbox__yellow"
                                        v-model="form.isOnline"
                                        label="Онлайн"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="auto">
                                    <v-checkbox
                                        class="chekbox__orange"
                                        v-model="form.isDouble"
                                        label="Вторичный"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col v-if="$helper.permission.canAssignClient(user)">
                                    <v-autocomplete
                                        v-model="form.lawyer"
                                        label="Юрист" :items="lawyers"
                                        item-value="id"
                                        item-text="fullName"
                                        :clearable="true">
                                    </v-autocomplete>
                                    <v-alert v-if="form.errors.has('lawyer')" dense outlined type="error" :icon="false">
                                        {{ form.errors.get('lawyer') }}
                                    </v-alert>
                                </v-col>
                                <v-col v-if="$helper.permission.canAssignClient(user)">
                                    <v-autocomplete
                                        v-model="form.lawyerAssistant"
                                        label="Помощник юриста"
                                        :items="lawyers"
                                        item-value="id"
                                        item-text="fullName"
                                        :clearable="true">
                                    </v-autocomplete>
                                    <v-alert v-if="form.errors.has('lawyerAssistant')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('lawyerAssistant') }}
                                    </v-alert>
                                </v-col>
                                <v-col v-if="$helper.permission.canChangeSubdivision(user)">
                                    <v-autocomplete
                                        v-model="form.subdivision"
                                        label="Подразделение"
                                        :items="subdivisions"
                                        item-value="id"
                                        item-text="name"
                                        :clearable="true">
                                    </v-autocomplete>
                                    <v-alert v-if="form.errors.has('subdivision')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('subdivision') }}
                                    </v-alert>
                                </v-col>
                                <v-col v-if="$helper.permission.canChangeSubdivision(user)">
                                    <v-autocomplete
                                        v-model="form.office"
                                        label="Офис"
                                        :items="offices"
                                        item-value="id"
                                        item-text="name"
                                        :clearable="true">
                                    </v-autocomplete>
                                    <v-alert v-if="form.errors.has('office')" dense outlined type="error" :icon="false">
                                        {{ form.errors.get('office') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-autocomplete
                                        v-model="form.additionalLawyer"
                                        label="Доп. юрист" :items="lawyers"
                                        item-value="id"
                                        item-text="fullName"
                                        :clearable="true">
                                    </v-autocomplete>
                                    <v-alert v-if="form.errors.has('additionalLawyer')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('additionalLawyer') }}
                                    </v-alert>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        v-model="form.fromSource"
                                        label="От кого">
                                    </v-text-field>
                                    <v-alert v-if="form.errors.has('fromSource')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('fromSource') }}
                                    </v-alert>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        v-model="form.subject"
                                        label="Тема обращения">
                                    </v-text-field>
                                    <v-alert v-if="form.errors.has('subject')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('subject') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col v-if="client !== null">
                                    <v-text-field
                                        :value="form.createdAt"
                                        disabled="disabled"
                                        label="Дата создания">
                                    </v-text-field>
                                </v-col>
                                <v-col v-if="client !== null">
                                    <v-text-field
                                        :value="form.assignedAt"
                                        disabled="disabled"
                                        label="Дата привязки">
                                    </v-text-field>
                                </v-col>
                                <v-col>
                                    <v-autocomplete
                                        v-model="form.area"
                                        :items="areas"
                                        item-value="id"
                                        item-text="name"
                                        label="Область права"
                                        :clearable="true">
                                    </v-autocomplete>
                                    <v-alert v-if="form.errors.has('area')" dense outlined type="error" :icon="false">
                                        {{ form.errors.get('area') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="form.userName" label="Имя"></v-text-field>
                                    <v-alert
                                        v-if="form.errors.has('userName')"
                                        dense
                                        outlined
                                        type="error"
                                        :icon="false">
                                        {{ form.errors.get('userName') }}
                                    </v-alert>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        v-model="form.userBirthday"
                                        label="Год рождения"
                                        v-mask="'##.##.####'"
                                    ></v-text-field>
                                    <v-alert v-if="form.errors.has('userBirthday')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('userBirthday') }}
                                    </v-alert>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        v-model="form.userPhone" label="Телефон"
                                        v-mask="'+7 (###) ###-##-## #'">
                                    </v-text-field>
                                    <v-alert v-if="form.errors.has('userPhone')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('userPhone') }}
                                    </v-alert>
                                </v-col>
                                <v-col v-if="form.userPhone">
                                    <a :href="`tel:${form.userPhone}`" style="text-decoration: none">
                                        <v-btn small outlined color="green">
                                            <v-icon left small style="margin: 0">mdi-phone</v-icon>
                                        </v-btn>
                                    </a>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        v-model="form.additionalPhone" label="Доп. телефон"
                                        v-mask="'+7 (###) ###-##-## #'">
                                    </v-text-field>
                                    <v-alert v-if="form.errors.has('userPhoneadditionalPhone')" dense outlined type="error" :icon="false">
                                        {{ form.errors.get('additionalPhone') }}
                                    </v-alert>
                                </v-col>
                                <v-col v-if="form.additionalPhone">
                                    <a :href="`tel:${form.additionalPhone}`" style="text-decoration: none">
                                        <v-btn small outlined color="green">
                                            <v-icon left small style="margin: 0">mdi-phone</v-icon>
                                        </v-btn>
                                    </a>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="form.userEmail" label="Email"></v-text-field>
                                    <v-alert v-if="form.errors.has('userEmail')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('userEmail') }}
                                    </v-alert>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="form.principal" label="Доверитель"></v-text-field>
                                    <v-alert v-if="form.errors.has('principal')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('principal') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-textarea auto-grow v-model="form.comment" label="Комментарий"
                                                rows="2"></v-textarea>
                                    <v-alert v-if="form.errors.has('comment')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('comment') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-textarea auto-grow v-model="form.problem" label="Проблема"></v-textarea>
                                    <v-alert v-if="form.errors.has('problem')" dense outlined type="error"
                                             :icon="false">
                                        {{ form.errors.get('problem') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" xl="3">

                        </v-col>
                    </v-row>
                </form>
            </v-card-text>
        </v-card>

        <div>
            <v-alert v-if="form.errors.any()" dense outlined type="error" :icon="false">
                Исправьте ошибки
            </v-alert>
            <v-alert v-if="form.errors.has('error')" dense outlined type="error" :icon="false">
                {{ form.errors.get('error') }}
            </v-alert>
            <div v-show="!form.busy">
                <v-btn
                    v-if="$helper.permission.canUpdateContract(user)"
                    large color="green"
                    @click="(client && client.id) ? update(false) : save(false)">
                    Сохранить
                </v-btn>
                <v-btn
                    v-if="$helper.permission.canUpdateContract(user)"
                    large
                    color="green"
                    @click="(client && client.id) ? update(true) : save(false)"
                    class="ml-4">
                    Сохранить и закрыть
                </v-btn>
            </div>
            <v-progress-circular v-show="form.busy" indeterminate color="primary"></v-progress-circular>
        </div>

        <ClientVisits
            v-if="client && client.id"
            :id="client.id">
        </ClientVisits>

        <div
            v-if="$helper.permission.canSeeRequest(user)"
            class="my-10">
            <RequestGrid
                v-if="client"
                :title="`Заявки клиента с номером: ${client.userPhone}`"
                :filter="{cleanPhone: client.cleanPhone, isDoubles: true}">
            </RequestGrid>
        </div>

        <CallsGrid
            v-if="client && $helper.permission.canSeeCalls(user)"
            :title="`Манго: вызовы для номера: ${client.userPhone}`"
            :filter="{clientPhone: client.cleanPhone}">
        </CallsGrid>

        <ContractCalls
            v-if="client && $helper.permission.canSeeContractCalls(user) && client.userPhone"
            :title="`МТС: исх. вызовы для номера: ${client.userPhone}`"
            :filter="{callingNumber: client.userPhone.replace(/\D+/g, '')}">
        </ContractCalls>

        <ContractCalls
            v-if="client && $helper.permission.canSeeContractCalls(user) && client.userPhone"
            :title="`МТС: вх. вызовы для номера: ${client.userPhone}`"
            :filter="{calledNumber: client.userPhone.replace(/\D+/g, '')}">
        </ContractCalls>

        <ClientReport
            v-if="(client && client.id)"
            :client-id="client.id"
            :contract-form="form"
            class="mb-5"
            :key="clientReportKey"
            :defaultExpand="clientReportKey !== 0"
            @updated="clientReportKey++">
        </ClientReport>

        <ClientComments
            v-if="client && client.id"
            :id="client.id">
        </ClientComments>

        <ContractGrid
            v-if="client && $helper.permission.canSeeContracts(user)"
            title="Договоры"
            :filter="{client: client.id}">
        </ContractGrid>
    </div>
</template>

<script>
import {Form} from '../_helpers/_form';
import ClientService from "../_services/ClientService";
import {mapState} from 'vuex';
import ClientComments from "../clients/comments/ClientComments";
import CallsGrid from "../calls/CallsGrid";
import RequestGrid from "../requests/RequestGrid";
import ClientVisits from "./visits/ClientVisits";
import ContractGrid from "../contracts/ContractGrid";
import ContractCalls from "@/contracts/calls/ContractCalls";
import ClientReport from "@/clients/blocks/ClientReport.vue";

export default {
    name: "ClientForm",
    components: {ClientReport, ContractCalls, ContractGrid, ClientVisits, RequestGrid, CallsGrid, ClientComments},
    props: {
        page: {
            type: Object,
            default: null
        },
        inPopup: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            form: this.page.client
                ? new Form(this.page.client)
                : new Form({}),
            states: [], //only manual driven states are stored here
            allStates: [],
            lawyers: this.page.lawyers,
            subdivisions: this.page.subdivisions,
            offices: this.page.offices,
            client: this.page.client,
            areas: this.page.areas,
            clientReportKey: 0,
            loading: false,
        }
    },
    methods: {
        fetch() {
            this.fetchStates();
        },
        fetchStates() {
            let data = this.page.states;
            this.allStates = data;
            data = data.filter(state => {
                return !(state.manualDriven !== 'true' && this.form.state !== state.value);
            });
            data = data.filter(state => {
                return !(!this.$helper.permission.canSetState(this.user, state) && this.form.state !== state.value);
            });
            this.states = data;
        },
        update(needToExit) {
            ClientService.update(this.client.id, this.form).then(() => {
                if (needToExit) {
                    this.$emit('closeAndUpdate');
                } else {
                    this.$emit('updated');
                }
            });
        },
        save() {
            ClientService.create(this.form).then(() => {
                this.$router.push('/clients');
            });
        },
        isManualDrivenState(clientState) {
            let state = this.allStates.find(v => v.value === clientState);
            return state.manualDriven === 'true' || state.value === 'NEW';
        },
        onChangeState(state) {
            if (state === 'RECALL') {
                this.$emit('recallSelected')
            }
        }
    },
    computed: {
        ...mapState(['user'])
    },
    created() {
        this.fetch();
    }
}
</script>

<style lang="scss">

</style>
