var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('v-card-title', [_vm._v("Напоминания")])], 1), _c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "fab": "",
      "text": "",
      "color": "white darken-2"
    },
    on: {
      "click": _vm.prev
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1), _c('v-btn', {
    attrs: {
      "fab": "",
      "text": "",
      "color": "white darken-2"
    },
    on: {
      "click": _vm.next
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1), _c('v-toolbar', {
    attrs: {
      "flat": "",
      "hidden": ""
    }
  }, [_vm.$refs.calendar ? _c('v-toolbar-title', [_vm._v(" " + _vm._s(_vm.$refs.calendar.title) + " ")]) : _vm._e(), _c('v-spacer')], 1)], 1), _c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "mx-4",
    attrs: {
      "color": "green"
    },
    on: {
      "click": function click($event) {
        return _vm.showDialog(null);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-calendar-clock")]), _c('div', [_vm._v("Добавить напоминание")])], 1), _c('ReminderDialog', {
    attrs: {
      "id": _vm.editedItemId,
      "dialogVisible": _vm.dialogVisible
    },
    on: {
      "update:id": function updateId($event) {
        _vm.editedItemId = $event;
      },
      "close": _vm.dialogClose,
      "save": _vm.onRemindAdd,
      "delete": _vm.onRemindDelete
    }
  })], 1), _c('v-col', [_c('v-menu', {
    attrs: {
      "bottom": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "outlined": "",
            "color": "blue"
          }
        }, 'v-btn', attrs, false), on), [_c('span', [_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]), _c('v-icon', {
          attrs: {
            "right": ""
          }
        }, [_vm._v(" mdi-menu-down ")])], 1)];
      }
    }])
  }, [_c('v-list', [_c('v-list-item', {
    on: {
      "click": function click($event) {
        _vm.type = 'day';
      }
    }
  }, [_c('v-list-item-title', [_vm._v("День")])], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        _vm.type = 'week';
      }
    }
  }, [_c('v-list-item-title', [_vm._v("Неделя")])], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        _vm.type = 'month';
      }
    }
  }, [_c('v-list-item-title', [_vm._v("Месяц")])], 1)], 1)], 1)], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-calendar', {
    ref: "calendar",
    attrs: {
      "first-time": "08:00",
      "value": _vm.today,
      "events": _vm.events,
      "type": _vm.type,
      "event-color": _vm.getEventColor
    },
    on: {
      "change": _vm.fetchEvents,
      "click:event": _vm.editEvent
    },
    model: {
      value: _vm.focus,
      callback: function callback($$v) {
        _vm.focus = $$v;
      },
      expression: "focus"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }