<template>
    <div class="widget widget__birthday">
        <div v-if="isBirthdayToday">
            {{ name }} поздравляем с Днём Рождения! 🎉 🎈🎈🎈
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import moment from "moment/moment";

export default {
    name: 'Birthday',
    props: {},
    data() {
        return {
            isBirthdayToday: false
        }
    },
    methods: {
        checkTodayIsBirthday() {
            const birthday = this.user?.birthday;
            const today = moment().format("YYYY-MM-DD");
            if (today === birthday) {
                this.isBirthdayToday = true;
            }
        },
    },
    computed: {
        ...mapState(['user']),
        name() {
            return this.user?.firstName ? this.user?.firstName + ", " : "";
        }
    },
    created() {
        this.checkTodayIsBirthday();
        setInterval(() => this.checkTodayIsBirthday(), 5 * 60 * 1000);
    }
}
</script>
