var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card-text', [_c('div', [_c('v-card-title', [_vm._v(" Статистика ЭПО ")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('DateField', {
    attrs: {
      "label": "Дата",
      "range": true
    },
    model: {
      value: _vm.filter.dates,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "dates", $$v);
      },
      expression: "filter.dates"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        return _vm.fetch();
      }
    }
  }, [_vm._v(" Сформировать ")])], 1)], 1)], 1)], 1), _vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "table-row@3"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading ? _c('div', {
    staticStyle: {
      "overflow": "auto",
      "border-left": "thin solid rgba(255, 255, 255, 0.18) !important"
    }
  }, [_c('v-data-table', {
    staticClass: "table__lined",
    style: "min-width: ".concat(_vm.getTableWidth(), "px"),
    attrs: {
      "headers": _vm.headers,
      "items": _vm.page.items,
      "hide-default-footer": "",
      "disable-pagination": ""
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var items = _ref.items;
        return [_c('tbody', [_vm._l(items, function (item) {
          var _vm$getHeader, _vm$getHeader2, _item$lawyerContractS, _vm$getHeader3, _item$lawyerContractS2, _vm$getHeader4, _item$lawyerContractS3, _vm$getHeader5, _vm$getHeader6, _vm$getHeader7;
          return _c('tr', {
            key: item.id
          }, [_c('td', {
            attrs: {
              "width": "160px"
            }
          }, [_vm._v(_vm._s(item.name))]), _c('td', [_vm._v(_vm._s(item.sentForDocumentsActions))]), _c('td', [_vm._v(_vm._s(item.transferredToCourtActions))]), _c('td', [_vm._v(_vm._s(item.documentsAreReadyActions))]), _c('td', [_vm._v(" " + _vm._s(_vm.$helper.report.getPairKey(item === null || item === void 0 ? void 0 : item.additionalPackageIsReadyActions)) + " "), _c('ContractLinks', {
            attrs: {
              "contracts": _vm.$helper.report.getPairValue(item === null || item === void 0 ? void 0 : item.additionalPackageIsReadyActions)
            }
          })], 1), _c('td', [_vm._v(" " + _vm._s(_vm.$helper.report.getPairKey(item === null || item === void 0 ? void 0 : item.sentDosudebkaEPOActions)) + " "), _c('ContractLinks', {
            attrs: {
              "contracts": _vm.$helper.report.getPairValue(item === null || item === void 0 ? void 0 : item.sentDosudebkaEPOActions)
            }
          })], 1), _c('td', [_vm._v(" " + _vm._s(_vm.$helper.report.getPairKey(item === null || item === void 0 ? void 0 : item.sentToCourtActions)) + " "), _c('ContractLinks', {
            attrs: {
              "contracts": _vm.$helper.report.getPairValue(item === null || item === void 0 ? void 0 : item.sentToCourtActions)
            }
          })], 1), _c('td', [_vm._v(_vm._s((_vm$getHeader = _vm.getHeader('inProgressInEPO')) === null || _vm$getHeader === void 0 ? void 0 : _vm$getHeader.calculate(item)))]), _c('td', [_vm._v(" " + _vm._s((_vm$getHeader2 = _vm.getHeader('documents')) === null || _vm$getHeader2 === void 0 ? void 0 : _vm$getHeader2.calculate(item)) + " "), _c('ContractLinks', {
            attrs: {
              "contracts": _vm.$helper.report.getPairValue(item === null || item === void 0 ? void 0 : (_item$lawyerContractS = item.lawyerContractSubjects) === null || _item$lawyerContractS === void 0 ? void 0 : _item$lawyerContractS.DOCUMENTS)
            }
          })], 1), _c('td', [_vm._v(" " + _vm._s((_vm$getHeader3 = _vm.getHeader('departure')) === null || _vm$getHeader3 === void 0 ? void 0 : _vm$getHeader3.calculate(item)) + " "), _c('ContractLinks', {
            attrs: {
              "contracts": _vm.$helper.report.getPairValue(item === null || item === void 0 ? void 0 : (_item$lawyerContractS2 = item.lawyerContractSubjects) === null || _item$lawyerContractS2 === void 0 ? void 0 : _item$lawyerContractS2.DEPARTURE)
            }
          })], 1), _c('td', [_vm._v(" " + _vm._s((_vm$getHeader4 = _vm.getHeader('court')) === null || _vm$getHeader4 === void 0 ? void 0 : _vm$getHeader4.calculate(item)) + " "), _c('ContractLinks', {
            attrs: {
              "contracts": _vm.$helper.report.getPairValue(item === null || item === void 0 ? void 0 : (_item$lawyerContractS3 = item.lawyerContractSubjects) === null || _item$lawyerContractS3 === void 0 ? void 0 : _item$lawyerContractS3.COURT)
            }
          })], 1), _c('td', [_vm._v(_vm._s(_vm.$helper.format.currency(item.amount)))]), _c('td', [_vm._v(" " + _vm._s(_vm.$helper.format.currency(_vm.$helper.report.getPairKey(item === null || item === void 0 ? void 0 : item.payedAmount))) + " "), _c('ContractLinks', {
            attrs: {
              "contracts": _vm.$helper.report.getPairValue(item === null || item === void 0 ? void 0 : item.payedAmount)
            }
          })], 1), _c('td', [_vm._v(" " + _vm._s((_vm$getHeader5 = _vm.getHeader('riskEvents')) === null || _vm$getHeader5 === void 0 ? void 0 : _vm$getHeader5.calculate(item)) + " ")]), _c('td', [_vm._v(" " + _vm._s((_vm$getHeader6 = _vm.getHeader('terminations')) === null || _vm$getHeader6 === void 0 ? void 0 : _vm$getHeader6.calculate(item)) + " ")]), _c('td', [_vm._v(" " + _vm._s((_vm$getHeader7 = _vm.getHeader('packageToLenyr')) === null || _vm$getHeader7 === void 0 ? void 0 : _vm$getHeader7.calculate(item)) + " "), _c('ContractLinks', {
            attrs: {
              "contracts": _vm.$helper.report.getPairValue(item === null || item === void 0 ? void 0 : item.packageToLenyr)
            }
          })], 1)]);
        }), _c('tr', {
          staticClass: "result-row"
        }, _vm._l(_vm.headers, function (header, i) {
          return _c('td', {
            key: i
          }, [header.value === 'name' ? _c('div', [_vm._v("Всего:")]) : _vm._e(), header.value !== 'name' ? _c('div', [_vm._v(" " + _vm._s(_vm.$helper.format.number(_vm.total(header.value))) + " ")]) : _vm._e()]);
        }), 0)], 2)];
      }
    }], null, false, 1208467854)
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }