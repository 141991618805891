<template>
    <v-chart :options="option"/>
</template>

<script>
    import "echarts/lib/chart/bar";
    import "echarts/lib/component/legend";

    export default {
        name: "Charts",
        props: {
            states: Array,
            reportsTuples: Array,
            config: {
                default() {
                    return {
                        rotate: 90,
                        align: 'left',
                        verticalAlign: 'middle',
                        position: 'insideBottom',
                        distance: 15,
                        textStyle: {
                            color: "#fff",
                            fontFamily: 'Roboto'
                        }
                    }
                }
            }
        },
        computed:{
            option() {
                return {
                    color: ['#8b8b8b', '#6A1B9A', '#ccc22a', '#D50000', '#4CAF50', '#E57373', '#FF9800', '#18FFFF', '#18FFFF', '#FF8A80', '#2196F3', '#76FF03'],
                    width: '90%',
                    textStyle: this.config.textStyle,
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            //type: 'shadow'
                        }
                    },
                    legend: {
                        //data: this.states.map(el => el.title),
                        textStyle: this.config.textStyle,
                    },
                    xAxis: [
                        {
                            type: 'category',
                            axisTick: {show: false},
                            data: this.reportsTuples.map(el => el.item),
                            splitLine: {
                                lineStyle: {
                                    color: '#7a7a7a'
                                }
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            splitLine: {
                                lineStyle: {
                                    color: '#7a7a7a'
                                }
                            }
                        }
                    ],
                    series: this.makeSeries()
                }
            }
        },
        methods: {
            makeSeries() {
                let labelOption = {
                    show: false,
                    position: this.config.position,
                    distance: this.config.distance,
                    align: this.config.align,
                    verticalAlign: this.config.verticalAlign,
                    rotate: this.config.rotate,
                    formatter: '{name|{a}}',
                    fontSize: 16,
                    rich: {
                        name: {
                            color: '#fff',
                            textBorderWidth: 0,
                            shadowColor: 'none'
                        }
                    }
                };
                let series = [];
                this.states.forEach((el) => {
                    let data = [];
                    this.reportsTuples.forEach((item) => {
                        let row = item.tuples.find(tuple => tuple.state === el.value);
                        let percent = 0;
                        if (row) {
                            percent = row.count / item.count * 100;
                        }
                        data.push(percent);
                    });

                    series.push({
                        name: el.text,
                        type: 'bar',
                        barGap: 0,
                        label: labelOption,
                        data: data
                    });
                });
                return series;
            }
        }
    }
</script>

<style scoped>
    .echarts {
        padding-top: 4em;
        width: 90% !important;
    }
</style>
