import Errors from './Errors'
import {deepCopy} from './util'

class Form {
  /**
   * Create a new form instance.
   *
   * @param {Object} data
   */
  constructor(data = {}) {
    this.busy = false
    this.hasChanges = false
    this.successful = false
    this.errors = new Errors()
    this.originalData = deepCopy(data)
    this.multipartData = null

    Object.assign(this, data)
  }

  /**
   * Fill form data.
   *
   * @param {Object} data
   */
  fill(data) {
    this.keys().forEach(key => {
      this[key] = data[key]
    })
  }

  /**
   * Get the form data.
   *
   * @return {Object}
   */
  data() {
    let data = this.keys().reduce((data, key) => (
        {...data, [key]: this[key]}
    ), {})
    if (this.multipartData) {
      data = this.multipartData
    }
    return data
  }

  /**
   * Get the form data keys.
   *
   * @return {Array}
   */
  keys() {
    return Object.keys(this)
        .filter(key => !Form.ignore.includes(key))
  }

  /**
   * Start processing the form.
   */
  startProcessing() {
    this.errors.clear()
    this.busy = true
    this.successful = false
  }

  /**
   * Finish processing the form.
   */
  finishProcessing() {
    this.busy = false
    this.successful = true
  }

  /**
   * Clear the form errors.
   */
  clear() {
    this.errors.clear()
    this.successful = false
  }

  /**
   * Reset the form fields.
   */
  reset() {
    Object.keys(this)
        .filter(key => !Form.ignore.includes(key))
        .forEach(key => {
          this[key] = deepCopy(this.originalData[key])
        })
  }

  /**
   * Extract the errors from the response object.
   *
   * @param  {Object} response
   * @return {Object}
   */
  extractErrors(response) {
    if (!response.data || typeof response.data !== 'object') {
      return {error: Form.errorMessage}
    }

    if (response.data.errors) {
      let errors = {...response.data.errors};

      response.data.errors.forEach(el => {
        errors[el.field] = el.defaultMessage;
      });

      return errors;
    }

    if (response.data.message) {
      return {error: response.data.message}
    }

    return {...response.data}
  }

  /**
   * Clear errors on keydown.
   *
   * @param {KeyboardEvent} event
   */
  onKeydown(event) {
    if (event.target.name) {
      this.errors.clear(event.target.name)
    }
  }
}

Form.errorMessage = 'Something went wrong. Please try again.'
Form.ignore = ['busy', 'successful', 'errors', 'originalData']

export default Form
