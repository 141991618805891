var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-title', [_vm._v(" Список сотрудников "), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.forceUpdate
    }
  }, [_c('v-icon', [_vm._v("mdi-reload")])], 1), _c('v-spacer'), _c('div', [_c('v-btn', {
    attrs: {
      "large": "",
      "color": "green",
      "to": "/users/create"
    }
  }, [_vm._v(" Добавить сотрудника ")])], 1)], 1), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "fixedHeader": "",
      "height": _vm.$helper.calcTableHeight(),
      "headers": _vm.headers,
      "items": _vm.users,
      "options": _vm.userGridOptions.options,
      "server-items-length": _vm.totalElements,
      "item-key": "id",
      "loading": _vm.loading,
      "loading-text": "Загрузка ...",
      "footer-props": {
        'items-per-page-options': [20, 30, 50]
      }
    },
    on: {
      "update:options": function updateOptions($event) {
        return _vm.$set(_vm.userGridOptions, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var items = _ref.items;
        return [_c('tbody', [_c('tr', {
          staticClass: "row_sticky"
        }, [_c('td', [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.userGridOptions.filter.id,
            callback: function callback($$v) {
              _vm.$set(_vm.userGridOptions.filter, "id", $$v);
            },
            expression: "userGridOptions.filter.id"
          }
        })], 1), _c('td', {
          attrs: {
            "width": "20"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.checkboxValues,
            "clearable": "",
            "item-value": "value",
            "item-text": "text"
          },
          model: {
            value: _vm.userGridOptions.filter.active,
            callback: function callback($$v) {
              _vm.$set(_vm.userGridOptions.filter, "active", $$v);
            },
            expression: "userGridOptions.filter.active"
          }
        })], 1), _c('td'), _c('td', [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.userGridOptions.filter.firstName,
            callback: function callback($$v) {
              _vm.$set(_vm.userGridOptions.filter, "firstName", $$v);
            },
            expression: "userGridOptions.filter.firstName"
          }
        })], 1), _c('td', [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.userGridOptions.filter.lastName,
            callback: function callback($$v) {
              _vm.$set(_vm.userGridOptions.filter, "lastName", $$v);
            },
            expression: "userGridOptions.filter.lastName"
          }
        })], 1), _c('td', [_c('v-text-field', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.userGridOptions.filter.phone,
            callback: function callback($$v) {
              _vm.$set(_vm.userGridOptions.filter, "phone", $$v);
            },
            expression: "userGridOptions.filter.phone"
          }
        })], 1), _c('td', [_c('DateField', {
          attrs: {
            "clearable": ""
          },
          model: {
            value: _vm.userGridOptions.filter.birthday,
            callback: function callback($$v) {
              _vm.$set(_vm.userGridOptions.filter, "birthday", $$v);
            },
            expression: "userGridOptions.filter.birthday"
          }
        })], 1), _c('td', [_c('v-select', {
          attrs: {
            "multiple": "",
            "items": _vm.roles,
            "clearable": ""
          },
          model: {
            value: _vm.userGridOptions.filter.roles,
            callback: function callback($$v) {
              _vm.$set(_vm.userGridOptions.filter, "roles", $$v);
            },
            expression: "userGridOptions.filter.roles"
          }
        })], 1), _c('td', [_c('v-select', {
          attrs: {
            "items": _vm.checkboxValues,
            "clearable": "",
            "item-value": "value",
            "item-text": "text"
          },
          model: {
            value: _vm.userGridOptions.filter.outOfPlace,
            callback: function callback($$v) {
              _vm.$set(_vm.userGridOptions.filter, "outOfPlace", $$v);
            },
            expression: "userGridOptions.filter.outOfPlace"
          }
        })], 1), _c('td')]), _vm._l(items, function (item) {
          return _c('tr', {
            key: item.id
          }, [_c('td', {
            attrs: {
              "width": "60"
            }
          }, [_vm._v(_vm._s(item.id))]), _c('td', [item.active ? _c('v-icon', {
            attrs: {
              "small": "",
              "color": "green"
            }
          }, [_vm._v(" mdi-check ")]) : _vm._e()], 1), _c('td', {
            attrs: {
              "width": "60"
            }
          }, [_vm._v(" " + _vm._s(_vm.$moment(item.createdAt).dateFormat()) + " ")]), _c('td', {
            attrs: {
              "width": "60"
            }
          }, [_vm._v(" " + _vm._s(item.firstName) + " ")]), _c('td', {
            attrs: {
              "width": "60"
            }
          }, [_vm._v(" " + _vm._s(item.lastName) + " ")]), _c('td', {
            attrs: {
              "width": "100"
            }
          }, [_vm._v(" " + _vm._s(item.phone) + " ")]), _c('td', {
            attrs: {
              "width": "60"
            }
          }, [_vm._v(" " + _vm._s(_vm.$moment(item.birthday).dateFormat()) + " ")]), _c('td', {
            attrs: {
              "width": "60"
            }
          }, [_vm._v(" " + _vm._s(item.roleDesc) + " ")]), _c('td', {
            attrs: {
              "width": "60"
            }
          }, [_c('v-switch', {
            attrs: {
              "disabled": _vm.$helper.permission.canSetOutOfPlace(_vm.user),
              "hide-details": ""
            },
            on: {
              "change": function change($event) {
                return _vm.changeOutOfOffice(item.id, item.outOfPlace);
              }
            },
            model: {
              value: item.outOfPlace,
              callback: function callback($$v) {
                _vm.$set(item, "outOfPlace", $$v);
              },
              expression: "item.outOfPlace"
            }
          })], 1), _c('td', {
            attrs: {
              "width": "60"
            }
          }, [_vm.$helper.permission.canEditUsers(_vm.user) ? _c('div', [_c('v-icon', {
            attrs: {
              "small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.$router.push("/users/".concat(item.id, "/edit"));
              }
            }
          }, [_vm._v("mdi-pencil")]), _c('v-dialog', {
            attrs: {
              "max-width": "320"
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function fn(_ref2) {
                var on = _ref2.on;
                return [_c('v-icon', _vm._g({
                  staticClass: "ml-4",
                  attrs: {
                    "small": "",
                    "color": "red"
                  }
                }, on), [_vm._v("mdi-delete")])];
              }
            }], null, true),
            model: {
              value: _vm.deleteDialog[item.id],
              callback: function callback($$v) {
                _vm.$set(_vm.deleteDialog, item.id, $$v);
              },
              expression: "deleteDialog[item.id]"
            }
          }, [_c('v-card', [_c('v-card-text', {
            staticClass: "text-center pt-7"
          }, [_vm._v("Удалить выбранный элемент?")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
            attrs: {
              "text": ""
            },
            on: {
              "click": function click($event) {
                _vm.deleteDialog[item.id] = false;
              }
            }
          }, [_vm._v("Отмена")]), _c('v-btn', {
            attrs: {
              "color": "red",
              "text": ""
            },
            on: {
              "click": function click($event) {
                return _vm.deleteItem(item.id);
              }
            }
          }, [_vm._v("Удалить")])], 1)], 1)], 1)], 1) : _vm._e()])]);
        })], 2)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }