var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "500px"
    },
    model: {
      value: _vm.intDialogVisible,
      callback: function callback($$v) {
        _vm.intDialogVisible = $$v;
      },
      expression: "intDialogVisible"
    }
  }, [_c('v-card', [_c('v-card-title', [!_vm.id ? _c('span', {
    staticClass: "headline"
  }, [_vm._v("Добавить напоминание")]) : _vm._e(), _vm.id ? _c('span', {
    staticClass: "headline"
  }, [_vm._v("Редактировать напоминание")]) : _vm._e()]), _vm.loading ? _c('div', {
    staticClass: "pa-5"
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "paragraph, button"
    }
  })], 1) : _vm._e(), !_vm.loading ? _c('div', {
    staticClass: "px-3"
  }, [_c('v-card-text', [_vm.form.requestId ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.requestId,
      expression: "form.requestId"
    }],
    attrs: {
      "type": "hidden"
    },
    domProps: {
      "value": _vm.form.requestId
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "requestId", $event.target.value);
      }
    }
  }) : _vm._e(), _vm.form.requestId ? _c('v-row', [_c('v-col', [_c('router-link', {
    attrs: {
      "to": "/requests/".concat(_vm.form.requestId, "/edit")
    }
  }, [_vm._v(" Заявка #" + _vm._s(_vm.form.requestId) + " ")])], 1)], 1) : _vm._e(), _vm.form.clientId ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.clientId,
      expression: "form.clientId"
    }],
    attrs: {
      "type": "hidden"
    },
    domProps: {
      "value": _vm.form.clientId
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "clientId", $event.target.value);
      }
    }
  }) : _vm._e(), _vm.form.clientId ? _c('v-row', [_c('v-col', [_c('router-link', {
    attrs: {
      "to": "/clients/".concat(_vm.form.clientId, "/edit")
    }
  }, [_vm._v(" Клиент #" + _vm._s(_vm.form.clientId) + " ")])], 1)], 1) : _vm._e(), _vm.form.contractId ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.contractId,
      expression: "form.contractId"
    }],
    attrs: {
      "type": "hidden"
    },
    domProps: {
      "value": _vm.form.contractId
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "contractId", $event.target.value);
      }
    }
  }) : _vm._e(), _vm.form.contractId ? _c('v-row', [_c('v-col', [_c('router-link', {
    attrs: {
      "to": "/contracts/".concat(_vm.form.contractId, "/edit")
    }
  }, [_vm._v(" Договор #" + _vm._s(_vm.form.contractId) + " ")])], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Заголовок"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _vm.form.errors.has('name') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(_vm._s(_vm.form.errors.get('name')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-dialog', {
    ref: "dateDialog",
    attrs: {
      "return-value": _vm.form.date,
      "persistent": "",
      "width": "290px"
    },
    on: {
      "update:returnValue": function updateReturnValue($event) {
        return _vm.$set(_vm.form, "date", $event);
      },
      "update:return-value": function updateReturnValue($event) {
        return _vm.$set(_vm.form, "date", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "Дата",
            "prepend-icon": "mdi-calendar-month-outline",
            "readonly": ""
          },
          model: {
            value: _vm.form.date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "date", $$v);
            },
            expression: "form.date"
          }
        }, on))];
      }
    }], null, false, 2216355392),
    model: {
      value: _vm.dateDialog,
      callback: function callback($$v) {
        _vm.dateDialog = $$v;
      },
      expression: "dateDialog"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "type": "date",
      "scrollable": "",
      "first-day-of-week": "1",
      "min": new Date().toISOString().slice(0, 10)
    },
    model: {
      value: _vm.form.date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "date", $$v);
      },
      expression: "form.date"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.dateDialog = false;
      }
    }
  }, [_vm._v("Отмена")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.dateDialog.save(_vm.form.date);
      }
    }
  }, [_vm._v(" OK ")])], 1)], 1), _vm.form.errors.has('date') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(_vm._s(_vm.form.errors.get('date')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-dialog', {
    ref: "timeDialog",
    attrs: {
      "return-value": _vm.form.time,
      "persistent": "",
      "width": "290px"
    },
    on: {
      "update:returnValue": function updateReturnValue($event) {
        return _vm.$set(_vm.form, "time", $event);
      },
      "update:return-value": function updateReturnValue($event) {
        return _vm.$set(_vm.form, "time", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "Время",
            "prepend-icon": "mdi-alarm-check",
            "readonly": ""
          },
          model: {
            value: _vm.form.time,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "time", $$v);
            },
            expression: "form.time"
          }
        }, on))];
      }
    }], null, false, 12040505),
    model: {
      value: _vm.timeDialog,
      callback: function callback($$v) {
        _vm.timeDialog = $$v;
      },
      expression: "timeDialog"
    }
  }, [_c('v-time-picker', {
    attrs: {
      "format": "24hr",
      "scrollable": ""
    },
    model: {
      value: _vm.form.time,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "time", $$v);
      },
      expression: "form.time"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.timeDialog = false;
      }
    }
  }, [_vm._v("Отмена")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.timeDialog.save(_vm.form.time);
      }
    }
  }, [_vm._v(" OK ")])], 1)], 1), _vm.form.errors.has('time') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(" " + _vm._s(_vm.form.errors.get('time')) + " ")]) : _vm._e()], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Описание"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  }), _vm.form.errors.has('description') ? _c('v-alert', {
    attrs: {
      "dense": "",
      "outlined": "",
      "type": "error",
      "icon": false
    }
  }, [_vm._v(_vm._s(_vm.form.errors.get('description')) + " ")]) : _vm._e()], 1)], 1)], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.id ? _vm.update() : _vm.save();
      }
    }
  }, [_vm._v("Сохранить")]), _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.intDialogVisible = false;
      }
    }
  }, [_vm._v("Отмена")]), _c('v-spacer'), _vm.id ? _c('v-btn', {
    attrs: {
      "color": "red darken-1",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.deleteReminder();
      }
    }
  }, [_vm._v("Удалить")]) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }