<template>
    <div class="panel__notification" v-bind:class="{ expanded: isFiltered() }">
        <v-card-title class="text-h6 panel-header__fixed">
            <v-row no-gutters style="width: 100%">
                <v-col sm="auto">
                    Уведомления {{ notificationSize }}
                </v-col>
                <v-spacer></v-spacer>
                <v-col sm="auto">
                    <v-btn text color="error" @click="$emit('closeNotificationPanel')" icon>
                        <v-icon large>mdi-close-circle-outline</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <div style="width: 100%">
                <v-dialog
                    ref="dateDialog"
                    v-model="dialog.createdDates.modal"
                    :return-value.sync="filterForm.createdAtDates"
                    persistent width="290px">
                    <template v-slot:activator="{ on }">
                        <v-text-field
                                v-model="filterForm.createdAtDates"
                                label="Дата"
                                prepend-icon="mdi-calendar-month-outline"
                                readonly
                                clearable
                                v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="dialog.createdDates.model"
                        type="date"
                        scrollable
                        range
                        first-day-of-week="1"
                    >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="dialog.createdDates.modal = false">Отмена</v-btn>
                        <v-btn text color="primary" @click="$refs.dateDialog.save(dialog.createdDates.model)">OK
                        </v-btn>
                    </v-date-picker>
                </v-dialog>
            </div>
            <v-row class="panel__notification-filter-checkbox">
                <v-col sm="auto">
                    <v-checkbox v-model="filterForm.notificationableTypes" label="Уведомления" value="Contract" multiple hide-details></v-checkbox>
                </v-col>
                <v-col sm="auto">
                    <v-checkbox v-model="filterForm.notificationableTypes" label="Комментарии" value="Comment" multiple hide-details></v-checkbox>
                </v-col>
                <v-col sm="auto">
                    <v-checkbox v-model="filterForm.notificationableTypes" label="Напоминания" value="Reminder" multiple hide-details></v-checkbox>
                </v-col>
            </v-row>
            <v-expansion-panels
                v-if="isFiltered()"
                class="filter-expansion">
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Фильтр
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row style="margin-bottom: -30px">
                            <v-col sm="12">
                                <v-autocomplete
                                    style="z-index: 99"
                                    label="Автор"
                                    v-model="filterForm.user"
                                    :items="users"
                                    item-value="id"
                                    item-text="fullName"
                                    :clearable="true">
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row
                            style="margin-bottom: -30px"
                            v-if="isNotificationSelected()"
                        >
                            <v-col sm="12">
                                <v-autocomplete
                                    style="z-index: 99"
                                    label="Тип уведомления"
                                    multiple
                                    v-model="filterForm.notificationTypes"
                                    :items="notificationTypes"
                                    :clearable="true">
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row style="margin-bottom: -30px" v-if="isCommentSelected()">
                            <v-col sm="12">
                                <v-select
                                    style="z-index: 99"
                                    label="Тип комментария"
                                    multiple
                                    v-model="filterForm.commentTypes"
                                    :items="commentTypes"
                                    :clearable="true">
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card-title>
        <v-card-text>
            <div
                v-for="notification in notifications"
                :key="notification.notificationId"
                class="flash__message"
                v-bind:class="notification.colorClass"
            >
                <div class="flash__message-content">
                    <div
                        v-if="notification.type && notification.type.value !== 'DEFAULT'"
                        class="subtitle-1"
                    >
                        <strong>{{ notification.type.text }}</strong>
                    </div>
                    <strong class="subtitle-2" v-html="notification.title"></strong><br>
                    <div v-if="notification.subTitle">
                        <strong class="subtitle-2" v-html="notification.subTitle"></strong><br>
                    </div>
                    <a :href="notification.url" class="linkTo" @click="$emit('closeNotificationPanel')">Открыть #{{ notification.id }}</a> <br>
                    {{ $helper.format.dateTime(notification.createdAt) }}<br>
                    <div
                        class="flash__message-content-body"
                        v-html="notification.body">
                    </div>
                </div>
                <button type="button" class="flash__close-button" @click="deleteNotification(notification.notificationId)"><span>×</span></button>
            </div>
        </v-card-text>
        <v-skeleton-loader
            v-bind="skeletonAttrs"
            v-if="moreDataToAvailable"
            v-intersect="loadNextPage"
            type="article"
            @forceUpdate="loaderKey++"
            :key="loaderKey">
        </v-skeleton-loader>
    </div>
</template>

<script>
    import NotificationService from "../_services/NotificationService";
    import {mapState} from 'vuex';

    export default {
        components: {},
        props: {},
        data() {
            return {
                loading: true,
                dialog: {
                    createdDates: {
                        model: [],
                        modal: false
                    },
                },
                filters: {
                  type: {
                      contract: false,
                      comment: false
                  }
                },
                notifications: [],
                skeletonAttrs: {
                    class: 'mb-3'
                },
                pageOptions: {
                    totalElements: 100,
                    page: 1,
                    itemsPerPage: 15,
                    sortBy: ['createdAt'],
                    sortDesc: [true]
                },
                notificationTypes: [],
                commentTypes: [],
                users: [],
                loaderKey: 0
            }
        },
        watch: {
            filterForm: {
                handler() {
                    if (!this.isNotificationSelected()) {
                        delete this.filterForm.notificationType;
                    }
                    if (!this.isCommentSelected()) {
                        delete this.filterForm.commentType;
                    }
                    this.fetch();
                },
                deep: true
            }
        },
        methods: {
            fetchInitData() {
                NotificationService.getInitData().then(data => {
                    this.notificationTypes = data.notificationTypes;
                    this.commentTypes = data.commentTypes;
                    this.users = data.users;
                    this.loading = false;
                });
            },
            fetch() {
                this.pageOptions = {
                    totalElements: 100,
                    page: 1,
                    itemsPerPage: 15,
                    sortBy: ['createdAt'],
                    sortDesc: [true],
                    loading: true,
                };
                this.notifications = [];
                this.loaderKey++;
            },
            loadNextPage() {
                NotificationService.getUserNotifications(this.pageOptions, this.filterForm).then((page) => {
                    page.content.forEach((notification) => this.notifications.push(notification));
                    this.pageOptions.totalElements = page.totalElements;
                    this.pageOptions.page = this.pageOptions.page + 1;
                    this.pageOptions.loading = false;
                });
            },
            deleteNotification(id) {
                NotificationService.deleteUserNotification(id).then(() => {
                    this.fetch();
                });
            },
            isNotificationSelected() {
                return this.filterForm.notificationableTypes == 'Contract' && !this.loading;
            },
            isCommentSelected() {
                return this.filterForm.notificationableTypes == 'Comment' && !this.loading;
            },
            isFiltered() {
                const notificationableTypes = this.filterForm?.notificationableTypes || '';
                return this.isNotificationSelected()
                    || this.isCommentSelected()
                    || notificationableTypes == 'Contract,Comment';
            }
        },
        computed: {
            ...mapState(['user']),
            filterForm() {
                return this.$store.state.notificationPanelOptions
            },
            moreDataToAvailable() {
                return this.pageOptions.totalElements > (this.pageOptions.itemsPerPage * (this.pageOptions.page - 1));
            },
            notificationSize() {
                return this.pageOptions.loading ? '' : `(${this.pageOptions.totalElements})`;
            }
        },
        created() {
            this.fetchInitData();
        }
    };
</script>

<style lang="scss">
    .notification-panel {
        z-index: 20;
        .v-skeleton-loader__article {
            background: none !important;
        }
    }
    .panel-header__fixed {
        position: absolute;
        width: calc(100% - 22px);
        top: 0;
        z-index: 99;
    }
    .theme--dark .panel-header__fixed {
        background-color: #363636;
    }
    .panel__notification {
        padding: 170px 0 0;
        &.expanded {
            padding: 230px 0 0;
        }
    }
    .panel__notification-filter-checkbox {
        width: 100%;
        margin: -30px 0 -15px 0;
    }
    .filter-expansion {
        margin: 20px 0 0;
        .v-expansion-panel-content {
            .v-expansion-panel-content__wrap {
                margin-top: -35px;
            }
        }
    }
</style>

