var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "display-1"
  }, [_vm._v(_vm._s(_vm.code))]), _c('p', [_vm._v(_vm._s(_vm.message))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }