var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, table-row@3, button"
    }
  })], 1)], 1) : _vm._e(), !_vm.loading ? _c('div', {
    staticClass: "my-10"
  }, [_c('v-card-title', [_vm._v(" События "), _c('v-spacer'), _c('EventDialog', {
    attrs: {
      "contractId": _vm.id,
      "types": _vm.types,
      "executors": _vm.executors
    },
    on: {
      "saved": _vm.onEventAdd
    }
  })], 1), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.events,
      "item-key": "id",
      "loading": _vm.loadingTable,
      "options": _vm.eventGridOptions.options,
      "loading-text": "Загрузка ...",
      "items-per-page": 10
    },
    on: {
      "update:options": function updateOptions($event) {
        return _vm.$set(_vm.eventGridOptions, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var items = _ref.items;
        return [_c('tbody', _vm._l(items, function (item) {
          var _item$type, _item$executor, _item$substitution, _item$user;
          return _c('tr', {
            key: item.id
          }, [_c('td', {
            attrs: {
              "width": "90"
            }
          }, [_c('v-row', [_c('v-col', [_c('EventDialog', {
            attrs: {
              "id": item.id,
              "contractId": item.contract,
              "types": _vm.types,
              "executors": _vm.executors
            },
            on: {
              "saved": _vm.onEventAdd
            }
          })], 1), _c('v-col', [_vm.$helper.permission.canDeleteContract(_vm.user) ? _c('v-dialog', {
            attrs: {
              "max-width": "320"
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function fn(_ref2) {
                var on = _ref2.on;
                return [_c('v-icon', _vm._g({
                  attrs: {
                    "small": "",
                    "color": "red"
                  }
                }, on), [_vm._v("mdi-delete")])];
              }
            }], null, true),
            model: {
              value: _vm.deleteDialog[item.id],
              callback: function callback($$v) {
                _vm.$set(_vm.deleteDialog, item.id, $$v);
              },
              expression: "deleteDialog[item.id]"
            }
          }, [_c('v-card', [_c('v-card-text', {
            staticClass: "text-center pt-7"
          }, [_vm._v("Удалить выбранный элемент?")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
            attrs: {
              "text": ""
            },
            on: {
              "click": function click($event) {
                _vm.deleteDialog[item.id] = false;
              }
            }
          }, [_vm._v("Отмена")]), _c('v-btn', {
            attrs: {
              "color": "red",
              "text": ""
            },
            on: {
              "click": function click($event) {
                return _vm.deleteItem(item.id);
              }
            }
          }, [_vm._v(" Удалить ")])], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('td', {
            attrs: {
              "width": "200"
            }
          }, [_vm._v(" " + _vm._s(_vm.$moment(item.dateTime).dateTimeFormat()) + " ")]), _c('td', {
            attrs: {
              "width": "260"
            }
          }, [item.type ? _c('div', [_vm._v(" " + _vm._s((_item$type = item.type) === null || _item$type === void 0 ? void 0 : _item$type.text) + " ")]) : _vm._e()]), _c('td', [_vm._v(" " + _vm._s(item.comment) + " ")]), _c('td', {
            attrs: {
              "width": "200"
            }
          }, [_vm._v(" " + _vm._s((_item$executor = item.executor) === null || _item$executor === void 0 ? void 0 : _item$executor.fullName) + " ")]), _c('td', {
            attrs: {
              "width": "270"
            }
          }, [_vm._v(" " + _vm._s((_item$substitution = item.substitution) === null || _item$substitution === void 0 ? void 0 : _item$substitution.fullName) + " ")]), _c('td', {
            attrs: {
              "width": "270"
            }
          }, [_vm._v(" " + _vm._s(_vm.$moment(item.createdAt).dateFormat()) + " ")]), _c('td', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.clientName) + " ")]), _c('td', {
            attrs: {
              "width": "310"
            }
          }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$user = item.user) === null || _item$user === void 0 ? void 0 : _item$user.fullName) + " ")])]);
        }), 0)];
      }
    }], null, false, 2731750462)
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }